export class Tree{
    tree
    constructor(tree,jsonData,id = null,callbackAfterSelectNode = null){
        this.tree = tree;
        init(tree,jsonData,id,callbackAfterSelectNode);
    }
    select_node(id = null){
        set_select_node(this.tree,id)    ;
    }   
    get_selected(){
        var id = null;
        var node_selected = $(this.tree).jstree("get_selected");
        if(node_selected.length > 0 ){
          id = parseInt(node_selected[0]);             
        }
        return id;
    }
}
function init(tree,jsonData,id,callbackAfterSelectNode) {
   $(tree).jstree({    
        core: {
           "check_callback": true,
           'data' : jsonData
  
        }
    }).on('loaded.jstree', function(e,data) {loaded(this,e,data,id);
    }).on("select_node.jstree", function (e, data) { 
        if(callbackAfterSelectNode){
            callbackAfterSelectNode(data.node.id)
        }
        
    })
}

function loaded(tree,e,data,id){
    set_select_node(tree,id)
}
function set_select_node(tree,id = null){   
    $(tree).jstree().deselect_all(true);
    if(id){
        $(tree).jstree('select_node', parseInt(id));
        $(tree).jstree("open_node", $(`#${id}`));
       
    }
    else
    {
        var childrens = $(tree).jstree("get_children_dom","#");
        if(childrens.length>0) 
        {
            $(tree).jstree('select_node', childrens[0].id);
            $(tree).jstree("open_node", $(`#${childrens[0].id}`));
        }
    }      
    var node_selected = $(tree).jstree("get_selected");
    var li = tree.querySelector(`li [id='${node_selected[0]}']`)
    if(li){
        li.scrollIntoViewIfNeeded({behavior: 'smooth'});
    }
    
    
}