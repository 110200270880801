export class PDF{
    id
    url
    bytes
    pages
    numPages
    loading = true
    constructor(){        
    }
   
}

function load_annotation() {
    
}