// class create Container contain content of file
import { factory } from '../annotation/factory';
import { loadAnnotations,add_element_annotation } from '../fabric/load_annotation_thumnail';
export class PDFThumnail{   
    constructor(baseView){       
        this.baseView = baseView
    }
    create(){
        this.baseView.viewComponents.thumnailContainer.replaceChildren();          
        this.fabricCanvases = load(this.baseView);
        
    }
    add_annotation(object_annotation,page_index){      
      add_element_annotation(this.fabricCanvases[page_index],object_annotation,this.baseView,page_index)       
    }
    remove_annotation(annotation_id,page_index){     
      var canvas = this.fabricCanvases[page_index];
      canvas.getObjects().forEach((obj) => {
        if(obj.id == annotation_id)
          factory.delete(canvas,obj);  
          return;
      });       
      var elem = this.baseView.viewComponents.thumnailContainer.querySelector(`[id='${annotation_id}']`);
      if(elem){
        elem.remove();
      }
      canvas.renderAll();
    }
    update_annotation(object_annotation,page_index){     
      this.remove_annotation(object_annotation.id,page_index) 
      this.add_annotation(object_annotation,page_index)
    }
    renderPage(pageNumber){
      renderPage(this.baseView.file.pages[pageNumber],this.baseView);
    }
    setPageNumber(pageNumber){         
        var pages = this.baseView.viewComponents.thumnailContainer.querySelectorAll(`.page`);
        
        for (let index = 0; index < pages.length; index++) {
          const element = pages[index];
          element.classList.remove('selected');
        }
        var page = this.baseView.viewComponents.thumnailContainer.querySelector(`#page-${pageNumber}`);
        page.classList.add('selected');
        page.scrollIntoViewIfNeeded();
    }
  
    rotate(page){        
        var div_page = this.baseView.viewComponents.thumnailContainer.querySelector(`#page-${page._pageIndex + 1}`);
        var div_page_after = create_div_page(page,this.baseView);        
        $(div_page).replaceWith(div_page_after);
        var canvas = renderPage(page,this.baseView);
        this.fabricCanvases[page._pageIndex] = canvas;
        this.setPageNumber(page._pageIndex + 1)
    }
}
function renderPage(page,baseView) {
    try{   
      var div_page = baseView.viewComponents.thumnailContainer.querySelector("#page-"+ page.pageNumber);
      var is_data_loaded = div_page.getAttribute("data-loaded");  
      if(is_data_loaded == "true" ) {return;}  
      
      var rotate = (page.rotate + page.viewRotate) % 360;  
      var viewport = page.getViewport({scale: 1,rotation:rotate});  

    var scale_thum = (parseInt(baseView.viewComponents.thumnailContainer.offsetWidth)-32) / viewport.width;

     viewport = page.getViewport({scale: scale_thum,rotation:rotate});   

      div_page.replaceChildren();
      div_page.setAttribute("scale",scale_thum);
      var canvas = create_canvas(viewport,baseView,div_page,page);
      var span = document.createElement("span");
      span.className="thumbnailNumber"
      span.textContent=(page._pageIndex+1);
      div_page.appendChild(span);      
      return canvas;
  }
  catch(error)
  {  
    console.log("Render error page" + page.pageNumber);
    console.log(error.message);
    throw error;
  }
}

function create_canvas(viewport,baseView,div_page,page){
    var width = parseFloat(viewport.width);
    var height = parseFloat(viewport.height);
    var canvas = document.createElement("canvas");  

    var divcanvasWrapper = document.createElement("div");  
    // Set id attribute with page-#{pdf_page_number} format
    divcanvasWrapper.setAttribute("class", "canvasWrapper");  
    divcanvasWrapper.style.width = width + "px";
    divcanvasWrapper.style.height = height + "px";
  
    divcanvasWrapper.appendChild(canvas);
    div_page.appendChild(divcanvasWrapper);
    canvas.height = height;
    canvas.width = width;
  
    var canvasannotation = createElementCanvasannotation(canvas);
    div_page.appendChild(canvasannotation);  

    var annotationLayer = document.createElement("div");
    annotationLayer.setAttribute("class", "annotationLayer");
    div_page.appendChild(annotationLayer);

    const renderContext = {
      annotationMode:  2,
      background: 'rgba(0,0,0,0)',
      get canvasContext() {
        return canvas.getContext('2d');
      },
      viewport: viewport,
    };
    var renderTask = page.render(renderContext); 
    var fabricCanvas = init(canvasannotation,baseView,page._pageIndex);
    renderTask.promise.then(function() {  
      page.getTextContent().then(function(textContent) {
       
        const eventBus = new pdfjsViewer.EventBus();
        const pdfLinkService = new pdfjsViewer.PDFLinkService({
          eventBus,
        });
        
        page.getAnnotations().then(function (annotationsData) {    
          pdfjsLib.AnnotationLayer.render({
            viewport: viewport.clone({ dontFlip: true }),
            div: annotationLayer,
            annotations: annotationsData,
            page: page,
            linkService: pdfLinkService,
            textLayerMode: 2  
          });        
        })     
       
      
      });       
    });
    return fabricCanvas;
      
  }

  function init(input_canvas,baseView,page_index){
    var canvas = new fabric.Canvas(input_canvas,{
      preserveObjectStacking: true
    });   
    
    loadAnnotations(canvas,baseView,page_index); 
    canvas.forEachObject(object => {
      object.selectable = false;
      object.evented = false;
    });
    return canvas;
}

function load(baseView){
    var page,div_page,canvas;
    var array_fabric_canvas = []
    var i;
    for (i = 0; i < baseView.file.pages.length; i++) {  
      page = baseView.file.pages[i];
      div_page = create_div_page(page,baseView);
      baseView.viewComponents.thumnailContainer.appendChild(div_page);
      canvas = renderPage(page,baseView) 
      array_fabric_canvas.push(canvas);
    }
    return array_fabric_canvas;
}
function createElementCanvasannotation(canvas){
    var canvasannotation = canvas.cloneNode();
    canvasannotation.width = canvas.width;
    canvasannotation.height = canvas.height;
    return canvasannotation;
  }

 function get_view_port(page){
   var rotate = (page.rotate + page.viewRotate) % 360;  
   return  page.getViewport({scale: 1,rotation:rotate});    
 }
 function get_scale_thumb(viewPort,baseView){
  return (parseInt(baseView.viewComponents.thumnailContainer.offsetWidth)-32) / viewPort.width;
 }
 function create_div_page(page,baseView) {
  var divPage,width,height;    
    var viewPort = get_view_port(page);      
    var scale_thum = get_scale_thumb(viewPort,baseView);
    width = viewPort.width * scale_thum;
    height= viewPort.height * scale_thum;

    divPage = document.createElement("div");
    divPage.setAttribute("id", "page-" + (page.pageNumber));
    divPage.setAttribute("class", "page");
    divPage.setAttribute("data-page-number", page.pageNumber);
    divPage.setAttribute("aria-label","Page " + (page.pageNumber));
    divPage.setAttribute("style", "position: relative");        
    divPage.style.width = width + 8 + "px";
    divPage.style.height = height + 8 + "px";  
    divPage.addEventListener('click', CanvasClick, false); 
    divPage.addEventListener('touchstart', CanvasClick, false); 
    function CanvasClick(event)    {

      var num = this.getAttribute("data-page-number");
      baseView.eventBus.dispatch("pagechanging", {
          source: this,
          pageNumber: parseInt(num),        
      });   
    }   
    var span = document.createElement("span");
    span.className="thumbnailNumber"
    span.textContent=(page._pageIndex+1);
    divPage.appendChild(span);      
    return divPage;
 }


 function removeAnnotation(canvas,annotation){
  
 }
 function replace(canvas,annotation){
  
 }