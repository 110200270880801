export const ANNOTATION = {
    NONE:0,    
    ADD_TEXT: 1,
    ADD_STAMP: 2,
    FREE_DRAW: 3 ,
    ADD_LINK: 4,
    ADD_IMAGE: 5,
    DRAW_RECTANGLE:6,
    DRAW_ELLIPSE:7,
    DRAW_LINE:8,
    ADD_TEXTBOX:9,
    ADD_TEXT_AREA:10,
    ADD_RADIO:11,
    ADD_CHECKBOX:12,
    ADD_DROPDOWN:13,
    ADD_XMARK:14,
    ADD_CHECKMARK:15,
    ADD_DOT:16,
    SELECT_OBJECT: 17,
    TEXT_HIGHLIGHT:18,
    TEXT_STROKEOUT:19,
    TEXT_UNDERLINE:20, 
    ERASE:21,
    PENCIL:22,
    DRAW_ARROW:23,
    MOVE: 24,
    SCREENSHOT: 25,
    HAND: 26
  };
  export const link_enum = {
    url:0,
    mail:1,
    phone:2,
    page:3
  };
  export const FORM_STATUS = {
    VIEWING:0,
    EDITTING:1,
    SAVING:2
}