// import { Callbacks } from 'jquery';
// import Quill from 'quill';
//import QuillMention from 'quill-mention';
// anhvt fix bugs warning : quill Overwriting modules/mention with ƒ Mention(quill, options) 
import 'quill-mention';

// Quill.register({ 'modules/mention': QuillMention });
$(document).ready(function() {
	if ($("#snow-editor").length > 0) {
		if ($(".form-bulletin").length > 0) {
			var form = document.querySelector('.form-bulletin');
		}
		var quill = new Quill('#snow-editor', {
			theme: 'snow',
			modules: {
				'toolbar': [
					[{ 'font': [] }, { 'size': [] }],
					['bold', 'italic', 'underline', 'strike'],
					[{ 'color': [] }, { 'background': [] }],
					[{ 'script': 'super' }, { 'script': 'sub' }],
					[{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'blockquote', 'code-block'],
					[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
					['direction', { 'align': [] }],
					['link'],
					['clean']
				]
			},
		});
		form.onsubmit = function() {
			var postContentInput = document.querySelector('#bulletin_content');
			postContentInput.value = quill.root.innerHTML;
		};
	}
	if ($("#bulletin_comment_content").length > 0) {
		load_quill_comment();
	}
	initButtonTitle();
});

$(document).on('turbo:load', function() {
  // event keydown Enter submit form
  $("body").delegate('#import_bulletin', 'keydown', function (event) {
		if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_import_bulletin").click();
		}
	});

  $("body").delegate('#export_bulletins', 'keydown', function (event) {
		if (event.key === "Enter") {
      event.preventDefault();
      $("#js-export-bulletins-submit").click();
		}
	});

		formatDataTableBulletin();

		$("#left_bar_bulletin ul a").click(function() {
				// remove classname 'active' from all li who already has classname 'active'
				$("#list_categories_default a.bulletin_active").removeClass("bulletin_active");
				$("#list_bulletin_categories_default a.bulletin_active").removeClass("bulletin_active");
				$("#list_bulletin_categories a.bulletin_active").removeClass("bulletin_active");
				// adding classname 'active' to current click li 
				this.classList.add("bulletin_active");
		});

		// choose start date bulletin
		$('#icon_start_time_bulletin').click(function() {
				if (!$("#content_start_time").hasClass("bg-disabled")) {
						$("#bulletin_start_time").datepicker().focus();
				}
		});
		datepicker("#bulletin_start_time");

		$("#text_starttime_bulletin").click(function() {
				document.getElementById("bulletin_is_publish").click();
		});

		$("#bulletin_is_publish").click(function() {
				var checked_value = this.checked;
				toggle_disabled_bulletin(checked_value, "bulletin_start_time", "#icon_start_time_bulletin", "start_time_hour", "start_time_minutes");
				$("#content_start_time").toggleClass("bg-disabled");
		});

		// choose end date bulletin
		$('#icon_end_time_bulletin').click(function() {
				if (!$(this).hasClass("bg-disabled")) {
						$("#bulletin_end_time").datepicker().focus();
				}
		});
		datepicker("#bulletin_end_time");

		$("#text_endtime_bulletin").click(function() {
				document.getElementById("bulletin_is_finish").click();
		});

		$("#bulletin_is_finish").click(function() {
				var checked_value = this.checked;
				toggle_disabled_bulletin(checked_value, "bulletin_end_time", "#icon_end_time_bulletin", "end_time_hour", "end_time_minutes");
				$("#content_end_time").toggleClass("bg-disabled");
		});

		$("#text_allow_comment").click(function() {
				document.getElementById("bulletin_allow_comment").click();
		});

		$("#bulletin_allow_comment").click(function() {
				var checked_value = this.checked;
				if (checked_value) {
						document.getElementById("bulletin_allow_emotion").checked = true;
						document.getElementById("bulletin_allow_emotion").disabled = false;
						// document.getElementById("direct_type_emotion").disabled = false;
						document.getElementById("listEmotion").disabled = false;
						$("#text_allow_emotion").addClass("hover_text_red").removeClass("disabled-text");
				} else {
						document.getElementById("bulletin_allow_emotion").checked = false;
						document.getElementById("bulletin_allow_emotion").disabled = true;
						// document.getElementById("direct_type_emotion").disabled = true;
						document.getElementById("listEmotion").disabled = true;
						$("#text_allow_emotion").removeClass("hover_text_red").addClass("disabled-text");
				}
		});

		$("#text_allow_emotion").click(function() {
				document.getElementById("bulletin_allow_emotion").click();
		});

		// $("#bulletin_allow_emotion").click(function() {
		// 		var checked_value = this.checked;
		// 		if (checked_value) {
		// 				// document.getElementById("direct_type_emotion").disabled = false;
		// 				// document.getElementById("listEmotion").disabled = false;
		// 		} else {
		// 				// document.getElementById("direct_type_emotion").disabled = true;
		// 				// document.getElementById("listEmotion").disabled = true;
		// 		}
		// });

		// $("#listEmotion").change(function() {
		// 		var emotion_type = $("#listEmotion option:selected").val();
		// 		if (emotion_type == "0") {
		// 				$("#direct_type_emotion").removeClass("d-none");
		// 		} else {
		// 				$("#direct_type_emotion").addClass("d-none");
		// 		}
		// });

		// Initialize Dropzone
		Dropzone.autoDiscover = false;
		$('.dropzone').each(function() {
			let dropzoneControl = $(this)[0].dropzone;
			if (dropzoneControl) {
					dropzoneControl.destroy();
			}
		});

		$(function() {
			//Dropzone bulletin document
			var dropzoneBulletin = $('#myDropzoneBulletin');
			if (dropzoneBulletin.length > 0) {
				$('#myDropzoneBulletin').dropzone({
					url: "/attach_bulletin_document",
					autoProcessQueue: false,
					paramName: "file",
					maxFilesize: 16,
					parallelUploads: 100,
					// addRemoveLinks: true,
					previewTemplate: document.querySelector('#uploadPreviewTemplateBulletin').innerHTML,
					maxFiles: 100,
					acceptedFiles: getListAcceptFileType("default"),
					clickable: true,
					accept: function(file, done) {
						defineIcon(file);
						done();
					},
					init: function() {
						setMessageErrorFile(this.options);
						var isSent = false;
						var myDropzone = this;
						var empty_storage = parseFloat(document.getElementById("empty_storage").value); // unit bytes
						document.getElementById("btnSaveDocumentBulletin").addEventListener("click", function(e) {
							// Make sure that the form isn't actually being sent.
							e.preventDefault();
							e.stopPropagation();
							myDropzone.processQueue();

						});

						this.on("error", function(file, message, xhr) {
							messageErrorFile(file, message);
							defineIcon(file);
							$(file.previewElement).find(".id_file_bulletin").removeClass("id_file_bulletin");
							var count_file_upload = document.getElementsByClassName("id_file_bulletin").length;
							if (count_file_upload == 1) {
									document.getElementById('is_file_upload_document_bulletin').value = 0;
							}
						});

						this.on("addedfile", function(file) {
								document.getElementById('borrow_id_bulletin').style.display = "block";
								document.getElementById('is_file_upload_document_bulletin').value = 1;
								var nameElement = file.previewElement.querySelector("[data-dz-name]");
								nameElement.title = file.name;
						});

						this.on("uploadprogress", function(file, progress, bytesSent) {
							if (file.previewElement) {
								var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
								progressElement.style.width = progress + "%";
								progressElement.querySelector(".progress-text").textContent = progress + "%";
							}
						});

						this.on("success", function(file, progress, bytesSent) {
							isSent = true;
						});

						this.on("removedfile", function(file) {
							// var id_file_normal_schedule = file.previewElement.querySelector('.id_file_normal_schedule');
							var count_file_upload = document.getElementsByClassName("id_file_bulletin").length;
							if (count_file_upload == 1) {
									document.getElementById('is_file_upload_document_bulletin').value = 0;
							}
							empty_storage = empty_storage + file.size;
						});

						this.on("complete", function(file) {
							if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0 && isSent) {
								var bulletin_category = document.getElementById('bulletin_category_selected').value;
								var bulletin_slug = document.getElementById('bulletin_slug').value;
								var page_back = document.getElementById("page_back").value;
								if (page_back == "bulletin_subject") {
									window.location.href = '/show_bulletin_subject?bulletin_category_id=' + bulletin_category + "&bulletin_id=" + bulletin_slug;
								}
								else if (page_back == "show_bulletin") {
									window.location.href = '/bulletins/' + bulletin_slug + '?bulletin_category_id=' + bulletin_category ;
								}
								else {
									window.location.href = '/bulletins?bulletin_category_id=' + bulletin_category;
								}
							}
						});
					}
				});
			}
		});

		// validate before click submit form bulletin
		$("#btnSaveBulletin").click(function() {
				validate_before_submit();
			// document.getElementById("btn_save_bulletin").click();
		});

		$("body").delegate('#btnConfirmSave', 'click', function() {
				window.$("#modal_warning_bulletin").modal("hide");
				document.getElementById("btn_save_bulletin").click();
		});

		$(".delete_file_bulletin").click(function() {
				var id_text_delete = this.id;
				var checkbox_delete = id_text_delete.replace('file_bulletin', 'is_delete_document');
				var content_file = id_text_delete.replace('file_bulletin', 'content_bulletin_document');
				if (document.getElementById(checkbox_delete).checked) {
					document.getElementById(checkbox_delete).checked = false;
					document.getElementById(content_file).classList.remove('checked_delete_bulletin')
					document.getElementById(content_file).classList.add('border');
				} else {
					document.getElementById(checkbox_delete).checked = true;
					document.getElementById(content_file).classList.add('checked_delete_bulletin')
					document.getElementById(content_file).classList.remove('border');
				}
		});

		$("#btnSaveCommentBulletin").click(function() {
				if (isQuillEmpty($("#bulletin_comment_content").val())) {
						$("#content_warning").text(I18n.t('bulletin_comments.message_warning.comment_invalid')); // 
						window.$("#modal_error_bulletin").modal('show');
				} else {
						var content_comment = window.$("#bulletin_comment_content").val();
						var doc = new DOMParser().parseFromString(content_comment, "text/html");
						var list_mention_user = doc.getElementsByClassName("mention");
						if (list_mention_user.length > 0) {
								var arr_mention_user = [];
								$.each(list_mention_user, function(index, elem) {
										arr_mention_user.push($(elem).data("id"));
								});
								document.getElementById("bulletin_comment_mention_user").value = arr_mention_user.toString();
						}
						document.getElementById("btn_save_comment_bulletin").click();
				}
		});

		$("body").delegate('#btnCancelReply', 'click', function() {
				document.getElementById("bulletin_comment_reply_comment_id").value = "";
				$('.bulletin_reply_comment').parent().remove();
				initElement();
				$("#bulletin_comment_content").val('<p><br></p>');
		});

		function initElement() {
				let element = document.getElementsByClassName("ql-editor");
				element[0].innerHTML = "";
		}

		// choose date delete multi bulletin
		$('#icon_date_delete_bulletin').click(function() {
				$("#date_delete_bulletin").datepicker().focus();
		});
		datepicker("#date_delete_bulletin");

});

function isQuillEmpty(value) {
		if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
				return true;
		}
		return false;
}

window.load_quill_comment = function() {
		var quillEditor = new Quill('#editor-container', {
				modules: {
						toolbar: [
								[{ 'font': [] }, { 'size': [] }],
								['bold', 'italic', 'underline', 'strike'],
								[{ 'color': [] }, { 'background': [] }],
								[{ 'script': 'super' }, { 'script': 'sub' }],
								[{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'blockquote', 'code-block'],
								[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
								['direction', { 'align': [] }],
								['link'],
								['clean']
						],
						mention: {
								allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
								mentionDenotationChars: ["@", "#"],
								source: function(searchTerm, renderList, mentionChar) {
										var content_comment = document.querySelector(".ql-editor").innerHTML;
										var doc = new DOMParser().parseFromString(content_comment, "text/html");
										var list_mention_user = doc.getElementsByClassName("mention");
										if (list_mention_user.length > 0) {
												var arr_mention_user = [];
												$.each(list_mention_user, function(index, elem) {
														arr_mention_user.push($(elem).data("id"));
												});
										}
										$.ajax({
												url: '/filter_mention_user',
												type: 'get',
												data: {
														q: searchTerm,
														without_user: arr_mention_user
												},
												async: true //This works but freezes the UI
										}).done(function(values) {
												renderList(values, searchTerm);
										});
								}
						}
				},
				theme: 'snow' // or 'bubble'
		});

		quillEditor.on('text-change', function() {
				$("#bulletin_comment_content").val(quillEditor.root.innerHTML);
		});
}

function validate_before_submit() {
		var bulletin_subject = $("#bulletin_subject").val();
		if (bulletin_subject == "") {
			window.$("#modal_warning_bulletin").modal("show");
		} else {
			document.getElementById("btn_save_bulletin").click();
		}
		
}


function toggle_disabled_bulletin(value_checkbox, id_date_field, id_icon_date, id_hour, id_minutes) {
		if (value_checkbox) {
				document.getElementById(id_date_field).disabled = false;
				document.getElementById(id_hour).disabled = false;
				document.getElementById(id_minutes).disabled = false;
		} else {
				document.getElementById(id_date_field).disabled = true;
				document.getElementById(id_hour).disabled = true;
				document.getElementById(id_minutes).disabled = true;
		}
		$(id_icon_date + " > div").toggleClass("bg-transparent");
}

window.formatDataTableBulletin = function() {
	var tblBulletin = document.getElementsByClassName("tbl_bulletin");
	if (tblBulletin.length > 0) {
		var datatable_custom = window.$('.tbl_bulletin').DataTable({
			'destroy': true,
			'ordering': false,
			drawCallback: function(oSettings) {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
				$(oSettings.nTHead).hide();
			},
			language: {
				paginate: {
					previous: "<i class='mdi mdi-chevron-left'>",
					next: "<i class='mdi mdi-chevron-right'>"
				},
				info: I18n.t('infoCustom'),
				search: I18n.t('searchCustom'),
				emptyTable: I18n.t('emptyTableMessage'),
				lengthMenu : I18n.t('lengthMenuCustom'),
				infoEmpty: I18n.t('infoEmptyCustom')
			},
		});
	}
}