$(document).on('turbo:load', function() {
	datatableMyGroup();

	
});

window.datatableMyGroup = function() {
	var tblMyGroup = document.getElementsByClassName("tbl_my_group");
	if (tblMyGroup.length > 0) {
		window.$('.tbl_my_group').DataTable({
			'destroy': true,
			'stateSave': true,
			'columnDefs': [
				{
					'targets': 0,
					'width': "4%",
					'checkboxes': {
						'selectRow': true
					}
				}
			],
			'select': {
				'style': 'multi'
			},
			'order': [1, 'asc'],
			drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"}},
		});
	}
}