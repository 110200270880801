import { forEach } from "jszip";
import { Uploader } from "./s3/upload";
import { url_helper } from "./supports/url_helper";
Dropzone.autoDiscover = false;
$(document).on('turbo:load', function () {  
  defineDropZone()   ;
  $('#s3_upload').click(function (e) {
    var name = document.getElementById('file'); 
  const partsize = 50 * 1024 * 1024; // 5MB
  const uploaderOptions = {
    bucket: document.body.getAttribute('data-s3-bucket'),
    file: name.files[0],
    baseURL: 'https://l6m599r0q4.execute-api.ap-northeast-1.amazonaws.com/staging',
    chunkSize: partsize,
    threadsQuantity: 30,
    useTransferAcceleration: false 
  }
  const uploader = new Uploader(uploaderOptions)
  uploader.start()    
  this.setAttribute("disabled",true)
});

});
function defineDropZone(){
	// end draw
	$('.dropzone').each(function () {
 
	 let dropzoneControl = $(this)[0].dropzone;
	 if (dropzoneControl) {
		 dropzoneControl.destroy();
	 }
   });
   
   // Pre init code
   $(function() {
	 //Dropzone class
	 var dopzone = document.getElementById('file_information_drop_zone');
	 if(dopzone){
	   $(dopzone).dropzone({
		 url: "/data_folder_files",
		 paramName: "file",
		 maxFilesize: parseInt(dopzone.getAttribute('max-file-size')),
     autoProcessQueue: false,
     parallelUploads: 100,
     timeout: 180000,

		 // addRemoveLinks: true,
		 previewTemplate: document.querySelector('#uploadPreviewTemplate').innerHTML, 
		 maxFiles: 1000,
		 acceptedFiles: getListAcceptFileType("default"),        
		 clickable: true,
     accept: function(file, done) {
      defineIcon(file);
      done();
    },
		 init: function() {
      this.on("sending", function(file, xhr, formData){
        formData.append("folder_id", document.getElementById("id_folder").value);
        formData.append("view_token", document.getElementById("view_token").value);

        // status
            var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
            // progressElement.style.width =  newPercentage + "%";
            progressElement.querySelector(".progress-text").textContent =  "...";
      });		   
      

        var dz = this;
        document.getElementById("btnUploadDataFolderFile").addEventListener("click", function(e) {
          this.setAttribute("disabled",true);
          // dz.processQueue();       getQueuedFiles() 
          processFile(dz.files)
        });
		   this.on("complete", function(file) { 			 
			});      
		   this.on("error", function(file, message, xhr) { 
			 if (xhr == null)
			 {
			   this.removeFile(file); // perhaps not remove on xhr errors
			   // var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
			   // progressElement.querySelector(".progress-text").textContent = message;
			 } 
			 alert(file.name +  message);
			 
		  });
 
			this.on("addedfile", function(file) {
				document.getElementById('borrow_id').style.display="block";
        
			});
			 
			 this.on("success", function(file, responseText) 
			 {   				
        
			
			   });		   
         
       
		 }
	 });
 
	 }
   });
   
 
  }

  let  total_file = 0,success = 0;
  function processFile(files){
    
    total_file = files.length;
    success = 0;
    var folder_id =  url_helper.get_value_params_from_URL('folder_id');
    var view_token = document.getElementById("view_token").value;
    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      defineUploader(file,folder_id,view_token);    
    }
    
  }

  function sleep (time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  function defineUploader(file,folder_id,view_token) {
    var host = window.location.host
    var subdomain = host.split('.')[0];
    const uploaderOptions = {
      bucket: document.body.getAttribute('data-s3-bucket'),
      file: null,
      baseURL: 'https://l6m599r0q4.execute-api.ap-northeast-1.amazonaws.com/staging',
      chunkSize:  50 * 1024 * 1024, // 5MB,
      threadsQuantity: 30,
      useTransferAcceleration: false 
    }
    var uploader = new Uploader(uploaderOptions);
    var path_file = `sanze_datas/${subdomain}/uploads/data_folder_file/${uuidv4()}/${file.name}`
    uploader.set_file(file,path_file);
    uploader.onProgress(({ percentage: newPercentage }) => {
      if(newPercentage === 100){        
        
      }    
      if (uploader.file.previewElement) {
        var progressElement = uploader.file.previewElement.querySelector("[data-dz-uploadprogress]");
        // progressElement.style.width =  newPercentage + "%";
        progressElement.querySelector(".progress-text").textContent = parseInt(newPercentage) + "%";
      }
    })  
    .onSuccess((result) => {     
      save_file(file,path_file,folder_id,view_token); 
      success++;
      if(success == total_file){
          
        // sleep(1500).then(() => {
        //   if (view_token == "") {
        //     window.location.href= window.location.origin  + `/data_folders?data_folder_id=` + folder_id;
        //   } 
        //   else {
        //     window.location.href= window.location.origin  + `/permission_folder_share_outers/` + view_token + `?data_folder_selected=` + folder_id;
        //   }
          
        // });   
           if (view_token == "") {
            window.location.href= window.location.origin  + `/data_folders?folder_id=` + folder_id;
          } 
          else {
            window.location.href= window.location.origin  + `/permission_folder_share_outers/` + view_token + `?data_folder_selected=` + folder_id;
          }            
      }
    })
    .onError((error) => {
      
      console.error(error)
    })
    uploader.start() ;
  }

  function save_file(file,path_file,folder_id,view_token) {
    $.ajax({
      type: 'POST',
      url: window.origin + "/data_folder_files",
      dataType: 'JSON',
      data: {
        filename: file.name,
        filesize: file.size,
        filetype: file.type,
        folder_id: folder_id,
        view_token: view_token,
        path: path_file
      },
      success: function(data, textStatus, jqXHR){    
        
				
        var btnRemove = file.previewElement.querySelector(".deleteFile");
        btnRemove.addEventListener("click",function(){       
        
           if(data.id){
             $.ajax({
               type: 'POST',
               url: window.origin + "/data_folder_files/destroy_file",
               dataType: 'script',
               data: {
                 id_file: data.id
               },
               success: function(data, textStatus, jqXHR){               
               },
               error:function(jqXHR, textStatus, errorThrown){
                 console.log("AJAX Error: #{textStatus}")
               }
             });
           }
        });
      },
      error:function(jqXHR, textStatus, errorThrown){
       console.log("AJAX Error: #{textStatus}")
      }
    });
  }

  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }