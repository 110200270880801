import { FLine } from './line';
export class FArrow extends FLine{  
  _number_of_head = null
  
  constructor(aCoords, options){
    super(aCoords, options);  

    this._number_of_head = options.number_head;
    draw_line_head(this)
  }


  get number_of_head(){
    return this._number_of_head;
  }
  set_end(point){
    if(this._end === point) return;    
    this.end = point;
    update_position_4_line(this);
   
  }
  set number_of_head(number){    
    if(this._number_of_head == number) return;
    this._number_of_head = number;
    draw_line_head(this);   

  }
  setMovedLine(){
    this.line_moved();
    update_position_4_line(this);
  } 

  setMovedHead(){
    this.head_moved();   
    update_position_4_line(this);
  }

  getLinesArrowBeside(){
    return getLinesArrowBeside(this.start, this.end,this.strokeWidth*3);
  }
}

function createLineBeside(coords,arrow) { 
  var options = {
    fill: arrow.fill,
    stroke: arrow.stroke,
    strokeWidth:  arrow.strokeWidth,
    selectable: false,
    hasControls: false,
    hasBorders: false,
    centeredRotation: false,
    centeredScaling: false,
    perPixelTargetFind: true,
    strokeDashArray: arrow.strokeDashArray,
    disableSelect: true,
    opacity: arrow.opacity
  }
  var line = new fabric.Line(coords,options);    
  return line;
}
function update_position_4_line(arrow){  
    var start = {
      x: arrow.head1.getCenterPoint().x - arrow.head1.radius/2,
      y:arrow.head1.getCenterPoint().y - arrow.head1.radius/2
    }
    var end = {
      x: arrow.head2.getCenterPoint().x - arrow.head2.radius/2,
      y:arrow.head2.getCenterPoint().y - arrow.head2.radius/2
    }
    var points = getLinesArrowBeside( start,end,arrow.strokeWidth*3);
    arrow.line1 && arrow.line1.set({ 'x1': points.start[0], 'y1': points.start[1] });   
    arrow.line1 && arrow.line1.set({ 'x2': points.start[2], 'y2': points.start[3] });
    arrow.line1.setCoords();

    arrow.line2 && arrow.line2.set({ 'x1': points.end[0], 'y1': points.end[1] });
    arrow.line2 && arrow.line2.set({ 'x2': points.end[2], 'y2': points.end[3] });    
    arrow.line2.setCoords();

    arrow.line3 && arrow.line3.set({ 'x1': points.startLeft[0], 'y1': points.startLeft[1] });
    arrow.line3 && arrow.line3.set({ 'x2': points.startLeft[2], 'y2': points.startLeft[3] });
    arrow.line3 && arrow.line3.setCoords();

    arrow.line4 && arrow.line4.set({ 'x1': points.endLeft[0], 'y1': points.endLeft[1] });  
    arrow.line4 && arrow.line4.set({'x2': points.endLeft[2], 'y2': points.endLeft[3]});
    arrow.line4 && arrow.line4.setCoords();
}
function draw_line_head(arrow){
  var points = getLinesArrowBeside(arrow.start, arrow.end,arrow.strokeWidth*3);  
  if(arrow._number_of_head == 1){     
    if(!arrow.line1){
      arrow.line1 = createLineBeside(points.start,arrow);
    }
    if(!arrow.line2){
      arrow.line2 = createLineBeside(points.end,arrow);
    }
  }
  if(arrow._number_of_head == 2){
    if(!arrow.line1){
      arrow.line1 = createLineBeside(points.start,arrow);
    }
    if(!arrow.line2){
      arrow.line2 = createLineBeside(points.end,arrow);
    }
    if(!arrow.line3){
      arrow.line3 = createLineBeside(points.startLeft,arrow);
    }
    if(!arrow.line4){
      arrow.line4 = createLineBeside(points.endLeft,arrow);
    }   
  }
}
function getLinesArrowBeside(start, end,headlen){
  var angle = Math.atan2(end.y - start.y, end.x - start.x);  
  var fromx = start.x,fromy = start.y,tox = end.x,toy = end.y;
  var fromxTemp = start.x + (headlen) * Math.cos(angle),
  fromyTemp = start.y + (headlen) * Math.sin(angle);
  var toxTemp = end.x - (headlen) * Math.cos(angle),
    toyTemp = end.y - (headlen) * Math.sin(angle);

  return {start:[toxTemp - (headlen) * Math.cos(angle - Math.PI / 2),toyTemp - (headlen) * Math.sin(angle - Math.PI / 2),tox,toy],
         end :  [ toxTemp - (headlen) * Math.cos(angle + Math.PI / 2), toyTemp - (headlen) * Math.sin(angle + Math.PI / 2),
         tox , toy ],
         startLeft:[fromxTemp + (headlen) * Math.cos(angle - Math.PI / 2),fromyTemp + (headlen) * Math.sin(angle - Math.PI / 2),fromx,fromy],
         endLeft:[fromxTemp - (headlen) * Math.cos(angle - Math.PI / 2),fromyTemp - (headlen) * Math.sin(angle - Math.PI / 2),fromx,fromy]
        }
}