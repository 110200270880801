$(document).on('turbo:load', function () {

   

    $('#iconOTFilterEndDate').click(function () {
        $("#OTFilterEndDate").datepicker().focus();
    });

    $('#iconOTFilterStartDate').click(function () {
        $("#OTFilterStartDate").datepicker().focus();
    });

    $('#iconOTFilterEndDateEdit').click(function () {
        $("#OTFilterEndDateEdit").datepicker().focus();
    });

    $('#iconOTFilterStartDateEdit').click(function () {
        $("#OTFilterStartDateEdit").datepicker().focus();
    });


    $('#OTFilterEndDate').click(function () {
        $("#OTFilterEndDate").datepicker().focus();
    });

    $('#OTFilterStartDate').click(function () {
        $("#OTFilterStartDate").datepicker().focus();
    });

    $('#OTFilterEndDateEdit').click(function () {
        $("#OTFilterEndDateEdit").datepicker().focus();
    });

    $('#OTFilterStartDateEdit').click(function () {
        $("#OTFilterStartDateEdit").datepicker().focus();
    });

    // manager index
    $('#iconEndDateManager').click(function () {
        $("#OTFilterEndDateManager").datepicker().focus();
    });

    $('#iconStartDateManager').click(function () {
        $("#OTFilterStartDateManager").datepicker().focus();
    });

    $('#OTFilterEndDateManager').click(function () {
        $("#OTFilterEndDateManager").datepicker().focus();
    });

    $('#OTFilterStartDateManager').click(function () {
        $("#OTFilterStartDateManager").datepicker().focus();
    });

    // employee index
    $('#iconEndDateEmployee').click(function () {
        $("#OTFilterEndDateEmployee").datepicker().focus();
    });

    $('#iconStartDateEmployee').click(function () {
        $("#OTFilterStartDateEmployee").datepicker().focus();
    });

    $('#OTFilterEndDateEmployee').click(function () {
        $("#OTFilterEndDateEmployee").datepicker().focus();
    });

    $('#OTFilterStartDateEmployee').click(function () {
        $("#OTFilterStartDateEmployee").datepicker().focus();
    });


    //datetimepicker input
    var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth();
    var firstDay = new Date().toLocaleDateString();
    var lastDay = new Date().toLocaleDateString();
    var dayofmonthStart = formatDate(firstDay);
    var dayofmonthEnd = formatDate(lastDay);
    $('.txtStart').val(dayofmonthStart);
    $('.txtEnd').val(dayofmonthEnd);
    // $('#beginDate').add('#endDate').datepicker().focus(function () {
    //     $(".ui-datepicker-next").hide();
    //     $(".ui-datepicker-prev").hide();
    // });
    $("#OTFilterStartDate").datepicker({
        dateFormat: 'yy-mm-dd',
        showMonthAfterYear: true,
        yearSuffix: '年',
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        onSelect: function (selected) {
            $("#OTFilterEndDate").datepicker("option", "minDate", selected)
        }
    });


    $("#OTFilterEndDate").datepicker({
        dateFormat: 'yy-mm-dd',
        showMonthAfterYear: true,
        yearSuffix: '年',
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        onSelect: function (selected) {
            $("#OTFilterStartDate").datepicker("option", "maxDate", selected)
        }
    });

    //datepicker in edit
    $("#OTFilterStartDateEdit").datepicker({
        dateFormat: 'yy-mm-dd',
        showMonthAfterYear: true,
        yearSuffix: '年',
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        onSelect: function (selected) {
            $("#OTFilterEndDateEdit").datepicker("option", "minDate", selected)
        }
    });

    $("#OTFilterEndDateEdit").datepicker({
        dateFormat: 'yy-mm-dd',
        showMonthAfterYear: true,
        yearSuffix: '年',
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        onSelect: function (selected) {
            $("#OTFilterStartDateEdit").datepicker("option", "maxDate", selected)
        }
    });
    
    //datepicker in user list
    $("#OTFilterStartDateEmployee").datepicker({
        dateFormat: 'yy-mm-dd',
        showMonthAfterYear: true,
        yearSuffix: '年',
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        onSelect: function (selected) {
            $("#OTFilterEndDateEmployee").datepicker("option", "minDate", selected)
        }
    });

    $("#OTFilterEndDateEmployee").datepicker({
        dateFormat: 'yy-mm-dd',
        showMonthAfterYear: true,
        yearSuffix: '年',
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        onSelect: function (selected) {
            $("#OTFilterStartDateEmployee").datepicker("option", "maxDate", selected)
        }
    });

    //datepicker in manager
    $("#OTFilterStartDateManager").datepicker({
        dateFormat: 'yy-mm-dd',
        showMonthAfterYear: true,
        yearSuffix: '年',
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        onSelect: function (selected) {
            $("#OTFilterEndDateManager").datepicker("option", "minDate", selected)
        }
    });

    $("#OTFilterEndDateManager").datepicker({
        dateFormat: 'yy-mm-dd',
        showMonthAfterYear: true,
        yearSuffix: '年',
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        onSelect: function (selected) {
            $("#OTFilterStartDateManager").datepicker("option", "maxDate", selected)
        }
    });



    // click button preview
    var seletedDate = moment(new Date());
    var d = new Date(seletedDate);
    var str = $.datepicker.formatDate('yy年mm月', d);
    $(".text_date_admin").text(str);

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('/');
    }

    function daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    function checkTime(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
    // var data = {
    //     hour: 2,
    //     date_register: "2021-08-01"
    // };

    try{
        var tableOT = document.getElementsByClassName("table-ot-list-user");
        if (tableOT.length > 0) {
            window.$('.table-ot-list-user').DataTable({
                'stateSave': true,
                'destroy': true,
                'columnDefs': [
                { "width": "6%", "targets": 0 },
                { "width": "30%", "targets": 1 },
                { "width": "10%", "targets": 2 },
                { "width": "10%", "targets": 3 },
                { "width": "20%", "targets": 4 },
                { "width": "20%", "orderable": false, "targets": 5 }
                ],
                'select': {
                    'style': 'multi'
                },
                // 'order': [0, 'desc'],
                drawCallback: function () {
                    $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
                },
                language: {
                    paginate: {
                        previous: "<i class='mdi mdi-chevron-left'>",
                        next: "<i class='mdi mdi-chevron-right'>"
                    }
                },
            });
        };
        var tblManagerRegisterOt = document.getElementsByClassName("tbl-manager-register-ot");
        if (tblManagerRegisterOt.length > 0) {
            window.$('.tbl-manager-register-ot').DataTable({
                'destroy': true,
                'columnDefs': [
                    {
                        'targets': 0,
                        'width': "5%",
                        'checkboxes': {
                            'selectRow': true
                        }
                    },
                    { "width": "0%", "targets": 1 },
                    { "width": "15%", "targets": 2 },
                    { "width": "30%", "targets": 3 },
                    { "width": "10%", "targets": 4 },
                    { "width": "10%", "targets": 5 },
                    { "width": "15%", "targets": 6 },
                    { "width": "15%", "orderable": false, "targets": 7 },
                ],
                'order': [1, 'desc'],
                drawCallback: function () {
                    $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
                },
                
                language: {
                    paginate: {
                        previous: "<i class='mdi mdi-chevron-left'>",
                        next: "<i class='mdi mdi-chevron-right'>"
                    }
                },
            });
        }
    }
    catch{window.location.reload();}

    

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }
    $('#btnPreviewRegisterOT').click(function () {
        var startDate = $("#OTFilterStartDate").datepicker('getDate');
        var endDate = $("#OTFilterEndDate").datepicker('getDate');
        $.ajax({
            type: 'GET',
            url: "preview_regist_ot",
            dataType: 'script',
            data: {
                start_date: formatDate(startDate),
                end_date: formatDate(endDate),
                ck_monday: $('#ck_monday').is(":checked"),
                ckTue: $('#ck_tuesday').is(":checked"),
                ckWed: $('#ck_wednesday').is(":checked"),
                ckThu: $('#ck_thursday').is(":checked"),
                ckFri: $('#ck_friday').is(":checked"),
                ckSat: $('#ck_saturday').is(":checked"),
                ckSun: $('#ck_sunday').is(":checked"),
                normal_time: txtnormal_time.value,
                weekend_time: txtweekend_time.value
            },
            success: function (data, textStatus, jqXHR) {
                // console.log(data)
                // 
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // console.log("AJAX Error: #{textStatus}")
            }
        })
    });
    $('#btnPreviewEditRegisterOT').click(function () {
        var startDate = $("#OTFilterStartDateEdit").datepicker('getDate');
        var endDate = $("#OTFilterEndDateEdit").datepicker('getDate');
        $("#btn_confirm_edit_register").prop('disabled', false);
        $.ajax({
            type: 'GET',
            url: "preview_edit_regist_ot",
            dataType: 'script',
            data: {
                id_ot_list: $("#ot_list_id").val(),
                start_date: formatDate(startDate),
                end_date: formatDate(endDate),
                ck_monday: $('#ck_monday').is(":checked"),
                ckTue: $('#ck_tuesday').is(":checked"),
                ckWed: $('#ck_wednesday').is(":checked"),
                ckThu: $('#ck_thursday').is(":checked"),
                ckFri: $('#ck_friday').is(":checked"),
                ckSat: $('#ck_saturday').is(":checked"),
                ckSun: $('#ck_sunday').is(":checked"),
                normal_time: txtnormal_time.value,
                weekend_time: txtweekend_time.value
            },
            success: function (data, textStatus, jqXHR) {
                // console.log(data)
                // 
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // console.log("AJAX Error: #{textStatus}")
            }
        })
    });

    $('#btnClear').click(function () {
        $('#ck_monday').prop('checked', false);
        $('#ck_tuesday').prop('checked', false);
        $('#ck_wednesday').prop('checked', false);
        $('#ck_thursday').prop('checked', false);
        $('#ck_friday').prop('checked', false);
        $('#ck_saturday').prop('checked', false);
        $('#ck_sunday').prop('checked', false);
        txtnormal_time.value = 0;
        txtweekend_time.value = 0;

        $('#table_ot tbody').empty();
        $("#label_sum_ot_time").html("残業の申請時間の合計: 0");
    });

    $('#btnClearEdit').click(function () {
        $('#ck_monday').prop('checked', false);
        $('#ck_tuesday').prop('checked', false);
        $('#ck_wednesday').prop('checked', false);
        $('#ck_thursday').prop('checked', false);
        $('#ck_friday').prop('checked', false);
        $('#ck_saturday').prop('checked', false);
        $('#ck_sunday').prop('checked', false);
        txtnormal_time.value = 0;
        txtweekend_time.value = 0;

        $('#table_ot_edit tbody').empty();
        $("#label_sum_ot_time").html("残業の申請時間の合計: 0");
    });
    window.$('#modal_regist_ot').on('shown.bs.modal', function (e) {
        var listRow = $('#table_ot > tbody > tr');
        var i;
        for (i = 0; i < listRow.length; i++) {
            var estimate_in_table = $('#table_ot tr')[listRow[i].rowIndex].cells[3].innerHTML;
            var id_estimate = `ot_list_ot_logs_attributes_${listRow[i].rowIndex - 1}_estimate_time`;
            var estimate_elem = document.getElementById(id_estimate);
            if (estimate_elem) {
                var estimate_in_modal = estimate_elem.value;
                if (estimate_in_table != estimate_in_modal) {
                    estimate_elem.value = estimate_in_table;
                }
            }

        }

    });
    $('#btn_share_submit_report').click(function () {
        var listRow = $('#table_ot tr');
        var i;
        for (i = 1; i < listRow.length; i++) {
            var estimate_in_table = $('#table_ot tr')[listRow[i].rowIndex].cells[3].innerHTML;
            var id_estimate = `ot_list_ot_logs_attributes_${listRow[i].rowIndex - 1}_actual_time`;
            var estimate_elem = document.getElementById(id_estimate);
            if (estimate_elem) {
                var estimate_in_modal = estimate_elem.value;
                if (estimate_in_table != estimate_in_modal) {
                    estimate_elem.value = estimate_in_table;
                }
            }

        }
    });

    $("body").delegate('#lst_department_ot_list', 'change', function () {
        $.ajax({
            type: 'GET',
            url: "filter_users",
            dataType: 'script',
            data: {
                department_id: $("#lst_department_ot_list option:selected").val()
            },
            success: function (data, textStatus, jqXHR) {
                ;
                set_status_button_submit_share();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log("AJAX Error");
            }
        });

    });
    function set_status_button_submit_share() {
        var $option = $("#user_shared_ot_list").find('option:selected');
        //Added with the EDIT
        var value = $option.val();//to get content of "value" attrib
        // var text = $option.text();//to get <option>Text</option> content
        if (value === "" || value === "選択する") {
            document.getElementById("btn_share_submit").disabled = true;
        }
        else {
            document.getElementById("btn_share_submit").disabled = false;
        }
    }

    $("#ck_monday").change(function () {
        if ($(this).val()) {
            $("#btn_confirm_edit_register").prop('disabled', true);
        }
    });

    $("#ck_tuesday").change(function () {
        if ($(this).val()) {
            $("#btn_confirm_edit_register").prop('disabled', true);
        }
    });

    $("#ck_wednesday").change(function () {
        if ($(this).val()) {
            $("#btn_confirm_edit_register").prop('disabled', true);
        }
    });

    $("#ck_thursday").change(function () {
        if ($(this).val()) {
            $("#btn_confirm_edit_register").prop('disabled', true);
        }
    });

    $("#ck_friday").change(function () {
        if ($(this).val()) {
            $("#btn_confirm_edit_register").prop('disabled', true);
        }
    });

    $("#ck_saturday").change(function () {
        if ($(this).val()) {
            $("#btn_confirm_edit_register").prop('disabled', true);
        }
    });

    $("#ck_sunday").change(function () {
        if ($(this).val()) {
            $("#btn_confirm_edit_register").prop('disabled', true);
        }
    });

    $("#txtnormal_time").change(function () {
        if ($(this).val()) {
            $("#btn_confirm_edit_register").prop('disabled', true);
        }
    });

    $("#txtweekend_time").change(function () {
        if ($(this).val()) {
            $("#btn_confirm_edit_register").prop('disabled', true);
        }
    });

    $("body").delegate('#btn-confirm-ot-list', 'click', function () {
        $("#btn-confirm-register-ot").click()
    });

    $("body").delegate('#btn-approve-ot-list', 'click', function () {
        $("#btn-approve-register-ot").click()
    });

    $("body").delegate('#btn-reject-ot-list', 'click', function () {
        $(".btn-reject-ot")[0].click()
    });



    $("body").delegate('#btn-confirm-edit-register', 'click', function () {
        var listRow = $('#table_ot_edit tr');
        if (listRow.length > 1) {
            $("#btn_share_submit_edit").click();
        } else {
            window.$("#modal_alert_edit").modal('show');
        }
    });

    $('#dropdownMenu a').on('click', function(){
      $("#dropdownMenuButton").text($(this).text());
    });

    $('#dropdownMenuManager a').on('click', function(){
      $("#dropdownMenuButtonManager").text($(this).text());
    });

    $('#btnFilterOTRegisterManager').click(function () {
        var startDate = $("#OTFilterStartDateManager").val();
        var endDate = $("#OTFilterEndDateManager").val();
        var state_string = $("#dropdownMenuButtonManager").text();
        var state = 0;
        switch(state_string) {
          case "未許可":
            state = 1;
            break;
          case "許可済":
            state = 2;
            break;
          case "未承認":
            state = 3;
            break;
          case "承認済":
            state = 4;
            break;
          case "リジェクト":
            state = 5;
            break;
          default:
            state = 0;
        }
        $.ajax({
            type: 'GET',
            url: "filter_ot_manager",
            dataType: 'script',
            data: {
                start_date: formatDate(startDate),
                end_date: formatDate(endDate),
                state: state
            },
            success: function (data, textStatus, jqXHR) {
                // console.log(data)
                // 
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // console.log("AJAX Error: #{textStatus}")
            }
        })
    });

    $('#btnFilterOTRegisterEmployee').click(function () {
        var startDate = $("#OTFilterStartDateEmployee").val();
        var endDate = $("#OTFilterEndDateEmployee").val();
        var state_string = $("#dropdownMenuButton").text();
        var state = 0;
        switch(state_string) {
          case "未許可":
            state = 1;
            break;
          case "許可済":
            state = 2;
            break;
          case "未承認":
            state = 3;
            break;
          case "承認済":
            state = 4;
            break;
          case "リジェクト":
            state = 5;
            break;
          default:
            state = 0;
        }
        $.ajax({
            type: 'GET',
            url: "/filter_ot_employee",
            dataType: 'script',
            data: {
                start_date: formatDate(startDate),
                end_date: formatDate(endDate),
                state: state
            },
            success: function (data, textStatus, jqXHR) {
                // console.log(data)
                // 
            },
            error: function (jqXHR, textStatus, errorThrown) {
                // console.log("AJAX Error: #{textStatus}")
            }
        })
    });
    $("body").delegate('#user_shared_ot_list', 'change', function() {
      set_status_button_submit_share();
    });

    $("#btnAcceptMultipleOTRegister").click(function () {
    var lst_selected = [];
    $.each($('.tbl-manager-register-ot tbody tr'), function (i, row) {
      var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
      if (checkBox) {
        id = row.cells[1].innerHTML;
        lst_selected.push(id);
        // lst_selected = parseInt($("input[type='checkbox']:checked")[0].value);
        return;
      }
    });
    if (lst_selected.length == 0) return;
    $.ajax({
      type: 'GET',
      url: "/confirm_accept_ot",
      dataType: 'script',
      data: {
        ot_lists: lst_selected,
      },
      success: function (data, textStatus, jqXHR) {
        window.$("#loadMe").modal('hide');
        // console.log(data);
        

      },
      error: function (jqXHR, textStatus, errorThrown) {
        window.$("#loadMe").modal('hide');
        console.log("AJAX Error: #{textStatus}")
      }
    });
  });

    $(".btn_edit_time_ot").click(function(e) {
        var targ=e.target?e.target:e.srcElement;

        var rowIndex_tbl_ot = $(targ).closest('tr').index();
        
        var date = $('#table_ot_edit tbody tr:eq('+rowIndex_tbl_ot+') td:eq(1)')[0].textContent;
        var time = $('#table_ot_edit tbody tr:eq('+rowIndex_tbl_ot+') td:eq(3)')[0].textContent;

        $.ajax({
            type: 'GET',
            url: "edit_ot_log",
            dataType: 'script',
            data: {
                date: date,
                estimate_time: time
            },
            success: function(data, textStatus, jqXHR){
                
            },
            error:function(jqXHR, textStatus, errorThrown){
                console.log("AJAX Error!")
            }
        });
    });
    $("body").delegate('#btn_delete_time_ot', 'click', function(e) {
        var targ=e.target?e.target:e.srcElement;

        var rowIndex_tbl_ot = $(targ).closest('tr').index();
        var dateInput = $('#table_ot_edit tbody tr:eq('+rowIndex_tbl_ot+') td:eq(1)')[0].textContent;
        var check = false;
        var delete_index = 0;
        $('#table_ot_edit >tbody > tr').each(function() {
            var date = $(this).find("td").eq(1).html();
            if (check)
            {
                $('#table_ot_edit tr')[this.rowIndex].cells[0].innerHTML = delete_index;
                delete_index++;
            }
            if(date==dateInput)
            {
                var dateF = $('#table_ot_edit tbody tr:eq('+rowIndex_tbl_ot+') td:eq(5)')[0].textContent;
                var element = $('.nested-fields > [id ^=ot_list_ot_logs_attributes][id $=date]').each(function() {
                    var date = formatDate(dateF);
                    var date2= formatDate(this.value);
                      if(date === date2)
                          {
                              var parent = $(this).parentNode;
                              var elm = {};
                                var elms = this.parentNode.querySelectorAll(".remove_fields");
                                for (var i = 0; i < elms.length; i++) {
                                        elm = elms[i];
                                        elm.click();
                                    }
                                
                                
                               
                          }                    
                });


                $(this).remove();
                check = true;
                delete_index = $(this).find("td").eq(0).html();
                
               
            }
        
         });
         function formatDate(date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
        
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
        
                return [year, month, day].join('-');
            }
        var total = 0;
         var rowValue = 0;
         $('#table_ot_edit > tbody > tr').each(function() {
            rowValue = $('#table_ot_edit tr')[this.rowIndex].cells[3].innerHTML;
            total += parseFloat(rowValue); 
         });
           $("#label_sum_ot_time").html(`残業の申請時間の合計: ${total}`);
    });

    $(".btn_edit_report").click(function(e) {
        var targ=e.target?e.target:e.srcElement;

        var rowIndex_tbl_ot = $(targ).closest('tr').index();
        
        var date = $('#table_ot tbody tr:eq('+rowIndex_tbl_ot+') td:eq(1)')[0].textContent;
        var time = $('#table_ot tbody tr:eq('+rowIndex_tbl_ot+') td:eq(3)')[0].textContent;

        $.ajax({
            type: 'GET',
            url: "report_ot_log",
            dataType: 'script',
            data: {
                date: date,
                actual_time: time
            },
            success: function(data, textStatus, jqXHR){
                
            },
            error:function(jqXHR, textStatus, errorThrown){
                console.log("AJAX Error!")
            }
        });
    });
});



