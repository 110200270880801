$(document).on('turbo:load', function () {

	$("body").delegate('#btnMoveUpEquipment', 'click', function () {
		$('#tblEquipmentList select :selected').each(function (i, selected) {
			if (!$(this).prev().length) return false;
			$(this).insertBefore($(this).prev());
		});
		$('#tblEquipmentList select').focus();
	});
	$("body").delegate('#btnMoveDownEquipment', 'click', function () {
		$($('#tblEquipmentList select :selected').get().reverse()).each(function (i, selected) {
			if (!$(this).next().length) return false;
			$(this).insertAfter($(this).next());
		});
		$('#tblEquipmentList select').focus();
	});
	$("body").delegate('#btnMoveTopEquipment', 'click', function () {
		var count_equipment = 0;
		$.each($('#tblEquipmentList select :selected'), function (i, row) {
			count_equipment = count_equipment + 1
		});
		if (count_equipment > 0){
			var first_elem = $("#tblEquipmentList option:first");
			if (first_elem.is(':selected')) return true;
			while (!first_elem.prev().length) {
				$('#tblEquipmentList select :selected').each(function (i, selected) {
					if (!$(this).prev().length) return false;
					$(this).insertBefore($(this).prev());
				});
				$('#tblEquipmentList select').focus();
			}
		}
	});
	$("body").delegate('#btnMoveBottomEquipment', 'click', function () {
		var count_equipment = 0;
		$.each($('#tblEquipmentList select :selected'), function (i, row) {
			count_equipment = count_equipment + 1
		});
		if (count_equipment > 0){
			var last_elem = $("#tblEquipmentList option:last");
			if (last_elem.is(':selected')) return true;
			while (!last_elem.next().length) {
				$($('#tblEquipmentList select :selected').get().reverse()).each(function (i, selected) {
					if (!$(this).next().length) return false;
					$(this).insertAfter($(this).next());
				});
				$('#tblEquipmentList select').focus();
			}
		}
	});
	$("body").delegate('#btnRelocateItems', 'click', function () {
		var lst = [];
		$("#lstEquipment option").each(function () {
			lst.push($(this).val());
		});
		category_id = $("#category_id").val();
		$.ajax({
			type: 'PATCH',
			url: "relocated_equipment",
			dataType: 'script',
			data: {
				lst_equipment: lst,
				category_id: category_id
			},
			success: function (data, textStatus, jqXHR) {
				// 
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error");
			}
		});
	});

	$("#btnRelocateEquipment").click(function () {
		var category_id = $("#list_category option:selected").val();
		$.ajax({
			method: 'GET',
			url: "relocate_items_equipment",
			dataType: 'script',
			data: {
				category_id: category_id
			},
			success: function (data, textStatus, jqXHR) {
				// 
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error");
			}
		});
	});

	$("#btnEditEquipment").click(function () {
		var current_category = $("#list_category option:selected").val();
		if(current_category == "")
		{
			current_category = "0";
		}
		var arr_equipment = [];
		$.each($('.tbl-equipment-list tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_equipment.push($(row).data('item-id'));
			}
		});
		if (arr_equipment.length == 1) {
			$.ajax({
				type: 'GET',
				url: "equipments/" + arr_equipment[0] + "/edit",
				dataType: 'script',
				data: {
					id: arr_equipment[0],
					current_category: current_category
				},
				success: function (data, textStatus, jqXHR) {
					// 
				},
				error: function (jqXHR, textStatus, errorThrown) {
					console.log("AJAX Error");
				}
			});
		}
	});
	$("#btnAddEquipment").click(function () {
		var category_id = $("#list_category option:selected").val();
		$.ajax({
			type: 'GET',
			url: "equipments/new",
			dataType: 'script',
			data: {
				category_id: category_id
			},
			success: function (data, textStatus, jqXHR) {
				// 
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error");
			}
		});
	});


	$("body").delegate('#list_category', 'change', function () {
		var category_id = $("#list_category option:selected").val();
		// if (category_id != 0) {
		//     window.location.href = window.location.origin + `/equipments?category_id=${category_id}`;
		// } else {
		//     window.location.href = window.location.origin + `/equipments`;
		// }
		$.ajax({
			url: "check_category",
			method: "GET",
			dataType: "script",
			data: { category_id: category_id },
			success: function (data, textStatus, jqXHR) {
				// 
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error");
			}
		});
	});

	var tblEquipmentList = document.getElementsByClassName("tbl-equipment-list");
	if (tblEquipmentList.length > 0) {
		formatDataTableEquipment();
	};

	$("#btnDeleteEquipment").click(function () {
		var arr_equipment = [];
		var category_id = $("#list_category option:selected").val();
		$.each($('.tbl-equipment-list tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_equipment.push($(row).data('item-id'));
			}
		});

		if (arr_equipment.length > 0) {
			$.ajax({
				type: 'GET',
				url: "confirm_destroy_selected_items_equipment",
				dataType: 'script',
				data: {
					id: arr_equipment,
					category_id: category_id
				},
				success: function (data, textStatus, jqXHR) {
					// 
				},
				error: function (jqXHR, textStatus, errorThrown) {
					console.log("AJAX Error");
				}
			});
		}

	});

  $("#file_equipment").change(function() {
	 
	window.$("#loadMe").modal({
	  backdrop: "static", //remove ability to close modal with click
	  keyboard: false, //remove option to close with keyboard
	  show: true //Display loader!
	});
	setTimeout(function() {
	  $("#close_loading").click();
	}, 100000);

	var elem = document.getElementById('form_equipment') // or $('#myform')[0] with jQuery
	Rails.fire(elem, 'submit');
   });
});

window.formatDataTableEquipment = function() {
	window.$('.tbl-equipment-list').DataTable({
			'destroy': true,
			'columnDefs': [
				{
					'targets': 0,
					'width': "4%",
					'checkboxes': {
						'selectRow': true
					}
				},
				{ "width": "30%", "targets": 1 },
				{ "width": "64%", "targets": 2 },
			],
			'select': {
				'style': 'multi'
			},
			drawCallback: function () {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language: {
				paginate: {
					previous: "<i class='mdi mdi-chevron-left'>",
					next: "<i class='mdi mdi-chevron-right'>"
				}
			},
		});
}
