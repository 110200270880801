import { TYPE_STAMP } from "../common"

const dateConfig = {
    fontSize: 18,
    format:  'YYYY/MM/DD',
    color:  '#FF0000',
    x_relative: 0,
    y_relative: -100
}

const personalConfig = {
    type: TYPE_STAMP.PERSONAL,
    size: 32,
    has_date: false,
    date: dateConfig
}

const managerNoDateConfig = {
    type: TYPE_STAMP.MANAGER_NODATE,
    size: 32,
    has_date: false,
    date: dateConfig
}

const managerHasDateConfig = {
    type: TYPE_STAMP.MANAGER_HAS_DATE,
    size: 48,
    has_date: false,
    date: dateConfig
}

const companyConfig = {
    type: TYPE_STAMP.COMPANY,
    size: 64,
    has_date: false,
    date: dateConfig
}

const signalConfig = {
    type: TYPE_STAMP.SIGNAL,
    size: 34.7073,
    has_date: false,
    date: dateConfig
}
const autoCardConfig = {
    type: TYPE_STAMP.AUTOCARD,
    size: 100,
    has_date: false,
    date: dateConfig
}
const StampConfig = {
    personal: personalConfig,
    manager_has_date: managerHasDateConfig,
    manager_no_date: managerNoDateConfig,
    company: companyConfig,
    signal: signalConfig,
    autocard: autoCardConfig
}
export {StampConfig};


