$(document).on('turbo:load', function () {
	

	// input value auto next to field
	$("body").delegate('.pin_code', 'keyup', function() {
		auto_next_to_field(".pin_code", this)
		var pin_code = $('.pin_code').map((_,el) => el.value).get().join("");
		if (pin_code.length == 6) {
			var mail = document.getElementById("email").value;
			$.ajax({
				type: 'GET',
				url: "/users/check_pin_code",
				dataType: 'script',
				data: {
					email: mail,
					pin_code: pin_code
				},
				success: function (data, textStatus, jqXHR) {

				},
				error: function (jqXHR, textStatus, errorThrown) {
					console.log("AJAX Error")
				}
			});
		}
	});

	$("body").delegate('.txt_pin_code', 'keyup', function() {
		auto_next_to_field(".txt_pin_code", this);
	});

	$("body").delegate('.txt_pin_code', 'keydown', function() {
		var key = event.keyCode;
		auto_prev_to_field(".txt_pin_code", this, key)
	});

	$("body").delegate('.confirm_pin_code', 'keyup', function() {
		auto_next_to_field(".confirm_pin_code", this);
	});

	$("body").delegate('.confirm_pin_code', 'keydown', function() {
		var key = event.keyCode;
		auto_prev_to_field(".confirm_pin_code", this, key)
	});

	$("#btnUpdatePinCode").click(function() {
		var pin_code = $('.txt_pin_code').map((_,el) => el.value).get().join("");
			if (pin_code.length < 6) {
				$("#warning_information").html(I18n.t("users.security_information.error_fill_in_number")).removeClass("d-none");
				$('.txt_pin_code').each( function (i, item) {
					if (item.value == "") {
						item.focus();
						return false;
					}
				});
			}
			else {
				$("#confirm_fill_in_pin_code").removeClass("d-none");
				$("#fill_in_pin_code").addClass("d-none");
				$(".confirm_pin_code")[0].focus();
			}
	});

	$("#btnBackUpdatePinCode").click(function() {
		$("#confirm_fill_in_pin_code").addClass("d-none");
		$("#fill_in_pin_code").removeClass("d-none");
		$("#confirm_pin_code1").focus();
	});

	$("#btnConfirmUpdatePinCode").click(function() {
		var confirm_pin_code = $('.confirm_pin_code').map((_,el) => el.value).get().join("");
		var pin_code = $('.txt_pin_code').map((_,el) => el.value).get().join("");

		if (confirm_pin_code.length < 6) {
			$("#warning_confirm_information").html(I18n.t("users.security_information.error_fill_in_number")).removeClass("d-none");
			$('.confirm_pin_code').each( function (i, item) {
				if (item.value == "") {
					item.focus();
					return false;
				}
			});
		}
		else if (confirm_pin_code.length == 6 && pin_code.length == 6 && (pin_code == confirm_pin_code)) {
				var mail = document.getElementById("email").value;

				$.ajax({
					type: 'GET',
					url: "/users/update_pin_code",
					dataType: 'script',
					data: {
						email: mail,
						pin_code: confirm_pin_code
					},
					success: function (data, textStatus, jqXHR) {

					},
					error: function (jqXHR, textStatus, errorThrown) {
						console.log("AJAX Error")
					}
				});
		}
		else {
			$("#warning_confirm_information").html(I18n.t("users.security_information.pin_code_not_match")).removeClass("d-none");
			$(".confirm_pin_code").val("");
			$("#confirm_pin_code1").focus();
		}
	});
	$("#new_user").submit(function() {
		check_cookie_for_pin_code();
	});

	$("body").delegate('.old_pin_code', 'keyup', function() {
		auto_next_to_field(".old_pin_code", this);
	});

	$("body").delegate('.old_pin_code', 'keydown', function() {
		var key = event.keyCode;
		auto_prev_to_field(".old_pin_code", this, key)
	});

	$("body").delegate('.new_pin_code', 'keyup', function() {
		auto_next_to_field(".new_pin_code", this);
	});

	$("body").delegate('.new_pin_code', 'keydown', function() {
		var key = event.keyCode;
		auto_prev_to_field(".new_pin_code", this, key)
	});

	$("body").delegate('.confirm_new_pin_code', 'keyup', function() {
		auto_next_to_field(".confirm_new_pin_code", this);
	});

	$("body").delegate('.confirm_new_pin_code', 'keydown', function() {
		var key = event.keyCode;
		auto_prev_to_field(".confirm_new_pin_code", this, key)
	});

	$("body").delegate('#btnConfirmOldPinCode', 'click', function() {
		var old_pin_code = $('.old_pin_code').map((_,el) => el.value).get().join("");
		if (old_pin_code.length < 6) {
			$("#warning_old_pin_code").html(I18n.t("users.security_information.error_fill_in_number")).removeClass("d-none");
			$('.old_pin_code').each( function (i, item) {
				if (item.value == "") {
					item.focus();
					return false;
				}
			});
		}
		else {
			$.ajax({
				type: 'GET',
				url: "/users/check_match_pin_code",
				dataType: 'script',
				data: {
					pin_code: old_pin_code
				},
				success: function (data, textStatus, jqXHR) {
					$(".old_pin_code")[0].focus();
				},
				error: function (jqXHR, textStatus, errorThrown) {
					console.log("AJAX Error")
				}
			});
		}
	});

	$("body").delegate('#btnBackOldPinCode', 'click', function() {
		$("#fill_old_pin_code").removeClass("d-none");
		$("#set_new_pin_code").addClass("d-none");
	});

	$("body").delegate('#btnSetNewPinCode', 'click', function() {
		var new_pin_code = $('.new_pin_code').map((_,el) => el.value).get().join("");
		if (new_pin_code.length < 6) {
			$("#warning_new_pin_code").html(I18n.t("users.security_information.error_fill_in_number")).removeClass("d-none");
			$('.new_pin_code').each( function (i, item) {
				if (item.value == "") {
					item.focus();
					return false;
				}
			});
		}
		else {
			$("#set_new_pin_code").addClass("d-none");
			$("#confirm_new_pin_code").removeClass("d-none");
			$("#warning_new_pin_code").html("").addClass("d-none");
			$(".confirm_new_pin_code")[0].focus();
		}
	});

	$("body").delegate('#btnBackNewPinCode', 'click', function() {
		$("#set_new_pin_code").removeClass("d-none");
		$("#confirm_new_pin_code").addClass("d-none");
	});

	$("body").delegate('#btnConfirmNewPinCode', 'click', function() {
		var new_pin_code = $('.new_pin_code').map((_,el) => el.value).get().join("");
		var confirm_pin_code = $('.confirm_new_pin_code').map((_,el) => el.value).get().join("");

		if (confirm_pin_code.length < 6) {
			$("#warning_confirm_pin_code").html(I18n.t("users.security_information.error_fill_in_number")).removeClass("d-none");
			$('.confirm_new_pin_code').each( function (i, item) {
				if (item.value == "") {
					item.focus();
					return false;
				}
			});
		}
		else if (confirm_pin_code.length == 6 && new_pin_code.length == 6 && (new_pin_code == confirm_pin_code)) {
				$.ajax({
					type: 'GET',
					url: "/users/set_up_pin_code",
					dataType: 'script',
					data: {
						pin_code: confirm_pin_code
					},
					success: function (data, textStatus, jqXHR) {
					},
					error: function (jqXHR, textStatus, errorThrown) {
						console.log("AJAX Error")
					}
				});
		}
		else {
			$("#warning_confirm_pin_code").html("PIN code not match").removeClass("d-none");
			$(".confirm_new_pin_code").val("");
			$(".confirm_new_pin_code")[0].focus();
		}
	});

	$("body").delegate('a[href="#tab_set_pin_code"]', 'click', function() {
		$(".old_pin_code")[0].focus();
	});

});

function check_cookie_for_pin_code() {
	if ($("#email_in_cookie").length > 0) {
		var email = document.getElementById("user_email").value;
		var password = document.getElementById("user_password").value;
		var email_in_cookie = false;
		if (email && password) {
			var language = document.getElementById("language_selected").value;
			var all_cookie = document.cookie.split(";");
			all_cookie.forEach(function(item) {
				var item_email = item.split("=")[0].trim();
				if (item_email == email) {
					email_in_cookie = true;
					return false;
				}
			});
		}
		document.getElementById("email_in_cookie").value = email_in_cookie;
	}
}

function auto_next_to_field(class_element, current_element) {
	if ((current_element.value.length == current_element.maxLength) && (!isNaN(parseInt(current_element.value)))) {
			$(current_element).parent().next().children(class_element).focus();
		}
		else {
			$(current_element).val("").focus();
		}
}

function auto_prev_to_field(class_element, current_element, keyCode) {
	if (keyCode == 8) {
		$(current_element).val("");
		$(current_element).parent().prev().children(class_element).focus();
	}
}