export class PageView{
    constructor(viewer,pageNum){
        this.viewer = viewer;
        this.pageNum = pageNum
    }
    get CanvasWrapper() {
        return this.viewer.viewComponents.viewContainer.querySelector(`#page-${this.pageNum}`).querySelector(`.canvasWrapper`);
    }
    get CanvasContainer() {
        return this.viewer.viewComponents.viewContainer.querySelector(`#page-${this.pageNum}`).querySelector(`.canvas-container`);
    }
    get TextLayer() {
        return this.viewer.viewComponents.viewContainer.querySelector(`#page-${this.pageNum}`).querySelector(`.textLayer`);
    }
    get AnnotationLayer() {
        return this.viewer.viewComponents.viewContainer.querySelector(`#page-${this.pageNum}`).querySelector(`.annotationLayer`);
    }
    get FormLayer() {
        return this.viewer.viewComponents.viewContainer.querySelector(`#page-${this.pageNum}`).querySelector(`.formLayer`);
    }    
}
