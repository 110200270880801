$(document).on('turbo:load', function () {

    var tblTempIsoFile = document.getElementsByClassName("tbl-iso-file");
    if (tblTempIsoFile.length > 0) {

        var iso_table = window.$('.tbl-iso-file').DataTable({
            'destroy': true,
            'columnDefs': [
                {
                    'targets': 0,
                    'width': "5%",
                    'checkboxes': {
                        'selectRow': true
                    }
                },
                { "width": "10%", "targets": 1 },
                { "width": "40%", "targets": 2 },
                { "width": "35%", "targets": 3 },
                { "width": "10%", "targets": 4 },
                { "width": "0%", "targets": 5 },
            ],
            'select': {
                'style': 'multi'
            },
            'order': [1, 'asc'],
            drawCallback: function () {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language: {
                paginate: {
                    previous: "<i class='mdi mdi-chevron-left'>",
                    next: "<i class='mdi mdi-chevron-right'>"
                }
            },
            paging: true,
            fixedColumns: {
                leftColumns: 1
            },
        });
    };

    var tblTemp = document.getElementsByClassName("tbl-project-search");
  if (tblTemp.length > 0) {

    window.$('.tbl-project-search').DataTable({
      'destroy': true,
      'columnDefs': [
        { "width": "5%", "targets": 0 },
        { "width": "10%", "targets": 1 },
        { "width": "15%", "targets": 2 },
        { "width": "15%", "targets": 3 },
        { "width": "15%", "targets": 4 },
        { "width": "15%", "targets": 5 },
        { "width": "10%", "targets": 6 },
        { "width": "15%", "targets": 7 },
      ],
      'select': {
        'style': 'multi'
      },
      'order': [0, 'asc'],
      drawCallback: function () {
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      },
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
        }
      },
    });

    $("body").delegate("#btn_add_iso_tag", "click", function(){
        if ($(this).hasClass("btn-light")){
            $(this).removeClass("btn-light");
            $(this).addClass("btn-success");
            $(this).find("i").removeClass("mdi-plus");
            $(this).find("i").addClass("mdi-close");
        }
        else{
            $(this).removeClass("btn-success");
            $(this).addClass("btn-light");
            $(this).find("i").removeClass("mdi-close");
            $(this).find("i").addClass("mdi-plus");
        }
    });

    $("body").delegate("#btn_show_list_tags", "click", function() {
        var hidden_iso_tag_search = $("#hidden_iso_tag_search").val();
        $.ajax ({
            type: 'GET',
            url: "/show_list_tags",
            dataType: 'script',
            data: {
                hidden_iso_tag_search, hidden_iso_tag_search
            },
            success: function(data, textStatus, jqXHR){
                // 
            },
            error:function(jqXHR, textStatus, errorThrown){
                console.log("AJAX Error")
            }
        });
    });

    $("body").delegate("#btn_add_tags","click",function (e){
        var iso_files = document.querySelectorAll("#btn_add_iso_tag");
        var hidden_iso_tag_search = $("#hidden_iso_tag_search").val()
        var list_tags = [];
        for(let i = 0;i< iso_files.length;i++){
          if($(iso_files[i]).hasClass("btn-success")){
            list_tags.push(iso_files[i].textContent.replace(/\n/g, '').trim())
          }
        }
        $.ajax
          ({
            type: 'GET',
            url: "/add_list_iso_file_tag",
            dataType: 'script',
            data: {
              tags: list_tags,
              hidden_iso_tag_search, hidden_iso_tag_search
            },
            success: function (data, textStatus, jqXHR) {
              // console.log(data);
              console.log("AJAX OK!");
              $('#file_with_tags').tokenfield('setTokens', list_tags);
            },
            error: function (jqXHR, textStatus, errorThrown) {
              console.log("AJAX Error: #{textStatus}")
            }
          })
      });
  };

    $(function () {
        var iso_file_note = [];
        // $('.dropzone').each(function () {

        //     let dropzoneControl = $(this)[0].dropzone;
        //     if (dropzoneControl) {
        //         dropzoneControl.destroy();
        //     }
        // });
        var dopzone = $('#dropzone_upload_iso_file');
        if (dopzone.length > 0) {
            $('#dropzone_upload_iso_file').dropzone({
                url: "upload_temp_iso_file",
                paramName: "file",
                maxFilesize: 10,
                previewTemplate: document.querySelector('#uploadPreviewTemplate').innerHTML,

                maxFiles: 100,
                autoDiscover: false,
                clickable: true,
                uploadMultiple: true,
                autoProcessQueue: false,
                parallelUploads: 10,
                timeout: 6000000,


                accept: function (file, done) {
                    switch (file.type) {
                        case 'application/pdf':
                            file.previewElement.querySelector("img").src = '/assets/images/pdf.png';
                            break;
                        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                            file.previewElement.querySelector("img").src = '/assets/images/word.png';
                            break;
                        case 'application/msword':
                            file.previewElement.querySelector("img").src = '/assets/images/word.png';
                            break;
                        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                            file.previewElement.querySelector("img").src = '/assets/images/excel.png';
                            break;
                        case 'application/vnd.ms-excel':
                            file.previewElement.querySelector("img").src = '/assets/images/excel.png';
                            break;
                        case 'applications/ppt':
                            file.previewElement.querySelector("img").src = '/assets/images/powerpoint.png';
                            break;
                        case 'applications/pptx':
                            file.previewElement.querySelector("img").src = '/assets/images/powerpoint.png';
                            break;
                        default:
                            file.previewElement.querySelector("img").src = '/assets/images/file.png';
                            break;
                    }

                    done();
                },
                init: function () {

                    var myDropzone = this;

                    this.on("error", function (file, message, xhr) {
                        if (xhr == null) {
                            this.removeFile(file);
                        }
                        alert(file.name + " upload error : " + message);

                    });

                    // $("body").delegate('#btn_creaate_table_iso_file', 'click', function () {
                    // 	myDropzone.processQueue();
                    // });
                    btnUploadFile = document.querySelector("#btn_creaate_table_iso_file");
                    btnUploadFile.addEventListener("click", function (e) {
                        var iso_file_block = document.getElementsByClassName("iso-file-row");
                        iso_file_block.forEach(function (file) {
                            iso_file_note.push(file.getElementsByClassName("iso-file-note")[0].value);
                        });
                        e.preventDefault();
                        e.stopPropagation();
                        myDropzone.processQueue();
                    });

                    this.on("sending", function (file, xhr, formData) {
                        formData.append("iso_file_note", iso_file_note);
                    });

                    this.on("addedfile", function (file) {
                        document.getElementById('borrow_id').style.display = "block";
                        document.getElementById('dropzone_upload_iso_file').style.display = "block";
                        document.getElementById('dropzone_upload_iso_file').style.minHeight = "min-content";
                    });

                    this.on("success", function (file, responseText) {
                        window.location.href = '/iso_files';
                        var removeButton = file.previewElement.querySelector('#btn_back_iso_file');
                        removeButton.addEventListener("click", function (e) {
                            e.preventDefault();
                            e.stopPropagation();

                        })
                    });
                    this.on("uploadprogress", function (file, progress, bytesSent) {
                        if (file.previewElement) {
                            var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
                            progressElement.style.width = progress + "%";
                            progressElement.querySelector(".progress-text").textContent = progress + "%";
                        }
                    });

                }
            });

        }
    });

    const _btnDeleteIsoFilets = document.querySelector("#btn_delete_iso_file");
    if (_btnDeleteIsoFilets) {
        _btnDeleteIsoFilets.addEventListener("click", function () {
            var mydata = [];
            var iso_table_new = window.$('.tbl-iso-file').DataTable();
            $.each(iso_table_new.rows().nodes(), function (i, row) {
                if ($(row).find("input[class='dt-checkboxes']").is(":checked")) mydata.push($(row).data('item-id'));
            });
            // console.log(mydata);
            if (mydata.length == 0) { return; }
            $.ajax
                ({
                    type: 'GET',
                    url: "confirm_delete_multi_iso_file",
                    dataType: 'script',
                    data: {
                        data: { ids: mydata },
                    },
                    success: function (data, textStatus, jqXHR) {
                        // console.log(data);
                        console.log("AJAX OK!")
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log("AJAX Error: #{textStatus}")
                    }
                })

        });
    }


    $('#iconIsoFileFrom').click(function () {
        $("#start_range_project").datepicker().focus();
    });

    $('#start_range_project').click(function () {
        $("#start_range_project").datepicker().focus();
    });

    $('#iconIsoFileTo').click(function () {
        $("#end_range_project").datepicker().focus();
    });

    $('#end_range_project').click(function () {
        $("#end_range_project").datepicker().focus();
    });

    $("#start_range_project").datepicker({
        dateFormat: 'yy-mm-dd',
        showMonthAfterYear: true,
        yearSuffix: '年',
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        onSelect: function (selected) {
            $("#end_range_project").datepicker("option", "minDate", selected)
        }
    });

    $("#end_range_project").datepicker({
        dateFormat: 'yy-mm-dd',
        showMonthAfterYear: true,
        yearSuffix: '年',
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        monthNames: ['1月', '２月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        onSelect: function (selected) {
            $("#start_range_project").datepicker("option", "maxDate", selected)
        }
    });

    var tblSearchIsoFile = document.getElementsByClassName("stbl-search-result-iso-file");
    if (tblSearchIsoFile.length > 0) {

        window.$('.stbl-search-result-iso-file').DataTable({
        'destroy': true,
        'columnDefs': [
            { "width": "5%", "targets": 0 },
            { "width": "50%", "targets": 1 },
            { "width": "10%", "targets": 2 },
            { "width": "10%", "targets": 3 },
            { "width": "10%", "targets": 4 },
            { "width": "10%", "targets": 5 },
            { "width": "5%", "targets": 6 },
        ],
        'select': {
            'style': 'multi'
        },
        'order': [0, 'asc'],
        drawCallback: function () {
            $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
        },
        fixedColumns: {
            leftColumns: 1
        },
        });
    };

    // var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    // var firstDay = new Date(y, m, 1);
    // var lastDay = new Date(y, m + 1, 0);
    // var dayofmonthStart = formatDate(firstDay);
    // var dayofmonthEnd = formatDate(lastDay);
    // $('.txtIsoFrom').val(dayofmonthStart);
    // $('.txtIsoTo').val(dayofmonthEnd);

    $("body").delegate('#btn_search_in_ISO_module', 'click', function() {
        $('#btn_rsearch_in_ISO_module').click();
    });

    // $("body").delegate('#btn_advanced_project_search_field', 'click', function() {
    //     if ($("#advanced_project_search_field_row").hasClass("d-none")){
    //         $("#advanced_project_search_field_row").removeClass("d-none");
    //     }
    //     else {

    //         $("#advanced_project_search_field_row").addClass("d-none");
    //     }
    // });

    $("body").delegate('#btn_advanced_iso_search_field', 'click', function() {
        $(this).toggleClass("down")  ; 
        if ($("#advanced_iso_search_field_row").hasClass("d-none")){
            $("#text_advanced_search").removeClass("d-none");
            $("#search-title-text").text("どんなファイルをお探しです？");
            $("#advanced_iso_search_field_row").removeClass("d-none");
            $("#search_iso_file_status").val(true);
        }
        else {
            $("#text_advanced_search").addClass("d-none");
            $("#search-title-text").text("どんな工事をお探しです？");
            $("#advanced_iso_search_field_row").addClass("d-none");
            $("#search_iso_file_status").val(false);
        }
    });

    $("body").delegate('#btn_view_note_iso_file_result', 'click', function(e) {
        e.stopPropagation();
        var upload_file_id = $(this).data('item-id');
        $.each($('.stbl-search-result-iso-file tbody tr'), function (i, row) {
            if (!$(row).hasClass('child')) {
            if ($(row).data('item-id') !== upload_file_id) {
                $(row).removeClass("selected");
                $(row).find('a').removeClass("add_note_color");
            }
            else {
                $(row).addClass("selected")
                $(row).find('a').addClass("add_note_color");
            };
            }
        });
        $.ajax
            ({
            type: 'GET',
            url: "/show_iso_file_note_searched",
            dataType: 'script',
            data: {
                upload_file_id: upload_file_id,
            },
            success: function (data, textStatus, jqXHR) {
                // console.log(data);
                console.log("AJAX OK!")
            },
            error: function (jqXHR, textStatus, errorThrown) {
                console.log("AJAX Error: #{textStatus}")
            }
            })
    });

    format_input_iso_file_tags('#file_with_tags', '#hidden_iso_tag_search');
    
});
// function formatDate(date) {
//     var d = new Date(date),
//         month = '' + (d.getMonth() + 1),
//         day = '' + d.getDate(),
//         year = d.getFullYear();

//     if (month.length < 2)
//         month = '0' + month;
//     if (day.length < 2)
//         day = '0' + day;

//     return [year, month, day].join('/');
// }

function format_input_iso_file_tags(add_tag_field, hidden_iso_tag_field){
    $(add_tag_field).tokenfield({
          autocomplete: {
              // source: ['red','blue','green','yellow','violet','brown','purple','black','white'],
        source: function ( request, response ) {
                  // autocomplete_tokenfield(id_hide_textfield_email, request, response);
                  var keyword = $(hidden_iso_tag_field).val();
                  keyword == "" ? keyword = "" : keyword = keyword + ","
                  $.getJSON( "/filter_iso_file_tag", {
                          term:  keyword + request.term,
                  }, response );
              },
              minLength: 0,
              delay: 100,
          },
          showAutocompleteOnFocus: true
      })
  
    .on('tokenfield:createtoken', function (e) {
          var data = splitStringAfterSecondSpace(e.attrs.value);
          e.attrs.value = data[1] || data[0];
          e.attrs.label = data[1] ? data[0].replace('(','').replace(')','') + data[1]: data[0];
          var existingTokens = $(this).tokenfield('getTokens');
          $.each(existingTokens, function(index, token) {
              if (token.value === e.attrs.value)
                  e.preventDefault();
          });
          
      })
  
    .on('tokenfield:createdtoken', function (e) {
          // Über-simplistic e-tags validation
          var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          var valid = re.test(e.attrs.value);
          if (!valid) {
              $(e.relatedTarget).addClass('tag-invalid');
        $(e.relatedTarget).find("a").css("margin-top","2px");
        $(e.relatedTarget).find("span").css("padding-left","0px");
          }
          arr_list_tag = $(this).tokenfield('getTokensList').split(", ");
          var tags = split_format_tag(arr_list_tag);
          $(hidden_iso_tag_field).val(tags.join(","));
      })
  
    .on('tokenfield:removedtoken', function (e) {
          arr_list_tag = $(this).tokenfield('getTokensList').split(", ");
          var tags = split_format_tag(arr_list_tag);
          $(hidden_iso_tag_field).val(tags.join(","));
      });
  
  
  }
  
  function splitStringAfterSecondSpace(string) {
      parts = string.match(/^(\S+? \S+?) ([\s\S]+?)$/);
      parts == null ? parts = string.split() : parts = parts.slice(1,3);
      return parts
  }
  
  function split_format_tag(arr_list_tag) {
      var tags = [];
      $.each(arr_list_tag, function(index, token) {
          if (token.includes(" ")) {
              var tag = splitStringAfterSecondSpace(token)[1];
              tags.push(tag.replace("","").replace("",""));
          } 
          else {
              tags.push(token.replace("","").replace("",""));
          }
      });
      return tags;
  }
  