$(document).on('turbo:load', function() { 
    $("body").delegate('.btn-toggle', 'click', function (){
        var selected = this.getAttribute('selected');
        if(selected == "true" || selected == true){
            this.setAttribute('selected',false);
        }
        else{
            this.setAttribute('selected',true);
        }
    })
})