$(document).on('turbo:load', function() {

	$("body").delegate('#btnMoveTopFolderMail', 'click', function () {
		var count_category = 0;
        $.each($('#tblFolderMailList select :selected'), function (i, row) {
            count_category = count_category + 1
        });
        if (count_category > 0){
			var first_elem = $("#tblFolderMailList option:first");
			if(first_elem.is(':selected')) return true;
			while(!first_elem.prev().length){
				$('#tblFolderMailList select :selected').each(function (i, selected) {
					if (!$(this).prev().length) return false;
					$(this).insertBefore($(this).prev());
				});
				$('#tblFolderMailList select').focus().blur();
			}	
		}
	});

	$("body").delegate('#btnMoveUpFolderMail', 'click', function () {
		$('#tblFolderMailList select :selected').each(function (i, selected) {
			if (!$(this).prev().length) return false;
			$(this).insertBefore($(this).prev());
		});
		$('#tblFolderMailList select').focus().blur();
	});

	$("body").delegate('#btnMoveDownFolderMail', 'click', function () {
		$($('#tblFolderMailList select :selected').get().reverse()).each(function (i, selected) {
			if (!$(this).next().length) return false;
			$(this).insertAfter($(this).next());
		});
		$('#tblFolderMailList select').focus().blur();
	});

	$("body").delegate('#btnMoveBottomFolderMail', 'click', function () {	
		var count_category = 0;
        $.each($('#tblFolderMailList select :selected'), function (i, row) {
            count_category = count_category + 1
        });
        if (count_category > 0){
			var last_elem = $("#tblFolderMailList option:last");
			if(last_elem.is(':selected')) return true;
			while(!last_elem.next().length){
				$($('#tblFolderMailList select :selected').get().reverse()).each(function (i, selected) {
					if (!$(this).next().length) return false;
					$(this).insertAfter($(this).next());
				});
				$('#tblFolderMailList select').focus().blur();
			}
		}
	});

	$("body").delegate('#btnUpdateIndexFolderMail', 'click', function () {
		var lst = [];
		$("#lstFolderMail option").each(function () {
			lst.push($(this).val());
		});
		$.ajax({
			type: 'GET',
			url: "/update_index_folder_mails",
			dataType: 'script',
			data: {
					lst_folder_mails: lst,
			},
			success: function (data, textStatus, jqXHR) {
					
			},
			error: function (jqXHR, textStatus, errorThrown) {
					console.log("AJAX Error: #{textStatus}")
			}
		});
	});

});