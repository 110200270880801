export class PropertiesToolbar{  
    eventBus = null  
    constructor(options, eventBus){
      this.eventBus = eventBus;     
        this.commons = [ 
            
          {
            element: options.toolButton,
            eventName: "toolButton"
          },
          {
            element: options.thick_item,
            eventName: "thick_item"
          },
          {
            element: options.dash_item,
            eventName: "dash_item"
          },
          {
            element: options.arrow_item,
            eventName: "arrow_item"
          },
          
          {
            element: options.color_item,
            eventName: "color_item"
          },
          {
            element: options.color_pick,
            eventName: "color_pick"
          },
          {
            element: options.opacity_item,
            eventName: "opacity_item"
          },
          {
            element: options.width_item,
            eventName: "width_item"
          },
          {
            element: options.height_item,
            eventName: "height_item"
          },          
          {
            element: options.font_family,
            eventName: "font_family"
          },
          {
            element: options.height_item,
            eventName: "height_item"
          }
          ,
          {
            element: options.font_size,
            eventName: "font_size"
          }
          ,
          {
            element: options.bold_text,
            eventName: "bold_text"
          }
          ,
          {
            element: options.italic_text,
            eventName: "italic_text"
          }  ,
          {
            element: options.slider_item,
            eventName: "slider_item"
          },    
          {
            element: options.text_slider,
            eventName: "text_slider"
          },       
          {
            element: options.check_box_item,
            eventName: "check_box_item"
          },
          {
            element: options.date_format,
            eventName: "date_format"
          },
          {
            element: options.date_out_put,
            eventName: "date_out_put"
          }
          
        ];

        this.commons_click = [
          {
            element: options.commonDelete,
            eventName: "commonDelete"
            } ,    
            {
              element: options.commonClone,
              eventName: "commonClone"
              } , 
          {
            
            element: options.c_stamp_personal,
            eventName: "selectStamp"
          } ,  
          {
            element: options.c_stamp_manager,
            eventName: "selectStamp"
          } ,  
          {
            element: options.c_stamp_company,
            eventName: "selectStamp"
          } ,           
          {
            element: options.canvas_signal,
            eventName: "selectStamp"
          }, 
          {
            element: options.c_stamp__autocad,
            eventName: "selectStamp"
          } 
          
      ];
        bindCommons(this.commons,this.eventBus);
        binCommonsClick(this.commons_click,this.eventBus)
    }    
    
}
function bindCommons(commons,eventBus){
    for (const {
        element,
        eventName,
        eventDetails
      } of commons) {       
        var elem = document.querySelector(element);
        if(elem){
          if(elem.tagName == 'INPUT'){
            $("body").delegate(element, 'input', function(ev) {
              eventBus.dispatch(eventName, {
                  element: this        
              }); 
            })
          }
          else{
            $("body").delegate(element, 'click', function(ev) {
              eventBus.dispatch(eventName, {
                  element: this        
              });    
          })
          }
        }
    }  
}

function binCommonsClick(commonsClick,eventBus){
  for (const {
      element,
      eventName,
      eventDetails
    } of commonsClick) {      
      if(element) {
        $("body").delegate(element, 'click', function(ev) {
          eventBus.dispatch(eventName, {
              element: this        
          });          
        })  
      }
      
  }  
}

