import {savePermision} from '../packs/permission';
import { Tree } from './jstree/tree';
import { download_helper } from './supports/download_helper';
var tree;
$(document).on('turbo:load', function () {
  $(window).on('popstate', function(event) {
    var current_url = window.location.href;
    if(current_url.includes('/folders/index/')){
      var parts = current_url.split('/');
      var id = parseInt(parts[parts.length-1]);
      tree.select_node(id)
    }
     
  })
  window.node_current_id = null;
  $(document).ready(function () {
    var current_url = window.location.href.substring(window.location.href.length - 8);
    if (current_url == "/folders") {
      var old_url = document.referrer;
      if (old_url.includes("/file_informations/upload_file_information?folder_id=")) {
        var uploaded_folder_id = old_url.split("/file_informations/upload_file_information?folder_id=")[1];
        $("#btnShowHistory").prop("href", "/folders/" + uploaded_folder_id);
        if (uploaded_folder_id != null) {
          node_current_id = uploaded_folder_id;
        }
      }
    }
    if(window.location.href.includes('/folders')){
      tree = initTreeFolder('tree_view',show_data);
    }
    
  });  
  
  window.$("#modal-window-multiple-file-relocation").on("shown.bs.modal", function () {
    initTreeFolder('tree_relocation',show_path_relocation)
  })
  window.$("#modal-window-file-relocation").on("shown.bs.modal", function () {
    initTreeFolder('tree_relocation',show_path_relocation)
  })
  window.$("#modal-window-relocation").on("shown.bs.modal", function () {
    initTreeFolder('tree_relocation_folder',show_path_relocation);
    $('#tree_relocation_folder').on('loaded.jstree', function(e,data) {      
      var selectedNode = $("#tree_relocation_folder").jstree("get_selected");
      var childrens = $("#tree_relocation_folder").jstree("get_children_dom",selectedNode);
      if(childrens.length>0) 
      {
        for(var i=0;i<childrens.length;i++)
          {
            $("#tree_relocation_folder").jstree("delete_node",childrens[i])
          }
        }
      })    
  })
  
  window.$("#sort_modal").on("shown.bs.modal", function () {
    $("#sortable").sortable();
  })
  $("body").delegate('.block-folder', 'click', function () 
{
      $("#myUL").find(".folder-mark-up").removeClass("folder-mark-up");
      var current_folder_id = parseInt($(this).find(".folder-id").text());
      SetSelectedTreeFolder(current_folder_id);
  });

  $("body").delegate('#btn_sort_folder', 'click', function () {
    var selectedNode = $("#tree_view").jstree("get_selected");
    if(selectedNode){
      var node_info = $('#tree_view').jstree("get_node",selectedNode[0]);
      $.ajax({
        type:"GET",
        url:"/sort_folder",
        dataType:"script",
        data: {
          id: selectedNode[0],
          children: node_info.children
        },
        success: function(data, textStatus, jqXHR){
            
        },
        error:function(jqXHR, textStatus, errorThrown){
          console.log(jqXHR.responseText)
        }
      })
    }
  })

  $("body").delegate('#btnRelocationFile', 'click', function () {
    var node_selected = $("#tree_relocation").jstree("get_selected");
    if(node_selected.length > 0 ){
      var folder_id_relocation = parseInt(node_selected[0]); 
      var file_id = $("#filename_information").val();

      if(folder_id_relocation != file_id){
        $.ajax({
          type: 'POST',
          url: window.origin +  "/file_informations/"+ file_id + "/update_location",
          dataType: 'script',
          data: {
            folder_id_relocation: folder_id_relocation
          },
          success: function (data, textStatus, jqXHR) {
            window.$("#modal-window-file-relocation").modal('hide');         
      
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log("AJAX Error: #{textStatus}")
          }
        });
      }      
    }
  })

  $("body").delegate('#btnRelocationFolder', 'click', function () {
    var node_selected = $("#tree_relocation_folder").jstree("get_selected");
    if(node_selected.length > 0 ){
      var parent_folder_id = parseInt(node_selected[0]);  
      var folder_id = parseInt(el("tree_relocation_folder").getAttribute('selected'));    
      if(parent_folder_id != folder_id){
        $.ajax({
          type: 'POST',
          url: window.origin +  "/folders/update_location",
          dataType: 'script',
          data: {
            id: folder_id, 
            parent_folder_id:parent_folder_id
          },
          success: function (data, textStatus, jqXHR) {
            window.$("#modal-window-relocation").modal('hide');         
      
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log("AJAX Error: #{textStatus}")
          }
        });
      }      
    }
  
  })
  
  $("body").delegate('#btn_save_order', 'click', function () {
    var ids = [];
    var lis = document.querySelectorAll('.sort-folder #sortable li');
    for (let index = 0; index < lis.length; index++) {
      ids.push(parseInt(lis[index].getAttribute("id")));      
    }
    $.ajax({
      type:"PATCH",
      url:"/update_sort_folder",
      dataType:"script",
      data: {
        ids: ids
      },
      success: function(data, textStatus, jqXHR){
          
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log(jqXHR.responseText)
      }
    })
  })
  $("body").delegate('.sort-folder .item-folder', 'click', function () {
    var selected_item = 'selected_item'; 
    if(this.classList.contains(selected_item)){
      this.classList.remove(selected_item)
    }
    else{
      this.classList.add(selected_item)
    }
  });
  $("body").delegate('#btnMoveTopFolder', 'click', function () {
    var count_personal_group = 0;
    $.each($('#sortable .selected_item'), function (i, row) {
      count_personal_group = count_personal_group + 1
    });
    if (count_personal_group > 0){
      var first_elem = $("#sortable .item-folder").first()[0];
      if(first_elem.classList.contains("selected_item") == true) return true;
      while($("#sortable .item-folder").first()[0].classList.contains("selected_item") != true){
        $('#sortable .selected_item').each(function (i, selected) {
          if (this.previousElementSibling == null) return false;
          $(this).insertBefore(this.previousElementSibling);
          $('#sortable .selected_item')[0].scrollIntoViewIfNeeded();
        });
      } 
    }
  });

  $("body").delegate('#btnMoveUpFolder', 'click', function () {
    $('#sortable .selected_item').each(function (i, selected) {
      if (this.previousElementSibling == null) return false;
      $(this).insertBefore(this.previousElementSibling);
      $('#sortable .selected_item')[0].scrollIntoViewIfNeeded();
    });
  });

  $("body").delegate('#btnMoveDownFolder', 'click', function () {
    $($('#sortable .selected_item').get().reverse()).each(function (i, selected) {
      if (this.nextElementSibling == null) return false;
      $(this).insertAfter(this.nextElementSibling); 
      $("#sortable .selected_item").last()[0].scrollIntoViewIfNeeded();    
    });   
  });

  $("body").delegate('#btnMoveBottomFolder', 'click', function () { 
    var count_category = 0;
    $.each($('#sortable .selected_item'), function (i, row) {
      count_category = count_category + 1
    });
    if (count_category > 0){
      if($("#sortable .item-folder").last().length != 0)
      var last_elem = $("#sortable .item-folder").last()[0];
      if(last_elem.classList.contains("selected_item") == true) return true;
      while($("#sortable .item-folder").last()[0].classList.contains("selected_item") != true){
        $($('#sortable .selected_item').get().reverse()).each(function (i, selected) {
          if (this.nextElementSibling == null) return false;
          $(this).insertAfter(this.nextElementSibling);
          $("#sortable .selected_item").last()[0].scrollIntoViewIfNeeded();
        });
      }
    }
  });

  $("body").delegate('.block-folder-files', 'click', function () {
    var changed_parent_id = parseInt($(this).find(".folder-id").text());
    $.ajax({
      type:"GET",
      url:"/sub_folder_relocation_file/" + changed_parent_id,
      dataType:"script",
      data: {
        id: changed_parent_id
      },
      success: function(data, textStatus, jqXHR){
          
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log(jqXHR.responseText)
      }
    })
  });
  
  $("body").delegate('#btn_delete_file', 'click', function () {
    var lst_selected = [];
		$.each($('.tbl-file-folder tbody tr'), function (i, row) {
			if ($(row).find("input[class='col_checkbox']:checked").is(":checked")) {
				lst_selected.push($(row).find(".col_checkbox").data('item'));
			} 
		});
    var folder_id = getSelectedTreeFolder();
    if (lst_selected.length == 0) return;
    $.ajax({
      type: 'GET',
      url: window.origin +  "/file_informations/confirm_delete",
      dataType: 'script',
      data: {
        files: lst_selected,
        folder_id: folder_id
      },
      success: function (data, textStatus, jqXHR) {         
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    });
  });

  $("body").delegate('#btnRelocationMultipleFile', 'click', function () {
    var lst_selected = [];
		
    var node_selected = $("#tree_relocation").jstree("get_selected");
    if(node_selected.length > 0 ){
      var folder_id = parseInt(node_selected[0]);     
      $.each($('.tbl-file-folder tbody tr'), function (i, row) {
        if ($(row).find("input[class='col_checkbox']:checked").is(":checked")) {
          lst_selected.push($(row).find(".col_checkbox").data('item'));
        } 
      });
      if (lst_selected.length == 0) return;
      $.ajax({
        type: 'GET',
        url: window.origin +  "/file_informations/update_location_multiple_file",
        dataType: 'script',
        data: {
          files: lst_selected,
          folder_id: folder_id
        },
        success: function (data, textStatus, jqXHR) {
          window.$("#modal-window-multiple-file-relocation").modal('hide');
          // console.log(data);
          tree.select_node(folder_id);
    
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
    }
  });

  function initTreeFolder(tree_id,callbackSelectNode) {
    var elemtree = el(tree_id);
    if(elemtree){
      var elemtreeData = document.getElementById('datajs');
      if(!elemtreeData) return;    
      var datajs = elemtreeData.value;    
      datajs = datajs.replace(/'/g, '"');
      datajs = JSON.parse(datajs);
      var id = elemtree.getAttribute('selected');      
      elemtreeData.remove();
      return new Tree(elemtree,datajs,id,callbackSelectNode); 
    }
    
  }

  

  window.formatTableFolder = function() {   
    var tbl_file_folder = document.getElementsByClassName("tbl-file-folder");
    if (tbl_file_folder.length > 0) {
      // tbl_file_folder = window.$('.tbl-file-folder').DataTable({
      //   'stateSave': true,
      //   'destroy': true,
      //   'columnDefs': [
      //     {
      //       'targets': 0,
      //       'width': "4%",
      //       'checkboxes': {
      //         'selectRow': true
      //       }
      //     },
      //     { "width": "6%", "targets": 1 }
      //   ],
      //   'select': {
      //     'style': 'multi'
      //   },
      //   'order': [1, 'asc'],
      //   drawCallback: function() {
      //     $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      //   },
      //   language:{paginate:{
      //   previous:"<i class='mdi mdi-chevron-left'>",
      //   next:"<i class='mdi mdi-chevron-right'>"}},
      // });
      $("#btn-download-file-folder").click(function() {        
        var files = [],pdfs = [];
        var checkboxes = $(".tbl-file-folder tr td .col_checkbox");
        var col_checkbox = null,pdf_id;
        var filezip = "Compress.zip";
        var node = getSelectedTreeDataFolder();
        if(node){
          filezip = $("#tree_view").jstree('get_selected', true)[0].text  + ".zip";
        }

      checkboxes.filter(':checked').map(function(){
        col_checkbox = $(this).closest('td')[0];
        pdf_id = parseInt(col_checkbox.getAttribute("data-pdf"));
        if(pdf_id){
          pdfs.push(pdf_id);
        }
        else{
          files.push({url: col_checkbox.getAttribute("data-url"),mime: col_checkbox.getAttribute("data-mime"),filename:col_checkbox.getAttribute("data-filename") });
        }
        
      })
      if(files.length == 0 && pdfs.length == 0) return;     
         download(files,pdfs,filezip);
      });
    }
    async function download(files,pdfs,filezip){      
      try {
        showFormLoading();
        await download_helper.download_list_file(files,pdfs,filezip);
      } catch (error) {
        console.log(error)
      }
      finally{
        hideFormLoading();
      }  
    }
    
    // $("body").delegate('.block-folder-files', 'click', function () {
    //   var changed_parent_id = parseInt($(this).find(".folder-id").text());
    //   $.ajax({
    //     type:"GET",
    //     url:"/sub_folder_relocation_file/"+changed_parent_id,
    //     dataType:"script",
    //     data: {
    //       id: changed_parent_id
    //     },
    //     success: function(data, textStatus, jqXHR){
            
    //     },
    //     error:function(jqXHR, textStatus, errorThrown){
    //       console.log(jqXHR.responseText)
    //     }
    //   })
    // });
  
    window.getSelectedFileInTableFileInformation = function () {
      var num = 0;
      $.each(tbl_file_folder.rows({ 'search': 'applied' }).nodes(), function (i, row) {
        var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
        if (checkBox) 
        {
          num++;                
        }   
        
      });
      return num;
    }
         
  }

  window.appendChildNodeFolder = function (idChild,text,text_folder_parent,icon_folder){

    // console.log($("#tree_view").jstree("get_selected").text());
    var node_selected = $("#tree_view").jstree("get_selected");
    if(node_selected.length>0){
       $('#tree_view').jstree().create_node(node_selected[0], {
        "id": idChild,
        "text": text,
        "icon": icon_folder
      }, "last", function() {        
      });
    }
    tree.select_node(idChild)
  }
  window.open_all = function (){
    $("#tree_view").jstree("open_all");

  }
  window.close_all = function (){
    $("#tree_view").jstree("close_all");

  }
  window.getSelectedTreeFolder = function (){
    var node_selected = $("#tree_view").jstree("get_selected");
    if(node_selected.length>0){
      return node_selected[0];
    }
    else{
      return "0";
    }
  }

  window.SetSelectedTreeFolder = function (id){
    $("#tree_view").jstree().deselect_all(true);
    $('#tree_view').jstree('select_node',id);
  }

  window.changeIconNodeFolder = function(current_folder_id) {
    $("#tree_view").jstree(true).set_icon(current_folder_id, "mdi mdi-folder-outline fs-4");
  }

    var toggler = document.getElementsByClassName("caret");
  var i;

  for (i = 0; i < toggler.length; i++) {
    toggler[i].addEventListener("click", function () {
      this.parentElement.querySelector(".nested").classList.toggle("active");
      this.classList.toggle("caret-down");
    });
  } 

  //for admin 
  $("body").delegate('#add-decentralized-group', 'click', function () {
    moveItemSelectTagToTable();
  });

  $("body").delegate('#remove-decentralized-group', 'click', function () {
    moveItemTableToSelectTag();
  });
  $("body").delegate('.jstree-anchor[limit_acess=true]', 'click', function (e) {
    if (e.offsetX > this.offsetWidth) {
      var node_selected = $("#tree_view").jstree("get_selected");
      if(node_selected.length == 0){
        node_selected = $("#tree_relocation_folder").jstree("get_selected");
      }
      if(node_selected.length == 0){
        node_selected = $("#tree_relocation").jstree("get_selected");
      }
      
      if(node_selected.length > 0){
        var id = parseInt(node_selected[0]);
        showModalDetailPermission(id);  
      }
      
    }    
  });
  $("body").delegate('.btn-detail-permission', 'click', function (e) {
    var node_selected = $("#tree_view").jstree("get_selected");
    if(node_selected.length>0){
      var id = parseInt(node_selected[0]);
      showModalDetailPermission(id);  
    }  
  });
  $("body").delegate('.btn-detail-permission-show-folder', 'click', function (e) {
    var id = parseInt(this.getAttribute("data-id"));
    showModalDetailPermission(id);  
  });
  
  $("body").delegate('#btnSubmitReleaseProject', 'click', function () {
    var selected = document.getElementById('tbl-lst-group-selected');
    var current_group_item = []
    for (let tr of selected.getElementsByTagName("tr")) {
      var row_id = tr.getElementsByTagName("td")[0].textContent;
      var row_is_read_only = tr.getElementsByClassName("rdb-is-read-only")[0].checked;
      var row = [row_id, row_is_read_only]
      current_group_item.push(row);
    }

    // Lay id dang dc chon
    // for (let item of $("#not-decentralized-group").children(':selected')) {
    //   switch_group_item.push([item.value, item.text]);
    // }
    var id = $(".decentralize-folder-id")[0].textContent;
    // dung ajax gui id len controller, controller se lay object tơơng ung voi id
    $.ajax({
      type: 'GET',
      url: "folders/admin_update_decentralize",
      dataType: 'script',
      data: {
        id: id,
        group_selected: current_group_item,
      },
      success: function (data, textStatus, jqXHR) {
        
        // $(select_tag).find('option:selected').detach().prop("selected", false);
        window.$("#modal-window-decentralize").modal('hide');
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    });
  });


  $("body").delegate('#btnSubmitDepartmentFolder', 'click', function () {
    var id = $(".decentralize-folder-id")[0].textContent;
    savePermision(window.origin + "/folders/update_decentralize",id,"folder");
  });

  // document.getElementById('tbl-lst-group-selected').getElementsByTagName("tr")[0].getElementsByTagName("td")[3]
  function moveItemSelectTagToTable() {
    var switch_group_item = $("#not-decentralized-group").val();
    if (switch_group_item.length > 0) {
      var selected = document.getElementById('tbl-lst-group-selected');
      var current_group_item = []
      if (selected.getElementsByTagName("tr").length > 0) {
        for (let tr of selected.getElementsByTagName("tr")) {
          var row_id = tr.getElementsByTagName("td")[0].textContent;
          var row_name = tr.getElementsByTagName("td")[1].textContent;
          var row_is_read_only = tr.getElementsByClassName("rdb-is-read-only")[0].checked;
          var row = [row_id, row_name, row_is_read_only]
          current_group_item.push(row);
        }
      }
      else {
        current_group_item.push(false);
      }

      // Lay id dang dc chon
      // for (let item of $("#not-decentralized-group").children(':selected')) {
      //   switch_group_item.push([item.value, item.text]);
      // }
      var id = $(".decentralize-folder-id")[0].textContent;
      // dung ajax gui id len controller, controller se lay object tơơng ung voi id
      $.ajax({
        type: 'GET',
        url: "folders/push_id_permission",
        dataType: 'script',
        data: {
          folder_id: id,
          current_group: current_group_item,
          switch_group: switch_group_item
        },
        success: function (data, textStatus, jqXHR) {
          
          // $(select_tag).find('option:selected').detach().prop("selected", false);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
    }
  }

 

  function moveItemTableToSelectTag() {
    var check_action = $(".selected-row").length
    if (check_action > 0) {
      var selected = $('.name_group_decentralize').not(".selected-row");
      var current_group_item = []
      if (selected.length > 0) {
        for (let td of selected) {
          var tr = td.parentNode
          var row_id = tr.getElementsByTagName("td")[0].textContent;
          var row_name = tr.getElementsByTagName("td")[1].textContent;
          var row_is_read_only = tr.getElementsByClassName("rdb-is-read-only")[0].checked;
          var row = [row_id, row_name, row_is_read_only]
          current_group_item.push(row);
        }
      }
      else {
        current_group_item.push(false);
      }

      // Lay id dang dc chon
      // var itemtable = document.getElementById('tbl-lst-group-selected');
      // var id = $("#tbl-lst-group-selected").val();
      $.ajax({
        type: 'GET',
        url: "folders/push_back_id_permission",
        dataType: 'script',
        data: {
          current_group: current_group_item
        },
        success: function (data, textStatus, jqXHR) {

          
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
    }
  }

  function showModalDetailPermission(id){
   
      $.ajax({
        type: 'GET',
        url: window.origin + "/folders/show_detail_permission",
        dataType: 'script',
        data: {
          id: id
        },
        success: function (data, textStatus, jqXHR) {

          
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
    
  }
  
  var tblFolderHistories = document.getElementsByClassName("tblFolderHistories");
  // if (tblFolderHistories.length > 0) {
  //   window.$('.tblFolderHistories').DataTable({
  //     'destroy': true,
  //     'columnDefs': [
  //       { "width": "10%", "targets": 0 },
  //       { "width": "10%", "targets": 1 },
  //       { "width": "25%", "targets": 2 },
  //       { "width": "25%", "targets": 3 },
  //       { "width": "30%", "targets": 4 },
  //     ],
  //     'order': [0, 'desc'],
  //     drawCallback: function () {
  //       $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
  //     },
  //     //add horizontal scrollbar code
  //     fnInitComplete: function () {
  //       // Enable TFOOT scoll bars
  //       $('.dataTables_scrollFoot').css('overflow', 'auto');

  //       //  Sync TFOOT scrolling with TBODY
  //       $('.dataTables_scrollFoot').on('scroll', function () {
  //         $('.dataTables_scrollBody').scrollLeft($(this).scrollLeft());
  //       });
  //     },
  //     scrollX: true,
  //     paging: true,
  //     fixedColumns: {
  //       leftColumns: 1
  //     },
  //     //end horizontal scrollbar code
  //     language: {
  //       paginate: {
  //         previous: "<i class='mdi mdi-chevron-left'>",
  //         next: "<i class='mdi mdi-chevron-right'>"
  //       }
  //     },
  //   });
  // }

  $("body").delegate('.tbl-file-folder tbody tr', 'mouseover', function() {
    $(this).find(".more-action-folder-table").removeClass('hide');
		
	})
  $("body").delegate('.tbl-file-folder tbody tr', 'mouseout', function() {
    $(this).find(".more-action-folder-table").addClass('hide');
  })

});



function show_data(id) { 
      if(id){
        $("#myUL").find(".folder-mark-up").removeClass("folder-mark-up");
        $(`#${id}`).addClass("folder-mark-up");
        $.ajax({
          type: "GET",
          url: window.origin + "/folders/sub_folder_block",
          dataType: "script",
          data: { folder_id: id },
          success: function (data, textStatus, jqXHR) {
            formatTableFolder();
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR.responseText)
          }
        })
        $("#btnShowHistory").prop("href", "/folders/" + id);
        var currentUrl = window.origin + '/folders/index/' + id;          
        var url = new URL(currentUrl);        
        history.pushState({}, "", url);  
       
      }
}

function show_path_relocation(folder_id){
  $.ajax({
    type:"GET",
    url:"/folders/show_path_relocation",
    dataType:"json",
    data: { id: folder_id },
    success: function(data, textStatus, jqXHR){
      var pathHtml = '';
      $.each(data.paths, function(index, path) {
        pathHtml += '<li class="breadcrumb-item active">' + path + '</li>';
      });
      var $pathName = $('#name-folder-after-relocation');
      $pathName.html(pathHtml);

    },
    error:function(jqXHR, textStatus, errorThrown){
      console.log(jqXHR.responseText)
    }
  })
}