export var url_helper = {};
url_helper.get_value_params_from_URL = function(key){
    var currentUrl = window.location.href;          
    var url = new URL(currentUrl);            
    return url.searchParams.get(key);
}
url_helper.add_params_to_url = function(key,value){
    var currentUrl = window.location.href;          
    var url = new URL(currentUrl);            
    url.searchParams.set(key, value);
    history.pushState({}, "", url);        
}

url_helper.format_url = function(url){   
    url = url.replace(/^[a-z]{4,5}\:\/{2}[a-z]{1,}\:[0-9]{1,4}.(.*)/, '$1');
    if(isValidUrl(url) && url.includes('http')){
        url = new URL(url).pathname;
    }
    return url;
}