$(document).on('turbo:load', function() {
	$('body').delegate("#language_select", 'change', function() {
		var language = $("#language_select option:selected").val();
		$.ajax({
			type: 'PUT',
			url: window.origin + "/client/change_language",
			dataType: 'script',
			data: {
				language: language
			},
			success: function(data, textStatus, jqXHR){
				console.log("change language");
				window.location.reload();

			},
			error: function(jqXHR, textStatus, errorThrown) {
			  console.log("AJAX Error: " + errorThrown);
			}
		
		});	
		
	});
	$('body').delegate(".select-locate", 'click', function() {
		var lang = this.getAttribute("data-item");
		$.ajax({
			type: 'GET',
			url: window.origin +  "/set_locate",
			dataType: 'script',   
			data: {
				locale: lang
			},
			success: function(data, textStatus, jqXHR){
				console.log("Update locate successfully!");
				window.location.reload();
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("Update locate Error: #{textStatus}")
			}
		});
	});
	
});
