import { stamp_helper } from "../common/stamp_helper";
import { ANNOTATION } from "../edit/annotation";
import { PDFSaveAnnotation } from "../edit/save_annotation";
import { fabric_format } from "../fabric/fabric_format";
import { FArrow } from "../fabric/items/arrow";
import { FLine } from "../fabric/items/line";
import { FText } from "../fabric/items/text";

export const factory = {};
factory.create = {   
    image: add_new_image,       
    line: add_new_line,
    circle: add_new_circle,
    arrow: create_arrow,
    add_date: addDateLabel
}

factory.delete = deleteOneElement;
factory.delete_multil_element = delete_multil_element;
factory.defaultDelete = defaultDelete;
function addDateLabel(stamp,viewer)
{    
    var date = stamp_helper.get_date_by_stamp(stamp);
    if(date){
      factory.delete(stamp.canvas,date);
    }
    var currentDate = new Date();
    var format_date = stamp.formatDate;//'YYYY-MM-DD';
    var dateString = moment(currentDate).format(format_date); 

   var property = {
    text: dateString,
    x: stamp.left - stamp.x_relative*viewer.currentScaleValue,
    y: stamp.top - stamp.y_relative*viewer.currentScaleValue,
    fontFamily:  'MS 明朝',
    fontSize: stamp.fontSize,
    parent_id: stamp.id,
    color: stamp.color,
    formatDate: format_date,
    id: random_string(10)
   }
   return startAddingDate(property,stamp.canvas,viewer);
   

}

function startAddingDate(property,canvas,viewer){ 
  var boldText = 'normal';
  var italicText = 'normal';
  var page = viewer.file.pages[viewer.currentPageNumber-1];
  var rotate = (page.rotate + page.viewRotate) % 360;  
  var txt = new FText(property.text, { 
      left: property.x,
      top:   property.y,
      fontFamily: 'MS 明朝',
      fontWeight: boldText,
      fontStyle: italicText,
      fill: property.color,
      fontSize: parseInt(property.fontSize*viewer.currentScaleValue),
      name: 'date',
      annotation : ANNOTATION.ADD_TEXT,
      id: property.id,
      parent_id: property.parent_id,
      scale: viewer.currentScaleValue,
      lockUniScaling: true,
      originFontSize: parseInt(property.fontSize),
      rotate:  rotate,
      textFormat:  property.textFormat,
      page: viewer.currentPageNumber,
      user_id: viewer.user_id
  })
  fabric_format.text(txt,viewer);    
  canvas.add(txt);
  canvas.renderAll();  
  canvas.setActiveObject(txt);  
  return txt;
}
function add_new_line(canvas,aCoords,properties){  
    var line = new FLine(aCoords,properties);    
    canvas.add(line);
    canvas.add(line.head1);
    canvas.add(line.head2);

    canvas.bringToFront(line.head1);
    canvas.bringToFront(line.head2);
    canvas.sendBackwards(line);
    return line;
}

function add_new_circle(canvas,properties){  
    var c = new fabric.Circle(properties);    
    return add_object(canvas,c)
}

function add_new_image(canvas,canvas_image,properties){   
    var image = new fabric.Image(canvas_image, properties);        
    return add_object(canvas,image)
}

function defaultDelete(canvas,element) {
  if(element.annotation == ANNOTATION.DRAW_ARROW){
    if(element.circle1){
      element.circle1.isReplace = true;
    } 
    if(element.circle2){
      element.circle2.isReplace = true;
    } 
    element.line1.isReplace = true;
    element.line2.isReplace = true;
    canvas.remove(element.circle1,element.circle2,element.line1,element.line2);
    if(element.number_of_head == 2){
      element.line3.isReplace = true;
      element.line4.isReplace = true;
      canvas.remove(element.line3,element.line4);
    }
    element.isReplace = true;
    canvas.remove(element); 
  }
  else if(element.annotation == ANNOTATION.DRAW_LINE){
    if(element.head1){
      element.head1.isReplace = true;
    } 
    if(element.head2){
      element.head2.isReplace = true;
    }             
    canvas.remove(element.head1,element.head2);      
    element.isReplace = true;
    canvas.remove(element); 
  }
  else{
    element.isReplace = true;
    canvas.remove(element); 
  }
}
  function deleteOneElement(canvas,element){
    if(element.annotation == ANNOTATION.DRAW_ARROW){
      canvas.remove(element.circle1,element.circle2,element.line1,element.line2);
      if(element.number_of_head == 2){
        canvas.remove(element.line3,element.line4);
      }
    }
    if(element.annotation == ANNOTATION.DRAW_LINE){
      canvas.remove(element.head1,element.head2);      
    }

    if(element.annotation == ANNOTATION.ADD_STAMP && element.hasDate){
      var date = stamp_helper.get_date_by_stamp(element);
      if(date){  
        canvas.remove(element);      
        canvas.remove(date); 
      }           
    }
    else{
      canvas.remove(element);
    }

    
  }
  function delete_multil_element(canvas,elements) {
    elements.forEach(element => {
      deleteOneElement(canvas,element);
    });
  }
  function add_object(canvas,object){
    canvas.add(object);
    object.setCoords();
    canvas.setActiveObject(object);
    object.setControlsVisibility({       
      mtr: false, 
    });
    return object;
}


function create_arrow(canvas,aCoords,properties){  
    var line = new FArrow(aCoords,properties);
    canvas.add(line);
    canvas.add(line.head1);
    canvas.add(line.head2);

    line.line1 && canvas.add(line.line1);
    line.line2 && canvas.add(line.line2);
    line.line3 && canvas.add(line.line3);
    line.line4 && canvas.add(line.line4);

    canvas.bringToFront(line.head1);
    canvas.bringToFront(line.head2);
    canvas.sendBackwards(line);
    
    return line;
}