import {PDFViewerApplication } from '../libs/pdf-editor/define/PDFViewerApplication';
import {toolBarHelper} from '../libs/pdf-editor/edit/toolbar_helper';
import { ANNOTATION } from '../libs/pdf-editor/edit/annotation';
import { StampConfig } from './pdf/stamp_config';
import { TYPE_STAMP } from './common';
import { stamp_helper } from '../libs/pdf-editor/common/stamp_helper';
$(document).on('turbo:load', function(){ 
    var currentUrl = window.location.pathname;
   if (currentUrl.includes('/pdf/view/') || currentUrl.includes(`/pdf/view_share/`)){        
    var url_file = $('#url_file').val();        
    open(url_file);  
    load_settings_tool(); 
    stamp_helper.init();
    toolBarHelper.setHiddenButton();   
    $("body").delegate('#btn_hide_pages_left', 'click', function () {
      var body_page = this.closest('.pdf-stamp-fluide');
      var hide_page = body_page.getAttribute('hide-page');
      if(hide_page == true || hide_page === 'true'){
          body_page.setAttribute('hide-page',false);
      }
      else{
        body_page.setAttribute('hide-page',true);          
      }
    });
    $("#tool-stamp,#tool-stamp-date").draggable({
        containment: "document", 
        stop: function( event, ui ) {
            event.target.setAttribute('moved', true)
        }       
    });
    
 
     $("body").delegate('#button_resume_stamp', 'click', function () {  
      var objectDataSubmit = {slug: this.getAttribute('data-slug')};  
      $.ajax({
          type: "POST",
          url: window.location.origin + "/pdf/resume_stamp",
          data: objectDataSubmit,    
          success: function(data)
          {
          },
          error: function (request, status, error) {
              console.log(request.responseText);
          }
      });
     
    });
    
   }        
});


async function open(url){
    var viewComponent = getViewComponents();
    var read_only = (el('read_only').value == "true" || el('read_only').value == true);
    if(read_only){
      PDFViewerApplication.set_mode(0);
    }
    else{
      PDFViewerApplication.set_mode(1);
    }
    PDFViewerApplication.set_app_type(2);
    await PDFViewerApplication.initialize(null,viewComponent); 
    await PDFViewerApplication.open(url);   
    if(read_only){
      PDFViewerApplication.set_annotation(ANNOTATION.NONE);
      el('btnSelectannotation').style.display = 'none';
    }
    else
    {
      PDFViewerApplication.set_annotation(ANNOTATION.MOVE);      
    }
}

function getViewComponents(){
    var container = document.getElementById('pdfViewContent');
    var viewContainer = container.querySelector('#stampViewer');
    var thumnailContainer = container.querySelector('#page-navi');
    const simpleBar = new SimpleBar(container.querySelector('#pdf-viewer'));
    var scroll = simpleBar.getScrollElement(); 
    return {
        toolbar:{
            firstpage: container.querySelector('#btnfirstpage'),
            endpage: container.querySelector('#btnendpage'),
            previous: container.querySelector('#btnPrevPdfInDocument'),				
            next: container.querySelector('#btnNextPdfInDocument'),
            rotateleft: container.querySelector('#btnRotateLeftInDocument'),
            rotateright: container.querySelector('#btnRotateRightInDocument'),
            zoomIn: container.querySelector('#btnZoomInDocument'),
            zoomOut: container.querySelector('#btnZoomOutDocument'),
            current_page: container.querySelector('#current_page_value'),
            total_page: container.querySelector('#total_page_value'),
            select_scale: container.querySelector('#scaleSelect'),           
            
        },
        edittoolbar:{            
          select_text: el('btnSelectPoint'),
          select_annotation : el('btnSelectannotation'),
          hand : el('btnHand'),
          undo: el('btnUndo'),
          redo: el('btnRedo') ,
          download: el('btn_downloadPdfShare')       
            
        },
        propertiesToolbar:{                        
            c_stamp_personal :   '.c_stamp_personal',
            c_stamp_manager :   '.c_stamp_manager',
            c_stamp_company :   '.c_stamp_company',
            c_stamp__autocad: '.img_autocad',
            canvas_signal :   '.canvas_signal',       
            commonDelete : '.objectdelete',
            color_item: '.color_item',
            color_pick: '.input_color',
            slider_item: '.slider_item',
            text_slider: '.text-slider',
            check_box_item: '.check_box_item',
            date_format: '.date_format',
            date_out_put: '.date_out_put'
        },
        viewContainer:viewContainer,
        scrollContent: scroll,
        pageScroll: container.querySelector('#pdf-viewer') ,
        thumnailContainer:thumnailContainer
    }
}
function load_settings_tool(){
    var settings = document.getElementById('json_stamp_setting').value;
    const jsonSettings = JSON.parse(settings);
    setAllConfig(jsonSettings)
    
  }

  function setAllConfig(jsonSettings){
    for (let index = 0; index < jsonSettings.length; index++) {
      const element = jsonSettings[index];         
      switch(element.type_stamp)
      {
        case TYPE_STAMP.PERSONAL:
          if(element.size){
            StampConfig.personal.size = parseFloat(element.size);
          }
          if(element.setting) {
            StampConfig.personal = element.setting;
          }           
          break;
        case TYPE_STAMP.MANAGER_HAS_DATE:
            if(element.size){
              StampConfig.manager_has_date.size = parseFloat(element.size);
            }
            if(element.setting) {
              StampConfig.manager_has_date = element.setting;
            }    
            break;
        case TYPE_STAMP.MANAGER_NODATE:
            if(element.size){
              StampConfig.manager_no_date.size = parseFloat(element.size);
            }
            if(element.setting) {
              StampConfig.manager_no_date = element.setting;
            }    
            break;
        case TYPE_STAMP.COMPANY:
          if(element.size){
            StampConfig.company.size = parseFloat(element.size);
          }
          if(element.setting) {
            StampConfig.company = element.setting;
          }    
          break;
        case TYPE_STAMP.SIGNAL:
          if(element.size){
            StampConfig.signal.size = parseFloat(element.size);
          }
          if(element.setting) {
            StampConfig.signal = element.setting;
          }  
          break;
        case TYPE_STAMP.AUTOCARD:
          if(element.size){
            StampConfig.autocard.size = parseFloat(element.size);
          }
          if(element.setting) {
            StampConfig.autocard = element.setting;
          } 
          break;
      }
    } 
  }


  
	