$(document).on('turbo:load', function () {

	renameIdPlatformClasses();

	var tblPlatform = document.getElementsByClassName("tbl-platform");
	if (tblPlatform.length > 0) {

		window.$('.tbl-platform').DataTable({
			stateSave: false,
			'destroy': true,
			'columnDefs': [
				{
					'targets': 0,
					'width': "5%",
					'checkboxes': {
						'selectRow': true
					}
				},
				{ "width": "5%", "targets": 1 },
				{ "width": "80%", "targets": 2 },
				{ "width": "10%", "targets": 3 },
			],
			'select': {
				'style': 'multi'
			},
			'order': [1, 'asc'],
			drawCallback: function () {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language: {
				paginate: {
					previous: "<i class='mdi mdi-chevron-left'>",
					next: "<i class='mdi mdi-chevron-right'>"
				}
			},
			paging: true,
			fixedColumns: {
				leftColumns: 1
			},
		});
	};

	var tblTempIsoFile = document.getElementsByClassName("tbl-temp-iso-file");
	if (tblTempIsoFile.length > 0) {

		var iso_table = window.$('.tbl-temp-iso-file').DataTable({
			'destroy': true,
			'columnDefs': [
				{
					'targets': 0,
					'width': "5%",
					'checkboxes': {
						'selectRow': true
					}
				},
				{ "width": "5%", "targets": 1 },
				{ "width": "60%", "targets": 2 },
				{ "width": "10%", "targets": 3 },
				{ "width": "10%", "targets": 4 },
				{ "width": "10%", "targets": 5 },
				{ "width": "0%", "targets": 6 },
			],
			'select': {
				'style': 'multi'
			},
			'order': [1, 'asc'],
			drawCallback: function () {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			paging: false,
			searching: false,
			info: false,
			fixedColumns: {
				leftColumns: 1
			},
		});
	};

	var tblCheckList = document.getElementsByClassName("tbl-check-list");
	if (tblCheckList.length > 0) {

		var check_list_table = window.$('.tbl-check-list').DataTable({
			'destroy': true,
			'columnDefs': [
				{
					'targets': 0,
					'width': "5%",
					'checkboxes': {
						'selectRow': true
					}
				},
				{ "width": "35%", "targets": 1, className:"check-list-name-truncate" },
				{ "width": "35%", "targets": 2, className:"check-list-author-truncate" },
				{ "width": "20%", "targets": 3 },
				{ "width": "5%", "targets": 4, },

			],
			'select': {
				'style': 'multi'
			},
			// "ordering": false,
			drawCallback: function () {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			createdRow: function(row){
				var td_0 = $(row).find(".check-list-name-truncate");
				var td_1 = $(row).find(".check-list-author-truncate");
				td_0.attr("title", td_0.html());
				td_1.attr("title", td_1.html());
		   	},
			language: {
				paginate: {
					previous: "<i class='mdi mdi-chevron-left'>",
					next: "<i class='mdi mdi-chevron-right'>"
				}
			},
			paging: true,
			fixedColumns: {
				leftColumns: 1
			},
		});
	};

	var tblCheckContent = document.getElementsByClassName("tbl-check-content");
	if (tblCheckContent.length > 0) {

		window.$('.tbl-check-content').DataTable({
			'destroy': true,
			'columnDefs': [
				{
					'targets': 0,
					'width': "5%",
					'checkboxes': {
						'selectRow': true
					}
				},
				{ "width": "40%", "targets": 1, className:"check-content-name-truncate" },
				{ "width": "30%", "targets": 2, className:"check-content-note-truncate" },
				{ "width": "5%", "targets": 3 },
				{ "width": "10%", "targets": 4 },
				{ "width": "10%", "targets": 5 },

			],
			'select': {
				'style': 'multi'
			},
			// "ordering": false,
			drawCallback: function () {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			createdRow: function(row){
				var td_0 = $(row).find(".check-content-name-truncate");
				var td_1 = $(row).find(".check-content-note-truncate");
				td_0.attr("title", td_0.find('a').html());
				td_1.attr("title", td_1.find('a').html());
		   	},
			language: {
				paginate: {
					previous: "<i class='mdi mdi-chevron-left'>",
					next: "<i class='mdi mdi-chevron-right'>"
				}
			},
			paging: true,
			fixedColumns: {
				leftColumns: 1
			},
		});
	};

	var tblSTempIsoFile = document.getElementsByClassName("stbl-temp-iso-file");
	if (tblSTempIsoFile.length > 0) {

		var iso_table = window.$('.stbl-temp-iso-file').DataTable({
			'destroy': true,
			'columnDefs': [
				{ "width": "0%", "targets": 0 },
				{ "width": "5%", "targets": 1 },
				{ "width": "65%", "targets": 2 },
				{ "width": "10%", "targets": 3 },
				{ "width": "10%", "targets": 4 },
				{ "width": "10%", "targets": 5 },
				{ "width": "0%", "targets": 6 },
			],
			'select': {
				'style': 'multi'
			},
			'order': [1, 'asc'],
			drawCallback: function () {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			scrollY: '200px',
			scrollCollapse: true,
			paging: false,
			info: false,
			searching: false,
			fixedColumns: {
				leftColumns: 1
			},
		});
	};


	var btnAddContent = document.getElementsByClassName("btn-add-new-check-content");
	for (var i = 0; i < btnAddContent.length; i++) {
		btnAddContent[i].addEventListener('click', function (e) {
		})
	}

	const _btnDeletePlatform = document.querySelector("#btn_delete_platform");
	if (_btnDeletePlatform) {
		_btnDeletePlatform.addEventListener("click", delete_Platform);
	}
	const _btnDeleteCheckList = document.querySelector("#btn_delete_check_lists");
	const _btnEditCheckList = document.querySelector('#btn_edit_check_list');
	if (_btnDeleteCheckList) {
		_btnDeleteCheckList.addEventListener("click", delete_Check_list);
	}
	if (_btnEditCheckList) {
		_btnEditCheckList.addEventListener("click", edit_Check_list);
	}

	const _btnDeleteCheckContent = document.querySelector("#btn_delete_check_contents");
	const _btnEditCheckContent = document.querySelector('#btn_edit_check_content');
	if (_btnDeleteCheckContent) {
		_btnDeleteCheckContent.addEventListener("click", delete_Check_content);
	}
	if (_btnEditCheckContent) {
		_btnEditCheckContent.addEventListener("click", edit_Check_content);
	}

	const _btnDeleteIsoFile = document.querySelector("#btn_delete_iso_files");
	if (_btnDeleteIsoFile) {
		_btnDeleteIsoFile.addEventListener("click", delete_Multi_Iso_file);
	}

	$("body").delegate('#btn_add_check_list', 'click', function () {
		document.getElementById('btn_add_new_check_list').click();
		renameIdPlatformClasses();
	});

	$("body").delegate('.btn-show-more', 'click', function () {
		if ($(this).parent().children(".toggle-text").hasClass("d-none")) {
			$(this).parent().children(".toggle-text").removeClass("d-none");

		} else {
			$(this).parent().children(".toggle-text").addClass("d-none");
		}
	});

	$("body").delegate('#btn_temp_file', 'click', function (e) {
		var check_content_id = $("#current_check_content_id").val();
		$.ajax({
			type: 'GET',
			url: "/add_temp_file_platform",
			dataType: 'script',
			data: {
				check_content_id: check_content_id,
			},
			success: function (data, textStatus, jqXHR) {
				load_drop_zone_for_platform();
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error")
			}
		});

	});

	window.$("#modal_temp_file").on("shown.bs.modal", function () {
		load_drop_zone_for_platform();
	})

	var url = window.location.href;
		if (!url.includes('check_list_id')){
			if ($('#tbl_check_list tbody tr:eq(0)').attr('id')) {
				$('#tbl_check_list tbody tr:eq(0)').addClass('selected');
			}
		}
		else
		{
			if(url.includes('check_list_id') && url.includes('edit')){
				var number = parseInt(url.split('=').pop());
				$.each(check_list_table.rows().nodes(), function (i, row) {
					if ($(row).data('item-id') !== number) {
						$(row).removeClass('selected');
					}
					else {
						$(row).addClass('selected');
						var row_id = $(row).attr('id');
						// check_list_table.row("#"+row_id).show().draw(false);
					}
				});
			}
		}

	$('body').delegate('#tbl_check_list > tbody > tr', 'click', function (e) {
		var current_row = $(this).closest('tr');
		var check_list_id = current_row.data('item-id');
		var current_platform_id = $("#current_platform_id").val()
		// $.each(check_list_table.rows().nodes(), function (i, row) {
		// 	if ($(row).data('item-id') !== check_list_id) {
		// 		$(row).removeClass('selected');
		// 	}
		// 	else {
		// 		$(row).addClass('selected');
		// 	}
		// });
		$.ajax
			({
				type: 'GET',
				url: "get_list_check_content",
				dataType: 'script',
				data: {
					check_list_id: check_list_id,
				},
				success: function (data, textStatus, jqXHR) {
					// console.log(data);
					window.history.pushState({ additionalInformation: 'Updated the URL with JS' }, "My new page Title", window.location.origin + "/platforms/" + current_platform_id + "/edit?check_list_id=" + check_list_id);
					window.history.replaceState({ additionalInformation: 'Updated the URL with JS' }, "My new page Title", window.location.origin + "/platforms/" + current_platform_id + "/edit?check_list_id=" + check_list_id);
					console.log("AJAX OK!")
				},
				error: function (jqXHR, textStatus, errorThrown) {
					console.log("AJAX Error: #{textStatus}")
				}
			})
	});

	$('body').delegate('#btn_download_temp_iso_file', 'click', function (e) {
		var lst_selected = [];
		var check_content_id = $("#current_check_content_id").val();
		$.each($('.tbl-temp-iso-file  tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) lst_selected.push($(row).data('item-id'));
		});
		if (lst_selected.length == 0) return;
		window.location = 'download_multiple_iso_file?files=' + lst_selected + '&check_content_id=' + check_content_id;
	});

	$('body').delegate('#tbl_show_platform > tbody > tr', 'click', function (e) {
		var check_content_id = "";
		var platform_id = $("#current_platform_id").val();
		var check_list_id = $(this).closest('tr').find('td').last().attr("id");
		var selectRow = this;
		if ($(this).closest('tr').data('item-id')) {
			check_content_id = $(this).closest('tr').data('item-id')
		}
		$.each($('.tbl-show-platform tbody tr'), function (i, row) {
			if (selectRow !== row) {
				for (let i = 0; i < row.cells.length; i++) {

					row.cells[i].style.backgroundColor = 'white';
					row.cells[i].style.color = '#838f9c';

				}
			}
			else {
				// $(row).addClass('hover_selected');
				for (let i = 0; i < row.cells.length; i++) {
					if (!$(row.cells[i]).attr('rowspan')) {

						row.cells[i].style.backgroundColor = '#3688fc';
						row.cells[i].style.color = 'white';

					}
				}
			}
		});
		$.ajax
			({
				type: 'GET',
				url: "get_list_temp_iso_file",
				dataType: 'script',
				data: {
					check_content_id: check_content_id,
					platform_id: platform_id,
					check_list_id: check_list_id
				},
				success: function (data, textStatus, jqXHR) {
					// console.log(data);
					console.log("AJAX OK!")
				},
				error: function (jqXHR, textStatus, errorThrown) {
					console.log("AJAX Error: #{textStatus}")
				}
			})
	});

	$("body").delegate('#btn_preview_file', 'click', function (e) {
		var iso_file_id = $(this).closest('td').data('item-id');
		$.ajax({
			type: 'GET',
			url: "/preview_temp_iso_file",
			dataType: 'script',
			data: {
				iso_file_id: iso_file_id,
			},
			success: function (data, textStatus, jqXHR) {
				load_drop_zone_for_platform();
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error")
			}
		});

	});

	// $(".panel-top").resizable({
	// 	handleSelector: ".splitter-horizontal",
	// 	handles: "n,s"
	// });

	// $(".panel-show-top").resizable({
	// 	handleSelector: ".splitter-horizontal",
	// 	handles: "n,s"
	// });


	$(".ui-icon-gripsmall-diagonal-se").addClass("d-none");

	if ($("#table_check_content").children().length > 0) {
		$("#table_check_content").closest(".panel-bottom").removeClass("d-none")
		$(".panel-all").css("height", "1080px");
	}
	else {
		$("#table_check_content").closest(".panel-bottom").addClass("d-none")
		$(".panel-all").css("height", "300px");
	}

	if ($("#short_table_temp_iso_file").children().length > 0) {
		$("#short_table_temp_iso_file").closest(".panel-show-bottom").removeClass("d-none")
		$(".panel-show-all").css("height", "1080px");
	}
	else {
		$("#short_table_temp_iso_file").closest(".panel-show-bottom").addClass("d-none")
		$(".panel-show-all").css("height", "300px");
	}

	$("body").delegate('#select_option_check_content', 'click', function (e) {
		$.ajax({
			type: 'GET',
			url: "get_checkbox_status",
			dataType: 'script',
			data: {
				option_selected: $("#select_option_check_content option:selected").val(),
			},
			success: function (data, textStatus, jqXHR) {
				
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error: #{textStatus}")
			}
		})

	});

	$("body").delegate('#edit_select_option_check_content', 'click', function (e) {
		$.ajax({
			type: 'GET',
			url: "update_checkbox_status",
			dataType: 'script',
			data: {
				option_selected: $("#edit_select_option_check_content option:selected").val(),
			},
			success: function (data, textStatus, jqXHR) {
				
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error: #{textStatus}")
			}
		})

	});

	var tblShowPlatform = document.getElementsByClassName("tbl-show-platform");
	if (tblShowPlatform.length > 0) {

		tbl_show_platform = window.$('.tbl-show-platform').DataTable({
			'destroy': true,
			'columnDefs': [
				{ "width": "10%", "targets": 0 },
				{ "width": "50%", "targets": 1 },
				{ "width": "5%", "targets": 2 },
				{ "width": "35%", "targets": 3 },

			],
			"ordering": false,
			scrollY: '430px',
			scrollCollapse: true,
			paging: false,
			info: false,
			searching: false,
		});
		tbl_show_platform.draw();
		tbl_show_platform.columns.adjust();
	};


	groupTable($('#tbl_show_platform tr:has(td)'), 0, 1);
	$('#tbl_show_platform .deleted').remove();

	if ($('#tbl_show_platform tbody tr:eq(0)').attr('id')) {
		var row = $("#tbl_show_platform tbody tr:eq(0)");
		for (let i = 0; i < row.find("td").length; i++) {
			if (!$(row.find("td")[i]).attr('rowspan')) {

				row.find("td")[i].style.backgroundColor = '#3688fc';
				row.find("td")[i].style.color = 'white';

			}
		}
	}

});

function delete_Multi_Iso_file(e) {
	var mydata = [];
	$.each($('.tbl-temp-iso-file tbody tr'), function (i, row) {
		if ($(row).find("input[class='dt-checkboxes']").is(":checked")) mydata.push($(row).data('item-id'));
	});
	if (mydata.length == 0) { return; }
	$.ajax
		({
			type: 'GET',
			url: "confirm_delete_multi_temp_iso_files",
			dataType: 'script',
			data: {
				data: { ids: mydata },
			},
			success: function (data, textStatus, jqXHR) {
				// console.log(data);
				console.log("AJAX OK!")
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error: #{textStatus}")
			}
		})
}


function delete_Platform(e) {
	var mydata = [];
	$.each($('.tbl-platform tbody tr'), function (i, row) {
		if ($(row).find("input[class='dt-checkboxes']").is(":checked")) mydata.push($(row).data('item-id'));
	});
	if (mydata.length == 0) { return; }
	$.ajax
		({
			type: 'GET',
			url: "confirm_delete_multi_platform",
			dataType: 'script',
			data: {
				data: { ids: mydata },
			},
			success: function (data, textStatus, jqXHR) {
				// console.log(data);
				console.log("AJAX OK!")
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error: #{textStatus}")
			}
		})
}

function delete_Check_list(e) {
	var mydata = [];
	$.each($('.tbl-check-list tbody tr'), function (i, row) {
		if ($(row).find("input[class='dt-checkboxes']").is(":checked")) mydata.push($(row).data('item-id'));
	});
	if (mydata.length == 0) { return; }
	$.ajax
		({
			type: 'GET',
			url: "confirm_delete_multi_check_list",
			dataType: 'script',
			data: {
				data: { ids: mydata },
			},
			success: function (data, textStatus, jqXHR) {
				// console.log(data);
				console.log("AJAX OK!")
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error: #{textStatus}")
			}
		})
}

function edit_Check_list(e) {
	var mydata = [];
	var platform_id = $("#current_platform_id").val();
	mydata.push($(this).closest("tr").data('item-id'));
	if (mydata.length != 1) { return; }
	$.ajax
		({
			type: 'GET',
			url: "edit_check_list",
			dataType: 'script',
			data: {
				check_list_id: mydata[0],
				platform_id: platform_id
			},
			success: function (data, textStatus, jqXHR) {
				// 
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error: #{textStatus}")
			}
		})
}

function delete_Check_content(e) {
	var mydata = [];
	var url = window.location.href;
	var number = parseInt(url.split('=').pop());
	$.each($('.tbl-check-content tbody tr'), function (i, row) {
		if ($(row).find("input[class='dt-checkboxes']").is(":checked")) mydata.push($(row).data('item-id'));
	});
	if (mydata.length == 0) { return; }
	if (isNaN(number)){
		$.ajax
		({
			type: 'GET',
			url: "confirm_delete_multi_check_content",
			dataType: 'script',
			data: {
				data: { ids: mydata },
			},
			success: function (data, textStatus, jqXHR) {
				// console.log(data);
				console.log("AJAX OK!")
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error: #{textStatus}")
			}
		})
	}	
	else 
	{
		$.ajax
		({
			type: 'GET',
			url: "confirm_delete_multi_check_content",
			dataType: 'script',
			data: {
				data: { ids: mydata },
				check_list_id: number,
			},
			success: function (data, textStatus, jqXHR) {
				// console.log(data);
				console.log("AJAX OK!")
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error: #{textStatus}")
			}
		})
	}

}

// function edit_Check_content(e) {
// 	var mydata = [];
// 	var check_list_id = $("#current_check_list_id").val();
// 	$.each($('.tbl-check-content tbody tr'), function (i, row) {
// 		if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) mydata.push($(row).data('item-id'));
// 	});
// 	if (mydata.length != 1) { return; }
// 	$.ajax
// 		({
// 			type: 'GET',
// 			url: "edit_check_content",
// 			dataType: 'script',
// 			data: {
// 				check_content_id: mydata[0],
// 				check_list_id: check_list_id,
// 			},
// 			success: function (data, textStatus, jqXHR) {
// 				// 
// 			},
// 			error: function (jqXHR, textStatus, errorThrown) {
// 				console.log("AJAX Error: #{textStatus}")
// 			}
// 		})
// }


function saveIndexCheckList(index) {
	$.ajax
		({
			type: 'GET',
			url: "create_index_check_list",
			dataType: 'script',
			data: {
				index: index,
			},
			success: function (data, textStatus, jqXHR) {
				// console.log(data);
				console.log("AJAX OK!")
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error: #{textStatus}")
			}
		})
}

function savePlatform() {
	var tblTemp = document.getElementsByClassName("tbl-temp");
	var tmpParent = document.getElementById("platform_owner").querySelectorAll('section:not([style*="display:none"]):not([style*="display: none"]');
	for (var i = 0; i < tmpParent.length; i++) {
		tblTemp[i].id = 'tbl_temp_' + i;
		tmpParent[i].id = 'temp_parent_' + i;
		$('#' + tmpParent[i].id).find('input:eq(1)').val(i + 1);
		var current_check_content_list = document.getElementById(tblTemp[i].id).querySelectorAll('span:not([style*="display:none"]):not([style*="display: none"]');
		for (var j = 0; j < current_check_content_list.length; j++) {
			current_check_content_list[j].id = 'content_parent_' + i + '_' + j;
			$('#' + current_check_content_list[j].id).find('input:eq(1)').val(j + 1);
		}
	}
}

function renameIdPlatformClasses() {
	var tblTemp = document.getElementsByClassName("tbl-temp");
	var tmpParent = document.getElementsByClassName("temp-parent");
	var btnAddNewCheckContent = document.getElementsByClassName("btn-add-new-check-content");
	for (var i = 0; i < tblTemp.length; i++) {
		tblTemp[i].id = 'tbl_temp_' + i;
		tmpParent[i].id = 'temp_parent_' + i;
		btnAddNewCheckContent[i].id = 'btn_add_new_check_content_' + i;
		$("#" + tblTemp[i].id).sortable();
	}
}

function load_drop_zone_for_platform() {
	$(function () {
		$('.dropzone').each(function () {

			let dropzoneControl = $(this)[0].dropzone;
			if (dropzoneControl) {
				dropzoneControl.destroy();
			}
		});
		var dopzone = $('#dropzone_temp_iso_file');
		if (dopzone.length > 0) {
			$('#dropzone_temp_iso_file').dropzone({
				url: "create_temp_iso_file",
				paramName: "file",
				maxFilesize: 50,
				previewTemplate: document.querySelector('#uploadPreviewTemplate').innerHTML,

				maxFiles: 100,
				clickable: true,
				autoProcessQueue: false,
				parallelUploads: 10,
				timeout: 6000000,


				accept: function (file, done) {
					switch (file.type) {
						case 'application/pdf':
							file.previewElement.querySelector("img").src = '/assets/images/pdf.png';
							break;
						case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
							file.previewElement.querySelector("img").src = '/assets/images/word.png';
							break;
						case 'application/msword':
							file.previewElement.querySelector("img").src = '/assets/images/word.png';
							break;
						case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
							file.previewElement.querySelector("img").src = '/assets/images/excel.png';
							break;
						case 'application/vnd.ms-excel':
							file.previewElement.querySelector("img").src = '/assets/images/excel.png';
							break;
						case 'applications/ppt':
							file.previewElement.querySelector("img").src = '/assets/images/powerpoint.png';
							break;
						case 'applications/pptx':
							file.previewElement.querySelector("img").src = '/assets/images/powerpoint.png';
							break;
						case 'applications/zip':
							file.previewElement.querySelector("img").src = '/assets/images/zip_icon.png';
							break;
						default:
							file.previewElement.querySelector("img").src = '/assets/images/file.png';
							break;
					}

					done();
				},
				init: function () {
					var list_file_error = [],isSent = false;
					var myDropzone = this;
					var check_content_id = $(this.element).closest('.modal-body').find('#check_content_id_modal').text();
					
					// this.on("error", function (file, message, xhr) {
					// 	if (xhr == null) {
					// 		this.removeFile(file);
					// 	}
					// 	alert(file.name + " upload error : " + message);

					// });

					$("body").delegate('#btn_save_temp_file_platform', 'click', function () {
						// e.preventDefault();
						// e.stopPropagation();
						myDropzone.processQueue();
						window.$('#modal_temp_file').modal('hide');
					});

					this.on("addedfile", function (file) {
						document.getElementById('borrow_id').style.display = "block";
						document.getElementById('dropzone_temp_iso_file').style.display = "block";
						document.getElementById('dropzone_temp_iso_file').style.minHeight = "min-content";
					});

					this.on("sending", function (file, xhr, formData) {
						formData.append("check_content_id", check_content_id);
					});

					this.on("success", function (file, responseText) {
							isSent = true;
					});

					this.on("error", function(file, message, xhr) { 
                        // var file_error = file.name + " upload error : "+ message
                        // list_file_error.push(file_error);
						window.$('#modal_temp_file').modal('hide');
						this.removeFile(file); // perhaps not remove on xhr errors
						// var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
						// progressElement.querySelector(".progress-text").textContent = message;
						var file_error = file.name + " (" + parseFloat(file.size/1024/1024).toFixed(2) + "MiB)"
						list_file_error.push(file_error);
						if (xhr.responseText.search("ValueTooLong") > 0){
							$("#iso_file_content_warning").html("File contain Text is too long: " + list_file_error.join(', '));
						}
						else{
							$("#iso_file_content_warning").html("File is too big upload error: " + list_file_error.join(', ') + ".<br> Max filesize:50MiB.");
						}
                        window.$("#warning-alert-modal").modal('show');
                        window.$('#warning-alert-modal').on('hidden.bs.modal', function () {
                        	list_file_error = [];
                      	});

                        // alert(file.name + " upload error : "+ message);
                    });

					this.on("uploadprogress", function (file, progress, bytesSent) {
						if (file.previewElement) {
							var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
							progressElement.style.width = progress + "%";
							progressElement.querySelector(".progress-text").textContent = progress + "%";
						}

					});

					this.on("complete", function (file) {
						if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0 && isSent) {
							$.ajax
							({
								type: 'GET',
								url: "update_temp_iso_file",
								dataType: 'script',
								data: {
									check_content_id: check_content_id,
								},
								success: function (data, textStatus, jqXHR) {
									// console.log(data);
									console.log("AJAX OK!")
								},
								error: function (jqXHR, textStatus, errorThrown) {
									console.log("AJAX Error: #{textStatus}")
								}
							})
							// window.location.href = '/personal_mails?fid=' + document.getElementById('fid').value;
							window.$('#modal_temp_file').modal('hide');
							// window.opener.location.href = '/personal_mails?fid=' + document.getElementById('fid').value;
						}
					});

				}
			});

		}
	});
}

function groupTable($rows, startIndex, total) {
	if (total === 0) {
		return;
	}
	var i, currentIndex = startIndex, count = 1, lst = [];
	var tds = $rows.find('td:eq(' + currentIndex + ')');
	var ctrl = $(tds[0]);
	lst.push($rows[0]);
	for (i = 1; i <= tds.length; i++) {
		if (ctrl.text() == $(tds[i]).text()) {
			count++;
			$(tds[i]).addClass('deleted');
			lst.push($rows[i]);
		}
		else {
			if (count > 0) {
				ctrl.attr('rowspan', count);
				groupTable($(lst), startIndex + 1, total - 1)
			}
			count = 1;
			lst = [];
			ctrl = $(tds[i]);
			lst.push($rows[i]);
		}
	}
}



