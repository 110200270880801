import 'bootstrap-datepicker';
window.datepicker = function(id_or_class_datepicker,date = new Date(),isMulti = false) {
    var el = document.querySelector(id_or_class_datepicker);
    if(el){
      return defineDatePicker(el,date,isMulti);
    }
}
function defineDatePicker(el,date,isMulti){

    let multidate = el.getAttribute("multidate");
    if(multidate && multidate == 'true'){
        isMulti = true;
    }
    if(multidate && multidate == 'false'){
        isMulti = false;
    }
    var dp = $(el).datepicker({
        language: get_locate(),
        sideBySide: true,
        autoclose: true,
        multidate: isMulti,

    }).on('changeDate', function(e) {
        var dateString = moment(e.date).format('YYYY-MM-DD');
        if(el.getAttribute("value") != dateString){
            el.setAttribute("value",dateString);
        }

    });

    let dateString = el.getAttribute("value");
    if(dateString){
        let [y,M,d] = dateString.split(/[- :]/);
        let yourDate =  new Date(y,parseInt(M)-1,d);
        $(el).datepicker('setDate', yourDate);
    }
    else{
        $(el).datepicker('setDate', date);
    }

    var icondate = el.closest('div').querySelector('.input-group-append');
    if(icondate){
        icondate.addEventListener('click',function(e){
            el.focus();
        })
    }

    // $(el).on('change', function() {
    //     try{
    //         let [y,M,d] = this.value.split(/[- :]/);
    //         let yourDate =  new Date(y,parseInt(M)-1,d);
    //         $(el).datepicker('setDate', yourDate);
    //     }
    //     catch{}

    // });
    return dp[0];
}
$(document).on('turbo:load', function () {
    format_vi();
    format_en();
    format_jp();
    var listDatePicker = document.querySelectorAll('[type=datepicker]');
    for (let index = 0; index < listDatePicker.length; index++) {
        const element = listDatePicker[index];
        defineDatePicker(element,new Date(),false);

    }

})




function format_jp(){
    $.fn.datepicker.dates['ja'] = {
        days: ['日曜日','月曜日','火曜日','水曜日','木曜日','金曜日','土曜日'],
        daysShort: ['日','月','火','水','木','金','土'],
        daysMin: ['日','月','火','水','木','金','土'],
        // months: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
        // monthsShort: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
        months: [1,2,3,4,5,6,7,8,9,10,11,12],
        monthsShort: [1,2,3,4,5,6,7,8,9,10,11,12],
        today: "今日",
        clear: "Clear",
        format: "yyyy年mm月dd日",
        titleFormat: "yyyy年MM月", /* Leverages same syntax as 'format' "%Y年%m月%d日" */
        weekStart: 0
    }
}
function format_en(){
    $.fn.datepicker.dates['en'] = {
        days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        daysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        months: [1,2,3,4,5,6,7,8,9,10,11,12],
        monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        today: "Today",
        clear: "Clear",
        format: "yyyy/mm/dd",
        titleFormat: "yyyy/mm", /* Leverages same syntax as 'format' */
        weekStart: 0
    };
}
function format_vi(){
    $.fn.datepicker.dates['vi'] = {
        days: ["Chủ nhật", "Thứ 2", "Thứ 2", "Thứ 2", "Thứ 2", "Thứ 2", "Thứ 7"],
        daysShort: ["CN", "T.2", "T.3", "T.4", "T.5", "T.6", "T.7"],
        daysMin: ["CN", "T.2", "T.3", "T.4", "T.5", "T.6", "T.7"],
        months: [1,2,3,4,5,6,7,8,9,10,11,12],
        monthsShort: ["Thg 1", "Thg 2", "Thg 3", "Thg 4", "Thg 5", "Thg 6", "Thg 7", "Thg 8", "Thg 9", "Thg 10", "Thg 11", "Thg 12"],
        today: "Hôm nay",
        clear: "Xóa",
        format: "dd/mm/yyyy",
        titleFormat: "mm/yyyy", /* Leverages same syntax as 'format' */
        weekStart: 0
    };

}
