
$(document).on('turbo:load', function(){    
		const _btnDelete = document.querySelector("#btn_delete_group");
		const _btnEdit = document.querySelector('#btn_edit_group');
		
		if(_btnDelete)
		{
		_btnDelete.addEventListener("click",delete_Group);	

		}
		if(_btnEdit)
		{
				_btnEdit.addEventListener("click",edit_Group);	

		}
		
		function edit_Group(e)
		{
				var mydata = [];
				$.each($('.tbl_group tbody tr'), function (i, row) {
						if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) mydata.push($(row).data('item-id'));
				});
				// console.log(mydata.length);
				if(mydata.length!=1) 
				{return;}
				// $("input[name='department[flg]']:checked").closest('tr').each(function() {
				$.ajax
				({
						type: 'GET',
						url: "update_group",
						dataType: 'script',
						data: {
								id: $("input[class='dt-checkboxes']:checked").closest('tr').attr("data-item-id")
						},
						success: function(data, textStatus, jqXHR){
								// 
						},
						error:function(jqXHR, textStatus, errorThrown){
								console.log("AJAX Error: #{textStatus}")
						}
				})
		}

		function delete_Group(e)
		{
				var mydata = [];
				$.each($('.tbl_group tbody tr'), function (i, row) {
						if ($(row).find("input[class='dt-checkboxes']").is(":checked")) mydata.push($(row).data('item-id'));
				});
				// console.log(mydata);
				if(mydata.length==0) 
				{return;}
				$.ajax
				({
						type: 'GET',
						url: "delete_group",
						dataType: 'script',
						data: {data:mydata},
						success: function(data, textStatus, jqXHR){
								// console.log(data);
								console.log("AJAX OK!")
						},
						error:function(jqXHR, textStatus, errorThrown){
								console.log("AJAX Error: #{textStatus}")
						}
				})
		}
// Add company stamp 
	$("body").delegate('#btn_add_stamp', 'click', function() {
		var rdStamp = document.querySelector('input[name = stamp]:checked').value;
		if (rdStamp == 'company_seal') {
			$.ajax({
				type: 'GET',
				url: "add_company_stamp",
				dataType: 'script',
				data: {},
				success: function(data, textStatus, jqXHR){
					
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error: #{textStatus}")
				}
			});
		}
	});
	$('.edit_group').submit(function() {
		// DO STUFF...
		$("#module_selected[multiple] option").prop("selected", "selected");


		return true; // return false to cancel form action
	});
	
	$("body").delegate('#btn_add_stamp_edit', 'click', function() {
		var rdStamp = document.querySelector('input[name = temp]:checked').value;
		if (rdStamp == 'company_seal') {
			$.ajax({
				type: 'GET',
				url: "add_company_stamp",
				dataType: 'script',
				data: {},
				success: function(data, textStatus, jqXHR){
					
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error: #{textStatus}")
				}
			});
			// showModal(window.location.origin + "/add_company_stamp");
		}
	});

	// $("#file_company_stamp").change(previewStamp);
	// $('body').delegate('#del_img', 'click', function() {
	// 	$("#preview_stamp").empty();
	// 	$("#file_company_stamp").val("");
	// 	document.getElementById('filename_companyStamp').innerHTML = "No file chosen";
	// 	document.getElementById('filename_selected').value = "";
	// });

	// $('body').delegate('#btn_delete_companyStamp', 'click', function() {
	// 	$(".image_stamp").empty();
	// 	document.getElementById('filename_companyStamp').innerHTML = "No file chosen";
	// 	document.getElementById('filename_selected').value = "";
	// });
	
	// $('body').delegate('#file_company_stamp', 'change', function() {
	// 	$(".image_company_stamp").remove();
	// 	var input = this;
	// 	if (input.files && input.files.length) {
	// 		var reader = new FileReader();
	// 		this.enabled = false
	// 		reader.onload = (function (e) {
	// 			$("#preview_stamp").html(['<img class="size_img" src="'+e.target.result+'" /><span><button type="button" class="btn btn-transparent dripicons-cross pt-0 custom-btn-remove" id="del_img" ></button></span>'].join(''))
	// 		});
	// 		reader.readAsDataURL(input.files[0]);
	// 		var path = document.getElementById('file_company_stamp').files[0].name;
	// 		var decodePath = decodeURIComponent(path);
	// 		document.getElementById('filename_companyStamp').innerHTML = decodePath;
	// 		document.getElementById('filename_selected').value = decodePath;
	// 	}
	// });

	$('body').delegate('#stamp_company', 'dblclick', function() {
		$.ajax({
			type: 'GET',
			url: "edit_company_stamp",
			dataType: 'script',
			data: {},
			success: function(data, textStatus, jqXHR){
				;
				var src = document.getElementById('stamp_company').attributes[2].value
				var filename = src.split("/")[src.split("/").length-1];
				var decodePath = decodeURIComponent(filename);
			document.getElementById('filename_companyStamp').innerHTML = decodePath;
				// document.getElementById('filename_companyStamp').innerHTML = filename;
				document.getElementById('filename_selected').value = decodePath;
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error: #{textStatus}")
			}
		});
	});

	// $('body').delegate('#btnSave_company', 'click', function() {
	//   var filename = document.getElementById('filename_companyStamp').innerHTML;
	//   $.ajax({
	//     type: 'GET',
	//     url: "update_company_stamp",
	//     dataType: 'script',
	//     data: {
	//       filename_company_stamp: filename
	//     },
	//     success: function(data, textStatus, jqXHR){
	//       
	//     },
	//     error:function(jqXHR, textStatus, errorThrown){
	//       console.log("AJAX Error: #{textStatus}")
	//     }
	//   });
	// });
	try{
		if ($('.tbl_group').length > 0) {
			window.$('.tbl_group').DataTable({
				'destroy': true,
				"lengthMenu": [5,10,15,20],
				"pagingType": "simple_numbers",
				language: {
					paginate: {
						next: '<i class="mdi mdi-chevron-right"></i>',
						previous: '<i class="mdi mdi-chevron-left"></i>'
					}
				},
				'columnDefs': [
						{
							'targets': 0,
							'checkboxes': {
								'selectRow': true
							}
						}
				],
				'select': {
					'style': 'multi'
				},
				'order': [[1, 'asc']]
			});
		}
    }
    catch{window.location.reload();}
	
});

// function previewStamp() {
// 	var input = this;
// 	if (input.files && input.files.length) {
// 		var reader = new FileReader();
// 		this.enabled = false
// 		reader.onload = (function (e) {
// 			$("#preview_stamp").html(['<img class="size_img" src="'+ e.target.result+ '" /><span><button type="button" class="btn btn-transparent dripicons-cross pt-0 custom-btn-remove" id="del_img" ></button></span>'].join(''))
// 		});
// 		reader.readAsDataURL(input.files[0]);
// 		var path = document.getElementById('file_company_stamp').files[0].name;
// 		document.getElementById('filename_companyStamp').innerHTML = path;
// 		document.getElementById('filename_selected').value = path;
// 	}
// }
