const app = {
    pageStart: 0,
    scaleStart:  1,
    verticalScrollTop:  0,
    horizontalScrollPosition:  0,
    currentScale: 1,
    url: "",
    data:  null
}


const config_addtext = {
    fontSize:20,
    fontFamily: "Times New Roman",
    value: "",   
    fill:"#2B00FF",       
    fontWeight: "normal",
    boldText:   0,
    italicText: 0    
}


const config_highlight = {
    color:"rgb(255, 213, 0)",
    opacity: 0.3,
    name: 'highlight'
}

const config_strokeout = {
    color:"rgb(255, 213, 0)",
    opacity: 1,
    name: 'strokeout'
}

const config_underline = {
    color:"rgb(255, 213, 0)",
    opacity: 1,
    name: 'underline'
}

const config_Text= {
    addtext:config_addtext,
    highlight: config_highlight       ,
    strokeout:config_strokeout,
    underline:config_underline
}

const config_Stamp = {
    width: 100,
    height: 100
}

const config_link = {
    url:'https://example.com',
    mail: '' ,
    phone: '' ,
    page: 2,
    radio: 0
}
const config_pencil = {
    color:'rgb(255, 213, 0)',
    opacity: 0.3 ,
    strokeWidth:12,
    dash:null,
}
const config_freedraw = {
    color: '#3688fc',
    opacity: 0.9,
    strokeWidth: 4 ,
    dash:null,
}
// form
const config_xmark = {
    width: 20,
    height:20,
    origin_width: 100  ,
    origin_height: 100  ,
    opacity: 1,
    color:'#FF0000',
}
const config_checkmark = {
    width: 20 ,
    height: 20  ,
    origin_width: 100  ,
    origin_height: 100 ,
    opacity: 1,
    color: '#00FF00',
}

const config_dot = {
    width: 20,
    height:20  ,
    origin_width: 100,
    origin_height: 100,
    opacity: 1,
    color: '#2B00FF'
}

const config_textfield = {
    width: 200,
    height: 40,
    color: '#2B00FF',
    borderColor: '#2B00FF',
    borderWidth:  1
}
const config_textarea = {
    width: 200  ,
    height: 50,
    color: '#2B00FF',
    borderColor:'#2B00FF',
    borderWidth: 1
}
const config_radio = {
    width: 40,
    height:40 ,
    backGroundColor: '',
    color: '#2B00FF',
    borderColor: '#2B00FF',
    borderWidth: 1
}

const config_checkbox = {
    width: 40  ,
    height: 40,
    backGroundColor: '',
    color: '#2B00FF',
    borderColor: '#2B00FF',
    borderWidth: 1
}
const config_dropdown = {
    width: 200 ,
    height: 40,
    backGroundColor: '',
    color:'#2B00FF',
    borderColor:'#2B00FF',
    borderWidth: 1
}

const config_form = {
    xmark: config_xmark,
    checkmark:config_checkmark,
    dot: config_dot,
    textfield: config_textfield,
    textarea:config_textarea,
    radio:config_radio,
    checkbox:config_checkbox,
    dropdown:config_dropdown,
}


const config_line = {
    dash: null,
    strokeWidth: 4,
    backGroundColor: "#FFE13A"  ,
    opacity: 1
}

const config_arrow = {
    dash: null ,
    size: 4,
    line_width: 1,
    style: 1, //2:dashed,
    position_arrow: 1, // 1 right, 2: left and right,
    color:"#FF0000",
    opacity: 1
}
const config_ellipse = {   
    dash: null,
    stroke:"#2B00FF",
    strokeWidth: 1,
    opacity: 1
}
const config_rectangle = {
    dash:null,
    stroke:"#2B00FF",
    strokeWidth: 1,
    opacity: 1
}

const config_shapes = {
    line:config_line,
    arrow:config_arrow,
    ellipse:config_ellipse,
    rectangle:config_rectangle
}


const Config = {app:  app, text: config_Text,stamp: config_Stamp,link: config_link, pencil:config_pencil,
    form: config_form, shape:config_shapes, freedraw:config_freedraw}
export {Config};