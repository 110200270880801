import { Convert } from './convert';
$(document).on('turbo:load', function() {
	var fileSelectd,currentPageIndex,signalFabricCanvas;
	if ($("#view_custom_signal").length > 0) {
		loadSignal();
	}

	// click button show modal custom signal
	window.$("#modal_add_signals_custom").on("shown.bs.modal", function () {
    viewStampcreateListSinalPreview();
    viewStampdefineDrawSignal();
  });

	// event change text signal
  $("body").delegate('#txtSignatureViewStamp', 'input', function(ev) {
    viewStampcreateListSinalPreview();
  });

  // event change color text signal
  $("body").delegate('#signatureTypeColorPickerViewStamp .color-signal', 'click', function(ev) {
    var active = 'selected';
    if(!this.classList.contains(active)){        
        $('.color-signal').removeClass(active);
        this.classList.add(active);
    }     
    viewStampcreateListSinalPreview();
  });

  // change color draw text signal
  $("body").delegate('#signatureDrawColorPickerViewStamp .color-signal-draw', 'click', function(ev) {
    var active = 'selected';
    if(!this.classList.contains(active)){        
        $('.color-signal-draw').removeClass(active);
        this.classList.add(active);
    }     
    var color = this.style.color;
    signalFabricCanvas.freeDrawingBrush.color = color;
  });

  // select signal
  $("body").delegate('.signaturePreviewViewStamp', 'click', function(ev) {
    var active = 'selected';
    if(!this.classList.contains(active)){        
        $('.signaturePreviewViewStamp').removeClass(active);
        this.classList.add(active);
    }     
    viewStampcreateListSinalPreview();
  });

  $("body").delegate('#btnSaveSignalsViewStamp', 'click',viewStampsaveSignals);
  $("body").delegate('#view_custom_signal .signal-delete', 'click', viewStampdeleteSignal);
  $("body").delegate('#btnClearSignalViewStamp', 'click', viewStampclearSignal) ;

  function viewStampcreateListSinalPreview(){
	  var divPreview = document.getElementById('signaturePreviewWrapViewStamp');
	  var colorElementSelected = document.getElementById('signatureTypeColorPickerViewStamp').querySelector('.selected');
	  var color = colorElementSelected.style.color;
	  var text = document.getElementById('txtSignatureViewStamp').value;
	  var divPreview = document.getElementById('signaturePreviewWrapViewStamp');
	  divPreview.style.color = color;
	  var listSinalPreview = divPreview.querySelectorAll('.signaturePreviewViewStamp');
	  for (let index = 0; index < listSinalPreview.length; index++) {
	    const element = listSinalPreview[index];
	    element.innerHTML = text;    
	  }  
	}

	function viewStampdefineDrawSignal() { 
	  if(!signalFabricCanvas){
	    var signatureDrawCanvas = document.getElementById('signatureDrawCanvasViewStamp');
	    signalFabricCanvas = new fabric.Canvas(signatureDrawCanvas); 
	    signalFabricCanvas.stateful = true;    
	    signalFabricCanvas.isDrawingMode = true;
	    signalFabricCanvas.freeDrawingBrush = new fabric['PencilBrush'](signalFabricCanvas);
	    signalFabricCanvas.freeDrawingBrush.width = 4;
	    var colorElementSelected = document.getElementById('signatureDrawColorPickerViewStamp').querySelector('.selected');
	    var color = colorElementSelected.style.color;
	    signalFabricCanvas.freeDrawingBrush.color = color;
	  }
	  else{
	    signalFabricCanvas.clear();
	  }
	}

	function viewStampsaveSignals(){
    var tabName = $('#modal_add_signals_custom .nav-tabs .active');
    var tabId = tabName[0].id;
    if(tabId == 'tabText'){
      viewStampsaveTabText();
    }
    if(tabId == 'tabDraw'){
      viewStampsaveTabDraw();
    } 
  }

  function viewStampsaveTabText() {
    var divPreview = document.getElementById('signaturePreviewWrapViewStamp');
    var colorElementSelected = document.getElementById('signatureTypeColorPickerViewStamp').querySelector('.selected');
    var color = Convert.rgbToHex( colorElementSelected.style.color);
    var text = document.getElementById('txtSignatureViewStamp').value;
    var divPreview = document.getElementById('signaturePreviewWrapViewStamp');
    var font_family = divPreview.querySelector('.selected').style.fontFamily;
    var objectDataSubmit = {color: color,text:text,font_family:font_family};
    // AjaxSubmit("/pdf/save_signal",'GET',objectDataSubmit);  
    $.ajax({
      url:window.location.origin + "/pdf/save_signal",
      type: "GET",
      data: objectDataSubmit,
      dataType: 'script',  
      success: function(data, textStatus, jqXHR){
       // console.log("AJAX success!")
       window.$("#modal_add_signals_custom").modal('hide');
     },
     error:function(jqXHR, textStatus, errorThrown){
         console.log("AJAX Error")
     }
   });
  }

  function viewStampsaveTabDraw() {
    var dataURL = signalFabricCanvas.toDataURL(); 
    // Get our file
    // var file= viewStampdataURLtoBlob(dataURL);
    // Create new form data
    var fd = new FormData();
    // Append our Canvas image file to the form data
    fd.append("image", dataURL);
    // And send it
    // var objectDataSubmit = {data: fd};
    // AjaxSubmit("/pdf/upload_signal",'POST',objectDataSubmit);  
    $.ajax({
       url:window.location.origin + "/pdf/upload_signal",
       type: "POST",
       data: fd,
       dataType: 'script',
       processData: false,
       contentType: false,
       success: function(data, textStatus, jqXHR){
        // console.log("AJAX success!")
        window.$("#modal_add_signals_custom").modal('hide');
      },
      error:function(jqXHR, textStatus, errorThrown){
          console.log("AJAX Error")
      }
    });
  }

  function viewStampdeleteSignal(){
    var id = this.closest(".row-signal-element").querySelector('.signal_ID').value;
    var objectDataSubmit = {id: id};
    AjaxSubmit("/pdf/deleteSignal",'GET',objectDataSubmit);  
  }

  function viewStampclearSignal() {
    var m = confirm("Do you want to clear?");
    if (m) {
      signalFabricCanvas.clear();
    }
  }
});


function viewStampdataURLtoBlob(dataURL) {
    // Decode the dataURL
    var binary = atob(dataURL.split(',')[1]);
    // Create 8-bit unsigned array
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    // Return our Blob object
    return new Blob([new Uint8Array(array)], {type: 'image/png'});
  }
