$(document).on('turbo:load', function() {
	if ( $("#top-page-information-content-editor").length > 0) {
		settingTopPageInformationQuill();
	}
});

function settingTopPageInformationQuill() {
	var quill = new Quill('#top-page-information-content-editor', {
		theme: 'snow',
		modules: {
			'toolbar': [
        [{ 'font': [] }, { 'size': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'super' }, { 'script': 'sub' }],
        [{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'blockquote', 'code-block'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['direction', { 'align': [] }],
        ['link', 'image', 'video'],
        ['clean']
    	]
		},
	});

	var form = document.querySelector('.form-top-page-information');		
	form.onsubmit = function() {
		var contentInput = document.querySelector('#top_page_information_content');
		contentInput.value = quill.root.innerHTML;
	};
}