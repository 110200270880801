import { ANNOTATION } from "../edit/annotation";

export var annotation_helper = {};
annotation_helper.toObject = function(element,view){
    switch(element.annotation){
        case ANNOTATION.ADD_LINK:
            save_link(element,view)
            break;
        default:
            save_by_type(element,view);
            break;
    }
    
}
annotation_helper.config = {
    line: 
    {
        fix_config: function(config){        
            config.selectable = true
            config.hasControls = false
            config.hasBorders = false,
            config.centeredRotation = false
            config.centeredScaling = false
            config.perPixelTargetFind = true            
            config.element = 0
            return config;
        }
    }  
}

annotation_helper.get_annotations_by_index = function(page_index){
    var list_annos = null,annotation,url;
    var output = []
    var id_page_annos = "annotation-id-" + (page_index+1);
    var page_annos = el(id_page_annos);
    if(page_annos){
        list_annos = page_annos.querySelectorAll('input') ;                 
    }   
    if(list_annos && list_annos.length > 0){

        for (let index = 0; index < list_annos.length; index++) {
            annotation = JSON.parse(list_annos[index].value);
            url = list_annos[index].getAttribute("src");
            setPermissionAWS(annotation,url)
            output.push(annotation);
        }
    }  
    return output;
}
function setPermissionAWS(annotation,url) {
    if(url)
    {
        annotation.src = url;
    }   
    if(!url && annotation.src){
        var oldUrl = annotation.src;
        try {
            var url = new URL(oldUrl);
            url.hostname = `${document.body.getAttribute('data-s3-bucket')}.s3.ap-northeast-1.amazonaws.com`;
            annotation.src = url.href;
        } catch (error) {
            annotation.src = `https://${document.body.getAttribute('data-s3-bucket')}.s3.ap-northeast-1.amazonaws.com` + oldUrl;
        }
       
    }   
    // annotation.src = "https://sanzes3.s3.ap-northeast-1.amazonaws.com/sanze_datas/kdc/uploads/pdf_page/path/7088/image20240814-53497-mgxbpf.png"
}
function save_link(element,view){
    element.toObject = (function(toObject) {                
        return function() {
            return fabric.util.object.extend(toObject.call(this), {
                scale: view.scale,
                scaleX: element.scaleX,
                scaleY: element.scaleY,
                rotate: view.rotate,
                origin_strokeWidth: element.origin_strokeWidth,
                origin_strokeDashArray: element.origin_strokeDashArray,
                id: element.id,
                page: element.page,
                name: element.name,
                aCoords: element.aCoords,
                annotation: element.annotation,                
                link_type: element.link_type,
                user_id: element.user_id
            });
        };
    })(element.toObject); 
}

function save_by_type(element,view){
    switch(element.type)
    {          
        case 'image':
            element.toObject = (function(toObject) {       
                if(element.src)  
                {
                    return function() {
                        return fabric.util.object.extend(toObject.call(this), {
                            scale: view.scale,
                            rotate: view.rotate,
                            id: element.id,
                            user_id: element.user_id,
                            name: element.name,
                            aCoords: element.aCoords,
                            annotation: element.annotation,
                            src: element.src,
                            page: element.page,
                            stroke: element.stroke,
                            strokeWidth:  element.strokeWidth,
                            typeStamp:element.typeStamp,
                            fontSize: element.fontSize,
                            hasDate: element.hasDate,
                            formatDate: element.formatDate,
                            color: element.color,
                            x_relative: element.x_relative,
                            y_relative: element.y_relative    
                        });
                    };
                }       
                else{
                    return function() {
                        return fabric.util.object.extend(toObject.call(this), {
                            scale: view.scale,
                            rotate: view.rotate,
                            id: element.id,
                            user_id: element.user_id,
                            name: element.name,
                            aCoords: element.aCoords,
                            annotation: element.annotation,
                            src: element.src,
                            page: element.page,
                            stroke: element.stroke,
                            strokeWidth:  element.strokeWidth,
                            typeStamp:element.typeStamp,
                            fontSize: element.fontSize,
                            hasDate: element.hasDate,
                            formatDate: element.formatDate,
                            color: element.color,
                            x_relative: element.x_relative,
                            y_relative: element.y_relative 
                        });
                    };
                }
                
            })(element.toObject); 
            break;      
            
        case 'line':  
            element.toObject = (function(toObject) {                
                return function() {
                    return fabric.util.object.extend(toObject.call(this), {
                        scale: view.scale,
                        rotate: view.rotate,
                        id: element.id,
                        page: element.page,
                        user_id: element.user_id,
                        annotation: element.annotation,
                        parent_id: element.parent_id,
                        name: element.name,
                        aCoords: element.aCoords,
                        element: element.element,
                        radius: element.radius,                
                        origin_strokeWidth: element.origin_strokeWidth,
                        origin_strokeDashArray: element.origin_strokeDashArray,                                
                        head1: {x: element.head1.getCenterPoint().x,y: element.head1.getCenterPoint().y},
                        head2: {x: element.head2.getCenterPoint().x,y: element.head2.getCenterPoint().y},
                        number_head: element.number_of_head
                    });
                };
            })(element.toObject); 
            break;        
        default:
            element.toObject = (function(toObject) {                
                return function() {
                    return fabric.util.object.extend(toObject.call(this), {
                        scale: view.scale,
                        scaleX: element.scaleX,
                        scaleY: element.scaleY,
                        rotate: view.rotate,
                        origin_strokeWidth: element.origin_strokeWidth,
                        origin_strokeDashArray: element.origin_strokeDashArray,
                        parent_id: element.parent_id,
                        id: element.id,
                        page: element.page,
                        user_id: element.user_id,
                        name: element.name,
                        aCoords: element.aCoords,
                        annotation: element.annotation,
                        position_x: element.aCoords.tl.x,
                        position_y: element.aCoords.tl.y,
                        originFontSize: element.originFontSize
                    });
                };
            })(element.toObject); 
            break;

    }    

}