var maxPage = 0;

$(document).on('turbo:load', function() { 
    
    $("body").delegate('#btn_select_from_complete_for_decompose', 'click', function () {
	
		$.ajax({
			type: 'GET',
			url: "select_from_complete_for_decompose",
			dataType: 'script',
			
			success: function(data, textStatus, jqXHR){
				;
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error: #{textStatus}")
			}
		});
	});
 
  $("body").delegate('#btnPushToLeft', 'click', function () {    
    pushDataToRight();
  });
 
  $("body").delegate('#ckSelectAll', 'change', function () {
    var page;
    var form_image_input = document.getElementById("form_image_input");
    var allPage = form_image_input.querySelectorAll(".pdf-thump-left");   
    if (this.checked) {     
      for (let i = 0; i < allPage.length; i++) {
        page = allPage[i];
        var selected = page.getAttribute("selected");
        if(selected != "true"){
          page.setAttribute("selected",true);
          page.classList.add("page_selected");
          $(page).closest('.thumbnail-frame').find(".thumbnailNumber").addClass("number-page-selected");
        }        
      }

    } else {
      for (let i = 0; i < allPage.length; i++) {
        page = allPage[i];
        var selected = page.getAttribute("selected");
        if(selected == "true"){
          page.setAttribute("selected",false);
          page.classList.remove("page_selected");
          $(page).closest('.thumbnail-frame').find(".thumbnailNumber").removeClass("number-page-selected");
        }        
      }
    }
  }); 
  $("body").delegate('#btnMoveUpDecomposePDF', 'click', function () {
    $('#lstRightItems .page_selected').each(function (i, selected) {
      var thumbnail = this.closest(".thumbnail-frame");
      if (thumbnail.previousElementSibling == null) return false;
      $(thumbnail).insertBefore(thumbnail.previousElementSibling);
    });
   
  });
  
  $("body").delegate('#btnMoveDownDecomposePDF', 'click', function () {
    $('#lstRightItems .page_selected').each(function (i, selected) {
      var thumbnail = this.closest(".thumbnail-frame");
      if (thumbnail.nextElementSibling == null) return false;
      $(thumbnail).insertAfter(thumbnail.nextElementSibling);     
    });   
  });
  
  $("body").delegate('#btnMoveTopDecomposePDF', 'click', function () {
    var count_category = 0;
    $.each($('#lstRightItems .page_selected'), function (i, row) {
      count_category = count_category + 1
    });
    if (count_category > 0){
      
      if($("#lstRightItems .box canvas").first().length != 0)
      var first_elem = $("#lstRightItems .box canvas").first()[0];
      if(first_elem.getAttribute("selected") == "true") return true;
      while($("#lstRightItems .box canvas").first()[0].getAttribute("selected") != "true"){
        $('#lstRightItems .page_selected').each(function (i, selected) {   
          var thumbnail = this.closest(".thumbnail-frame");
          if (thumbnail.previousElementSibling == null) return false;
          $(thumbnail).insertBefore(thumbnail.previousElementSibling);
        }); 
      }      
      
    }
  });
  
  $("body").delegate('#btnMoveBottomDecomposePDF', 'click', function () {	
    var count_category = 0;
    $.each($('#lstRightItems .page_selected'), function (i, row) {
      count_category = count_category + 1
    });
    if (count_category > 0){
      if($("#lstRightItems .box canvas").last().length != 0)
      var last_elem =$("#lstRightItems .box canvas").last()[0];
      if(last_elem.getAttribute("selected") == "true") return true;
      while($("#lstRightItems .box canvas").last()[0].getAttribute("selected") != "true"){
        $($('#lstRightItems .page_selected').get().reverse()).each(function (i, selected) {
          var thumbnail = this.closest(".thumbnail-frame");
          if (thumbnail.nextElementSibling == null) return false;
          $(thumbnail).insertAfter(thumbnail.nextElementSibling);
        });
      }
    }
  });

  $("body").delegate('#btnDeleteDecomposePDF', 'click', function () {
    $('#lstRightItems .page_selected').each(function (i, selected) {  
      var thumbnail = this.closest(".thumbnail-frame");
      thumbnail.remove(); 
    })
  });

  $("body").delegate('#btnComposePDF', 'click', function () {
		var lst = [];
    var lstNumPage = [];
    var item =""
    $.each($('#lstRightItems .box'), function (i, row) {     
      lst.push(this.getAttribute("data-path"));
      lstNumPage.push(this.getAttribute("num"));
    });		
		if(lst.length == 0) return;
    $.ajax({
			type: 'POST',
			url: "compose_pdfs_to_one",
			dataType: 'script',
			data: {
				listPath: lst,
        listNum: lstNumPage
			},
			success: function(data, textStatus, jqXHR){
				;
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error: #{textStatus}")
			}
		});
		// window.location.href = window.origin + '/pdf/compose_pdfs_to_one?listPath='+lst + '&listNum=' + lstNumPage;
	}); 
  $("body").delegate('#btn_push_pdf_from_computer', 'click', function () {		
		var listIdElem = document.querySelectorAll('.id_file_pdf');
		var item,pdf_id,filename;
    var lstPath = [];
		var form_name = document.getElementById("form_name").value;
    $(".btn_close_modal").click();
		if(form_name == "decompose_documents"){
      for(let i = 0;i< listIdElem.length;i++){
				item = listIdElem[i];
				if(item == null) continue;
				
				filename = item.getAttribute("data-path");
				if(filename != null && filename != ""){							
					lstPath.push(decodeURIComponent(filename));
				}
			}
      setLeft(lstPath);
		}
		else{
			var option;
			var select = document.getElementById("lstPdfMerge");
			for(let i = 0;i< listIdElem.length;i++){
				item = listIdElem[i];
				if(item == null) continue;
				pdf_id = item.value;
				filename = item.getAttribute("data-name");
				if(pdf_id != null && pdf_id != ""){
					option = document.createElement("option");
					option.text = filename;
					option.value = pdf_id;				
					select.appendChild(option);
				}
			}
		}
		
		
	});
}); 
export async function setLeft(lstPath){
  for await (const pathFile of lstPath) {
    getUrl(pathFile);      
  }
}


async function pushToRight(listCanvasSelected){
  for await (const c of listCanvasSelected) {
    maxPage++;
    createRight(c);
  }
}

function pushDataToRight(){
  var form_image_input = document.getElementById("form_image_input");
  var listCanvasSelected = form_image_input.querySelectorAll(".page_selected");
  
  var list_right = document.getElementById("lstRightItems");
  list_right.replaceChildren();
  maxPage = 0;
  pushToRight(listCanvasSelected);
}
function createRight(canvasSelected){
  
  var dv = document.createElement("div");   
   dv.id="thumbanil-frame-"+ maxPage;
   dv.className="thumbnail-frame ms-1 me-1 d-flex flex-column btn-fit-content";
  // Label
  var lBel = document.createElement("label");
  lBel.className="m-0"
  lBel.textContent= maxPage;
  lBel.style.padding=0;
  lBel.style.width="100%";
  lBel.style.textAlign="center";
  lBel.style.color="green";
  

  var list_right = document.getElementById("lstRightItems");
  var backCanvas = document.createElement('canvas');
  backCanvas.className= "pdf-thump box";
  backCanvas.id = "child_img";
  var parentFieldTag = canvasSelected.parentElement.parentElement.parentElement;
  var path_file  = parentFieldTag.getAttribute("data-path");
  backCanvas.setAttribute("data-path",path_file);

  var numberOfPage = canvasSelected.getAttribute("num");
  backCanvas.setAttribute("num",numberOfPage);
  backCanvas.width = canvasSelected.width;
  backCanvas.height = canvasSelected.height;
  backCanvas.addEventListener('click', page_click, false);
  backCanvas.addEventListener('contextmenu', event => event.preventDefault());

      
  var backCtx = backCanvas.getContext('2d');
  backCtx.drawImage(canvasSelected, 0,0);
  dv.appendChild(backCanvas);
  dv.appendChild(lBel);
  list_right.appendChild(dv);

}
var touchtime = 0;
function page_click(event){
  if (touchtime == 0) {
    // set first click
    touchtime = new Date().getTime();
    right_Items_Image_Click(event);
  } else {
      // compare first click to this click and see if they occurred within double click threshold
      if (((new Date().getTime()) - touchtime) < 300) {
          // double click occurred
          var path = event.target.getAttribute("data-path");
          if(path == null || path == ""){ // list left
            var parentFieldTag = event.target.parentElement.parentElement.parentElement;
            path = parentFieldTag.getAttribute("data-path");
          }
          var numpage = event.target.getAttribute("num")
          right_Items_Image_Click(event);
          view_page(path,numpage);
          
          touchtime = 0;
      } else {
          // not a double click so set as a new first click
          touchtime = new Date().getTime();
          right_Items_Image_Click(event);
      }
  }
}


function right_Items_Image_Click(event)
{
  var selected = event.target.getAttribute("selected");
  if(selected != "true"){
    event.target.setAttribute("selected",true);
    event.target.classList.add("page_selected");
  }
  else{
    event.target.setAttribute("selected",false);
    event.target.classList.remove("page_selected");
  }  
}
function view_page(path_file,numberOfPage){    
  window.$("#modal_view_page").modal('toggle');
  view_detail_page(path_file,numberOfPage);

}
async function view_detail_page(path_file,numberOfPage){
  const formUrl = window.location.origin + path_file;
  var pdfBytes = await loadFile(formUrl) ;   
  const pdfDoc = pdfjsLib.getDocument({
    data: pdfBytes,
    cMapUrl: '/cmaps/',
    cMapPacked: true
  });
  pdfDoc.promise.then(function (doc) {  
   return doc.getPage(parseInt(numberOfPage)).then(createCanvasViewPage);
  }).catch(console.error);    
}
async function getUrl(path){
    const formUrl = window.location.origin + path;
    var pdfBytes = await loadFile(formUrl) ;   
    displayPdf(pdfBytes,path);    
}
async function loadFile(formUrl){
  return  await fetch_data(formUrl); 
}
function displayPdf(PdfBytes,path)
{ 
  var form_image_input = document.getElementById("form_image_input");
  var fieldset_tag = document.createElement("fieldset");
  fieldset_tag.setAttribute("data-path",path);
  fieldset_tag.className = "fieldset_tag";
  var legent_tag = document.createElement("legend");
  legent_tag.className = "legent_tag";
  var filename = path.replace(/^.*[\\\/]/, '')
  legent_tag.textContent = filename;
  var rowImage = document.createElement("div");
  rowImage.className = "row ms-2 me-2";
  fieldset_tag.appendChild(legent_tag);
  fieldset_tag.appendChild(rowImage);  
  form_image_input.appendChild(fieldset_tag);
  
  const pdfDoc = pdfjsLib.getDocument({
    data: PdfBytes,
    cMapUrl: '/cmaps/',
    cMapPacked: true
  });
  
  pdfDoc.promise.then(function (doc) {  
    var pages = []; while (pages.length < doc.numPages) 
    {
      pages.push(pages.length + 1);
    }

    return Promise.all(pages.map(function (num) {    
  
      return doc.getPage(num).then(makeThumb)
        .then(function (li) {
          rowImage.appendChild(li);
      });
    }));

  }).catch(console.error);    
 
}

function createCanvas(page)
  {      
      var canvas = document.createElement("canvas");
      canvas.id = "pdf_thum"+ page._pageIndex ;
      canvas.className="pdf-thump";
      canvas.width = 90;
      canvas.height= 120;
      canvas.setAttribute("selected",false);
      canvas.setAttribute("num",page._pageIndex + 1);          
      canvas.addEventListener('click', page_click, false);
      canvas.addEventListener('contextmenu', event => event.preventDefault());
      
      return canvas;
  }

  function makeThumb(page) {     

   var dv = document.createElement("div");
   
   dv.id="thumbanil-frame-"+ page._pageIndex ;
   dv.className="thumbnail-frame ms-1 me-1 d-flex flex-column btn-fit-content";
   // draw page to fit into 96x96 canvas
   var vp = page.getViewport({scale:1});

   var canvas = createCanvas(page);

   var scale_thum = Math.min(canvas.width / vp.width, canvas.height / vp.height);
   
   page.render({canvasContext: canvas.getContext("2d"), viewport: page.getViewport({scale:scale_thum})}).promise;

   // Label
   var lBel = document.createElement("label");
   lBel.className="m-0"
   lBel.textContent=(page._pageIndex+1);
   lBel.style.padding=0;
   lBel.style.width="100%";
   lBel.style.textAlign="center";
   lBel.style.color="green";
   dv.appendChild(canvas);
   dv.appendChild(lBel);
   return dv;
 }

 function createCanvasViewPage(page){
  var parent = document.getElementById("viewer");
  parent.replaceChildren();
  var canvas = document.createElement("canvas");  
  canvas.id = "pdf_thum"+ page._pageIndex ;
  canvas.className="canvas-view";
  canvas.width = 600;
  canvas.height= 800;  
  canvas.addEventListener('contextmenu', event => event.preventDefault());
  var vp = page.getViewport({scale:1});
  var scale_thum = Math.min(canvas.width / vp.width, canvas.height / vp.height);   
   page.render({canvasContext: canvas.getContext("2d"), viewport: page.getViewport({scale:scale_thum})}).promise;
   parent.appendChild(canvas);
 }
 



