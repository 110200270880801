$(document).on('turbo:load', function() {
  var ajaxInProgress = false;
  $("body").delegate('.issue_job', 'blur keydown', function(event) {
    var content = $(".issue_job").val()
    if (event.type === 'keydown' && event.keyCode !== 13) {
          return;
    }
    if (ajaxInProgress) {
      return;
    }
    ajaxInProgress = true;
    if (content.trim().length > 0) {
      $.ajax({
        type: "POST",
        url: $(this).data('url'),
        dataType: "script",
        data: { 
          issue_job:{content: content, issue_id: $(this).data('id') }
        },
        success: function (data, textStatus, jqXHR) {
    
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        },
        complete: function() {
          // Reset the flag to false when the AJAX call is complete
          ajaxInProgress = false;
        }
      })
    }
    else {
      ajaxInProgress = false;
    }
  });

  $("body").delegate('.issue_job_update', 'blur keydown', function(event) {
    var content = $(this).val()
    if (event.type === 'keydown' && event.keyCode !== 13) {
      return;
    }
    if (ajaxInProgress) {
      return;
    }
    ajaxInProgress = true;
    $.ajax({
      type: "PUT",
      url: $(this).data('url'),
      dataType: "script",
      data: { 
        issue_job: {content: content},
      },
      success: function (data, textStatus, jqXHR) {
  
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      },
      complete: function() {
        // Reset the flag to false when the AJAX call is complete
        ajaxInProgress = false;
      }
    })
  });

  $('body').delegate('#checkbox-issue-job', 'change', function (e) {
    $.ajax({
      type: "PUT",
      url: $(this).data('url'),
      dataType: "script",
      data: { 
        issue_job: { done: this.checked }, 
      },
      success: function (data, textStatus, jqXHR) {
  
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  });

  
});