$(document).on('turbo:load', function() {
	formatDatatableLogTime();
  
  var date;
  $("#date_log_time").datepicker({
    format: "yyyy-mm-dd",
    prevText:"&#x3C;前",
    nextText:"次&#x3E;",
    monthNames:["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"],
    dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
    showMonthAfterYear: true,
    yearSuffix: '年',
    autoclose: true,
  }).on('changeDate', function(e) {
    date = this.value;
      $.ajax({
        type: "GET",
        url: "select_date_staff_login",
        dataType: "script",
        data: { 
          date: date,
          id_user: id_user
        },
        success: function (data, textStatus, jqXHR) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        }
      })
  }).datepicker("setDate",'now');

  var department;
  $('#list_department_employee').change(function(e) {
    var e = document.getElementById("list_department_employee");
    department = e.value;
    $.ajax({
      type: "GET",
      url: "select_name_log_time",
      dataType: "script",
      data: { 
        date:date,
        department: department 
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  });
 
  // $('#select_name_employee').change(function(e) {
  var id_user;
  $("body").delegate('#select_name_employee', 'change', function(e) {
    var e = document.getElementById("select_name_employee");
    id_user = e.value;
    $.ajax({
      type: "GET",
      url: "filter_by_name_log_time",
      dataType: "script",
      data: { 
        date:date,
        department:department,
        id_user: id_user
        },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  });

  var list_columns = [];
  $("body").delegate('.apply_setting_columns_log_time', 'click', function() {
    var checkbox = document.getElementsByClassName('check-box-columns');
    for(var i = 0; i < checkbox.length; i++)
    {
      list_columns[i] = [checkbox[i].value, checkbox[i].checked];
    }
    $.ajax({
      type: "GET",
      url: "change_table_by_columns_staff_log_time",
      dataType: "script",
      data: { 
        list_columns: list_columns,
        date: date,
        id_user: id_user
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  })
});

window.formatDatatableLogTime = function() {
	var tblLogTime = document.getElementsByClassName("tbl_log_time");
	if (tblLogTime.length > 0) {
    window.$('.tbl_log_time').DataTable({
      'stateSave': true,
      'destroy': true,
      'responsive': false,
      'scrollX': true,
      'columnDefs': [
        {
          'targets': 0,
          'width': "5%",
        }
		
      ],
      // 'order': [0, 'asc'],
      "ordering": false,
      drawCallback: function () {
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      },
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
        }
      },
    });
  };
};
