import {savePermision} from '../packs/permission';
$(document).on('turbo:load', function() {
	$("body").delegate('#btnSubmitDepartmentPdfUserShare', 'click', function () {
    var id = $(".decentralize-pdf-id")[0].textContent;
    savePermision("/pdf_user_shares/update_decentralize",id,"pdf_share");
  });

  formatTblPdfUserShare()
});

function formatTblPdfUserShare(id) {   
	var tbl_pdf_user_share = document.getElementsByClassName("tblPdfUserShare");
	if (tbl_pdf_user_share.length > 0) {
		tbl_pdf_user_share = window.$('.tblPdfUserShare').DataTable({
				'stateSave': false,
				'destroy': true,
				'fixedColumns': true,
				'columnDefs': [
					{ "width": "6%", "targets": 0 }
				],
				'order': [0, 'asc'],
				drawCallback: function() {
					$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
				},
				language: {
					paginate: {
						previous: "<i class='mdi mdi-chevron-left'>",
						next: "<i class='mdi mdi-chevron-right'>"
					},
					info: I18n.t('infoCustom'),
					search: I18n.t('searchCustom'),
					emptyTable: I18n.t('emptyTableMessage'),
					lengthMenu : I18n.t('lengthMenuCustom'),
					infoEmpty: I18n.t('infoEmptyCustom')
				},
			});
		};
	// $("#btnDeleteMultilDocument").click(function() {
	// 	var id_pdf;
	// 	var lstPdf = [];
	// 	$.each(tbl_pdf.rows({ 'search': 'applied' }).nodes(), function (i, row) {
	// 			var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
	// 			if (checkBox) 
	// 			{
	// 			id_pdf =parseInt(row.querySelector('.col_checkbox').getAttribute('data-item'));
	// 			lstPdf.push(id_pdf);
	// 			return;                
	// 			}   
	// 	});
	// 	if(lstPdf.length==0) return;
	// 	$.ajax({
	// 			type: 'GET',
	// 			url: "confirm_delete_multi_document",
	// 			dataType: 'script',
	// 			data: {
	// 			list_id_pdf: lstPdf
	// 			},
	// 			success: function(data, textStatus, jqXHR){
				
	// 			},
	// 			error:function(jqXHR, textStatus, errorThrown){
	// 			console.log("AJAX Error: #{textStatus}")
	// 			}
	// 	});
	// });
	 
}

