import { PDFSplit } from './PDFSplit';

$(document).on('turbo:load', function () {
  $("#btn_split_pdf").click(function () {
    var id = $("#pdf_id").val();
    $.ajax({
      type: 'GET',
      url: "/pdf/edit_split_pdf",
      dataType: 'script',
      data: {
        id: id
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: " + textStatus);
      }
    });
  });
})

window.openPdfSplit = async function (url) {
  var pdfSplit = new PDFSplit(url);
  await pdfSplit.open();
}
