$(document).on('turbo:load', function() {
    $("body").delegate('#btnUserVersionLogin', 'click', function() {
        var title = $("#title").val();
        $.ajax
        ({
            type: 'GET',
            url: "check_user",
            dataType: 'script',
            data: {
                title: title
            },
            success: function(data, textStatus, jqXHR){
                // console.log(data)
                // 
            },
            error:function(jqXHR, textStatus, errorThrown){
                // console.log("AJAX Error: #{textStatus}")
            }
        })
    });
    $("body").delegate('#btnSaveVersionManager', 'click', function() {
        var title = $("#title").val();
        var content = $("input[name='content']").val()
        var email = $("#email").val();
        var password = $("#password").val();
        $.ajax({
            type:"POST",
            url: '/version_managers',
            data: {
                title: title,
                content: content,
                email:email,
                password:password
            },
        })  
    });
    $('action-text-attachment img').click(function(){
        var scr_img = $(this)[0].getAttribute('src');
        $.ajax({
            type:"GET",
            url: '/preview_image',
            dataType: 'script',
            data:{
                image:scr_img
              },
            success: function(data, textStatus, jqXHR){
				
			},
			error:function(jqXHR, textStatus, errorThrown){
				
			}
        }) 
    });
    var tblVersion = document.getElementsByClassName("tbl_version_manager");
    if (tblVersion.length > 0) {
        var table = window.$('.tbl_version_manager').DataTable({
            "autoWidth" : false,
            'stateSave': true,
            'destroy': true,
            'columnDefs': [
                {
                    'targets': 0,
                    'width': "4%",
                    'checkboxes': {
                      'selectRow': true
                    }
                  },
                  { "width": "4%", "targets": 1 },
                  { "width": "20%", "targets": 2 },
                  { "width": "8%", "targets": 3 },
                  { "width": "64%", "targets": 4 },
            ],
            'select': {
                'style': 'multi'
             },
             'order': [1, 'asc'],
            drawCallback: function() {
                $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
            },
            language:{paginate:{
                previous:"<i class='mdi mdi-chevron-left'>",
                next:"<i class='mdi mdi-chevron-right'>"}},
            });
    };
    $('.btnDeleteVersion').click(function(){
        var mydata = [];
        $.each($('.tbl_version_manager tbody tr'), function (i, row) {
            if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) mydata.push($(row).data('item-id'));
        });
        if(mydata.length==0) 
        {return;}
        // $("input[name='department[flg]']:checked").closest('tr').each(function() {
        $.ajax
        ({
            type: 'GET',
            url: "delete_version",
            dataType: 'script',
            data: {
                ids:mydata,
            },
            success: function(data, textStatus, jqXHR){
                // 
            },
            error:function(jqXHR, textStatus, errorThrown){
                console.log("AJAX Error: #{textStatus}")
            }
        })
    });

    $('.btnEditVersion').click(function(){
        var mydata = [];
        $.each($('.tbl_version_manager tbody tr'), function (i, row) {
            if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) mydata.push($(row).data('item-id'));
        });
        if(mydata.length!=1) 
        {return;}
        // $("input[name='department[flg]']:checked").closest('tr').each(function() {
        $.ajax
        ({
            type: 'GET',
            url: "update_version",
            dataType: 'script',
            data: {
                id: $("input[class='dt-checkboxes']:checked").closest('tr').attr("data-item-id")
            },
            success: function(data, textStatus, jqXHR){
                // 
            },
            error:function(jqXHR, textStatus, errorThrown){
                console.log("AJAX Error: #{textStatus}")
            }
        })
    });
})



