import { moveOption } from '../schedule.js';
$(document).on('turbo:load', function() {
    // add/remove from list user to list user selected
  $("body").delegate('#add_member_lst_project', 'click', function() {
    var lst_user_select = document.getElementById('list_member_lst_project');
    var lst_user_selected = document.getElementById('member_selected_lst_project');
    moveOption(lst_user_select, lst_user_selected);
  });

  $("body").delegate('#remove_member_lst_project', 'click', function() {
    var lst_user_select = document.getElementById('list_member_lst_project');
    var lst_user_selected = document.getElementById('member_selected_lst_project');
    moveOption(lst_user_selected, lst_user_select);
  });

// event click button create/update record personal group

  $("body").delegate('#btnSaveMemberProject', 'click', function () {
    var id_users = [];
    var options_user = document.getElementById("member_selected_lst_project");
    for (var i=0; i<options_user.length; i++) {
      options_user[i].selected = "true";
    }
    for (var i=0; i<options_user.length; i++) {
      id_users[i] = options_user[i].value;
    }
    $.ajax({
      type: "GET",
      url: "/master_plans/add_member",
      dataType: "script",
      data: {
        id_users: id_users
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  });

  window.$('#new-master-plan-modal, #edit-master-plan-modal').on('shown.bs.modal', function (e) {

    $("body").delegate('#master_plan_start_date, .icon_start_date_master_plan', 'click', function() {
      let end_date = $("#master_plan_end_date").val();
      $('#master_plan_start_date').datepicker('setEndDate', end_date);
    });
    $("body").delegate('#master_plan_end_date, .icon_end_date_master_plan', 'click', function() {
      let sDate = $("#master_plan_start_date").val();
      $('#master_plan_end_date').datepicker('setStartDate', sDate);
    });
    datepicker("#master_plan_start_date");
    let end_date = $("#master_plan_end_date").val();
    datepicker("#master_plan_end_date", end_date);
  });
});

window.quickCreateFormMasterPlan = function() {
  $('#add_customer_btn_append').hide();
  $('#add_status_btn_append').hide();
  $('#add_job_type_btn_append').hide();
  $('#append_template_btn').hide();

  $('#master_plan_customer_id').select2({
    dropdownParent: $('.select2-customer'),
  }).on('select2:open', function() {
    let addButton = $('#add_customer_btn_append');
  if (!$('.select2-dropdown').has(addButton).length) {
    addButton.show();
    $('.select2-customer .select2-dropdown').append(addButton);
  }
  });

  $('#master_plan_status_id').select2({
    dropdownParent: $('.select2-status'),
    minimumResultsForSearch: -1
  }).on('select2:open', function() {
    let addButton = $('#add_status_btn_append');
  if (!$('.select2-dropdown').has(addButton).length) {
    addButton.show();
    $('.select2-status .select2-dropdown').append(addButton);
  }
  });

  $('#master_plan_job_type_id').select2({
    dropdownParent: $('.select2-job-type'),
    minimumResultsForSearch: -1
  }).on('select2:open', function() {
    let addButton = $('#add_job_type_btn_append');
  if (!$('.select2-dropdown').has(addButton).length) {
    addButton.show();
    $('.select2-job-type .select2-dropdown').append(addButton);
  }
  });

  $('#lstProjectTemplateId').select2({
    dropdownParent: $('.select2-template'),
  }).on('select2:open', function() {
    let addButton = $('#append_template_btn');
  if (!$('.select2-dropdown').has(addButton).length) {
    addButton.show();
    $('.select2-template .select2-dropdown').append(addButton);
  }
  });
}
