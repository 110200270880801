$(document).on('turbo:load', function(){
	formatDataTableListModule();

	$("#btnEditModule").click(function() {
		var arr_module = [];
		$.each($('.tbl_module tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_module.push($(row).data('item-id'));
			} 
		});
		if (arr_module.length == 1) {
			$.ajax ({
				type: 'GET',
				url: "list_modules/" + arr_module[0] + "/edit",
				dataType: 'script',
				data: {
					id: arr_module[0]
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}
	});

	$("#btnDeleteModule").click(function() {
		var arr_module = [];
		$.each($('.tbl_module tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_module.push($(row).data('item-id'));
			} 
		});

		if (arr_module.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "confirm_destroy_module",
				dataType: 'script',
				data: {
					id: arr_module
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});



});

window.formatDataTableListModule = function() {
	var tblListModule = document.getElementsByClassName("tbl_module");
	if (tblListModule.length > 0) {
		window.$('.tbl_module').DataTable({
			'destroy': true,
			'stateSave': true,
			'columnDefs': [
				{
					'targets': 0,
					'width': "4%",
					'checkboxes': {
						'selectRow': true
					}
				},
				{ "width": "6%", "targets": 1 },
				{ "width": "90%", "targets": 2 },
			],
			'select': {
				'style': 'multi'
			},
			'order': [1, 'asc'],
			drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"}},
		});
	}
}