import {fabric} from 'fabric'; 
export class FLine extends fabric.Line{ 
  _start
  _end
  constructor(aCoords, options){
    super(aCoords, options);    
    this.init();
    this.setHeads();
    this.setEvents();   
  }
  
  init(){
    this._start = {x: this.x1,y: this.y1}
    this._end = {x: this.x2,y: this.y2}
    this.element = 0;
  }
  setHeads(){
    var heads = create2Head(this);
    this.head1 = heads.head1;
    this.head2 = heads.head2;
  }
  setEvents(){
    events(this);
  }

  get start(){
    return this._start;    
  }
  
  set start(point){    
    // if(this._start.x == point.x && this.start.y == point.y) return;
    if(this._start === point) return;    
    this._start = point;
    this.set({ 'x1': point.x, 'y1': point.y});
    this.head1 && this.head1.set({ 'left': point.x -  this.head1.radius/2, 'top': point.y -  this.head1.radius/2 });  
    this.setCoords();
    this.head1.setCoords();
    this.setMoving();
  }

  get end(){
    return this._end;
  }

  set end(point){    
    if(this._end === point) return;    
    this._end = point;
    this.set({ 'x2': point.x, 'y2': point.y});
    this.head2 && this.head2.set({ 'left': point.x -  this.head2.radius/2, 'top': point.y -  this.head2.radius/2 });   
    this.setCoords();
    this.head2.setCoords();    
  }

  set_end(point){
    this.end = point;
  }

  line_moved(){
    var circle1,circle2,line ;
    var p = this;
    circle1 = p.head1,circle2 = p.head2 , line = p;
    var c1Left, c1Top, c2Left, c2Top;
      // CALCULATE THE circle1 AND circle2 POINTS FOR EACH SCENARIO
      if (circle1.top < circle2.top) {
         if (circle1.left < circle2.left) {
            c1Left = p.left  -  circle1.radius/2;
            c1Top = p.top  -  circle1.radius/2;
            c2Left = p.left + p.width - circle2.radius/2;
            c2Top = p.top + p.height - circle2.radius/2;
         }
         else {
            c1Left = p.left + p.width - circle1.radius/2;
            c1Top = p.top - circle1.radius/2;
            c2Left = p.left - circle1.radius/2 ;
            c2Top = p.top + p.height - circle2.radius/2;
         }
      }
      else {
         if (circle1.left < circle2.left) {
            c1Left = p.left - circle1.radius/2;
            c1Top = p.top + p.height - circle1.radius/2;
            c2Left = p.left + p.width - circle1.radius/2;
            c2Top = p.top - circle1.radius/2;
         }
         else {
            c1Left = p.left + p.width - circle1.radius/2;
            c1Top = p.top + p.height - circle1.radius/2;
            c2Left = p.left - circle1.radius/2;
            c2Top = p.top - circle1.radius/2;
         }
      }
      circle1.set({ left: c1Left, top: c1Top });
      circle2.set({ left: c2Left, top: c2Top });
      circle1.setCoords();
      circle2.setCoords();       
  } 

  head_moved(){
    var circle1,circle2 ;
    var p = this;    
    circle1 = p.head1,circle2 = p.head2 ;
    p && p.set({ 'x1': circle1.getCenterPoint().x - circle1.radius/2, 'y1': circle1.getCenterPoint().y - circle1.radius/2});
    p && p.set({ 'x2': circle2.getCenterPoint().x - circle1.radius/2, 'y2': circle2.getCenterPoint().y - circle1.radius/2});
    p.setCoords();    
  }
  setMovedLine(){
    this.line_moved();
  } 

  setMovedHead(){
    this.head_moved();   
  }
}
function events(arrow){
      arrow.head1.on('deselected', function (options){
        this.visible = this.line.head2.visible = false;
      });
      arrow.head1.on('selected', function (options) {
        this.visible = this.line.head2.visible = true;
      });
      arrow.head2.on('deselected', function (options) {
        this.visible = this.line.head1.visible = false;
      });
      arrow.head2.on('selected', function (options) {
        this.visible = this.line.head1.visible  = true;
      });
    
      arrow.on('deselected', function (options) {
        this.head1.visible = this.head2.visible = false;
      });
      arrow.on('selected', function (options) {
        this.head1.visible = this.head2.visible = true;
      });
}
function create2Head(arrow){
    var head1 = createHead(arrow,1);
    head1.line = arrow;

    var head2 = createHead(arrow,2);
    head2.line = arrow;
    return {head1: head1,head2: head2};
}

function createHead(arrow,index){
    var radius = arrow.strokeWidth;    
    var leftCircle = arrow.x1 - radius/2;
    var topCircle = arrow.y1 - radius/2;
    if(index == 2){
      leftCircle = arrow.x2 - radius/2;
      topCircle = arrow.y2 - radius/2;
    }
    return new fabric.Circle({
      left: leftCircle,
      top: topCircle,
      startX: leftCircle,
      startY: topCircle,
      radius: radius,
      stroke: 'black',
      fill:'red',
      hasControls: false,
      hasBorders: false,
      name: 'point-'+ index,
      element: index,
      visible: false,
      annotation: arrow.annotation,
      display: "false",
      opacity: 1
    });
}
