$(document).on('turbo:load', function() {
  $("body").delegate('#btn_save_wiki', 'click', function() {
    $('#btn_submit_description_wiki').click();
  });

  $('body').delegate('#btn_upload_file_wiki', 'click', function (e) {
    $("#js_add_more_attachment_wiki").click()
	});

  $('body').delegate('#select_file_for_wiki_box', 'cocoon:after-insert', function (e) {
    $(this).find("div.nested-fields:last input:first").click();
    $(this).find("div.nested-fields:last").addClass("d-none");
	});

  $('body').delegate('#select_file_for_wiki_box div.nested-fields .js_input_attachment', 'cancel', function() {
		if (this.files.length < 1) {
			$(this).parent().find(".js_remove_attachment").click()
		}
	})

  $('body').delegate('#select_file_for_wiki_box div.nested-fields .js_input_attachment', 'change', function() {
		var $input = $(this);
		if (this.files && this.files.length) {
      $(this).parent().parent().removeClass("d-none")
      var size = this.files[0].size
      var name = this.files[0].name
      var extension = name.split('.').pop()
      if (size >= 1000000) {
        size = (size/1000000).toFixed(2).toString() + " MB"
      } else if (size >= 1000) {
        size = (size/1000).toFixed(2).toString() + " KB"
      }else{
        size = size.toString() + " B"
      }
			let reader = new FileReader();
			this.enabled = false
			reader.onload = (function (e) {
				let template = []
				template.push('<div class="name-file"><div class="fw-bold d-flex font-12 mx-2 mt-1"><div class="text-truncate">'
          + name + '</div><span>.' + extension + '</span></div><p class="mb-1 font-10 mx-2">' + size + '</p></div>'
        )
				$input.prev(".js_add_attachment").html(template.join(''))
			});
			$(this).parent().find(".js_input_attachment_type").val(this.files[0].type)
			$(this).parent().find(".js_label_attachment").html(`<label>${this.files[0].name}</label>`)
			reader.readAsDataURL(this.files[0]);
		}
	});

})
