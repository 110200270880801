import { moveOption } from '../schedule.js';
$(document).on('turbo:load', function() {
  $("body").delegate('#btn_role_member', 'click', function () {
    let master_plan_id = $(this).data('item-id');
    let role_id = $("#data_role_id").data('role-id');
    let list_role_permissions = [];

    $( "#permissions_list div .form-check-input" ).each(function( index ) {
      list_role_permissions.push({
        id: $(this).data("item-id"),
        project_permission_id: $(this).val(),
        _destroy: !$(this).is(':checked')
      })
    });

    $.ajax ({
      type: 'PUT',
      url: "/master_plans/" + master_plan_id + "/roles/" + role_id,
      dataType: 'script',
      data: {
        role: {
          role_permissions_attributes: list_role_permissions
        },
      },
      success: function(data, textStatus, jqXHR){
        //
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });

  });

  $("body").delegate('.btn_role_link', 'click', function(e) {
    let master_plan_id = $(this).data('master-plan-id');
    let role_id = $(this).data('role-id');
    $.ajax ({
      type: 'GET',
      url: "/master_plans/" + master_plan_id + "/roles/" + role_id,
      dataType: 'script',
      success: function(data, textStatus, jqXHR){

      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });
  });

  $("body").delegate('#permissions_list div .form-check-input ', 'click', function(e) {
    let permisson_code = $(this).data("permision-code")

    if(permisson_code == "only_view"){
      if($(this).is(":checked")){
        $( "#permissions_list div .form-check-input" ).each(function( index ) {
          if ( $(this).data("permision-code") != "only_view") {
            $(this).attr("disabled","disabled")
            $(this).prop( "checked", false)
          }
        })
      }else{
        $( "#permissions_list div .form-check-input" ).each(function( index ) {
          if ( $(this).data("permision-code") != "only_view") {
            $(this).attr("disabled", false)
          }
        })
      }
    } else {
      if($(this).is(":checked")){
        $("#permissions_list div #only_view").prop("checked", false)
      }
    }
  });

  $("body").delegate('#permissions_list div .form-check-input ', 'change', function(e) {
    if ($("#permissions_list").find("input[type='checkbox']:checked").is(":checked")) {
      $("#btn_role_member").attr("disabled",false)
    }else {
      $("#btn_role_member").attr("disabled",true)
    }
  });

  $("body").delegate('.select_role_member', 'change', function(e) {
    var member_id = $(this).attr('name');
    var role_id = $(this).val();
    $.ajax({
      type: "PUT",
      url: "members/" + member_id,
      dataType: "script",
      data: {
        member_id: member_id,
        role_id: role_id
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  });

  $("body").delegate(".rename_role", "click", function() {
    let role_id = $("#data_role_id").data('role-id');
    $('#change_name_role_'+role_id).removeClass('d-none'); // toggle visibility of text_field_tag
    $('#nav_link_'+role_id).addClass('d-none');
    $('#input_rename_role_'+role_id).focus();
     // Set cursor position to end of input tag
    let input = $('#input_rename_role_'+role_id);
    let value = $('#span_role_'+role_id).text();
    input[0].selectionStart = input[0].selectionEnd = input.val().length;

    $('.btn_save_name_role').on('click', function(event) {
      let role_name = input.val();
      if (role_name.trim().length > 0) {
        var master_plan_id = input.data('item-id');
        $.ajax({
          type: "PUT",
          url: "/master_plans/" + master_plan_id + "/roles/" + role_id,
          dataType: "script",
          data: {
            role: { name: role_name },
            master_plan_id: master_plan_id
          },
          success: function(response) {
            // Do something with the response
          },
          error: function(jqXHR, textStatus, errorThrown) {
            // Handle the error
          },
        });
      }
    });

    $("body").delegate('#change_name_role_'+role_id, 'blur', function(e) {
      if (!$(e.relatedTarget).is('.btn_save_name_role')) {
        hide_input_rename_role(this, '#nav_link_'+role_id, '#input_rename_role_'+role_id, value)
      }
    });
  });

  window.hide_input_rename_role = function(elem_hide, elem_show, input, value) {
    $(elem_hide).addClass("d-none");
    $(elem_show).removeClass("d-none");
    $(input).val(value);
  };

  $("body").delegate("#add_role", "click", function() {
    $("#new_role").removeClass("d-none");
    $("#add_role").addClass("d-none");
    $("#role_input").focus();
  });

  $("body").delegate("#btn_add_role_input", 'click', function(event) {
    var master_plan_id = $("#role_input").data('item-id');
    $.ajax({
      type: "POST",
      url: "/master_plans/" + master_plan_id + "/roles",
      data: {
        name: $("#role_input").val(),
        master_plan_id: master_plan_id
      },
      success: function(response) {
        // Do something with the response
      },
      error: function(jqXHR, textStatus, errorThrown) {
        // Handle the error
      },
    });

  });

  $("body").delegate("#new_role", 'blur', function(e) {
    if (!$(e.relatedTarget).is('#btn_add_role_input')) {
      hide_input_rename_role('#new_role', '#add_role', '#role_input', '')
    }
  });


  $("body").delegate('#add_member', 'click', function() {
    var lst_user_select = document.getElementById('list_member');
    var lst_user_selected = document.getElementById('member_selected');
    moveOption(lst_user_select, lst_user_selected);
  });

  $("body").delegate('#remove_member', 'click', function() {
    var lst_user_select = document.getElementById('list_member');
    var lst_user_selected = document.getElementById('member_selected');
    moveOption(lst_user_selected, lst_user_select);
  });

// event click button create/update record personal group

  $("body").delegate('#btn_save_member', 'click', function () {
    var master_plan_id = $(this).data('item-id');
    var id_users = [];
    var options_user = document.getElementById("member_selected");
    for (var i=0; i<options_user.length; i++) {
      options_user[i].selected = "true";
    }
    for (var i=0; i<options_user.length; i++) {
      id_users[i] = options_user[i].value;
    }
    $.ajax({
      type: "GET",
      url: "/master_plans/" + master_plan_id + "/members/add_members",
      dataType: "script",
      data: {
        id_users: id_users
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  });

});

$(document).on('keyup', '.search_box_member', function() {
  var searchTerm = $(this).val();
  var role_id = $(this).attr('name');
  var master_plan_id = $(this).data('item-id');
  $.ajax({
    type: "GET",
    dataType: "script",
    url: "/master_plans/" + master_plan_id + "/issues/load_member",
    data: {
      search: searchTerm,
      role_id: role_id,
      master_plan_id: master_plan_id
    },
    success: function (data, textStatus, jqXHR) {
    },
    error: function(jqXHR, textStatus, errorThrown) {
      // Handle the error
    }
  });
});
