import { ANNOTATION } from '../edit/annotation';
import { ultil_mdethods } from '../common/ultil_methods';
import { factory } from '../annotation/factory';
import { annotation_helper } from '../common/annotation_helper';
import { fabric_format } from './fabric_format';
import { HighLight } from './items/highlight';
import { canvas_helper } from '../canvas/canvas_helper';
export function loadAnnotations(canvas,view,page_index){
    var parent = 
    {
        width : parseFloat(canvas.wrapperEl.style.width),
        height :  parseFloat(canvas.wrapperEl.style.height)
    };  

    var page = view.file.pages[page_index];
    var rotate = (page.rotate + page.viewRotate) % 360;
    var scale = parseFloat( view.viewComponents.thumnailContainer.querySelector(`#page-${page_index+1}`).getAttribute('scale'));
    const app_components = {
        parent: parent,
        rotate: rotate,
        canvas: canvas,
        view: view    ,  
        scale :scale   
    }

    var list_annos = page.annotation ;
    if(list_annos && list_annos.length > 0){
        for (let index = 0; index < list_annos.length; index++) {
            addObject(list_annos[index],app_components);
        }
    }    
    var objects = canvas.getObjects();
    
    for (let index = 0; index < objects.length; index++) {
        const element = objects[index];
        // if(element.origin_strokeWidth)
        // {
        //     if(element.origin_strokeDashArray){
        //         var stroke = ultil_mdethods.get_stroke_by_scale(element.origin_strokeDashArray,element.origin_strokeWidth,scale);     
        //         element_helper.set_thick(element,stroke.strokeWidth);
        //         element_helper.set_dash(element,stroke.strokeDashArray);
        //     }
        //     else
        //     {
        //         element_helper.set_thick(element,element.origin_strokeWidth*scale);
        //     }
        // }  

        if(element.origin_strokeWidth)
        {
            if(element.origin_strokeDashArray){
                var stroke = ultil_mdethods.get_stroke_by_scale(element.origin_strokeDashArray,element.origin_strokeWidth,scale);     
                element.set("strokeWidth", stroke.strokeWidth); 
                element.set("strokeDashArray", stroke.strokeDashArray); 
            }
            else
            {
                element.set("strokeWidth", element.origin_strokeWidth*scale);
            }
        }  
        
    }
    canvas.renderAll();
}
export function add_element_annotation(canvas,annotation,view,page_index){    
    var page = view.file.pages[page_index];
    var rotate = (page.rotate + page.viewRotate) % 360;
    var scale = parseFloat( view.viewComponents.thumnailContainer.querySelector(`#page-${page_index+1}`).getAttribute('scale'));
    var parent = 
    {
        width : parseFloat(canvas.wrapperEl.style.width),
        height :  parseFloat(canvas.wrapperEl.style.height)
    };  
    const app_components = {
        parent: parent,
        rotate: rotate,
        canvas: canvas,
        view: view    ,  
        scale :scale   
    }
    addObject(annotation,app_components);
}

function addObject(element,appObjects){
    var obj;    
    switch(element.annotation)
        {          
            case ANNOTATION.ADD_STAMP: 
            case ANNOTATION.ADD_IMAGE: 
            case ANNOTATION.ADD_CHECKMARK:   
            case ANNOTATION.ADD_DOT: 
            case ANNOTATION.ADD_XMARK:                
                if(element.type == "image"){
                    // if(element.src == undefined || element.src == null || (element.src != null && element.src.length == 0)){
                    //     element.src = element.toDataURL('image/png');
                    // }
                    // var src = element.src.replace(/^[a-z]{4,5}\:\/{2}[a-z]{1,}\:[0-9]{1,4}.(.*)/, '$1');
                    // if(isValidUrl(src) && src.includes('http')){
                    //     src = new URL(src).pathname;
                    // }
                    obj = fabric.Image.fromURL(element.src,(foo) => {
                        addImageToCanvas(foo,appObjects,element)
                    },{crossOrigin: 'anonymous'});
                    
                }
                break;
            case ANNOTATION.ADD_TEXT: 
                if(element.text.length > 0){
                    obj = new fabric.IText.fromObject(element, function(foo) {   
                        addObjectToCanvas(foo,appObjects,element);
                        fabric_format.text(foo,appObjects.view);
                                        
                    })
                }           
                break;
            case ANNOTATION.FREE_DRAW:   
            case ANNOTATION.PENCIL:  
                obj = new fabric.Path.fromObject(element, function(foo) {  
                    fabric_format.path(foo) 
                    addObjectToCanvas(foo,appObjects,element);
                                
                })
                break;
            case ANNOTATION.ADD_LINK: 
            case ANNOTATION.DRAW_ELLIPSE:  
            case ANNOTATION.DRAW_RECTANGLE: 
                obj = new fabric.Rect.fromObject(element, function(foo) {               
                    addObjectToCanvas(foo,appObjects,element);  
                    if(element.annotation == ANNOTATION.ADD_LINK){
                        foo.link_type = element.link_type
                    }                     
                })
                break;      
            case ANNOTATION.DRAW_LINE:     
                obj = renderLine(appObjects,element); 
                break;
            case ANNOTATION.DRAW_ARROW:
                obj = renderArrow(appObjects,element); 
                break;
            case ANNOTATION.TEXT_HIGHLIGHT:
            case ANNOTATION.TEXT_UNDERLINE:
            case ANNOTATION.TEXT_STROKEOUT:
                addHTMLObject(element,appObjects);
                break; 
            case ANNOTATION.ADD_TEXTBOX:           
                break;
            case ANNOTATION.ADD_TEXT_AREA:
                break;
            case ANNOTATION.ADD_RADIO:
                break;
            case ANNOTATION.ADD_CHECKBOX:
                break;
            case ANNOTATION.ADD_DROPDOWN:
                break;
            case ANNOTATION.ERASE:
                break;       
            
        }    
        return obj;
}
function addHTMLObject(object,configs){
    var scale = configs.scale/object.scale;
    var options = {
        id: object.id,
        annotation: object.annotation,
        className: object.className,
        color: object.color,
        name: object.name,
        page: object.page,
        scale: object.scale,
        opacity: object.opacity,
        left: object.left*scale,
        top: object.top*scale,
        width: object.width*scale,
        height: object.height*scale
    }    
    options.disable = true
    var elem = new HighLight(options);
    var html = elem.html();
    $(html).attr('disabled','disabled');

    configs.view.viewComponents.thumnailContainer.querySelector(`#page-${object.page}`).querySelector(`.annotationLayer`).append(html);
}
function addObjectToCanvas(object_insert,configs,element){
    var scale = configs.scale/element.scale
    var p = {x: object_insert.left*scale,y: object_insert.top*scale};   
    object_insert.scale = configs.scale;
    object_insert.left = p.x;
    object_insert.top = p.y;    
    object_insert.scaleX = object_insert.scaleX*scale;
    object_insert.scaleY = object_insert.scaleY*scale;
    object_insert.angle = element.angle;
    object_insert.rotate = element.rotate;
    object_insert.position_x = element.position_x;
    object_insert.position_y = element.position_y;  
    object_insert.aCoords = element.aCoords;     
    object_insert.setCoords();

    if(element.origin_strokeWidth)
    {
        if(element.origin_strokeDashArray){
            var stroke = ultil_mdethods.get_stroke_by_scale(element.origin_strokeDashArray,element.origin_strokeWidth,configs.scale);     
            object_insert.set("strokeWidth", stroke.strokeWidth); 
            object_insert.set("strokeDashArray", stroke.strokeDashArray); 
        }
        else
        {
            object_insert.set("strokeWidth", element.origin_strokeWidth*configs.scale);
        }
    }  

    var target = canvas_helper.find_object_by_id(configs.canvas,element.id);
    if(target){
        configs.canvas.insertAt(object_insert, configs.canvas.getObjects().indexOf(target), true);
    }
    else{
        configs.canvas.add(object_insert);
    }

    var app_rotate = configs.rotate;
    var view = configs.parent;
    rotate_object(view,object_insert,app_rotate);

 
    return object_insert;
}
function addImageToCanvas(foo,appObjects,element){
    var scale = appObjects.scale/element.scale
    foo.annotation = element.annotation;
    foo.id = element.id;
    foo.src = element.src;
   
    
    var current_scale = appObjects.scale;

    var p = {x: element.left*scale,y: element.top*scale}; 
    foo.left = p.x;
    foo.top = p.y;

    var out_height = element.height*element.scaleY*current_scale/element.scale;
    var out_width = element.width*element.scaleX*current_scale/element.scale;     
    var scaleX = out_width/foo.width;
    var scaleY = out_height/foo.height;
    
    foo.scaleX = scaleX;
    foo.scaleY = scaleY;
    foo.scale = current_scale;        
    foo.angle = element.angle;
    foo.opacity = element.opacity;
    foo.rotate = element.rotate;
    foo.aCoords = element.aCoords;     
    appObjects.canvas.add(foo) ;
    rotate_object(appObjects.parent,foo, appObjects.rotate);
}
function renderLine(appObjects,element){    
    var scale = appObjects.scale/element.scale;    
    var p1 = ultil_mdethods.calculate_point_after_rotate(appObjects.canvas
        ,{left: element.head1.x*scale,top: element.head1.y*scale}, 
         appObjects.rotate-element.rotate);       
    var p2 = ultil_mdethods.calculate_point_after_rotate(appObjects.canvas,{left: element.head2.x*scale,top: element.head2.y*scale},  appObjects.rotate-element.rotate);
    var aCoords = [p1.x, p1.y, p2.x, p2.y];

    var properties = {        
      fill: element.fill,
      stroke: element.stroke,
      strokeWidth: element.strokeWidth*appObjects.scale/element.scale,     
      annotation: element.annotation,
      id: element.id,
      scale: appObjects.scale,
      origin_strokeWidth: element.origin_strokeWidth,
      origin_strokeDashArray: element.origin_strokeDashArray,
      name: 'line'  
    }
    properties.strokeDashArray = element.strokeDashArray.map((p) => p*properties.strokeWidth/element.strokeWidth )
    
    properties = annotation_helper.config.line.fix_config(properties);
    var line = factory.create.line(appObjects.canvas,aCoords,properties)

    var p3 = ultil_mdethods.rotate_object(appObjects.canvas
        ,element, 
         appObjects.rotate-element.rotate,scale);   
    line.set('left',p3.x);
    line.set('top',p3.y);
    line.setMovedLine();

}
function renderArrow(appObjects,element){    
    var scale = appObjects.scale/element.scale;    
    var p1 = ultil_mdethods.calculate_point_after_rotate(appObjects.canvas
        ,{left: element.head1.x*scale,top: element.head1.y*scale}, 
         appObjects.rotate-element.rotate);       
    var p2 = ultil_mdethods.calculate_point_after_rotate(appObjects.canvas,{left: element.head2.x*scale,top: element.head2.y*scale},  appObjects.rotate-element.rotate);
    var aCoords = [p1.x, p1.y, p2.x, p2.y];

    var properties = {        
      fill: element.fill,
      stroke: element.stroke,
      strokeWidth: element.strokeWidth*appObjects.scale/element.scale,     
      annotation: element.annotation,
      id: element.id,
      scale: appObjects.scale,
      number_head: element.number_head,
      opacity: element.opacity,
      origin_strokeWidth: element.origin_strokeWidth,
      origin_strokeDashArray: element.origin_strokeDashArray,
      name: 'arrow'  
    }
    properties.strokeDashArray = element.strokeDashArray.map((p) => p*properties.strokeWidth/element.strokeWidth )
    
    properties = annotation_helper.config.line.fix_config(properties);
    var line = factory.create.arrow(appObjects.canvas,aCoords,properties)

    var p3 = ultil_mdethods.rotate_object(appObjects.canvas
        ,element, 
         appObjects.rotate-element.rotate,scale);   
    line.set('left',p3.x);
    line.set('top',p3.y);
    line.setMovedLine();

}

function rotate_object(page,obj,app_rotate){
    var p = ultil_mdethods.calculate_point_after_rotate(page,obj,  app_rotate-obj.rotate);        
    obj.top = p.y ;
    obj.left = p.x ;
    obj.angle += (app_rotate-obj.rotate)
    obj.setCoords()
}

