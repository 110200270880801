import { set_events } from './event_bus';
import { loadData } from './load_json';
export function init(input_canvas,baseView,page_index){
  var canvas = new fabric.Canvas(input_canvas,{
    preserveObjectStacking: true
  });   
  loadData(canvas,baseView,page_index);
  canvas.stateful = true;
  canvas.pageNum = page_index;    
  set_events(canvas,baseView.eventBus);   
  return canvas;
}
