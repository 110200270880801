$(document).on('turbo:load', function() {  
    $('body').delegate('#form_expired .form-check-input', 'input', function(e) {       
       load_payment(parseInt( this.getAttribute("data-month")));
    })
    
})

function  load_payment(month) {
    var data = {month: month};    

    $.ajax({
        type: "GET",
        url: "/orders/get_fee_by_time",
        dataType: 'json',
        data: data,
        success: function(data, textStatus, jqXHR){
          if(data){                      
            el('vat').innerHTML = data.vat;
            el('disscount').innerHTML = data.disscount;           
            el('disscount').setAttribute("value",data.disscount)
 

            el('date_expired').innerHTML = data.date_expired;  
            el('months').innerHTML = month;   

            el('months').setAttribute("value",month)

            var total = el("total_costs");
            total.innerHTML = data.total_costs_format;
            total.setAttribute("value", data.total_costs);    
            
            el('paypal_container').classList.remove('d-none');
            
          }
          
        },
        error:function(jqXHR, textStatus, errorThrown){          
            alert("Error: #{textStatus}")
        }
    });      
  }