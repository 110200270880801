import { PDFArrangePage } from './PDFArrangePage';

$(document).on('turbo:load', function () {
  $("#btn_arange_page").click(function () {
    var id = $("#pdf_id").val();
    $.ajax({
      type: 'GET',
      url: "/pdf/edit_arrange_page",
      dataType: 'script',
      data: {
        id: id
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: " + textStatus);
      }
    });
  });
})

window.openPdfArrangePage = async function (url) {
  var pdfArrangePage = new PDFArrangePage(url);
  await pdfArrangePage.open();
}
