// require( 'datatables.net' )
// import * as dt from 'datatables.net';
$(document).on('turbo:load', function() {
    // var  tb = $('.tbl_log').DataTable({
    //     scrollY:        '38vh',
    //     scrollCollapse: true,
    //     paging:         false,
    //     bFilter: false,
    //     bInfo: false
    // }).draw();
    //check condition set color column finish equal 'missing'
    $(".tbl_log td:nth-child(3)").each(function() {
        if ($(this).text() === "Missing") {
            $(this).parent().addClass("bg-danger text-white");
    	}
    });

    // click button increment month
    var seletedDate = moment(new Date());
    var d = new Date(seletedDate);
    var str = moment(d).format('YYYY年MM月')
    $(".text_date").text(str);
     //check condition set color today
    $('#next').click(function() {
        var lastDayOfNextMonth = moment(seletedDate.add(1,"M")).endOf('month');
        // Update selected date
        seletedDate = lastDayOfNextMonth;
        var d = new Date(seletedDate);
        str = moment(d).format('YYYY年MM月')
        $(".text_date").text(str);
        $.ajax
        ({
            type: 'GET',
            url: "log_next_month",
            dataType: 'script',
            data: { month: d.getUTCMonth() + 1,
                    year: d.getUTCFullYear()
             },
            success: function(data, textStatus, jqXHR){
                // console.log(data)
                // 
            },
            error:function(jqXHR, textStatus, errorThrown){
                // console.log("AJAX Error: #{textStatus}")
            }
        })
    });
    // click button decrease month
    $('#pre').click(function() {
        var lastDayOfPreviousMonth = moment(seletedDate.add(-1,"M")).endOf('month');
        // Update selected date
        seletedDate = lastDayOfPreviousMonth;
        var d = new Date(seletedDate);
        var str = moment(d).format('YYYY年MM月');
        $(".text_date").text(str);
        $.ajax
        ({
            type: 'GET',
            url: "log_prev_month",
            dataType: 'script',
            data: { month: d.getUTCMonth() + 1,
                    year: d.getUTCFullYear()
             },
            success: function(data, textStatus, jqXHR){
                // console.log(data)
                // 
            },
            error:function(jqXHR, textStatus, errorThrown){
                // console.log("AJAX Error: #{textStatus}")
            }
        })
    });
    // click button now month
    $("#now").click(function(){
        var dateNow = moment(new Date());
        seletedDate = dateNow;
        var d = new Date(seletedDate);
        var str = moment(d).format('YYYY年MM月');
        $(".text_date").text(str);
        $.ajax
        ({
            type: 'GET',
            url: "log_now_month",
            dataType: 'script',
            data: { month: d.getUTCMonth() + 1,
                    year: d.getUTCFullYear(),
             },
            success: function(data, textStatus, jqXHR){
                // console.log(data)
                // 
            },
            error:function(jqXHR, textStatus, errorThrown){
                // console.log("AJAX Error: #{textStatus}")
            }
        })
        
    });
     // click get time start today
     $(".btnStart").click(function() {
        $("#now").click();
        var today = new Date();
        var options = {  year: 'numeric', month: 'short', day:'numeric' };
        var dd = String(today.toLocaleDateString("en-US", options));
        var timenow = today.toLocaleTimeString('en-US', { hour12: false, 
            hour: "numeric", 
            minute: "numeric"});
        $.ajax
        ({
            type: 'GET',
            url: "time_start",
            dataType: 'script',
            data: { day: dd,
                    start: timenow.concat(":00"),
                    status_start:true
                },
            success: function(data, textStatus, jqXHR){
                var btnFinish = document.getElementById('btnFinish');
                if(btnFinish){
                    btnFinish.addEventListener('click', Finish, true);
                }
            },
            error:function(jqXHR, textStatus, errorThrown){
                // console.log("AJAX Error: #{textStatus}")
            }
        })
        $(".tbl_log tr").find('td:first').each(function() {
            if (Number($(this).text().match(/\d+/)[0]) === d.getUTCDate()) {
                row=$(this).parent();
                if (row.length){           
                    // var ele = $('div.dataTables_scrollBody');
                    // if(ele.length)
                    // {
                    //     ele.scrollTop(row.prop('offsetTop') - $('.dataTables_scrollBody').height()/2);
                    // }
                    var w = $(window);
                    if (row.length){
                        $('html,body').animate({scrollTop: row.offset().top - (w.height()/2)}, 1000 );
                    }
                        
                }
            }       
        }); 
        
        $(".tbl_log tr").find('td:first').each(function() {
            if (Number($(this).text().match(/\d+/)[0]) === d.getUTCDate()) {
                row=$(this).parent();
                if (row.length){         
                    
                    var w = $(window);
                    if (row.length){
                        $('html,body').animate({scrollTop: row.offset().top - (w.height()/2)}, 1000 );
                    }
                        
                }
            }       
        }); 
    });
    // click get time finish today
    var btnFinish = document.getElementById('btnFinish');
    if(btnFinish){
        btnFinish.addEventListener('click', Finish, false);
    }
    //show day off
    var month = d.getUTCMonth() + 1
    var year = d.getUTCFullYear()
    var dayofmonth = daysInMonth(month,year);
    var arr = $('.tbl_log tr').find('td:first').map(function(){
        return Number($(this).text().match(/\d+/)[0])
    }).get()
    days = ['日','月', '火', '水', '木', '金', '土'];
    
    var day_array = new Array(dayofmonth).fill(1).map( (_, i) => i+1 )
    var diff_day = day_array.filter(x => arr.indexOf(x) === -1);
    
    for(let i=0;i<diff_day.length;i++){
        var day_name = new Date(year,month-1,diff_day[i]);
        var name_day = days[day_name.getDay()];
        var row = "<tr><td class='day'>" + checkTime(diff_day[i]) + "(" + name_day + ")" +"</td>"+
                       "<td>" + "--" + "</td>"+
                       "<td>"+ "--" +"</td>"+
                       "<td class='table-action'>"+ "<a href='javascript:void(0);' class='action-icon'>" + 
                                    "<i class='dripicons-clock text-secondary'>" + "</i>" + 
                                "</a>" +
                                "<a href='javascript:void(0);' class='action-icon'>" +
                                    "<i class='dripicons-message text-secondary'>" + "</i>" +
                                "</a>" +
                        "</td>"+
                       "<td>"+ "--" +"</td>"+
                       "<td>"+ "--" +"</td>"+
                    "</tr>"
    $(".tbl_log tbody").append(row);
    }
    
    //set color today
    $(".tbl_log tr").find('td:first').each(function() {
        if (Number($(this).text().match(/\d+/)[0]) === d.getUTCDate()) {
            $(this).parent().addClass("bg-primary text-white");
            // row=$(this).parent();
            // if (row.length){           
            //     var ele = $('div.dataTables_scrollBody');
            //     $ ('.dataTables_scrollBody').ScrollTop=2000;
            //     if(ele.length)
            //     {
            //         ele.scrollTop(2000);
            //         // var idx = tb.row(row).index();
            //         // ele.scrollTop(row.prop('offsetTop') - $('.dataTables_scrollBody').height()/2);
            //         // ele.scrollTo(row);
            //     }
                 
            // }
    	}       
    });
    // $(".tbl_log tr").find('td:first').each(function() {
    //     if (Number($(this).text().match(/\d+/)[0]) === d.getUTCDate()) {
    //         row=$(this).parent();
    //         if (row.length){         
                
    //             var w = $(window);
    //             if (row.length){
    //                 $('html,body').animate({scrollTop: row.offset().top - (w.height()/2)}, 1000 );
    //             }
                    
    //         }
    //     }       
    // }); 

    $("#table_log tbody tr").map(function() {
        if($(this).find("td:first-child").text().indexOf("土")>-1 || $(this).find("td:first-child").text().indexOf("日")>-1)
        {
            if($(this).find("td:nth-child(3)").text().indexOf("Missing")>-1)
            {
                $(this).find("td:first-child").addClass("text-warning")
            }
            else{
                $(this).find("td:first-child").addClass("text-danger")
            }
        }    
    });
    var table_log = $("#table_log");
    if(table_log.length>0){
        try{
        window.$("#table_log").DataTable({
            "searching": false,
            "bPaginate": false,
            "scrollX": false,
            "autoWidth" : false,
            "stateSave": true,
            columnDefs: [{
                  targets: 0,
                  width:"10%",
                  render: function ( data, type, row ) {
                     return type === 'display' && data.length > 10 ?
                         data.substr( 0, 30 ) +'…' :
                         data;
                 }
               },
               {
                  targets: 1,
                  width:"10%",
                  render: function ( data, type, row ) {
                     return type === 'display' && data.length > 10 ?
                         data.substr( 0, 30 ) +'…' :
                         data;
                 }
               },
               {
                  targets: 2,
                  width:"10%",
                  render: function ( data, type, row ) {
                     return type === 'display' && data.length > 10 ?
                         data.substr( 0, 30 ) +'…' :
                         data;
                 }
               },
               {
                  targets: 3,
                  width:"10%",
               },
              {
               targets: 4,
               width:"30%",
               render: function ( data, type, row ) {
                  return type === 'display' && data.length > 10 ?
                      data.substr( 0, 30 ) +'…' :
                      data;
              }
            },
            {
               targets: 5,
               width:"30%",
               render: function ( data, type, row ) {
                  return type === 'display' && data.length > 10 ?
                      data.substr( 0, 20 ) +'…' :
                      data;
              }
            }],
             "bPaginate": false,
             "searching": false,
             destroy:true,
        });
    }
    catch{window.location.reload();}
    }
    
    
});
function Finish() {
    $("#now").click();
    var today = new Date();
    var timenow = today.toLocaleTimeString('en-US', { hour12: false, 
        hour: "numeric", 
        minute: "numeric"});
    var seletedDate = moment(new Date());
    var d = new Date(seletedDate);
        
    $.ajax
    ({
        type: 'GET',
        url: "time_finish",
        dataType: 'script',
        data: {
                date: d.getUTCDate(),
                month: d.getUTCMonth() + 1,
                year: d.getUTCFullYear(),
                finish: timenow.concat(":00"),
                status_finish:true
            },
        success: function(data, textStatus, jqXHR){
            // console.log(data)
            // 
        },
        error:function(jqXHR, textStatus, errorThrown){
            // console.log("AJAX Error: #{textStatus}")
        }
    })  
    // $(".tbl_log tr").find('td:first').each(function() {
    //     if (Number($(this).text().match(/\d+/)[0] ) === d.getUTCDate()) {
    //         row=$(this).parent();
    //         if (row.length){           
    //             var ele = $('div.dataTables_scrollBody');
    //             if(ele.length)
    //             {
    //                 ele.scrollTop(row.prop('offsetTop') - $('.dataTables_scrollBody').height()/2);
    //             }
                    
    //         }
    //     }       
    // });     
    $(".tbl_log tr").find('td:first').each(function() {
        if (Number($(this).text().match(/\d+/)[0]) === d.getUTCDate()) {
            row=$(this).parent();
            if (row.length){         
                
                var w = $(window);
                if (row.length){
                    $('html,body').animate({scrollTop: row.offset().top - (w.height()/2)}, 1000 );
                }
                    
            }
        }       
    }); 
}

function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
}

function checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
}



