import { PDFSaveAnnotation } from "../edit/save_annotation";

export const fabric_format = {
    text: function(obj,viewer){
        obj.set("fontSize",parseFloat( obj.originFontSize)*obj.scale);
        obj.scaleX = 1;
        obj.scaleY = 1;
        obj.setControlsVisibility({
            mt: false, 
            mb: false, 
            ml: false, 
            mr: false, 
            bl: false,
            br: false, 
            tl: false, 
            tr: false,
            mtr: false, 
        });  
        obj.on('changed', function(e) {
            if(containsNonLatinCodepoints(this.text) && this.fontFamily != 'ＭＳ ゴシック'){
              this.fontFamily = 'MS 明朝';        
            }  
            PDFSaveAnnotation.saveElement(viewer,this);
        });
    },
    path: function(obj){
        
        obj.lockRotation = true;
        obj.lockScalingX = true;
        obj.lockScalingY = true;
        obj.hasControls = false;
        obj.fill = '';
        obj.strokeLineCap = 'butt';
        obj.strokeLineJoin = 'butt';
        obj.lockUniScaling = true;
        obj.strokeUniform = true;
    }
}
function containsNonLatinCodepoints(s) {
    return /[^\u0000-\u00ff]/.test(s);
  }