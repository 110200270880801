$(document).on('turbo:load', function() {
    formatDatatableTotalLoginTime();
    var date;
    $("#date_total_login_time").datepicker({
      format: "yyyy-mm-dd",
      prevText:"&#x3C;前",
      nextText:"次&#x3E;",
      monthNames:["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"],
      dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
      showMonthAfterYear: true,
      yearSuffix: '年',
      autoclose: true,
    }).on('changeDate', function(e) {
      date = this.value;
        $.ajax({
          type: "GET",
          url: "select_date_total_login_time",
          dataType: "script",
          data: { date: date },
          success: function (data, textStatus, jqXHR) {
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log(jqXHR.responseText)
          }
        })
    }).datepicker("setDate",'now');

    var list_columns = [];
    $("body").delegate('.apply_setting_columns', 'click', function() {
      var checkbox = document.getElementsByClassName('check-box-columns');
      for(var i = 0; i < checkbox.length; i++)
      {
        list_columns[i] = [checkbox[i].value, checkbox[i].checked];
      }
      $.ajax({
        type: "GET",
        url: "change_table_by_columns_total_log_time",
        dataType: "script",
        data: {
          list_columns: list_columns,
          date: date,
          month: month,
          employee_name: name
        },
        success: function (data, textStatus, jqXHR) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        }
      })
    })

    var month;
    $("#month_total_login_time").datepicker({
      format: "yyyy-mm",
      startView: "months",
      minViewMode: "months",
      changeMonth: true,
      changeYear: true,
      autoclose: true
    }).on('changeMonth').on('change', function(e){
      month = this.value;
      $.ajax({
        type: "GET",
        url: "select_month_total_login_time",
        dataType: "script",
        data: {
          month: month,
          user_id: user_id
        },
        success: function (data, textStatus, jqXHR) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        }
      })
  }).datepicker("setDate",'now');

  var list_columns = [];
  $("body").delegate('.apply_setting_columns_total_log_time', 'click', function() {
    var checkbox = document.getElementsByClassName('check-box-columns');
    for(var i = 0; i < checkbox.length; i++)
    {
      list_columns[i] = [checkbox[i].value, checkbox[i].checked];
    }
    $.ajax({
      type: "GET",
      url: "change_table_by_columns_total_log_time",
      dataType: "script",
      data: {
        list_columns: list_columns,
        date: date,
        month: month,
        user_id: user_id
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  })

  // $("#month_total_login_time_t").change(function(e) {
  //   month = this.value;
  //   console.log(month);
    // $.ajax({
    //   type: "GET",
    //   url: "select_month_total_login_time",
    //   dataType: "script",
    //   data: {
    //     month: month,
    //     employee_name: name
    //   },
    //   success: function (data, textStatus, jqXHR) {
    //   },
    //   error: function (jqXHR, textStatus, errorThrown) {
    //     console.log(jqXHR.responseText)
    //   }
    // })
  // })

  var user_id;
  $('#filter_name_total_login_time').select2({
  }).on("change", function(e){
    user_id = this.value;
    $.ajax({
      type: "GET",
      url: "filter_by_name_total_login_time",
      dataType: "script",
      data: {
        month: month,
        user_id: user_id
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  });
});

window.formatDatatableTotalLoginTime = function() {
  var tblTotalLoginTime = document.getElementsByClassName("tbl_total_login_time");
  if (tblTotalLoginTime.length > 0) {
    window.$('.tbl_total_login_time').DataTable({
      'stateSave': true,
      'destroy': true,
      'responsive': false,
      'scrollX': true,
      "ordering": false,
      drawCallback: function () {
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      },
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
        }
      },
    });
  };
};
