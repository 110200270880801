$(document).on('turbo:load', function() { 
	var tbl_requirement = document.getElementsByClassName("tbl_requirement");	
	if (tbl_requirement.length > 0) {
		try{
			window.$('.tbl_requirement').DataTable({
				'destroy': true,
					  "columnDefs": [{ "width": "4%", "targets": 0 }]
				  });
		}
		catch{window.location.reload();}

	}
});