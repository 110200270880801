$(document).on('turbo:load', function () {

  var tblTemp = document.getElementsByClassName("tbl-project");
  if (tblTemp.length > 0) {

    window.$('.tbl-project').DataTable({
      'destroy': true,
      'columnDefs': [
        {
          'targets': 0,
          'width': "2%",
          'checkboxes': {
            'selectRow': true
          }
        },
        { "width": "5%", "targets": 1 },
        { "width": "10%", "targets": 2 },
        { "width": "15%", "targets": 3 },
        { "width": "15%", "targets": 4 },
        { "width": "15%", "targets": 5 },
        { "width": "15%", "targets": 6 },
        { "width": "10%", "targets": 7 },
        { "width": "13%", "targets": 8 },
      ],
      'select': {
        'style': 'multi'
      },
      'order': [1, 'asc'],
      drawCallback: function () {
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      },
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
        }
      },
    });
  };

  var tblTemp = document.getElementsByClassName("tbl-project-complete");
  if (tblTemp.length > 0) {

    window.$('.tbl-project-complete').DataTable({
      'destroy': true,
      'columnDefs': [
        {
          'targets': 0,
          'width': "2%",
          'checkboxes': {
            'selectRow': true
          }
        },
        { "width": "5%", "targets": 1 },
        { "width": "25%", "targets": 2 },
        { "width": "16%", "targets": 3 },
        { "width": "16%", "targets": 4 },
        { "width": "16%", "targets": 5 },
        { "width": "15%", "targets": 6 },
        { "width": "20%", "targets": 7 },
      ],
      'select': {
        'style': 'multi'
      },
      'order': [1, 'asc'],
      drawCallback: function () {
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      },
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
        }
      },
    });
  };

  $("body").delegate('#project_platform_id', 'click', function () {
    // var optionSelected = $("option:selected", this);
    var valueSelected = this.value;
    $.ajax({
      type: 'GET',
      url: "/get_platform_view",
      dataType: 'script',
      data: {
        platform_id: valueSelected,
      },
      success: function (data, textStatus, jqXHR) {
        
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error")
      }
    });
  });


  var tblSTempIsoFile = document.getElementsByClassName("stbl-iso-file-project");
  if (tblSTempIsoFile.length > 0) {

    var iso_table = window.$('.stbl-iso-file-project').DataTable({
      'destroy': true,
      'columnDefs': [
        {
          'targets': 0,
          'width': "5%",
          'checkboxes': {
            'selectRow': true
          }
        },
        { "width": "5%", "targets": 1 },
        { "width": "55%", "targets": 2 },
        { "width": "10%", "targets": 3 },
        { "width": "10%", "targets": 4 },
        { "width": "10%", "targets": 5 },
        { "width": "5%", "targets": 6 },
      ],
      'select': {
        'style': 'multi'
      },
      'order': [1, 'asc'],
      drawCallback: function () {
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      },
      paging: false,
      info: false,
      searching: false,
      fixedColumns: {
        leftColumns: 1
      },
    });
  };

  $("body").delegate('#project_platform_id_edit', 'change', function () {
    var valueSelected = this.value;

    var job_no = document.getElementById("platform_project_attributes_job_no").value;
    var customer = document.getElementById("platform_project_attributes_customer").value;
    var job_name = document.getElementById("platform_project_attributes_job_name").value;
    var responsible = document.getElementById("platform_project_attributes_responsible").value;
    $.ajax({
      type: 'GET',
      url: "/get_platform_view_edit",
      dataType: 'script',
      data: {
        platform_id: valueSelected,
        job_no: job_no,
        customer: customer,
        job_name: job_name,
        responsible: responsible
      },
      success: function (data, textStatus, jqXHR) {
        
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error")
      }
    });
  });
  window.$("#modal_add_file").on("shown.bs.modal", function () {
    load_drop_zone_upload_iso_file();
  })

  $("body").delegate('#btn_project_add_more_file', 'click', function () {

    var lst_nested_iso = this.parentElement.parentElement.querySelectorAll('.nested-fields');
    var lstId = [], id;
    if (lst_nested_iso.length > 0) {
      for (let index = 0; index < lst_nested_iso.length; index++) {
        const element = lst_nested_iso[index];
        id = element.querySelectorAll('.iso_file_id')[0].value;
        lstId.push(parseInt(id));
      }
    }

    var parent_checkcontent = this.closest('.nested-fields-check-content');
    var check_content_id = parent_checkcontent.querySelector('.check-content-name').id;
    $.ajax({
      type: 'GET',
      url: "/show_modal_choose_file",
      dataType: 'script',
      data: {
        lstId: lstId,
        check_content_id

      },
      success: function (data, textStatus, jqXHR) {

        load_drop_zone_upload_iso_file();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error")
      }
    });
  });

  $("body").delegate('#btnPushListIsoFile', 'click', function () {
    var path, filename, id;
    $.each(table.rows({ 'search': 'applied' }).nodes(), function (i, row) {
      var checkBox = $(row).find("input[type='checkbox']:checked").is(":checked");
      if (checkBox) {
        id = row.querySelector('#col_checkbox').getAttribute('data-item');
        filename = row.querySelector('#col_file_name').textContent;
        path = row.querySelector('#col_file_name').getAttribute("data-path");

        var check_content_id = document.getElementById("check_content_id").value;
        var check_content_parent = document.getElementById(check_content_id).closest('.nested-fields-check-content');

        var listAddIsoButton = check_content_parent.querySelectorAll("#btn_add_new_io_file");
        if (listAddIsoButton.length > 0) {
          listAddIsoButton[listAddIsoButton.length - 1].click();
        }
        var arrIsoFile = check_content_parent.querySelectorAll('.nested-fields-iso-file');
        var lastIsoFile = arrIsoFile[arrIsoFile.length - 1];
        lastIsoFile.querySelector('.iso_file_id').value = id;
        lastIsoFile.querySelector('.label_filename').innerText = filename;
        lastIsoFile.querySelector('.link_download_file').href = path;
      }
    });
    $(".btn_close_modal").click();
  });

  const _btnDeleteProjects = document.querySelector("#btn_delete_projects");
  if (_btnDeleteProjects) {
    _btnDeleteProjects.addEventListener("click", delete_Projects);
  }

  $('body').delegate('#tbl_show_platform_project > tbody > tr', 'click', function (e) {
    var check_content_id = "";
    $("#modal_get_check_content_temp_iso_file").addClass('d-none');
    var platform_id = $("#current_platform_id").val();
    var project_id = $("#current_project_id").val();
    var check_list_id = $(this).closest('tr').find('td').last().attr("id");
    if ($(this).closest('tr').data('item-id')) {
      check_content_id = $(this).closest('tr').attr('id');
    }
    $.each($('.tbl-show-platform-project tbody tr'), function (i, row) {
      if ($(row).attr('id') !== check_content_id) {
        for (let i = 0; i < row.cells.length; i++) {
          $(row.cells[i]).removeClass("row_selected_background");
        }
      }
      else {
        // $(row).addClass('hover_selected');
        for (let i = 0; i < row.cells.length; i++) {
          if (!$(row.cells[i]).attr('rowspan')) {
            $(row.cells[i]).addClass("row_selected_background");
          }
        }
      }
    });

    var current_url = window.location.href;
    var new_url;
    if (current_url.includes('show_project_detail')) {
      new_url = "show_project_detail/get_list_upload_iso_file";
    }
    else {
      if (!(current_url.includes('edit') || current_url.includes('projects/new'))) {
        new_url = project_id + "/get_list_upload_iso_file";
      }
      else {
        if (current_url.includes('edit')) {
          new_url = "edit/get_list_upload_iso_file";
        }
      }
    }
    $.ajax
      ({
        type: 'GET',
        url: new_url,
        dataType: 'script',
        data: {
          check_content_id: check_content_id,
          platform_id: platform_id,
          check_list_id: check_list_id,
          project_id: project_id,
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      })

  });

  $("body").delegate('#select_department_project', 'change', function () {
    var listIdSelected = [];
    $("#user_selected_edit option").each(function () {
      listIdSelected.push($(this).val());
    });
    $.ajax({
      type: 'GET',
      url: "new/get_user_list",
      dataType: 'script',
      data: {
        deparment_id: $("#select_department_project option:selected").val(),
        lstUserSelected: listIdSelected
      },
      success: function (data, textStatus, jqXHR) {
        
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    })
  });

  $("body").delegate('#select_department_project_edit', 'change', function () {
    var listIdSelected = [];
    $("#user_selected_edit option").each(function () {
      listIdSelected.push($(this).val());
    });
    $.ajax({
      type: 'GET',
      url: "edit/get_user_list",
      dataType: 'script',
      data: {
        deparment_id: $("#select_department_project_edit option:selected").val(),
        lstUserSelected: listIdSelected
      },
      success: function (data, textStatus, jqXHR) {
        
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    })
  });

  $("body").delegate('#add_partner_project', 'click', function () {
    var lst_user_select = document.getElementById('list_user_project');
    var lst_user_selected = document.getElementById('user_selected_edit');
    moveUser(lst_user_select, lst_user_selected);
  });

  $("body").delegate('#remove_partner_project', 'click', function () {
    var lst_user_select = document.getElementById('list_user_project');
    var lst_user_selected = document.getElementById('user_selected_edit');
    moveUser(lst_user_selected, lst_user_select);
  });

  $('#new_project').submit(function () {
    $('#user_selected_edit option').prop('selected', true);
    var platform_selected = $('#project_platform_id').val();
    if (platform_selected != ""){
      return true;
    }
    else {
      window.$('#project_platform_id').tooltip({
        title: 'Please choose at least one platform. If you dont have, create one',
        trigger: 'manual',
        placement: 'bottom',
        template: '<div class="tooltip tooltip-mycustom" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
      });

      window.$('#project_platform_id').tooltip('show');
      document.getElementById('project_platform_id').style.border = "1px solid red";

      setTimeout(function() { 
        window.$('#project_platform_id').tooltip('dispose');
        document.getElementById('project_platform_id').style.border = "";
      }, 3000);
      return false;
    }
  });

  $('.edit_project').submit(function () {
    $('#user_selected_edit option').prop('selected', true);
    return true;
  });



  $("body").delegate('#btn_open_temp_file_list', 'click', function (e) {
    e.stopPropagation();
    $("#modal_get_check_content_temp_iso_file").removeClass('d-none');
    var check_content_id = $(this).closest('tr').data('item-id');
    var url = window.location.href;
    if (url.includes('edit') || url.includes('projects/new')) {
      $("#modal_get_check_content_temp_iso_file").css({
        'position': 'absolute',
        'left': $(this).offset().left,
        'top': $(this).offset().top - 650
      });
    }
    else {
      $("#modal_get_check_content_temp_iso_file").css({
        'position': 'absolute',
        'left': $(this).offset().left,
        'top': $(this).offset().top - 350
      });
    }
    $.ajax({
      type: 'GET',
      url: "/get_check_content_temp_iso_files",
      dataType: 'script',
      data: {
        check_content_id
      },
      success: function (data, textStatus, jqXHR) {

        load_drop_zone_upload_iso_file();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error")
      }
    });
  });
  $("body").delegate('#btn_close_iso_file_box', 'click', function (e) {
    $("#modal_get_check_content_temp_iso_file").addClass('d-none');
  });

  $("body").delegate('#btn_upload_file_project', 'click', function (e) {
    var project_id = $("#current_project_id").val();
    var check_content_id = $("#current_check_content_project_id").val();
    var url = window.location.href;
    if (!(url.includes('edit') || url.includes('projects/new'))) {
      $.ajax
        ({
          type: 'GET',
          url: project_id + "/add_upload_file_project",
          dataType: 'script',
          data: {
            check_content_id: check_content_id,
          },
          success: function (data, textStatus, jqXHR) {
            load_drop_zone_upload_iso_file();
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log("AJAX Error")
          }
        })
    }
    else {
      if (url.includes('edit')) {
        $.ajax
          ({
            type: 'GET',
            url: "/add_upload_file_project",
            dataType: 'script',
            data: {
              check_content_id: check_content_id,
              id: project_id,
            },
            success: function (data, textStatus, jqXHR) {
              load_drop_zone_upload_iso_file();
            },
            error: function (jqXHR, textStatus, errorThrown) {
              console.log("AJAX Error")
            }
          })
      }
    }
  });

  $("body").delegate('#btn_download_upload_iso_file_project', 'click', function (e) {
    var project_id = $("#current_project_id").val();
    var lst_selected = [];
    var check_content_id = $("#current_check_content_project_id").val();
    $.each($('.stbl-iso-file-project tbody tr'), function (i, row) {
      if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) lst_selected.push($(row).data('item-id'));
    });
    if (lst_selected.length == 0) return;
    window.location = '/download_multiple_upload_iso_file?files=' + lst_selected + '&check_content_id=' + check_content_id;
  });

  $("body").delegate('#btn_delete_upload_files_project', 'click', function (e) {
    var project_id = $("#current_project_id").val();
    var mydata = [];
    $.each($('.stbl-iso-file-project tbody tr'), function (i, row) {
      if ($(row).find("input[class='dt-checkboxes']").is(":checked")) mydata.push($(row).data('item-id'));
    });
    if (mydata.length == 0) { return; }
    $.ajax
      ({
        type: 'GET',
        url: "/confirm_delete_multi_upload_iso_files",
        dataType: 'script',
        data: {
          data: { ids: mydata },
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      })
  });

  window.$("#modal_upload_temp_file").on("shown.bs.modal", function () {
    load_drop_zone_upload_iso_file();
  })


  $("body").delegate('#btn_view_note_iso_file', 'click', function (e) {
    var project_id = $("#current_project_id").val();
    var url = window.location.href;
    if (url.includes('show_project_detail')) {
      get_view_note(e, this, "show_project_detail/add_note_iso_file", true);
    }
    else {
      get_view_note(e, this, url + "/add_note_iso_file", true);
    }
  });

  $("body").delegate('#btn_save_iso_note', 'click', function (e) {
    var file_note = $("#note_file").val();
    var project_id = $("#current_project_id").val();
    var iso_file_id = $("#iso_file_id").val();
    $.ajax
      ({
        type: 'GET',
        url: "/save_note_iso_file",
        dataType: 'script',
        data: {
          file_note: file_note,
          project_id: project_id,
          iso_file_id: iso_file_id
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      })
  });

  $("body").delegate('#btn_preview_file_project', 'click', function (e) {
    var iso_file_id = $(this).closest('td').data('item-id');
    var project_id = $("#current_project_id").val();
    var url = window.location.href;
    var new_url = url.includes('show_project_detail') ? "show_project_detail/preview_upload_iso_file" : "/preview_upload_iso_file";
    $.ajax({
      type: 'GET',
      url: new_url,
      dataType: 'script',
      data: {
        iso_file_id: iso_file_id,
      },
      success: function (data, textStatus, jqXHR) {
        load_drop_zone_upload_iso_file();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error")
      }
    });

  });

  $("body").delegate('#btn_restore_logic_project', 'click', function (e) {
    var mydata = [];
    $.each($('.tbl-project-complete tbody tr'), function (i, row) {
      if ($(row).find("input[class='dt-checkboxes']").is(":checked")) mydata.push($(row).data('item-id'));
    });
    if (mydata.length == 0) { return; }
    $.ajax
      ({
        type: 'GET',
        url: "/confirm_restore_projects",
        dataType: 'script',
        data: {
          data: { ids: mydata },
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      })
  });

  var tblShowPlatformProject = document.getElementsByClassName("tbl-show-platform-project");
  if (tblShowPlatformProject.length > 0) {

    tbl_show_platform_project = window.$('.tbl-show-platform-project').DataTable({
      'destroy': true,
      'columnDefs': [
        { "width": "10%", "targets": 0 },
        { "width": "50%", "targets": 1 },
        { "width": "5%", "targets": 2 },
        { "width": "35%", "targets": 3 },

      ],
      "ordering": false,
      scrollY: '350px',
      scrollCollapse: true,
      paging: false,
      info: false,
      searching: false,
      autoWidth: false,
    });
    tbl_show_platform_project.draw();
    tbl_show_platform_project.columns.adjust();
  };


  groupTableProject($('#tbl_show_platform_project tr:has(td)'), 0, 1);
  $('#tbl_show_platform_project .deleted').remove();

  if ($('#tbl_show_platform_project tbody tr:eq(0)').attr('id')) {
    var row = $("#tbl_show_platform_project tbody tr:eq(0)");
    for (let i = 0; i < row.find("td").length; i++) {
      if (!$(row.find("td")[i]).attr('rowspan')) {
        $(row.find("td")[i]).addClass("row_selected_background");
      }
    }
  }

  $("body").delegate('.check_content_option', 'click', function (e) {
    update_check_content_status(e, this);
  });

  $("body").delegate('#btn_convert_to_text', 'click', function (e) {
    var iso_file_id = $("#iso_file_id").val();
    var image_path = $('#image_from_iso_file').attr('src');
    $.ajax
      ({
        type: 'GET',
        url: "/convert_iso_image_file_to_text",
        dataType: 'script',
        data: {
          image_path: image_path,
          iso_file_id: iso_file_id,
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      })
  });

  // $("body").delegate('#add_new_tag_field', 'click', function (e) {
  //   var tags = $("#add_tag_field").val();
  //   var iso_file_id = $('#iso_file_id').val();
  //   $("#add_tag_field").val('');
  //   $.ajax
  //     ({
  //       type: 'GET',
  //       url: "/add_iso_file_tag",
  //       dataType: 'script',
  //       data: {
  //         tags: tags,
  //         iso_file_id: iso_file_id ,
  //       },
  //       success: function (data, textStatus, jqXHR) {
  //         // console.log(data);
  //         console.log("AJAX OK!")
  //       },
  //       error: function (jqXHR, textStatus, errorThrown) {
  //         console.log("AJAX Error: #{textStatus}")
  //       }
  //     })
  // });

  $("body").delegate('#delete_iso_file_tag', 'click', function (e) {
    var iso_file_tag_id = $(this).closest("button").data('item-id');
    var iso_file_id = $('#iso_file_id').val();
    $.ajax
      ({
        type: 'GET',
        url: "/delete_iso_file_tag",
        dataType: 'script',
        data: {
          iso_file_tag_id: iso_file_tag_id,
          iso_file_id: iso_file_id,
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      })
  });
 
  if ($(".ui-autocomplete").show()){
    $('.ui-autocomplete.ui-front').addClass('fixed-height');
  }
  else{
    $('.ui-autocomplete.ui-front').removeClass('fixed-height');
  }

  $("body").delegate('#comment_iso_file_tab', 'click', function (e){
    $("#show_tags_iso_file_tab").removeClass("active");
    $("#show_note_iso_file_tab").removeClass("active");
    $("#show_tags_iso_file_tab").removeClass("show");
    $("#show_note_iso_file_tab").removeClass("show");
    $("#show_comment_iso_file_tab").addClass("active");
    $("#show_comment_iso_file_tab").addClass("show");
  });

  $("body").delegate('#tags_iso_file_tab', 'click', function (e){
    $("#show_comment_iso_file_tab").removeClass("active");
    $("#show_note_iso_file_tab").removeClass("active");
    $("#show_comment_iso_file_tab").removeClass("show");
    $("#show_note_iso_file_tab").removeClass("show");
    $("#show_tags_iso_file_tab").addClass("active");
    $("#show_tags_iso_file_tab").addClass("show");
  });

  $("body").delegate('#note_iso_file_tab', 'click', function (e){
    $("#show_comment_iso_file_tab").removeClass("active");
    $("#show_tags_iso_file_tab").removeClass("active");
    $("#show_comment_iso_file_tab").removeClass("show");
    $("#show_tags_iso_file_tab").removeClass("show");
    $("#show_note_iso_file_tab").addClass("active");
    $("#show_note_iso_file_tab").addClass("show");
  });


  $("body").delegate("#btn_iso_tag","click",function (e){
    var iso_file_id = $('#iso_file_id').val();
    if ($(this).hasClass("btn-light")){
      $(this).removeClass("btn-light");
      $(this).addClass("btn-success");
      $.ajax
      ({
        type: 'GET',
        url: "/add_iso_file_tag_from_modal",
        dataType: 'script',
        data: {
          tag: $(this).data('item-name'),
          iso_file_id: iso_file_id ,
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      })
    }
    else{
      $(this).removeClass("btn-success");
      $(this).addClass("btn-light");
      $.ajax
      ({
        type: 'GET',
        url: "/remove_iso_file_tag_from_modal",
        dataType: 'script',
        data: {
          tag: $(this).data('item-name'),
          iso_file_id: iso_file_id ,
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      })
    }
  });

  $("body").delegate("#find_tag_name","keyup",function (e){
    var find_tag_name = document.getElementById("find_tag_name").value;
    var iso_file_id = $('#iso_file_id').val();
    $.ajax
      ({
        type: 'GET',
        url: "/get_suggest_from_tag_input",
        dataType: 'script',
        data: {
          iso_file_id: iso_file_id,
          find_tag_name: find_tag_name ,
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
    if(e.keyCode == 13){
      $("#find_tag_name").val('');
      $.ajax
        ({
          type: 'GET',
          url: "/add_iso_file_tag",
          dataType: 'script',
          data: {
            tags: find_tag_name,
            iso_file_id: iso_file_id ,
          },
          success: function (data, textStatus, jqXHR) {
            // console.log(data);
            console.log("AJAX OK!")
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log("AJAX Error: #{textStatus}")
          }
        })
    }
  });

  // $('body').delegate("#btn_iso_tag","mouseenter mouseleave", function(e) {
  //   if(e.type === 'mouseenter'){
  //     $(this).find("#show_tags_menu").removeClass("d-none");
  //   }
  //   else{
  //     $(this).find("#show_tags_menu").addClass("d-none");
  //   }
  // });

  $('body').delegate("#show_tags_menu", "click", function(e) {
    e.stopPropagation();
    if ($("#iso_file_tag_menu").hasClass('d-none')){
      $("#iso_file_tag_menu").removeClass('d-none');
    }
    var tag_id = $(this).attr("data-item-id");
    $("#iso_file_tag_menu").attr("value",tag_id);
    $("#iso_file_tag_menu").css({
      'position': 'absolute',
      'top': this.offsetTop + 16,
      'left': this.offsetLeft + 10,
    });
  });

  $('body').delegate("#rename_iso_tag", "click", function(e) {
    e.stopPropagation();
  });

  $('body').delegate("#delete_iso_tag", "click", function(e) {
    e.stopPropagation();
    var iso_file_tag_id = $(this).closest("div").attr("value");
    var iso_file_id = $('#iso_file_id').val();
    $.ajax
      ({
        type: 'GET',
        url: "/destroy_iso_file_tag",
        dataType: 'script',
        data: {
          iso_file_tag_id: iso_file_tag_id ,
          iso_file_id: iso_file_id
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      })
  });

  $('body').delegate("#btn_summit_iso_comment", "click", function(e) {
    var iso_comment = $("#txt_area_iso_comment").val();
    var iso_file_id = $("#iso_file_id").val();
    var reply_comment = $("#get_content_reply_comment").data('item-id');
    var hidden_suggest_user_id = $("#hidden_suggest_user_name").val();
    var add_comment_func = $("#btn_summit_iso_comment").data('item-id');
    $("#txt_area_iso_comment").val("");
    $.ajax
      ({
        type: 'GET',
        url: "/add_iso_comment",
        dataType: 'script',
        data: {
          iso_comment: iso_comment,
          iso_file_id: iso_file_id,
          reply_comment: reply_comment,
          suggest_user_id: hidden_suggest_user_id,
          add_comment_func: add_comment_func
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
          $('#suggest_user_name').tokenfield('setTokens', []);
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      })
  });

  format_input_user_name("#suggest_user_name", "#hidden_suggest_user_name");

  $("body").delegate('.simpleReplyTextLink', 'mouseenter mouseleave', function (e) {
    e.stopPropagation();
    if( e.type === 'mouseenter' )  {
      if ($("#simpleReplyImageMenu").hasClass('d-none')){
        $("#simpleReplyImageMenu").removeClass('d-none');
      }
      var comment_id = $(this).closest("div").attr("data-item-id");
      $("#simpleReplyImageMenu").attr("value",comment_id);
      $("#simpleReplyImageMenu").css({
        'position': 'absolute',
        'top': this.getBoundingClientRect().top + $(window).scrollTop() - 120,
        'left': this.offsetLeft,
      });
    }
  });

  $("body").delegate('.simpleReplyImageMenu', 'mouseenter mouseleave', function (e) {
    // if the target of the click isn't the container nor a descendant of the container
    if (e.type === 'mouseleave') {
      $("#simpleReplyImageMenu").addClass('d-none');
    }
  });


  $(document).mouseup(function (e) {
    var container = $("#simpleReplyImageMenu");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) {
      $("#simpleReplyImageMenu").addClass('d-none');
    }
  });

  $("body").delegate('.simpleReplyPreImage', 'click', function (e) {
    var icon_value = $(this).data('icon-id');
    var comment_id = $(this).closest("div").attr("value");
    var iso_file_id = $("#iso_file_id").val();
    let total_reaction = $(this).closest('ul').find('li');
    for (let i = 0; i < total_reaction.length; i++) {
      if ($(total_reaction[i]).data('icon-id') === icon_value) {
        $(total_reaction[i]).addClass('activeSimpleReplyImage');
      }
      else{
        $(total_reaction[i]).removeClass('activeSimpleReplyImage');
      }
    }
    $.ajax
    ({
      type: 'GET',
      url: "/add_reaction_in_iso_comment",
      dataType: 'script',
      data: {
        icon_value: icon_value,
        comment_id: comment_id,
        iso_file_id: iso_file_id,
      },
      success: function (data, textStatus, jqXHR) {
        // console.log(data);
        console.log("AJAX OK!")
        $("#hidden_current_reaction_in_comments").val(icon_value);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    })
  });

  $("body").delegate('.simpleReplyTextLink', 'click', function (e) {
    var icon_value = $(this).data('icon-id');
    var comment_id = $(this).closest("div").data('item-id');
    var iso_file_id = $("#iso_file_id").val();
    if ($(this).hasClass('reaction-selected')){
      $(this).removeClass('reaction-selected');
      $.ajax
      ({
        type: 'GET',
        url: "/remove_reaction_in_iso_comment",
        dataType: 'script',
        data: {
          icon_value: icon_value,
          comment_id: comment_id,
          iso_file_id: iso_file_id,
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
          $("#hidden_current_reaction_in_comments").val("");
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
    }
    else{
      $(this).addClass('reaction-selected');
      $.ajax
      ({
        type: 'GET',
        url: "/add_reaction_in_iso_comment",
        dataType: 'script',
        data: {
          icon_value: 0,
          comment_id: comment_id,
          iso_file_id: iso_file_id,
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
    }
  });

  if (!$("#suggest_user_name").closest(".tokenfield").hasClass("d-none")){
    var iso_tag_user_field = $("#suggest_user_name").closest(".tokenfield");
    iso_tag_user_field.addClass("d-none");
  };
  
  $("body").delegate("#iso_file_tag_person", "click", function (e) {
    if ($("#suggest_user_name").closest(".tokenfield").hasClass("d-none")){
      $("#suggest_user_name").closest(".tokenfield").removeClass("d-none");
      var iso_tag_user_field = $("#suggest_user_name").closest(".tokenfield");
      iso_tag_user_field.css({
        'border': 'none !important',
        'border-top': '1px solid #dee2e6 !important',
        'border-radius': 'none !important',
      });
    }
    else{
      $("#suggest_user_name").closest(".tokenfield").addClass("d-none")
    }
  });


  $("body").delegate("#iso_file_reply_comment", "click", function (e) {
    var comment_id = $(this).closest("div").data('item-id');
    var iso_file_id = $("#iso_file_id").val();
    var iso_comment_index = $(this).closest('.media-body').find('#current_comment_index').text()
    var iso_comment_fullname = $(this).closest('.media-body').find('#current_comment_fullname_user').text()
    var iso_comment_user_id = $(this).closest('.media-body').find('#current_comment_fullname_user').data('item-id');
    if ($("#suggest_user_name").closest(".tokenfield").hasClass("d-none")){
      $("#suggest_user_name").closest(".tokenfield").removeClass("d-none");
      var iso_tag_user_field = $("#suggest_user_name").closest(".tokenfield");
      iso_tag_user_field.css({
        'border': 'none !important',
        'border-top': '1px solid #dee2e6 !important',
        'border-radius': 'none !important',
      });
    }
    $.ajax
      ({
        type: 'GET',
        url: "/reply_in_iso_comment",
        dataType: 'script',
        data: {
          comment_id: comment_id,
          iso_file_id: iso_file_id,
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!");
          $('#suggest_user_name').tokenfield('createToken', { id: parseInt(iso_comment_user_id), value: iso_comment_fullname, label: iso_comment_fullname });
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
  });

  $("body").delegate("#get_content_reply_comment", "click", function (e) {
    var comment_id = $(this).data('item-id');
    var iso_comment_content = $("#txt_area_iso_comment").val();
    $.ajax
      ({
        type: 'GET',
        url: "/get_content_reply_comment",
        dataType: 'script',
        data: {
          comment_id: comment_id,
          iso_comment_content: iso_comment_content,
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
  });

  $("body").delegate("#remove_reply_zone", "click", function (e) {
    $("#reply_to_container").addClass('d-none');
    $(".vr-replyLink-comment").text('');
    $("#txt_get_text_reply").text('を引用する');
    $("#reply_to_container").html("");
  });

  $("body").delegate("#iso_file_delete_comment", "click", function (e) {
    var comment_id = $(this).closest("div").data('item-id');
    var iso_file_id = $("#iso_file_id").val();
    var delete_comment_func = $(this).data('item-id');
    $.ajax
      ({
        type: 'GET',
        url: "/delete_in_iso_comment",
        dataType: 'script',
        data: {
          comment_id: comment_id,
          iso_file_id: iso_file_id,
          delete_comment_func: delete_comment_func
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
  });

  $("body").delegate("btn_get_list_reaction", "click", function (e) {
    var comment_id = $(this).closest("div").data('item-id');
    var iso_file_id = $("#iso_file_id").val();
    $.ajax
      ({
        type: 'GET',
        url: "/get_list_reaction_user",
        dataType: 'script',
        data: {
          comment_id: comment_id,
          iso_file_id: iso_file_id,
        },
        success: function (data, textStatus, jqXHR) {
          // console.log(data);
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      });
  })
});

function load_drop_zone_upload_iso_file() {
  $(function () {
    $('.dropzone').each(function () {

      let dropzoneControl = $(this)[0].dropzone;
      if (dropzoneControl) {
        dropzoneControl.destroy();
      }
    });
    var dopzone = $('#dropzone_upload_iso_file');
    if (dopzone.length > 0) {
      $('#dropzone_upload_iso_file').dropzone({
        url: "create_upload_iso_file",
        paramName: "file",
        maxFilesize: 50,
        previewTemplate: document.querySelector('#uploadPreviewTemplate').innerHTML,

        maxFiles: 100,
        clickable: true,
        autoProcessQueue: false,
        parallelUploads: 10,
        timeout: 6000000,


        accept: function (file, done) {
          switch (file.type) {
            case 'application/pdf':
              file.previewElement.querySelector("img").src = '/assets/images/pdf.png';
              break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
              file.previewElement.querySelector("img").src = '/assets/images/word.png';
              break;
            case 'application/msword':
              file.previewElement.querySelector("img").src = '/assets/images/word.png';
              break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              file.previewElement.querySelector("img").src = '/assets/images/excel.png';
              break;
            case 'application/vnd.ms-excel':
              file.previewElement.querySelector("img").src = '/assets/images/excel.png';
              break;
            case 'applications/ppt':
              file.previewElement.querySelector("img").src = '/assets/images/powerpoint.png';
              break;
            case 'applications/pptx':
              file.previewElement.querySelector("img").src = '/assets/images/powerpoint.png';
              break;
            case 'applications/zip':
              file.previewElement.querySelector("img").src = '/assets/images/zip_icon.png';
              break;
            default:
              file.previewElement.querySelector("img").src = '/assets/images/file.png';
              break;
          }

          done();
        },
        init: function () {
          var list_file_error = [], isSent = false;
          var project_id = $("#current_project_id").val();
          var myDropzone = this;
          var check_content_id = $(this.element).closest('.modal-body').find('#check_content_id_modal').text();
          var project_id = $(this.element).closest('.modal-body').find('#project_id_modal').text();
          // this.on("error", function (file, message, xhr) {
          //   if (xhr == null) {
          //     this.removeFile(file);
          //   }
          //   alert(file.name + " upload error : " + message);

          // });

          $("body").delegate('#btn_save_upload_file_project', 'click', function () {
            myDropzone.processQueue();
            window.$('#modal_upload_temp_file').modal('hide');
          });

          this.on("error", function (file, message, xhr) {
            // var file_error = file.name + " upload error : "+ message
            // list_file_error.push(file_error);
            window.$('#modal_temp_file').modal('hide');
            this.removeFile(file); // perhaps not remove on xhr errors
            // var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
            // progressElement.querySelector(".progress-text").textContent = message;
            var file_error = file.name + " (" + parseFloat(file.size / 1024 / 1024).toFixed(2) + "MiB)"
            list_file_error.push(file_error);
            if (xhr.responseText.search("ValueTooLong") > 0) {
              $("#upload_iso_file_content_warning").html("File contain Text is too long: " + list_file_error.join(', '));
            }
            else {
              $("#upload_iso_file_content_warning").html("File is too big upload error: " + list_file_error.join(', ') + ".<br> Max filesize:50MiB.");
            }
            window.$("#warning-alert-modal").modal('show');
            window.$('#warning-alert-modal').on('hidden.bs.modal', function () {
              list_file_error = [];
            });

            // alert(file.name + " upload error : "+ message);
          });

          this.on("addedfile", function (file) {
            document.getElementById('borrow_id').style.display = "block";
            document.getElementById('dropzone_upload_iso_file').style.display = "block";
            document.getElementById('dropzone_upload_iso_file').style.minHeight = "min-content";
          });

          this.on("sending", function (file, xhr, formData) {
            formData.append("check_content_id", check_content_id);
            formData.append("project_id", project_id);
          });

          this.on("success", function (file, responseText) {
            $.ajax
            // ({
            //   type: 'GET',
            //   url: project_id + "/update_upload_iso_file_project",
            //   dataType: 'script',
            //   data: {
            //     check_content_id: check_content_id,
            //     project_id: project_id,
            //   },
            //   success: function (data, textStatus, jqXHR) {
            //     // console.log(data);
            //     console.log("AJAX OK!")
            //   },
            //   error: function (jqXHR, textStatus, errorThrown) {
            //     console.log("AJAX Error: #{textStatus}")
            //   }
            // })
            var url = window.location.href;
            if (!(url.includes('edit') || url.includes('projects/new'))) {
              $.ajax
                ({
                  type: 'GET',
                  url: project_id + "/update_upload_iso_file_project",
                  dataType: 'script',
                  data: {
                    check_content_id: check_content_id,
                    project_id: project_id,
                  },
                  success: function (data, textStatus, jqXHR) {
                    // console.log(data);
                    console.log("AJAX OK!")
                  },
                  error: function (jqXHR, textStatus, errorThrown) {
                    console.log("AJAX Error: #{textStatus}")
                  }
                })
            }
            else {
              if (url.includes('edit')) {
                $.ajax
                  ({
                    type: 'GET',
                    url: "/update_upload_iso_file_project",
                    dataType: 'script',
                    data: {
                      check_content_id: check_content_id,
                      project_id: project_id,
                    },
                    success: function (data, textStatus, jqXHR) {
                      // console.log(data);
                      console.log("AJAX OK!")
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                      console.log("AJAX Error: #{textStatus}")
                    }
                  })
              }
            }

          });

          this.on("uploadprogress", function (file, progress, bytesSent) {
            if (file.previewElement) {
              var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
              progressElement.style.width = progress + "%";
              progressElement.querySelector(".progress-text").textContent = progress + "%";
            }

          });

        }
      });

    }
  });
}

function delete_Projects(e) {
  var mydata = [];
  $.each($('.tbl-project tbody tr'), function (i, row) {
    if ($(row).find("input[class='dt-checkboxes']").is(":checked")) mydata.push($(row).data('item-id'));
  });
  // console.log(mydata);
  if (mydata.length == 0) { return; }
  $.ajax
    ({
      type: 'GET',
      url: "confirm_delete_multi_project",
      dataType: 'script',
      data: {
        data: { ids: mydata },
      },
      success: function (data, textStatus, jqXHR) {
        // console.log(data);
        console.log("AJAX OK!")
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    })
}

function moveUser(selectUserFrom, selectUserTo) {
  $(selectUserFrom).find('option:selected').detach().prop("selected", false).appendTo($(selectUserTo));
}

function groupTableProject($rows, startIndex, total) {
  if (total === 0) {
    return;
  }
  var i, currentIndex = startIndex, count = 1, lst = [];
  var tds = $rows.find('td:eq(' + currentIndex + ')');
  var ctrl = $(tds[0]);
  lst.push($rows[0]);
  for (i = 1; i <= tds.length; i++) {
    if (ctrl.text() == $(tds[i]).text()) {
      count++;
      $(tds[i]).addClass('deleted');
      lst.push($rows[i]);
    }
    else {
      if (count > 0) {
        ctrl.attr('rowspan', count);
      }
      count = 1;
      lst = [];
      ctrl = $(tds[i]);
      lst.push($rows[i]);
    }
  }
}

function update_check_content_status(e, current) {
  e.stopPropagation();
  var current_value = $(current).val();
  var check_content_id = $(current).attr('data_item');
  $.ajax
    ({
      type: 'GET',
      url: "/update_check_content_status",
      dataType: 'script',
      data: {
        check_content_id: check_content_id,
        current_value: current_value
      },
      success: function (data, textStatus, jqXHR) {
        // console.log(data);
        console.log("AJAX OK!")
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    })
}

function get_view_note(e, current, url, is_disabled) {
  e.stopPropagation();
  var upload_file_id = $(current).data('item-id');
  var project_id = $("#current_project_id").val();
  $.each($('.stbl-iso-file-project tbody tr'), function (i, row) {
    if (!$(row).hasClass('child')) {
      if ($(row).data('item-id') !== upload_file_id) {
        $(row).removeClass("selected");
        $(row).find('a').removeClass("add_note_color");
      }
      else {
        $(row).addClass("selected")
        $(row).find('a').addClass("add_note_color");
      };
    }
  });
  $.ajax
    ({
      type: 'GET',
      url: url,
      dataType: 'script',
      data: {
        upload_file_id: upload_file_id,
        project_id: project_id,
        is_disabled: is_disabled,
      },
      success: function (data, textStatus, jqXHR) {
        // console.log(data);
        console.log("AJAX OK!")
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    })
}


function format_input_user_name(add_user_field, hidden_user_field){
  $(add_user_field).tokenfield({
    autocomplete: {
      source: function ( request, response ) {
                // autocomplete_tokenfield(id_hide_textfield_email, request, response);
                var keyword = $(hidden_user_field).val();
                keyword == "" ? keyword = "" : keyword = keyword + ","
                $.getJSON( "/filter_user_iso_comment", {
                        term:  keyword + request.term,
                }, response );
            },
            minLength: 0,
            delay: 100,
        },
        showAutocompleteOnFocus: true
    })

  .on('tokenfield:createtoken', function (e) {
        var existingTokens = $(this).tokenfield('getTokens');
        $.each(existingTokens, function(index, token) {
            if (token.id === e.attrs.id)
                e.preventDefault();
        }); 
    })

  .on('tokenfield:createdtoken', function (e) {
        arr_list_name = $(this).tokenfield('getTokensList').split(", ");
        var get_name = this;
        var list_id = split_format_name(arr_list_name, get_name);
        $(hidden_user_field).val(list_id.join(","));
        if ($("#hidden_iso_tag_search").val()){
          $("file_with_tags").attr("placeholder", "");
        }else{
          $("file_with_tags").attr("placeholder", "Tag");
        }
    })

  .on('tokenfield:removedtoken', function (e) {
    arr_list_name = $(this).tokenfield('getTokensList');
    if (arr_list_name !== ''){
      var get_name = this;
      var list_id = split_format_name(arr_list_name.split(", "), get_name);
      $(hidden_user_field).val(list_id.join(","));
    }
    else {
      $(hidden_user_field).val('');
    }
    if ($("#hidden_iso_tag_search").val()){
      $("file_with_tags").attr("placeholder", "");
    }else{
      $("file_with_tags").attr("placeholder", "Tag");
    }
       
  });
}

function split_format_name(arr_list_name, get_name) {
	var user_id_list = [];
	$.each(arr_list_name, function(index, value) {
    var user_id = $(get_name).tokenfield('getTokens').filter(obj => {return obj.value === value})[0].id;
		user_id_list.push(user_id);
	});
	return user_id_list;
}

