export function selected_page(event) {
  var selected = event.target.getAttribute("selected");
  if (selected != "true") {
    event.target.setAttribute("selected", true);
    event.target.classList.add("page_selected");
    $(event.target).closest('.thumbnail-frame').find(".thumbnailNumber").addClass("number-page-selected");
  } else {
    event.target.setAttribute("selected", false);
    event.target.classList.remove("page_selected");
    $(event.target).closest('.thumbnail-frame').find(".thumbnailNumber").removeClass("number-page-selected");
  }
}
