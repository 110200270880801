import { PDFMerge } from './PDFMerge';
import { selected_page } from '../common/selected_page';
import {PDFDocument} from 'pdf-lib';
var currentPathFile;

$(document).on('turbo:load', function () {
  $("#btn_merge_page").click(function () {
    var id = $("#pdf_id").val();
    $.ajax({
      type: 'GET',
      url: "/pdf/edit_merge_pdf",
      dataType: 'script',
      data: {
        id: id
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: " + textStatus);
      }
    });
  });
})

window.initModalMergePDF = function () {
  document.querySelector('.merge-process-step').style.display = 'none';
  document.querySelector('#btnSaveMerge').style.display = 'none';
  document.querySelector('#btnAcceptFiles').style.display = 'block';
  document.querySelector('.merge-borrow-step').style.display = 'block';
  document.querySelector('#form_image_input').replaceChildren();
  document.querySelector('#lstRightItems').replaceChildren();

}

window.load_drop_zone_for_merge_file = function () {
  $(function () {
    $('.dropzone').each(function () {

      let dropzoneControl = $(this)[0].dropzone;
      if (dropzoneControl) {
        dropzoneControl.destroy();
      }
    });
    var dopzone = $('#dz_merge_file');
    if (dopzone.length > 0) {
      $('#dz_merge_file').dropzone({
        url: window.origin + "/pdf/create_pdf_tem",
        paramName: "file",
        maxFilesize: 16,
        maxFiles: 100,
        previewTemplate: document.querySelector('#uploadPreviewTemplate').innerHTML,
        acceptedFiles: ".pdf",
        clickable: true,
        autoProcessQueue: false,
        accept: function (file, done) {
          switch (file.type) {
            case 'application/pdf':
              file.previewElement.querySelector("img").src = '/assets/images/pdf.png';
              break;
            default:
              var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
              progressElement.querySelector(".progress-text").textContent = "error";
              break;
          }

          done();
        },
        init: function () {
          setMessageErrorFile(this.options);
          var btn_upload = document.querySelector("#btnAcceptFiles");
          var myDropzone = this;
          var empty_storage = parseFloat(document.getElementById("empty_storage").value); // unit bytes
          btn_upload.addEventListener("click", function (e) {
            myDropzone.processQueue();
            showProcessMerge();
          });

          this.on("error", function (file, message, xhr) {
            messageErrorFile(file, message);
            defineIcon(file);	
          });

          this.on("addedfile", function (file) {
            myDropzone.options.autoProcessQueue = false;
            document.getElementById('borrow_id').style.display = "block";
            document.getElementById('dz_merge_file').style.display = "block";
            document.getElementById('dz_merge_file').style.minHeight = "min-content";
            if (file.size > empty_storage) {
              alert("Empty storage capacity: " + calculate_empty_storage(empty_storage));
              this.removeFile(file);
            }
            else {
              empty_storage = (empty_storage - file.size);
            }
          });

          this.on("success", function (file, responseText) {
            myDropzone.options.autoProcessQueue = true;
            var fileSelectd = responseText.file;
            file.previewTemplate.querySelector('.id_file_pdf').value = fileSelectd.slug;
            file.previewTemplate.querySelector('.id_file_pdf').setAttribute("data-name", fileSelectd.filename);
            file.previewTemplate.querySelector('.id_file_pdf').setAttribute("data-path", fileSelectd.path.url);
            var removeButton = file.previewElement.querySelector('[data-dz-remove]');
            removeButton.addEventListener("click", function (e) {
              e.preventDefault();
              e.stopPropagation();
              var id_file_pdf = file.previewElement.querySelector('.id_file_pdf').value;
              $.ajax({
                type: 'GET',
                url: window.origin + "/pdf/destroy_file_pdf",
                dataType: 'script',
                data: {
                  id_file_pdf: id_file_pdf
                },
                success: function (data, textStatus, jqXHR) {
                  ;
                },
                error: function (jqXHR, textStatus, errorThrown) {
                  console.log("AJAX Error: #{textStatus}")
                }
              });
            })
          });

          this.on("removedfile", function(file) {
            empty_storage = empty_storage + file.size;
          });
          this.on("uploadprogress", function (file, progress, bytesSent) {
            if (file.previewElement) {
              var progressElement = file.previewElement.querySelector("[data-dz-uploadprogress]");
              progressElement.style.width = progress + "%";
              progressElement.querySelector(".progress-text").textContent = progress + "%";
            }
          });

          this.on("complete", function (file) {
            if (this.getUploadingFiles().length === 0 && this.getQueuedFiles().length === 0) {
              displayMergeDataLeft();
            }
          });
        }
      });

    }
  });
}

function showProcessMerge() {
  document.querySelector('.merge-borrow-step').style.display = 'none';
  document.querySelector('#btnAcceptFiles').style.display = 'none';
  document.querySelector('.merge-process-step').style.display = 'block';
  document.querySelector('#btnSaveMerge').style.display = 'block';
}

function displayMergeDataLeft() {
  document.querySelector('#form_image_input').replaceChildren();
  var listIdElem = document.querySelectorAll('.id_file_pdf');
  var item, filename,filepath;
  var files = [];
  for (let i = 0; i < listIdElem.length; i++) {
    item = listIdElem[i];
    if (item == null) continue;

    filename = item.getAttribute("data-name");
    filepath = item.getAttribute("data-path");
    if (filename != null && filename != "") {
      files.push({name: filename,url: filepath});
    }
  }
  setLeft(files);
}

export async function setLeft(files) {
  for await (const file of files) {
    getUrl(file);
  }
}

async function getUrl(file) {
  var pdfBytes = await loadFile(file.url);
  displayPdfLeft(pdfBytes, file);
}

async function loadFile(formUrl) {
  return await fetch_data(formUrl); 
}

async function displayPdfLeft(PdfBytes, file) {
  var form_image_input = document.getElementById("form_image_input");
  var fieldset_tag = document.createElement("fieldset");
  fieldset_tag.setAttribute("data-path", file.url);
  fieldset_tag.className = "fieldset_tag";
  var legent_tag = document.createElement("legend");
  legent_tag.className = "legent_tag";
  var filename = file.name;
  legent_tag.textContent = filename;
  var rowImage = document.createElement("div");
  rowImage.className = "lstPage row mx-2";
  fieldset_tag.appendChild(legent_tag);
  fieldset_tag.appendChild(rowImage);
  form_image_input.appendChild(fieldset_tag);
  currentPathFile = path;
  try 
  {
    await PDFDocument.load(PdfBytes);
  } catch (error) {
    var label = document.createElement('label');
    label.className='ms-2 text-danger'
    label.innerHTML = 'This document is encrypted !!!'//error
    fieldset_tag.appendChild(label); 
    return;
  }
  const pdfDoc = pdfjsLib.getDocument({
    data: PdfBytes,
    cMapUrl: '/cmaps/',
    cMapPacked: true
  });

  pdfDoc.promise.then(function (doc) {
    var pages = []; while (pages.length < doc.numPages) {
      pages.push(pages.length + 1);
    }

    return Promise.all(pages.map(function (num) {
      return doc.getPage(num).then(makeThumnailImageLeft)
        .then(function (li) {
          rowImage.appendChild(li);
        });
    }));

  }).catch(console.error);

}

function makeThumnailImageLeft(page) {
  var vp = page.getViewport({ scale: 1 });
  var canvas = createCanvasLeft(page);
  setAtrributeRightCanvas(canvas, currentPathFile, page._pageIndex);
  canvas.addEventListener('click', selected_page, false);

  var scale_thumbnail = Math.min(canvas.width / vp.width, canvas.height / vp.height);
  page.render({ canvasContext: canvas.getContext("2d"), viewport: page.getViewport({ scale: scale_thumbnail }) }).promise;
  var dv = createContainerPageLeft(page._pageIndex, canvas);
  return dv;
}

function createCanvasLeft(page) {
  var canvas = document.createElement("canvas");
  canvas.className = "pdf-thump-left";

  var viewPort = page.getViewport({ scale: 1 })
  var scale_thum = 120 / viewPort.width;
  canvas.width = viewPort.width * scale_thum;
  canvas.height = viewPort.height * scale_thum;
  canvas.setAttribute("selected", false);
  canvas.setAttribute("num", page._pageIndex);
  canvas.addEventListener('contextmenu', event => event.preventDefault());

  return canvas;
}

function setAtrributeRightCanvas(backCanvas, path_file, numberOfPage) {
  backCanvas.className = "pdf-thump-left box";
  backCanvas.setAttribute("data-path", path_file);
  backCanvas.setAttribute("num", numberOfPage);
  backCanvas.addEventListener('contextmenu', event => event.preventDefault());
  return backCanvas;
}

function createContainerPageLeft(numPage, canvas) {
  var dv = document.createElement("div");
  dv.id = "thumbnail-frame-" + numPage;
  dv.className = "thumbnail-frame d-flex flex-column btn-fit-content p-0 m-1 droptarget";

  var span = document.createElement("span");
  span.className = "thumbnailNumber";
  span.textContent = (numPage + 1);

  dv.appendChild(canvas);
  dv.appendChild(span);
  return dv;
}

window.openMergePDF = async function (url) {
  var pdfMerge = new PDFMerge(url);
  await pdfMerge.open();
}
