$(document).ready(function() {
  if ($("#workload").length > 0) {
    let data = gon.log_works;
    let list_holiday = gon.list_holiday;
    let width = $(window).width();
    let a = data.length;

    const weekday = I18n.t("date.abbr_day_names");
    const months = I18n.t("date.month_names");
    const years = I18n.t("common.year");
  
    function daysInMonth (month, year) {
      return new Date(year, month, 0).getDate();
    }

    function IsdaySat (day, month, year) {
      date =  new Date(`${month}/${day}/${year}`).getDay();
      return (date == 6) 
    }

    function IsdaySun (day, month, year) {
      date =  new Date(`${month}/${day}/${year}`).getDay();
      return (date == 0) 
    }
    
    function Isholiday (day, month, year) {
      date =  new Date(`${month}/${day}/${year}`).getDay();
      return (date == 0 || date == 6 || check_holiday(day, list_holiday))
    }

    function check_holiday(day, list_holiday) {
      let a = false
      $.each(list_holiday, function(i, value) {
        if(day == value){ a = true}
      })

      return a;
    }

    function Isweekdays (day, month, year) {
      date =  new Date(`${month}/${day}/${year}`).getDay();
      return date
    }

    function listDay(day_count){
      let array_day = []
      for (var i = 0; i < day_count; i++){
        array_day.push(i + 1);
      }
      return array_day
    }

    let date = $("#workload").data("day")

    let date_select = new Date(date);
        year = date_select.getUTCFullYear();
        month = date_select.getUTCMonth() + 1;

    let days = listDay(daysInMonth(month, year))

    let header = [
      { type: 'hidden', title:'Id'},
      { type: 'hidden', title:'par' },
      { type: 'text', title: I18n.t("work_reports.pj_no"), width: 75, color: "#fffff" },
      { type: 'text', title: I18n.t("work_reports.project"), width: 125, color: "#fffff", align: 'left' },
    ]
  
    $.each(days, function(i, value) {
      let weekdays = Isweekdays (value, month, year)
      if (check_holiday (i+1, list_holiday)){
        let day = weekday[weekdays]
        header.push({ type: 'numeric', title:`${day}`, width: 35, color: 'red' })
      }
      else if( weekdays == 6){
        let day = weekday[weekdays]
        header.push({type: 'numeric', title:`${day}`, width: 35, color: "#3688fc"})
      }
      else if( weekdays == 0){
        let day = weekday[weekdays]
        header.push({type: 'numeric', title:`${day}`, width: 35, color: "red"})
      }
      else {
        let day = weekday[weekdays]
        header.push({ type: 'numeric', title:`${day}`, width: 35})
      }
    })

    header.push({ type: 'numeric', title: I18n.t("work_reports.total"), width: 45, color: "#42d29d" })

    const list_work_day = [];
    for (let i = 4; i < header.length-1; i++) {
      if (data[a-1][i] === null )
      list_work_day.push("0")
      else
      list_work_day.push(parseInt(data[a-1][i].split(":")[0]) + parseInt(data[a-1][i].split(":")[1])/60);
    }
    const list_total = [];
    for (let i = 4; i < header.length-1; i++) {
      if (data[a-1][i] === null )
      list_total.push("0")
      else
      list_total.push(data[a-5][i]);
    }
    const total_dif_work_day = [];
    for (let i = 0; i < list_total.length; i++) {
      if (list_work_day[i] != list_total[i]){
        total_dif_work_day.push(i);
      }
    }

    let estimated_day = 0
    $.each(days, function(i, value) {
      let weekdays = Isweekdays (value, month, year);
      if (weekdays != 0 && weekdays != 6 && check_holiday (value, list_holiday) != true) {
        estimated_day = estimated_day +1
      }
    })

    document.getElementById("estimated").innerHTML = estimated_day ;
    let nestedHeaders = [
      {
        title: '',
        colspan: "2",
      },
    ]
    $.each(days, function(i, value) {
      if (IsdaySat(value, month, year)) {
        nestedHeaders.push({ type: 'numeric', title:`${value}`, colspan:"1", color: "#3688fc", width: 35})
      }
      else if (Isholiday (value, month, year) || IsdaySun(value, month, year)) {
        nestedHeaders.push({ type: 'numeric', title:`${value}`, colspan:"1", color: "red", width: 35})
      }
      else {
        nestedHeaders.push({ type: 'numeric', title:`${value}`, colspan:"1", width: 35})
      }
    })
    nestedHeaders.push({ type: 'numeric', title: "", width: 45 })
    let nestedHeader = [
      [
        {
          title: `${year}${years}${months[month]}`,
          colspan: `${days.length + 3}`
        },
      ], 
      nestedHeaders
    ]
    table = jspreadsheet(document.getElementById('workload'), {
      data:data,
      tableOverflow: true,
      editable:false,
      tableWidth: width,
      tableHeight: "1400px",
      columns: header,
      nestedHeaders: nestedHeader,
      updateTable:function(instance, cell, col, row, val, label, cellName) {
        
        // if(col == (header.length -1)) {
        //   cell.style.backgroundColor = '#43bffd26'
        //   cell.style.fontWeight = 'bold';
        // }

        for (let i = 0; i < total_dif_work_day.length; i++) {
          if (row == data.length-1 && col == total_dif_work_day[i]+4 ) {
            cell.style.backgroundColor = 'red'
            cell.style.color = 'white'
          }
        }
        
        if (col == header.length-1) {
          cell.style.color = '#42d29d'
        }

        if (row == data.length - 5) {
          cell.style.color = 'red'
        }
       
       if (row > data.length-4 && col > 3 && col < header.length+1){
        cell.lastChild.style.display = 'block'
        cell.lastChild.style.transform = 'rotate(-90deg)';
        cell.style.height = '64px';
       }
       
      },
    });
  
    table.setMerge(`C${a}`, 2, 1)
    table.setMerge(`C${a-1}`, 2, 1)
    table.setMerge(`C${a-2}`, 2, 1)
    table.setMerge(`C${a-3}`, 2, 1)
  }
});
