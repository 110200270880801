import 'quill-mention';
import Quill from 'quill';
$(document).on('turbo:load', function() {
	// quill for comment schedule
	quillCommentSchedule("#editor_comment_schedule","#comment_content");
	initButtonTitle();

	$("body").delegate('#btnSaveComment', 'click', function () {
		var content_comment = $("#comment_content").val();
		if (content_comment.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
			$("#content_warning").text(I18n.t('schedules.messages.content_comment'));
      window.$("#warning-alert-modal").modal('show');
		} 
		else {
			var doc = new DOMParser().parseFromString(content_comment, "text/html");
			var list_mention_user = doc.getElementsByClassName("mention");
			if (list_mention_user.length > 0) {
				var arr_mention_user = [];
				$.each(list_mention_user, function(index, elem) {
					arr_mention_user.push($(elem).data("id"));
				});
				document.getElementById("comment_mention_user").value = arr_mention_user.toString();
			}
			document.getElementById("btn_save_comment").click();
		}
	});
});

window.quillCommentSchedule = function(element, input) {
	if ($(element).length > 0) {
		var quillEditor = new Quill(element, {
			modules: {
				toolbar: [
					[{ 'font': [] }, { 'size': [] }],
					['bold', 'italic', 'underline', 'strike'],
					[{ 'color': [] }, { 'background': [] }],
					[{ 'script': 'super' }, { 'script': 'sub' }],
					[{ 'header': [false, 1, 2, 3, 4, 5, 6] }, 'blockquote', 'code-block'],
					[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
					['direction', { 'align': [] }],
					['link'],
					['clean']
				],
				mention: {
					allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
					mentionDenotationChars: ["@", "#"],
					source: function(searchTerm, renderList, mentionChar) {
						var content_comment = document.querySelector(".ql-editor").innerHTML;
						var doc = new DOMParser().parseFromString(content_comment, "text/html");
						var list_mention_user = doc.getElementsByClassName("mention");
						if (list_mention_user.length > 0) {
							var arr_mention_user = [];
							$.each(list_mention_user, function(index, elem) {
								arr_mention_user.push($(elem).data("id"));
							});
						}
						$.ajax({
							url: '/filter_mention_user',
							type: 'get',
							data: {
								q: searchTerm,
								without_user: arr_mention_user
							},
							async: true //This works but freezes the UI
						}).done(function(values) {
							renderList(values, searchTerm);
						});
					}
				}
			},
			theme: 'snow' // or 'bubble'
		});

		quillEditor.on('text-change', function() {
				$(input).val(quillEditor.root.innerHTML);
		});
	}
}

// popover in toolbar quill js
window.initButtonTitle = function() {
  const titleConfig = [
    {
      Choice: ".ql-bold",
      title: I18n.t('quill_tooltip_toolbar.txt_bold') // "加粗"
    },
    {
      Choice: ".ql-italic",
      title: I18n.t('quill_tooltip_toolbar.txt_italic') // "斜体" 
    },
    {
      Choice: ".ql-underline", 
      title: I18n.t('quill_tooltip_toolbar.txt_underline') // "下划线"
    },
    {
      Choice: ".ql-header",
      title: I18n.t('quill_tooltip_toolbar.txt_header') //"段落格式" // 
    },
    {
      Choice: ".ql-strike",
      title: I18n.t('quill_tooltip_toolbar.txt_strike') //"删除线" // 
    },
    {
      Choice: ".ql-blockquote",
      title: I18n.t('quill_tooltip_toolbar.txt_blockquote') // "块引用" // 
    },
    {
      Choice: ".ql-code",
      title: I18n.t('quill_tooltip_toolbar.txt_code')  // "插入代码" //
    },
    {
      Choice: ".ql-code-block",
      title: I18n.t('quill_tooltip_toolbar.txt_code_block') // "插入代码段" //
    },
    {
      Choice: ".ql-font",
      title: I18n.t('quill_tooltip_toolbar.txt_font') // "字体" //
    },
    {
      Choice: ".ql-size",
      title: I18n.t('quill_tooltip_toolbar.txt_size') // "字体大小" //
    },
    {
      Choice: '.ql-list[value="ordered"]',
      title: I18n.t('quill_tooltip_toolbar.txt_list_value_order') // "编号列表" //
    },
    {
      Choice: '.ql-list[value="bullet"]',
      title: I18n.t('quill_tooltip_toolbar.txt_list_value_bullet') // "项目列表" //
    },
    {
      Choice: ".ql-direction",
      title: I18n.t('quill_tooltip_toolbar.txt_direction') // "文本方向" //
    },
    {
      Choice: '.ql-header[value="1"]',
      title: I18n.t('quill_tooltip_toolbar.txt_header_value_1') // "h1" //
    },
    {
      Choice: '.ql-header[value="2"]',
      title: "h2" // I18n.t('quill_tooltip_toolbar.txt_header_value_2') //
    },
    {
      Choice: ".ql-align",
      title: I18n.t('quill_tooltip_toolbar.txt_align') // "对齐方式" //
    },
    {
      Choice: ".ql-color",
      title: I18n.t('quill_tooltip_toolbar.txt_color') // "字体颜色" //
    },
    {
      Choice: ".ql-background",
      title: I18n.t('quill_tooltip_toolbar.txt_background') // "背景颜色" //
    },
    {
      Choice: ".ql-image",
      title: I18n.t('quill_tooltip_toolbar.txt_image') // "图像" //
    },
    {
      Choice: ".ql-video",
      title: I18n.t('quill_tooltip_toolbar.txt_video') // "视频" //
    },
    {
      Choice: ".ql-link",
      title: I18n.t('quill_tooltip_toolbar.txt_link') // "添加链接" //
    },
    {
      Choice: ".ql-formula",
      title: I18n.t('quill_tooltip_toolbar.txt_formula') // "插入公式" //
    },
    {
      Choice: ".ql-clean",
      title: I18n.t('quill_tooltip_toolbar.txt_clean') // "清除字体格式" //
    },
    {
      Choice: '.ql-script[value="sub"]',
      title: I18n.t('quill_tooltip_toolbar.txt_script_sub') // "下标" //
    },
    {
      Choice: '.ql-script[value="super"]',
      title: I18n.t('quill_tooltip_toolbar.txt_script_super') // "上标" //
    },
    {
      Choice: '.ql-indent[value="-1"]',
      title: I18n.t('quill_tooltip_toolbar.txt_indent_1') //"向左缩进" // 
    },
    {
      Choice: '.ql-indent[value="+1"]',
      title: I18n.t('quill_tooltip_toolbar.txt_indent_sub_1') //"向右缩进" // 
    },
  ];
  for (let item of titleConfig) {
    let tip = document.querySelector(item.Choice);
    if (!tip) continue;
    tip.setAttribute("data-bs-toggle", "popover");
    tip.setAttribute("data-bs-placement", "top");
    tip.setAttribute("data-bs-trigger", "hover");
    tip.setAttribute("data-bs-content", item.title);
  }
  window.$('[data-bs-toggle="popover"]').popover();
}