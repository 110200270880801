export class ToolBar{  
    eventBus = null  
    constructor(options, eventBus){
        this.eventBus = eventBus;
        this.buttons = [{
          element: options.firstpage,
          eventName: "firstpage"
        },
        {
          element: options.endpage,
          eventName: "endpage"
        },
        {
          element: options.previous,
          eventName: "previouspage"
        },
        {
          element: options.next,
          eventName: "nextpage"
        }, {
          element: options.zoomIn,
          eventName: "zoomin"
        }, {
          element: options.zoomOut,
          eventName: "zoomout"
        }, {
          element: options.rotateleft,
          eventName: "rotateleft"
        }, 
        {
          element: options.rotateright,
          eventName: "rotateright"
        }];
        this.currentpage = options.current_page;
        this.selectScale = options.select_scale;
        this.#bindListeners(options);
    }   
 
    
    #bindListeners(options) {   
        for (const {
          element,
          eventName,
          eventDetails
        } of this.buttons) {
          element.addEventListener("click", evt => {
            if (eventName !== null) {
              const details = {
                source: this
              };
    
              if (eventDetails) {
                for (const property in eventDetails) {
                  details[property] = eventDetails[property];
                }
              }
    
              this.eventBus.dispatch(eventName, details);
            }
          });
        }    
        this.currentpage.addEventListener('change',evt =>{
          var numberPage = 0;
          numberPage = parseInt(this.currentpage.value);
          if (!Number.isInteger(numberPage)) {
            throw new Error("Invalid page number.");
          }
          this.eventBus.dispatch("pagechanging", {
            source: null,
            pageNumber: numberPage,        
          }); 
        })

        this.selectScale.addEventListener('change',evt =>{
          var scaleValue = this.selectScale.value;        
          this.eventBus.dispatch("changescale", {
            source: null,
            scale_value: scaleValue,        
          }); 
        })
       
      }
}


