import {fabric} from 'fabric'; 
export class FText extends fabric.IText{
  // toJSON(){
  //   return fabric.util.object.extend(this.callSuper('toObject'), {
  //     label: this.get('label')
  //   });
  // }
  constructor(text, options){
    super(text, options);

  }
 
}