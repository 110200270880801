$(document).on('turbo:load', function() {
  formatDatatableShift();

  // event Enter submit form
  $("body").delegate('#shift_modal', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_save_shift").click();
    }
  });

  $("body").delegate('#setting_work_log_time', 'keydown', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btn_save_setting_general_shift").click();
    }
  });
});

window.formatDatatableShift = function() {
  if ($("#tbl_shift").length > 0) {

    window.$('#tbl_shift').DataTable({
      'destroy': true,
      'columnDefs': [
        { "width": "5%", "targets": 0},
        { "width": "25%", "targets": 1},
        { "width": "15%", "targets": 2 },
        { "width": "15%", "targets": 3 },
        { "width": "20%", "targets": 4 },
        { "width": "10%", "targets": 4 },
      ],
      "ordering": true,
      scrollCollapse: true,
      paging: false,
      info: false,
      searching: false,
    });
  };
}
