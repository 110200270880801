$(document).on('turbo:load', function() {
	$("body").delegate('#list_department_delete_schedule', 'change', function() {
    var data_type = "1";
    var category_id, department_id;
    if ($("#list_department_delete_schedule").find(":selected").is("[data-equipment]")) {
    	category_id = $("#list_department_delete_schedule option:selected").val();
    } 
    else {
    	department_id = $("#list_department_delete_schedule option:selected").val();
	    data_type = $("#list_department_delete_schedule option:selected").attr('data-type');
    }

    $.ajax({
      type: 'GET',
      url: "/filter_users_or_equipment_delete_schedule_all",
      dataType: 'script',
      data: {
        department_id: department_id,
        data_type: data_type,
        category_id: category_id
      },
      success: function(data, textStatus, jqXHR){
        
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    });
  });
  $("body").delegate('#btnDestroySchedules', 'click', showFormLoading )
  $("#btnDeleteScheduleAll").click(function() {	
  	var ckb_date_delete_schedule = $("#ckb_date_delete_schedule").is(':checked');
  	var date_delete, department_id, data_type, category_id;
  	if (ckb_date_delete_schedule) {
  		date_delete = $("#date_delete_schedule").val();
  	}

  	if ($("#list_department_delete_schedule").find(":selected").is("[data-equipment]")) {
    	category_id = $("#list_department_delete_schedule option:selected").val();
    } 
    else {
    	department_id = $("#list_department_delete_schedule option:selected").val();
	    data_type = $("#list_department_delete_schedule option:selected").attr('data-type');
    }

  	var item_id = $("#list_user_delete_schedule_all").val();

  	if (item_id.length > 0) {
  		$.ajax({
	      type: 'GET',
	      url: "/confirm_delete_schedule_all",
	      dataType: 'script',
	      data: {
	        item_id: item_id,
	        department_id: department_id,
	        data_type: data_type,
	        date_delete: date_delete,
	        category_id: category_id
	      },
	      success: function(data, textStatus, jqXHR){
	        
	      },
	      error:function(jqXHR, textStatus, errorThrown){
	        console.log("AJAX Error")
	      }
	    });
  	}
  });

  $('#icon_date_delete_schedule').click(function() {
			if (document.getElementById("date_delete_schedule").disabled == false) {
				$("#date_delete_schedule").datepicker().focus();
			}
		});

  $("#date_delete_schedule").datepicker();

  $("#text_delete_schedule_to").click(function() {
		document.getElementById("ckb_date_delete_schedule").click();
	});

	$("#ckb_date_delete_schedule").click(function() {
		var checked_value = this.checked;
		if (checked_value) {
			document.getElementById("date_delete_schedule").disabled = false;
			$("#icon_date_delete_schedule > .input-group-text").removeClass("disabled");
		}
		else {
			document.getElementById("date_delete_schedule").disabled = true;
			$("#icon_date_delete_schedule > .input-group-text").addClass("disabled");
		}
	});
});