import datepicker from 'bootstrap-datepicker';

$(document).on('turbo:load', function () {
    var staffCalendar = document.getElementById("staff-calendar");
	if(staffCalendar) {
		datepickerLogTime(staffCalendar);
	}

	var inputCalendarLog = document.getElementById("mobile_date_log_time");
	if(inputCalendarLog) {
		datepickerLogTimeMobile();
	}

	$('body').delegate('#btn_save_log', 'click', function (e) {
		if ($(".nested-fields").length > 0) {
			document.getElementById("btnSaveLog").click();
			showFormLoading();
		}
	});

	$('body').delegate('.show_work_log_time', 'click', function (e) {
    $.ajax ({
      type: 'GET',
      url: "/show_log_work",
      dataType: 'script',
      data: {
				day_of_year: $(this).data('date'),
				master_plan_id: $(this).data('id')
      },
      success: function(data, textStatus, jqXHR){
				$(".select_master_plan").each(function(){
          $(this).select2({
            dropdownParent: $("#modal_show_log_work"),
          })
        });
				$(".select_issue").each(function(){
          $(this).select2({
            dropdownParent: $("#modal_show_log_work"),
          })
        });
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
  });

	$("body").delegate('#add_issue_in_log_work', 'click', function(event) {
		let timeValue = parseFloat($("#time_issue_in_day_input").val());
		timeValue = isNaN(timeValue) ? 0 : timeValue;
		let issue_id = $('.select_issue').val();
		$.ajax({
			type: "GET",
			url: '/add_log_work',
			dataType: "script",
			data: {
				employee_log_time: {
					day_of_year:  $(this).data('date'),
					user_id: $("#log_time_issue_user_id").val(),
					log_works_attributes: {"0": {
						master_plan_id: $(this).data('master_plan_id'),
						time: timeValue,
						issue_id: issue_id,
					}}
				}
			},
			success: function (data, textStatus, jqXHR) {
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log(jqXHR.responseText)
			}
		})
  });

	$('body').delegate('#time_issue_in_day_input', 'keydown', function (event) {
    if (event.keyCode == 13) {
      document.getElementById("add_issue_in_log_work").click();
    }
	});

	$('body').delegate('.delete_issue_in_log_work', 'click', function (event) {
    $(this).parent().parent().addClass("d-none destroy_log_work");
    $(this).parent().parent().find("input").removeClass("time_Log_work_issue");

    var total = 0;
    $('.time_Log_work_issue').each(function() {
      total += parseFloat($(this).val() || $(this).text() ) || 0;
    });
    $('.actual_time_issue').text(total)
    if (total == 0){
      $("#issue_log_work_present").addClass("d-none")
    }
	});

	$("body").delegate('#save_issue_in_log_work', 'click', function() {
    let total_log_work = $('.log-work-issue-tbl').find("[data-log-work-id]")
    let log_work = $('.log-work-issue-tbl').find("[data-log-work-id]")
    let id = $('.log-work-issue-tbl').find("[data-issue-id]")
    let employee_log_times = addLogWorksToEmployeeLogTimes(total_log_work, log_work, id);
    $.ajax({
      type: "GET",
      url:'/save_log_work',
      dataType: "script",
      data: {
        employee_log_time: employee_log_times,
        actual_time: $(".actual_time_issue").text(),
				master_plan_id: $("#add_issue_in_log_work").attr("data-master_plan_id"),
				create: $(".select_master_plan").val()
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      }
    })
  });

	$("body").delegate('.btn_delete_with_img', 'click', function() {
		$.ajax ({
			type: 'GET',
			url: "/log_works/confirm_delete_log_work",
			dataType: 'script',
			data: {
				master_plan_id: $(this).data('id'),
				day_of_year: $(".show_work_log_time").data('date')
			},
			success: function(data, textStatus, jqXHR){
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error");
			}
		});
  });

	$("body").delegate('.select_master_plan', 'change', function() {
		$("#issue_log_work_present").addClass("d-none");
		let master_plan_id = $(this).val();
		$.ajax ({
			type: 'GET',
			url: "/log_works/show_lowest_level_issue",
			dataType: 'script',
			data: {
				master_plan_id: master_plan_id,
			},
			success: function(data, textStatus, jqXHR){
				$(".select_issue").each(function(){
          $(this).select2({
            dropdownParent: $("#modal_show_log_work"),
          })
        });
			},
			error:function(jqXHR, textStatus, errorThrown){
				console.log("AJAX Error");
			}
		});
  });

	$("body").delegate('#show_lowest_level_issue', 'change', function() {
		$('.log-work-issue-tbl').find(".validate-hour-issue").addClass("d-none");
  });
});

function addLogWorksToEmployeeLogTimes(total_log_work, log_work, id) {
  let employee_log_times = [];
  let day_of_year = $("#add_issue_in_log_work").data("date");
  let user_id = $("#log_time_issue_user_id").val();
  let master_plan_id = $("#add_issue_in_log_work").attr("data-master_plan_id");

  for (let i = 0; i < total_log_work.length; i++) {
    let selector = '[data-issue-id="' + $(id[i]).data('issue-id') + '"]';
    let time = $(total_log_work[i]).parent().find("input").val();
    let log_work_id = $(log_work[i]).data('log-work-id');
    let issue_id = $(id[i]).data('issue-id');
    let destroy = false;

    if ($('.log-work-issue-tbl').find(selector).parent().hasClass('destroy_log_work')) {
      destroy = true;
    }

    let log_works_attributes = {
      id: log_work_id,
      master_plan_id: master_plan_id,
      time: parseFloat(time),
      issue_id: issue_id,
      _destroy: destroy
    };
    employee_log_times.push(log_works_attributes);
  }

  let employee_log_time = {
    day_of_year: day_of_year,
    time_start: $('.time_start_select').first().val() + ":" + $('.time_start_select').last().val(),
    time_end: $('.time_end_select').first().val() + ":" + $('.time_end_select').last().val(),
    user_id: parseInt(user_id),
    log_works_attributes: employee_log_times
  };
  return employee_log_time;
}

function getLogWork(day_of_year) {
	$.ajax ({
		type: 'GET',
		url: "/log_works/get_log_work",
		dataType: 'script',
		data: {
			day_of_year: day_of_year
		},
		success: function(data, textStatus, jqXHR){
		},
		error:function(jqXHR, textStatus, errorThrown){
			console.log("AJAX Error");
		}
	});
}

function datepickerLogTime(staffCalendar) {
	var today = new Date();
	$('#staff-calendar').datepicker({
			language: get_locate(),
			endDate: "0d",
			todayHighlight: true,
			format: "yyyy-mm-dd"
		})
		.on('changeDate', function(e) {
			getLogWork(e.date);
			var day =  String(e.date.getDate()).padStart(2, '0');
			var month = String(e.date.getMonth() + 1).padStart(2, '0');
			var change_day = e.date.getFullYear() + '-' + month + '-' + day;
			$("#mobile_date_log_time").val(change_day);
		});
		$('#staff-calendar').datepicker('setDate', today.toLocaleDateString('fr-CA'));
		var headTable = staffCalendar.querySelector('thead');
		var tr = document.createElement("tr");
		tr.className = 'div-hr-datepicker';
		headTable.insertBefore(tr, headTable.children[2]);

		tr = document.createElement("tr");
		tr.className = 'div-hr-height';
		headTable.insertBefore(tr, headTable.children[3]);

		var trEnd = document.createElement("tr");
		trEnd.className = 'div-hr-datepicker-end'
		var table = staffCalendar.querySelector('.table-condensed');
		var tfoot = staffCalendar.querySelector('tfoot');
		table.insertBefore(trEnd, tfoot);
}

function datepickerLogTimeMobile() {
	var today = new Date();
	$("#mobile_date_log_time").datepicker({
		endDate: "0d",
		autoclose: true,
		language: get_locate(),
		format: "yyyy-mm-dd",
		todayHighlight: true
	})
	.on('changeDate', function(e) {
		getLogWork(e.date);
		$("#staff-calendar").datepicker("setDate", e.date);
	});
	$("#mobile_date_log_time").datepicker('setDate', today.toLocaleDateString('fr-CA'));
}
