$(document).on('turbo:load', function() {
  $("#js-export-bulletins-submit").on('click', function(e) {
    e.preventDefault();
    let errors = 0;
    let format = 'YYYY-MM-DD';
    let start_time = $("#bulletin_start_time").val();
    let end_time = $("#bulletin_end_time").val();
    let time_errors = 0

    if ($("#listBulletinCategory").val() == "") {
      errors += 1
      $(".bulletin_category_id_errors").html("必須項目「力テゴリ」が選択されていません。")
    } else {
      $(".bulletin_category_id_errors").html("")
    }

    if (!moment(start_time, format, true).isValid()) {
      errors += 1
      time_errors +=1
      $(".start_time_errors").html("start_timeを入力してください")
    } else {
      $(".start_time_errors").html("")
    }

    if (!moment(end_time, format, true).isValid()) {
      errors += 1
      time_errors +=1
      $(".end_time_errors").html("end_timeを入力してください")
    } else {
      $(".end_time_errors").html("")
    }

    if (time_errors == 0) {
      if (new Date(start_time) > new Date(end_time)) {
        errors += 1
        $(".time_errors").html("スタートタイムはエンドタイム以下の値にしてください")
      } else {
        $(".time_errors").html("")
      }
    }

    if (errors == 0) {
      $("#js-export-bulletins-form").submit();
    }
  })
})

