import { moveOption } from './schedule.js';
$(document).on('turbo:load', function() {
	window.$('#modal_position').on('shown.bs.modal', function () {
		$("#new_position").validate({
			//error place
			errorPlacement: function (error, element) {
			  error.insertAfter(element);
			},
		  //adding rule
			rules: {
			// username is required with max of 20 and min of 6
			"position[name]":{
			  required: true		  
			},		
			},
			// error messages
			messages: {
				"position[name]":{
				required: I18n.t("validation.position.name")				
			  }
			}
		  });
	})
	// move top/up/down/bottom position
		$("body").delegate('#btnMoveTopPositions', 'click', function () {
			var count_position = 0;
					$.each($('#tblPositionList select :selected'), function (i, row) {
							count_position = count_position + 1
					});
					if (count_position > 0){
				var first_elem = $("#tblPositionList option:first");
				if(first_elem.is(':selected')) return true;
				while(!first_elem.prev().length){
					$('#tblPositionList select :selected').each(function (i, selected) {
						if (!$(this).prev().length) return false;
						$(this).insertBefore($(this).prev());
					});
					$('#tblPositionList select').focus();
				} 
			}
		});

		$("body").delegate('#btnMoveUpPositions', 'click', function () {
			$('#tblPositionList select :selected').each(function (i, selected) {
				if (!$(this).prev().length) return false;
				$(this).insertBefore($(this).prev());
			});
			$('#tblPositionList select').focus();
		});

		$("body").delegate('#btnMoveDownPositions', 'click', function () {
			$($('#tblPositionList select :selected').get().reverse()).each(function (i, selected) {
				if (!$(this).next().length) return false;
				$(this).insertAfter($(this).next());
			});
			$('#tblPositionList select').focus();
		});

		$("body").delegate('#btnMoveBottomPositions', 'click', function () { 
			var count_position = 0;
					$.each($('#tblPositionList select :selected'), function (i, row) {
							count_position = count_position + 1
					});
					if (count_position > 0){
				var last_elem = $("#tblPositionList option:last");
				if(last_elem.is(':selected')) return true;
				while(!last_elem.next().length){
					$($('#tblPositionList select :selected').get().reverse()).each(function (i, selected) {
						if (!$(this).next().length) return false;
						$(this).insertAfter($(this).next());
					});
					$('#tblPositionList select').focus();
				}
			}
		});

	// update index positions
		$("body").delegate('#btnUpdateIndexPositions', 'click', function () {
			var lst = [];
			$("#lstPositionChangeIndex option").each(function () {
				lst.push($(this).val());
			});
			$.ajax({
				type: 'GET',
				url: "/update_index_positions",
				dataType: 'script',
				data: {
					lst_positions: lst,
				},
				success: function (data, textStatus, jqXHR) {
						
				},
				error: function (jqXHR, textStatus, errorThrown) {
						console.log("AJAX Error")
				}
			});
		});

	// filter user by department in modal position
		$("body").delegate('#listDepartmentUserPosition', 'change', function() {
	    var options_user = document.getElementById("user_position_selected");
	    var department_id = $("#listDepartmentUserPosition option:selected").val();
	    var data_type = "1";
	    data_type = $("#listDepartmentUserPosition option:selected").attr('data-type');
	    var lst_user_selected = [];
	    for (var i=0; i<options_user.length; i++) {
	      lst_user_selected.push(options_user[i].value);
	    }
	    $.ajax({
	      type: 'GET',
	      url: "/filter_list_user_position",
	      dataType: 'script',
	      data: {
	        department_id: department_id,
	        list_user: lst_user_selected,
	        data_type: data_type
	      },
	      success: function(data, textStatus, jqXHR){
	        
	      },
	      error:function(jqXHR, textStatus, errorThrown){
	        console.log("AJAX Error")
	      }
	    });
	  });

	// add/remove from list user to list user selected modal user position
		$("body").delegate('#add_partner_user_position', 'click', function() {
			var lst_user_select = document.getElementById('list_user_position');
			var lst_user_selected = document.getElementById('user_position_selected');
			moveOption(lst_user_select, lst_user_selected);
		});

		$("body").delegate('#remove_partner_user_position', 'click', function() {
			var lst_user_select = document.getElementById('list_user_position');
			var lst_user_selected = document.getElementById('user_position_selected');
			moveOption(lst_user_selected, lst_user_select);
		});

	// event click button create/update record user position
		$("body").delegate('#btnSavePosition', 'click', function () {
			var options_user = document.getElementById("user_position_selected");
			for (var i=0; i<options_user.length; i++) {
				options_user[i].selected = "true";
			}
		});

	// move top/up/down/bottom user position
		$("body").delegate('#btnMoveTopUserPosition', 'click', function () {
			var count_user_position = 0;
			$.each($('#user_position_selected option:selected'), function (i, row) {
				count_user_position = count_user_position + 1
			});
			if (count_user_position > 0){
				var first_elem = $("#user_position_selected option:first");
				if(first_elem.is(':selected')) return true;
				while(!first_elem.prev().length){
					$('#user_position_selected option:selected').each(function (i, selected) {
						if (!$(this).prev().length) return false;
						$(this).insertBefore($(this).prev());
					});
					$('#user_position_selected').focus();
				}	
			}
		});

		$("body").delegate('#btnMoveUpUserPosition', 'click', function () {
			$('#user_position_selected option:selected').each(function (i, selected) {
				if (!$(this).prev().length) return false;
				$(this).insertBefore($(this).prev());
			});
			$('#user_position_selected').focus();
		});
		
		$("body").delegate('#btnMoveDownUserPosition', 'click', function () {
			$($('#user_position_selected option:selected').get().reverse()).each(function (i, selected) {
				if (!$(this).next().length) return false;
				$(this).insertAfter($(this).next());
			});
			$('#user_position_selected').focus();
		});
		
		$("body").delegate('#btnMoveBottomUserPosition', 'click', function () {	
			var count_user_position = 0;
			$.each($('#user_position_selected option:selected'), function (i, row) {
				count_user_position = count_user_position + 1
			});
			if (count_user_position > 0){
				var last_elem = $("#user_position_selected option:last");
				if(last_elem.is(':selected')) return true;
				while(!last_elem.next().length){
					$($('#user_position_selected option:selected').get().reverse()).each(function (i, selected) {
						if (!$(this).next().length) return false;
						$(this).insertAfter($(this).next());
					});
					$('#user_position_selected').focus();
				}
			}
		});

  // event keyup Enter submit form
  $("body").delegate('#modal_position', 'keyup', function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        $("#btnSavePosition").click();
      }
  });

  $("body").delegate('#modal_change_index_positions', 'keyup', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btnUpdateIndexPositions").click();
    }
  });
      
});