
$(document).on('ready turbo:load', function() {
  $("#screen_properties_table").delegate(".column_item", "click", function () {
    if (isNaN(parseInt($(this).parent().attr("data-item-id")))) {
      $.ajax({
        type: "POST",
        url: "/project_manager/screen_properties",
        dataType: "json",
        data: {
          screen_id: $("#screen_id").val(),
          screen_property: {
            screen_id: $("#screen_id").val(),
            property_id: $(this).val(),
            active: $(this).is(":checked")
          }
        },
        success: function(data, textStatus, jqXHR){
          let $input = $("#screen_properties_table").find(`input.column_item[value = '${JSON.parse(data.screen_property).property_id}']`)
          if(data.errors == null){
            $input.parent().attr("data-item-id", JSON.parse(data.screen_property).id)
            $input.removeClass("errors_checkbox")
            $input.next().text("")
          } else {
            $input.prop("checked", !JSON.parse(data.screen_property).active)
            $input.addClass("errors_checkbox")
            $input.next().text(data.errors)
          }
        },
        error:function(jqXHR, textStatus, errorThrown){
          console.log("AJAX Error")
        }
      })
    } else {
      $.ajax({
        type: "PATCH",
        url: "/project_manager/screen_properties/"+ $(this).parent().attr("data-item-id"),
        dataType: "json",
        data: {
          screen_id: $("#screen_id").val(),
          screen_property: {
            active: $(this).is(":checked")
          }
        },
        success: function(data, textStatus, jqXHR){
          let $input = $("#screen_properties_table").find(`input.column_item[value = '${JSON.parse(data.screen_property).property_id}']`)
          if(data.errors == null){
            $input.removeClass("errors_checkbox")
            $input.next().text("")
          } else {
            $input.prop("checked", !JSON.parse(data.screen_property).active)
            $input.addClass("errors_checkbox")
            $input.next().text(data.errors)
          }
        },
        error:function(jqXHR, textStatus, errorThrown){
          console.log("AJAX Error")
        }
      })
    }
  });
})
