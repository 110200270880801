import {PDFArray,PDFString,PDFName,PDFDict,PDFRef,rgb,PageSizes,degrees, Rotation, PDFFont} from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { ANNOTATION, link_enum } from "../edit/annotation";
import { Config } from "../define/AppConfig";
import { Convert } from '../../../packs/convert';
import { url_helper } from '../../../packs/supports/url_helper';
export class SavePageItem{
    constructor(page){this.page = page}    
    save_line(item){save_line(item,this.page)}
    async save_text(item){await save_text(item,this.page)};
    async save_image(item){await save_image(item,this.page)};
    save_path(item){save_path(item,this.page)};
    save_link(item){save_link(item,this.page)};
    save_rectangle(item){save_rectangle(item,this.page)};
    save_ellipse(item){save_ellipse(item,this.page)};
    save_highlight(item){save_highlight(item,this.page)}
}
function save_line(item,page){     
    // console.log('save_line');
    var scale = item.scale;
    var color = item.stroke;
    color = Convert.toRGB(color);
    var x1 = item.head1.x/scale,y1 = item.head1.y/scale;    
    var x2 = item.head2.x/scale,y2 = item.head2.y/scale;    
    var p1 = calculatePosition(page,x1,y1,item.rotate);
    var p2 = calculatePosition(page,x2,y2,item.rotate); 
    var properties = {
      thickness: parseInt(item.strokeWidth/scale),
      color: rgb(parseFloat(color.r)/255.0, parseFloat(color.g)/255.0, parseFloat(color.b)/255.0),
      opacity: item.opacity,
      dashArray: item.strokeDashArray,
      scale: item.scale,
      rotate: item.rotate
    }   
    page.drawLine({
        start: { x: p1.x, y: p1.y },
        end: { x: p2.x, y: p2.y   },
        thickness: properties.thickness,
        color: properties.color,
        opacity: properties.opacity,
        dashArray: properties.dashArray,
        rotate: page.getRotation()
    }) 
    
    if(item.name == "arrow"){
      saveArrow(item,properties,page);
    }
  } 

  function saveArrow(arrow,properties,page){
    var points = getLinesArrowBeside(arrow.head1, arrow.head2,arrow.strokeWidth*3);    
    var line1,line2,line3,line4 = null;
    var scale = arrow.scale;
    if(arrow.number_head == 1){   
      line1 = {p1: {x: points.start[0]/scale, y: points.start[1]/scale},p2:{x: points.start[2]/scale, y: points.start[3]/scale}}
      saveLineArrow(line1,properties,page);
      line2 = {p1: {x: points.end[0]/scale, y: points.end[1]/scale},p2:{x: points.end[2]/scale, y: points.end[3]/scale}}
      saveLineArrow(line2,properties,page);
    }
    if(arrow.number_head == 2){
      line1 = {p1: {x: points.start[0]/scale, y: points.start[1]/scale},p2:{x: points.start[2]/scale, y: points.start[3]/scale}}
      saveLineArrow(line1,properties,page);
      line2 = {p1: {x: points.end[0]/scale, y: points.end[1]/scale},p2:{x: points.end[2]/scale, y: points.end[3]/scale}}
      saveLineArrow(line2,properties,page);

      line3 = {p1: {x: points.startLeft[0]/scale, y: points.startLeft[1]/scale},p2:{x: points.startLeft[2]/scale, y: points.startLeft[3]/scale}}
      saveLineArrow(line3,properties,page);
      line4 = {p1: {x: points.endLeft[0]/scale, y: points.endLeft[1]/scale},p2:{x: points.endLeft[2]/scale, y: points.endLeft[3]/scale}}
      saveLineArrow(line4,properties,page);
    }   
  }
  function getLinesArrowBeside(start, end,headlen){
    var angle = Math.atan2(end.y - start.y, end.x - start.x);  
    var fromx = start.x,fromy = start.y,tox = end.x,toy = end.y;
    var fromxTemp = start.x + (headlen) * Math.cos(angle),
    fromyTemp = start.y + (headlen) * Math.sin(angle);
    var toxTemp = end.x - (headlen) * Math.cos(angle),
      toyTemp = end.y - (headlen) * Math.sin(angle);
  
    return {start:[toxTemp - (headlen) * Math.cos(angle - Math.PI / 2),toyTemp - (headlen) * Math.sin(angle - Math.PI / 2),tox,toy],
           end :  [ toxTemp - (headlen) * Math.cos(angle + Math.PI / 2), toyTemp - (headlen) * Math.sin(angle + Math.PI / 2),
           tox , toy ],
           startLeft:[fromxTemp + (headlen) * Math.cos(angle - Math.PI / 2),fromyTemp + (headlen) * Math.sin(angle - Math.PI / 2),fromx,fromy],
           endLeft:[fromxTemp - (headlen) * Math.cos(angle - Math.PI / 2),fromyTemp - (headlen) * Math.sin(angle - Math.PI / 2),fromx,fromy]
          }
  }
  function saveLineArrow(item, properties,page){      
    var p1 = calculatePosition(page,item.p1.x,item.p1.y,properties.rotate);
    var p2 = calculatePosition(page,item.p2.x,item.p2.y,properties.rotate); 
    page.drawLine({
        start: { x: p1.x, y: p1.y },
        end: { x: p2.x, y: p2.y   },
        thickness: properties.thickness,
        color: properties.color,
        opacity: properties.opacity,
        dashArray: properties.dashArray,
        // rotate: page.getRotation(),
    }) 
  }
  async function save_text(item,page){
    // console.log('save_text');
    var customFont = null;
    var url = '';
    switch(item.fontFamily){
      case 'MS 明朝':     
        url = window.location.origin+'/fonts/MSMINCHO.TTF'         
        break;
      case 'ＭＳ ゴシック':
        url = window.location.origin+'/fonts/MSGOTHIC.TTF'    
        break;
      case 'Times New Roman':
        url = window.location.origin+'/fonts/times-new-roman.ttf'         
        break;
      case 'Arial':
        url = window.location.origin+'/fonts/Arialn.ttf' 
        break;  
      case 'OpenSans':
          url = window.location.origin+'/fonts/OpenSans-Italic-VariableFont.ttf' 
          break;          
    }
    customFont = await embedFont( page, url);
    await sub_saveText(item,page,customFont);  
  }
  async function sub_saveText(item,page,customFont){   
    var scale = item.scale;   
    var x = item.position_x/scale;
    var y = item.position_y /scale;
    var p = calculatePosition(page,x,y,item.rotate);
    var  padding = (item.height - item.fontSize)/scale;
    var size = item.fontSize*item.scaleY/scale;
    // var angle = page.getRotation().angle + item.rotate;
    var angle =  item.rotate;
    var rotate = angle - item.angle;
    var color = Convert.toRGB(item.fill); 
    color = rgb(parseFloat(color.r)/255.0, parseFloat(color.g)/255.0, parseFloat(color.b)/255.0);
    switch(angle){
      case 0:
      case 360:
        p.x = p.x  
        p.y = p.y  -  item.fontSize/scale + padding ;
        break;
      case 90:
      case -270:
        p.x = p.x + item.fontSize/scale - padding;   
        p.y = p.y ;
        break;
      case 180:
      case -180:
        p.x = p.x ;     
        p.y = p.y +  item.fontSize/scale - padding;
        break;
      case 270:
      case -90:
        p.x = p.x -  item.fontSize/scale + padding;     
        p.y = p.y;
        break;
    }
    console.log(customFont.encodeText(item.text));
    var text = customFont.encodeText(item.text)
    if (customFont){
      page.drawText(item.text, {
        x: p.x,
        y: p.y ,
        lineHeight: item.lineHeight,
        size: size,
        font: customFont,
        color: color,
        rotate:  degrees(rotate)
      })
    }
    else{
      page.drawText(item.text, {
        x: p.x,
        y: p.y ,
        size: size,
        lineHeight: item.lineHeight,
        color: color,
        rotate:  degrees(rotate)
      })
    }
    
  }
  async function embedFont(page, url){
      var customFont = null;    
      page.doc.registerFontkit(fontkit)  
      
      var fontBytes =  await fetch(url).then((res) => res.arrayBuffer())
      //fetch_data(url);
      customFont = await page.doc.embedFont(fontBytes, { subset: true })
      page.setFont(customFont);    
      return customFont;
  }
  async function save_image(item,page){
    // console.log('save_image');
    await saveImages(item,page)
  }
  function save_path(item,page){
    // console.log('save_path');
    savePath(item,page);
  }
  function save_link(item,page){
    // console.log('save_link');
    saveLink(item,page);
  }
  function save_rectangle(item,page){
    saveRectangle(item,page);
    // console.log('save_rectangle');
  }
  function save_ellipse(item,page){
    saveEllipse(item,page);
    // console.log('save_ellipse');
  }
  function saveRectangle(item,page){
    var scale = item.scale;
    var borderColor= Convert.toRGB(item.stroke); 
     // start calculate rotate

     var x = (item.aCoords.bl.x + item.strokeWidth/2)/scale,y = (item.aCoords.bl.y- item.strokeWidth/2)/ scale;     
     var width = item.width*item.scaleX/ scale,height= item.height*item.scaleY/ scale;
     var p = calculatePosition(page,x,y,item.rotate);
     // end  
    //  var angle = page.getRotation().angle + item.rotate;
    var angle =  item.rotate;
     var rotate = angle - item.angle;
     var backGroundColor= Convert.toRGB(item.fill); 
      var dash = [];
      if(item.strokeDashArray){
        dash = item.strokeDashArray
      }
      if(dash) {
        dash = dash.map((p) => p/scale)
      }
    if(item.fill.trim() != ""){
      
      page.drawRectangle({   
        x: p.x,
        y: p.y,
        width: width,
        height: height,
        borderWidth: parseFloat(item.strokeWidth)/scale,
        borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
        color: rgb(parseFloat(backGroundColor.r)/255.0, parseFloat(backGroundColor.g)/255.0, parseFloat(backGroundColor.b)/255.0),
        opacity: parseFloat(item.opacity),
        borderOpacity: parseFloat(item.opacity),
        borderDashArray: dash,
        rotate:  degrees(rotate)
      })
    }
    else{
      page.drawRectangle({   
        x: p.x,
        y: p.y,
        width: width,
        height: height,
        borderWidth: parseFloat(item.strokeWidth)/scale,
        borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
        opacity: parseFloat(item.opacity),
        borderOpacity: parseFloat(item.opacity),
        borderDashArray: dash,
        rotate:  degrees(rotate)
      })
    }
  
   
  }

  //https://github.com/Hopding/pdf-lib/discussions/1233
function saveLink(item,page){
  var scale = item.scale;

  var p1 = calculatePosition(page,item.aCoords.bl.x/scale,item.aCoords.bl.y/scale,item.rotate);
  var p2 = calculatePosition(page,item.aCoords.tr.x/scale,item.aCoords.tr.y/scale,item.rotate); 


  if(item.link_type.link_type == link_enum.page){
    var pageRef = page.doc.getPage(parseInt(item.link_type.page)-1);
    const link = page.doc.context.register(
      page.doc.context.obj({
        Type: 'Annot',
        Subtype: 'Link',
        Rect: [p1.x,// lower left x coord
        p1.y ,// lower left y coord
         p2.x,// upper right x coord
         p2.y],// upper right y coord
        Border: [0, 0, 0], // [0, 0, 2] to test
        C: [0, 0, 1],
        Dest: [pageRef.ref, 'XYZ', null, null, null]
      }));
      let annots;
    try{
      annots = page.node.lookup(PDFName.of('Annots'), PDFArray);    
    } catch (error){      
      console.log('no links found:',error)
      annots = page.doc.context.obj([]);
      page.node.set(PDFName.of("Annots"), annots);
    }
    annots.push(link)  
    page.node.set(PDFName.of('Annots'), annots);
  }
  else{    
    var uri = item.link_type.url;    
      if(item.link_type.link_type==link_enum.phone){uri = 'tel:' + item.link_type.phone;}
      if(item.link_type.link_type==link_enum.mail){uri = 'mailto:' + item.link_type.email;}
      if(item.link_type.link_type==link_enum.url){uri =  item.link_type.url;}
    const link = page.doc.context.register(
      page.doc.context.obj({
        Type: 'Annot',
        Subtype: 'Link',
        Rect: [p1.x,// lower left x coord
        p1.y ,// lower left y coord
         p2.x,// upper right x coord
         p2.y],// upper right y coord
        Border: [0, 0, 0], // [0, 0, 2] to test
        C: [0, 0, 1],
        A: {
          Type: 'Action',
          S: 'URI',
          URI: PDFString.of(uri),//URI: PDFString.of("mailto:anhvt@lotusys.vn"),PDFString.of("tel:0981560517")
        },
      }));
      
      let annots;
    try{
      annots = page.node.lookup(PDFName.of('Annots'), PDFArray);    
    } catch (error){      
      console.log('no links found:',error)
      annots = pdfDoc.context.obj([]);
      page.node.set(PDFName.of("Annots"), annots);
    }
    annots.push(link)  
    page.node.set(PDFName.of('Annots'), annots);
  }
}
  function saveEllipse(item,page){
    var scale = item.scale;
    var borderColor= Convert.toRGB(item.stroke);
    // var angle = page.getRotation().angle + item.rotate;
    var angle =  item.rotate;
    var rotate = angle - item.angle;
    var x = (item.aCoords.tl.x+ item.aCoords.br.x)/(2*scale);  
    var y = (item.aCoords.tl.y+ item.aCoords.br.y)/(2*scale);  
    var dash = [];
    if(item.strokeDashArray){
      dash = item.strokeDashArray
    }
    if(dash) {
      dash = dash.map((p) => p/scale)
    }
    var p = calculatePosition(page,x,y,item.rotate);
    if(item.fill.trim() != ""){
      var backGroundColor= Convert.toRGB(item.fill);
      page.drawEllipse({
        x: p.x,
        y: p.y,
        xScale: item.rx*item.scaleX/scale,
        yScale: item.ry*item.scaleY/scale,
        borderWidth: item.strokeWidth/scale,
        borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
        color: rgb(parseFloat(backGroundColor.r)/255.0, parseFloat(backGroundColor.g)/255.0, parseFloat(backGroundColor.b)/255.0),
        opacity: parseFloat(item.opacity),
        borderOpacity: parseFloat(item.opacity),
        rotate:  degrees(rotate),
        borderDashArray: dash
      })
    }
    else{
      page.drawEllipse({
        x: p.x,
        y: p.y,
        xScale: item.rx*item.scaleX/scale,
        yScale: item.ry*item.scaleY/scale,
        borderWidth: item.strokeWidth/scale,
        borderColor: rgb(parseFloat(borderColor.r)/255.0, parseFloat(borderColor.g)/255.0, parseFloat(borderColor.b)/255.0),
        opacity: parseFloat(item.opacity),
        borderOpacity: parseFloat(item.opacity),
        dashArray: dash,
        borderDashArray: dash,
        rotate:  degrees(rotate)
      })
    }
    
  }
  async function saveImages(img,page) {
    try {      
          // var angle = page.getRotation().angle + img.rotate;
          var angle =  img.rotate;
          var rotate = angle - img.angle;
          var scale = img.scale;             
          var x = img.aCoords.bl.x/scale,y = img.aCoords.bl.y/scale;    
          var p = calculatePosition(page,x,y,img.rotate);    
          const pngImageBytes = await fetch_data(img.src); 
          const pngImage = await page.doc.embedPng(pngImageBytes);   
          var x = img.aCoords.bl.x/scale,y = img.aCoords.bl.y/scale;    
          var p = calculatePosition(page,x,y,img.rotate);


          page.drawImage(pngImage, {
            x: p.x ,
            y: p.y ,
            width: img.width*img.scaleX/scale,
            height: img.height*img.scaleY/scale,
            opacity: img.opacity,
            rotate:  degrees(rotate)
          })           
  
    } catch (error) {
      console.error(error);
      // alert(error); 
    }
  }
  function savePath(item,page) {
    var scale = item.scale; 
    var color,opacity = parseFloat(item.opacity),dash;
    // var angle = page.getRotation().angle + item.rotate;
    var angle =  item.rotate;
    var rotate = angle - item.angle;
    try {
      item.path.forEach(point => {
        for(let i = 1;i< point.length;i++){
          point[i] = point[i]/scale ;
        }
      })
  
      var pathItem = item.path;
      const svgPath = pathItem.toString();   

      // start calculate rotate
      var x = 0,y = 0;    
      // var x = item.aCoords.bl.x/scale,y = item.aCoords.bl.y/scale;    
      var p = calculatePosition(page,x,y,item.rotate);
      // end
      color = Convert.toRGB(item.stroke);
      opacity = parseFloat( item.opacity);
      if(item.strokeDashArray){
        dash = item.strokeDashArray
      }
      if(dash) {
        dash = dash.map((p) => p/scale)
      }  
  
      if(item.annotation == ANNOTATION.PENCIL){
        opacity = parseFloat( Config.pencil.opacity);       
      }
      page.drawSvgPath(svgPath, {
        x: p.x,    
        y: p.y,
        borderColor: rgb(parseFloat(color.r)/255.0, parseFloat(color.g)/255.0, parseFloat(color.b)/255.0),
        borderWidth: item.strokeWidth/scale,
        borderOpacity: opacity,
        borderDashArray: dash,
        opacity : opacity,
        rotate:  degrees(rotate)
      })
      
      return true;
    } catch (error) {
      console.error(error);
      alert(error); 
      return false;
    }
  }
  function calculatePosition(page,x,y,rotate){
    var page_height = page.getBleedBox().height;
    var page_width = page.getBleedBox().width;
    var out_x = x,out_y = y;
    switch(rotate){
      case 0:
      case 360:
        out_x =  x;
        out_y =  page_height - y;
        break;
      case 90:
      case -270:
        out_x =  y;
        out_y =  x;
        break;
      case 180:
      case -180:
        out_x =  page_width - x;
        out_y =   y;
        break;
      case 270:
      case -90:
        out_x = page_width -  y;
        out_y = page_height - x;
        break;
    }
    // Fix bug save A3 sai vi tri con dau

    // if exist ArtBox() of page
    var artBoxX = page.getArtBox().x;
    var artBoxY = page.getArtBox().y;
    out_x += artBoxX;
    out_y += artBoxY;
    return {x: out_x,y: out_y};
  }


  function save_highlight(item,page){
    // console.log('save_highlight');
    var scale = item.scale;
    var backGroundColor= Convert.toRGB(item.color); 
      var x = item.left/scale,y = item.top/ scale;     
     var p = calculatePosition(page,x,y,0);
    page.drawRectangle({   
      x: p.x ,
      y: p.y - item.height/scale,
      width: item.width/scale ,
      height: item.height/scale ,
      color: rgb(parseFloat(backGroundColor.r)/255.0, parseFloat(backGroundColor.g)/255.0, parseFloat(backGroundColor.b)/255.0),
      opacity: parseFloat(item.opacity),
    }) 
    
  }

