export class FolderEntity{
    name    
    folders = []
    constructor(files){
        var name = files[0].webkitRelativePath.split('/')[0];
        this.name = name;
        this.folders  = this.set_data(files);
    }
    set_data(files){  
        var folders = [], webkitRelativePath,components,folder,index,path,folder_path,custom_file;

        for (let file of Array.from(files)){
            webkitRelativePath = file.webkitRelativePath.replace(/[\n\r]/g,' ');
            components = webkitRelativePath.split('/');
            folder_path = [];

            for (index = 0; index < components.length - 1; index++) {
                folder_path.push(components[index]);                
            }
            
            path = folder_path.join('/');
            folder = check_folder_exist(path,folders);
            custom_file = {name: file.name,upload_path: webkitRelativePath}
            if(!folder){
                folder = {
                    level: folder_path.length - 1,         
                    path: path,
                    name: folder_path[folder_path.length-1],
                    files: [custom_file],
                    code: random_string(16),
                    parent_code: null
                }
                folders.push(folder)
            }
            else
            {                
                folder.files.push(custom_file)
            }
          
        };
        arrangeFolders(folders);
        matching_folders(folders);
        return folders;
    }    
}
function check_folder_exist(path,folders){
    return folders.find((element) => element.path == path);
}

function arrangeFolders(folders) {
    folders.sort((a, b) => a.level - b.level);
}
function matching_folders(folders) {
    var parent = null;
    for (let index = 0; index < folders.length; index++) {
        const element = folders[index];
        if(element.level != 0){
            parent = find_parent(element,folders);
            element.parent_code = parent.code;
        }
        
    }
}

function find_parent(folder,folders) {
    return folders.find((p) => p.level == (folder.level - 1) && folder.path.includes(p.path));
}