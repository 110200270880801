$(document).on('turbo:load', function(){
	var tblListModuleGroup = document.getElementsByClassName("tbl_module_group");
	if (tblListModuleGroup.length > 0) {
		window.$('.tbl_module_group').DataTable({
			'destroy': true,
			'stateSave': true,
			'columnDefs': [
				{
					'targets': 0,
					'width': "6%",
					// 'checkboxes': {
					// 	'selectRow': true
					// }
				},
				{ "width": "20%", "targets": 1 },
				{ "width": "50%", "targets": 2 },
				{ "width": "24%", "targets": 3, "orderable":false },
			],
			'select': {
				'style': 'multi'
			},
			'order': [0, 'asc'],
			drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"}},
		});
	}

// Move module active
	$("body").delegate('#add_module_group', 'click', function() {
		var lst_item_select = document.getElementById('modules');
		var lst_item_selected = document.getElementById('module_selected');
		moveItemSelect(lst_item_select, lst_item_selected);
	});

	$("body").delegate('#remove_module_group', 'click', function() {
		var lst_item_select = document.getElementById('modules');
		var lst_item_selected = document.getElementById('module_selected');
		moveItemSelect(lst_item_selected, lst_item_select);
	});

// Change sort module
	$("body").delegate('#btnMoveTopSettingModule', 'click', function () {
    var count_module = 0;
    $.each($('#module_selected option:selected'), function (i, row) {
      count_module = count_module + 1
    });
    if (count_module > 0){
      var first_elem = $("#module_selected option:first");
      if(first_elem.is(':selected')) return true;
      while(!first_elem.prev().length){
        $('#module_selected option:selected').each(function (i, selected) {
          if (!$(this).prev().length) return false;
          $(this).insertBefore($(this).prev());
        });
        $('#module_selected select').focus().blur();
      }	
    }
  });

  $("body").delegate('#btnMoveUpSettingModule', 'click', function () {
    $('#module_selected option:selected').each(function (i, selected) {
      if (!$(this).prev().length) return false;
      $(this).insertBefore($(this).prev());
    });
    $('#module_selected select').focus().blur();
  });

  $("body").delegate('#btnMoveDownSettingModule', 'click', function () {
    $($('#module_selected option:selected').get().reverse()).each(function (i, selected) {
      if (!$(this).next().length) return false;
      $(this).insertAfter($(this).next());
    });
    $('#module_selected select').focus().blur();
  });

  $("body").delegate('#btnMoveBottomSettingModule', 'click', function () {	
    var count_module = 0;
    $.each($('#module_selected option:selected'), function (i, row) {
      count_module = count_module + 1
    });
    if (count_module > 0){
      var last_elem = $("#module_selected option:last");
      if(last_elem.is(':selected')) return true;
      while(!last_elem.next().length){
        $($('#module_selected option:selected').get().reverse()).each(function (i, selected) {
          if (!$(this).next().length) return false;
          $(this).insertAfter($(this).next());
        });
        $('#module_selected select').focus().blur();
      }
    }
  }); 

// Submit form setting module
  $('#setting_module_active').submit(function() {
    $('#module_selected option').prop('selected', true);
    $('#modules option').prop('selected', true);
    return true; 
  });

});

function moveItemSelect(selectItemFrom, selectItemTo) {
	$(selectItemFrom).find('option:selected').detach().prop("selected", false).appendTo($(selectItemTo));
}