// export const UndoRedo = {      
//     undo(canvas) {
//         // if (canvas.mods < canvas.state.length) {
//         //     canvas.clear().renderAll();
//         //     canvas.loadFromJSON(canvas.state[canvas.state.length - 1 - canvas.mods - 1]);
//         //     canvas.renderAll();
//         //     // console.log("geladen " + (state.length-1-mods-1));
//         //     console.log("state " + canvas.state.length);
//         //     canvas.mods += 1;
//         //     //console.log("mods " + mods);
//         // }
//         canvas.undo();
//     },
    
//     redo(canvas) {
//         // if (canvas.mods > 0) {
//         //     canvas.clear().renderAll();
//         //     canvas.loadFromJSON(canvas.state[canvas.state.length - 1 - canvas.mods + 1]);
//         //     canvas.renderAll();
//         //     //console.log("geladen " + (state.length-1-mods+1));
//         //     canvas.mods -= 1;
//         //     //console.log("state " + state.length);
//         //     //console.log("mods " + mods);
//         // }
//         canvas.redo();
//     }
// }
export class UndoRedoHandler {
    constructor() {
      this._undoStack = [];
      this._redoStack = [];
    }
  
    insert(state) {
      this._undoStack.push(state);
      this._redoStack.length = 0;
    //   this._redoStack.push(state);
    }
  
    getPrevState() {
      if (this._undoStack.length >= 1) {
        let state = this._undoStack.pop();
        this._redoStack.push(state);
        return state;
      }
    }
    
    getNextState() {
      if (this._redoStack.length >= 1) {
        let state = this._redoStack.pop();
        this._undoStack.push(state);
        return state;
      }
    }
  
    clear() {
      if (this._redoStack.length >= 1) {
        let state = this._redoStack.pop();
        this._undoStack.length = 0;
        this._redoStack.length = 0;
        this._redoStack.push(state);
      }
    }
    removeItemStack(elem){
        for (let index = 0; index < this._undoStack.length; index++) {
          const element = this._undoStack[index];
          if(element.typeObject == TYPE_OBJECT.OBJECT_CANVAS){
            if(element.canvasObject.id == elem.id){
                this._undoStack.splice(index, 1);
                // this._redoStack.push(element);
            }
          }
          
          if(element.typeObject == TYPE_OBJECT.OBJECT_HTML){
            if( element.htmlObject.id == elem.id){
                this._undoStack.splice(index, 1);
            }
            else{
                if(elem.parentElement && element.htmlObject.id == elem.parentElement.id ){
                    this._undoStack.splice(index, 1);
                }
            }
          }
        }     
      }
  }
export const TYPE_OBJECT = {
    OBJECT_CANVAS: 0,
    OBJECT_HTML:1   
};


export const TYPE_HISTORY = {
    ADD:0,
    MODIFY:1,
    DELETE:2  
};
export class PdfHistory{
    pageNumber = null
    typeObject = null
    typeHistory = null
    canvasObject = null
    htmlObject = null
    annotation = null
   constructor(pageNumber,annotation,typeObject,typeHistory,canvasObject,htmlObject){
    this.pageNumber = pageNumber
    this.annotation = annotation
    this.typeHistory = typeHistory
    this.typeObject = typeObject
    this.canvasObject = canvasObject
    this.htmlObject = htmlObject
   }
   
};