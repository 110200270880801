

$(document).on('turbo:load', function (){  
    // initGalaxy();
    /** view image
     * find list image
     *  create galary
     * show galary
    */    
    $("body").delegate('#btnPreviewImage', 'click', function() {      
        let src = this.getAttribute('image-src');        
        let div_file_events = this.closest('.file-events');

         if(src && div_file_events){         
            let images = div_file_events.querySelector('#images_galary');
            let loaded = images.getAttribute("loaded");
            if(loaded != 'true'){
                images.galaxy = new Viewer(images,
                    {
                        backdrop: true,
                        movable: true
                    });
                images.setAttribute("loaded",true);
            }
            let decode_src = decodeURI(src);
            let index = images.galaxy.images.findIndex((p)=> decode_src.includes(p.alt));
            if(index >= 0)
            {
                images.galaxy.index = index;
                images.galaxy.show();
            }   
        }       
	});

    $("body").delegate('.js_show_img', 'click', function() {
        $('#js_show_image_modal .modal-body').find('img').attr("src", this.src)
        $('#js_show_image_modal .modal-body').find('img').attr("style", "width: 100%;height: 100%;")
        $('#js_show_image_modal .modal-body').find('.js_show_image_modal_title').html(this.alt)
        window.$("#js_show_image_modal").modal("show")
	});   
});

function initGalaxy(){
    let images;
    let images_galary = document.querySelectorAll('#images_galary');
    for (let index = 0; index < images_galary.length; index++) {
        images = images_galary[index];
        images.galaxy = new Viewer(images,
            {
                backdrop: true,
                movable: true
            });            
    }     
}