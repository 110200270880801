$(document).on('turbo:load', function() {
  $('body').delegate('#issue_tag_update', 'click', function (e) {
    let issue_id = $("#issue_tag").val()
    let tag_array = $('#list_issue_tag textarea').get().map(function(textarea) {
      let id = $(textarea).data('id')
      let value = $(textarea).val();
    
      return { [id]: value };
    });
    let tag_params = Object.assign({}, ...tag_array);
    $.ajax({
      type: 'GET',
      url:  '/master_plans/' + $("#detail_master_plan").data('id') + '/tags/update_tags',
      dataType: "script",
      data: { 
        tag_params,
        issue_id: issue_id
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function() {
        alert('Error: Unable to save tags');
      },
    });
	});

  $('body').delegate('.update_tag', 'keypress', function (e) {
    if (event.key === "Enter") {
      event.preventDefault();
      document.getElementById("issue_tag_update").click();
    }
	});

  $('body').delegate('#delete_issue_tag', 'click', function (e) {
    let tag_id = $(this.parentElement).find("textarea").data('id');
    let issue_id = $("#issue_tag").val()
    $.ajax ({
      type: 'GET',
      url:'/master_plans/' + $("#detail_master_plan").data('id') + "/tags/" + tag_id + "/confirm_destroy_tag",
      dataType: 'script',
      data: {
        issue_id: issue_id,
        tag_id: tag_id
      },
      success: function(data, textStatus, jqXHR){
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error");
      }
    });
	});

  $("body").delegate("#find_issue_tag_name", "keyup", function (e){
    let find_tag_name = $("#find_issue_tag_name").val().trim();
    $("#suggest_issue_tag").removeClass("d-none")
    let tag_in_issue = $(".name_tag_in_issue").map(function() {
        return $(this).data('tag-name');
    }).get();

    let tag_id = $(".name_tag_in_issue")
    .filter(function() {
      return !$(this).hasClass("d-none");
    })
    .map(function() {
      return $(this).data('tag-id');
    }).get();

    if (tag_in_issue.includes(find_tag_name)) {
      $(".validate-name-tag").removeClass("d-none")
    } else {
      $(".validate-name-tag").addClass("d-none")
      if(e.keyCode == 13 && find_tag_name != ""){
        let issue_id = $("#issue_tag").val()
        $.ajax({
          type: 'POST',
          url:'/master_plans/' + $("#detail_master_plan").data('id') + '/tags',
          dataType: 'script',
          data: {
            name: find_tag_name,
            id: issue_id
          },
          success: function (data, textStatus, jqXHR) {
            // console.log(data);
            console.log("AJAX OK!")
          },
          error: function (jqXHR, textStatus, errorThrown) {
            console.log("AJAX Error: #{textStatus}")
          }
        })
      }
    }
    $.ajax({
      type: 'GET',
      url: '/master_plans/' + $("#detail_master_plan").data('id') + '/issue_tags/suggest_from_issue_tag_input',
      dataType: 'script',
      data: {
        tag_id: tag_id,
        find_tag_name: find_tag_name ,
      },
      success: function (data, textStatus, jqXHR) {
        // console.log(data);
        console.log("AJAX OK!")
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    });
  });

  $("body").delegate(".add_tag_for_issue", "click", function (e){
    let name = $(this).text();
    $.ajax({
      type: 'POST',
      url:'/master_plans/' + $("#detail_master_plan").data('id') + '/tags',
      dataType: 'script',
      data: {
        name: name
      },
      success: function (data, textStatus, jqXHR) {
        console.log("AJAX OK!")
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    })
  });

  $("body").delegate("#save_issue_tag", "click", function (e){
    let issue_id = $("#issue_tag").val()
    let issue_tags = [];
    let list_tag_ids = $('#show-tag-issue').find("[data-tag-id]")
    if (list_tag_ids.length > 0) {
      for (let i = 0; i < list_tag_ids.length; i++) {

        let tag_id = $(list_tag_ids[i]).data('tag-id');
        let issue_tag_id = $(list_tag_ids[i]).data('issue-tag-id');
        let destroy = false;
    
        if ($(list_tag_ids[i]).hasClass("d-none")) {
          destroy = true;
        }
    
        let issue_tags_attributes = {
          id: issue_tag_id,
          tag_id: tag_id,
          issue_id: issue_id,
          _destroy: destroy
        };
        issue_tags.push(issue_tags_attributes);
      }
      let issue = {
        issue_tags_attributes: issue_tags
      };

      $.ajax({
        type: 'POST',
        url:'/master_plans/' + $("#detail_master_plan").data('id') + '/issue_tags',
        dataType: 'script',
        data: {
          id: issue_id,
          issue: issue
        },
        success: function (data, textStatus, jqXHR) {
          console.log("AJAX OK!")
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log("AJAX Error: #{textStatus}")
        }
      })
    }
  });

  $("body").delegate("#delete_tag_in_issue", "click", function (e){
    if ( $(this).parent().parent().data("issue-tag-id") === "") {
      $(this).parent().remove();
    }
    else {
      $(this).parent().addClass("d-none")
    }

  });

  $("body").delegate("#find_tag_name_to_edit", "keyup", function (e){
    let issue_id = $("#issue_tag").val()
    let find_tag_name = $("#find_tag_name_to_edit").val().trim();
    $.ajax({
      type: 'GET',
      url: '/master_plans/' + $("#detail_master_plan").data('id') + "/tags/suggest_from_tag_input",
      dataType: 'script',
      data: {
        issue_id: issue_id,
        find_tag_name: find_tag_name ,
      },
      success: function (data, textStatus, jqXHR) {
        console.log("AJAX OK!")
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    });
  });
});
