
$(document ).ready(function() {
    window.onbeforeunload = function (e){
        if (e.path && e.path[0].location.pathname == "/personal_mails/new" && $("#confirm_close_page").val() == '') {
            e.preventDefault();
                e.returnValue = '';
        }
        
        // if(e.currentTarget.location.pathname.includes(`/pdf/`) && e.currentTarget.location.pathname.includes(`/view`)){
        //     var imageUnsaves = document.querySelectorAll("[class*=dragme]");
        //     var is_Editting = document.querySelector(".stampViewer").getAttribute('edit');
        //     if(imageUnsaves.length > 0 || is_Editting){           
        //       var confirmationMessage = 'Are you sure to leave the page?';  // a space
        //             (e || window.event).returnValue = confirmationMessage;
        //             return confirmationMessage;
              
        //     }        
        // } 
        //   if(e.currentTarget.location.pathname.includes(`/pdf/`) && e.currentTarget.location.pathname.includes(`/edit`)){
        //     var editting = document.getElementById("viewer").getAttribute('edit');
        //     if(editting || editting == 'true'){
        //       var confirmationMessage = 'Are you sure to leave the page?';  // a space
        //             (e || window.event).returnValue = confirmationMessage;
        //             return confirmationMessage;
        //     }
        // }    
    };
   
    //   history.pushState({ page: 1 }, "title 1", "?page=1");
     
    
})