export class EventBus {
    constructor() {
      this._listeners = Object.create(null);
    }
  
    on(eventName, listener, options = null) {
      this._on(eventName, listener, {
        external: true,
        once: options?.once
      });
    }
  
    off(eventName, listener, options = null) {
      this._off(eventName, listener, {
        external: true,
        once: options?.once
      });
    }
  
    dispatch(eventName, data) {
      const eventListeners = this._listeners[eventName];
  
      if (!eventListeners || eventListeners.length === 0) {
        return;
      }
  
      let externalListeners;
  
      for (const {
        listener,
        external,
        once
      } of eventListeners.slice(0)) {
        if (once) {
          this._off(eventName, listener);
        }
  
        if (external) {
          (externalListeners ||= []).push(listener);
          continue;
        }
  
        listener(data);
      }
  
      if (externalListeners) {
        for (const listener of externalListeners) {
          listener(data);
        }
  
        externalListeners = null;
      }
    }
  
    _on(eventName, listener, options = null) {
      const eventListeners = this._listeners[eventName] ||= [];
      eventListeners.push({
        listener,
        external: options?.external === true,
        once: options?.once === true
      });
    }
  
    _off(eventName, listener, options = null) {
      const eventListeners = this._listeners[eventName];
  
      if (!eventListeners) {
        return;
      }
  
      for (let i = 0, ii = eventListeners.length; i < ii; i++) {
        if (eventListeners[i].listener === listener) {
          eventListeners.splice(i, 1);
          return;
        }
      }
    }
  
  }