import { PDFDownloadHelper } from "../../libs/pdf-editor/helper/pdf_download_helper";
var JSZip = require("jszip");
export var download_helper = {
    downloadPDF_by_base64: function(base64,fileName){
        let pos = fileName.lastIndexOf(".");
        fileName = fileName.substr(0, pos < 0 ? file.length : pos) + ".pdf";
        var blob = new Blob([base64], {type: "application/pdf"});
        downloadFile(blob,fileName);
    }   ,
    download_list_file: async function(files,pdfs,zip_filename = "Compress.zip"){
        var data_other_file = [];
        if(pdfs.length > 0){
          $.ajax({
            type: 'GET',
            url: window.origin + "/pdf/get_data_multi_file",
            dataType: 'json',
            data: {
              pdf_ids: pdfs
            },
            success: function(data, textStatus, jqXHR){
              download_compose(data.list_data,files,zip_filename);	
              
            },
            error: function(jqXHR, textStatus, errorThrown) {
              console.log("AJAX Error: " + errorThrown);
              hideFormLoading(); 
            }
          
          });
        }
        else{
            data_other_file = await download_multifile_file(files);
            auto_download_multifile_zip_blob(data_other_file,zip_filename);
        }
      }
    }

    async function download_compose(list_data,files,zip_filename){
        var data_pdf = await download_multifile_pdf(list_data);
        var data_other_file = await download_multifile_file(files);
        var all_file = data_other_file.concat(data_pdf);
        auto_download_multifile_zip_blob(all_file,zip_filename);
    }
  async function download_multifile_pdf(list_data){	
    var blob, data = [];
    for await(const element of list_data) {        
          // save annotations
          blob = await sub_auto_download_multifile(element.url,element.annotations,element.rotations);       
      data.push({filename: element.filename,blob: blob})
    }
    return data;    
  }
  async function sub_auto_download_multifile(url,annotations,rotations){	
    var bytes =  await fetch_data(url);    
    var helper = new PDFDownloadHelper(null);
    return await helper.combine_data_file_to_blob(bytes,annotations,rotations); 	
  }
  async function download_multifile_file(files){	
    var blob, data = [];
    for await(const file of files) {        
          blob = await sub_download_list_url(file);       
          data.push({filename: file.filename,blob: blob})
    }  
    return data;
  }
  async function sub_download_list_url(file){	   
  var bytes =  await fetch_data(file.url); 
    var blob = new Blob([bytes], {type: file.mime});
    return blob;
  }
  
  function auto_download_multifile_zip_blob(data,zip_filename = "Compress.zip") {
    var zipContent = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      zipContent.push({
        name: element.filename,
        content: element.blob
      });	
    }
    var totalFiles = zipContent.length,file;
    var JSZip = require("jszip");
    var zip = new JSZip();
    for (var i = 0; i < totalFiles; i++) {
      file = zipContent[i];
      addFileToZip(file,zip);
    }
    zip.generateAsync({
      type: "blob"
    }).then(function (content) {
      // Create a download link
      var link = document.createElement("a");
      link.href = URL.createObjectURL(content);
      link.download = zip_filename;
      link.click();
      URL.revokeObjectURL(link.href);
    });
  }
  function addFileToZip(file,zip) {
    const currDate = new Date();
    const dateWithOffset = new Date(currDate.getTime() - currDate.getTimezoneOffset() * 60000);
    JSZip.defaults.date = dateWithOffset;
    var filename = file.name;
    var content = file.content;
    zip.file(filename, content, {
      binary: true,
    });	
  }
function downloadFile(blob, fileName){
    var link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };
  