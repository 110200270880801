$(document).on('turbo:load', function() { 
    $("body").delegate('.btn-download-directly', 'click', function (event) {
        downloadFile(this.getAttribute("data-url"),this.getAttribute("data-name"));
    });
});
async function downloadFile(url,filename) {
  let headers = new Headers();
  headers.append('Origin',"*");
  headers.append('Access-Control-Allow-Origin',"*");
  fetch(url, {
    mode: 'cors',
    cache: 'no-cache',
    method: 'GET',
    headers: headers
})

  .then(response => response.blob())
  .then(blob => {
    // Create a new FileReader innstance
    const reader = new FileReader;
  
    // Add a listener to handle successful reading of the blob
    reader.addEventListener('load', () => {
      // const image = new Image;
      
      // Set the src attribute of the image to be the resulting data URL
      // obtained after reading the content of the blob

      
       var link = document.createElement("a");
    // If you don't know the name or want to use
    // the webserver default set name = ''
    link.setAttribute('download', filename);
    link.href = reader.result;
    document.body.appendChild(link);
    link.click();
    link.remove();
    
    });
  
    // Start reading the content of the blob
    // The result should be a base64 data URL
    reader.readAsDataURL(blob);
  });
  }

