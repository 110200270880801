import { ANNOTATION } from '../edit/annotation';
import {Config} from '../define/AppConfig';
import { HighLight } from '../fabric/items/highlight';
import { PDFSaveAnnotation } from './save_annotation';
import { configHelper } from './config_helper';
export const TextHelper = {   
    addHightLight(selection,pdfViewer){
        if (selection.toString().length>0) {
          // highlightSelection();
          var range = selection.getRangeAt(0).cloneRange();
          //https://stackoverflow.com/questions/667951/how-to-get-nodes-lying-inside-a-range-with-javascript
          var nodes = range.cloneContents().querySelectorAll("*");
          var elem,property,ielement,html;
          var isSuccess = true;
          if(nodes.length>0){
            for(var i=0;i<nodes.length;i++)
            {
              elem = getElemOrigin(nodes[i]);
              if(elem){
                // addNode(nodes[i],i==0,i==nodes.length-1,elem,pdfViewer);
                property = getPropertiesNode(nodes[i],i==0,i==nodes.length-1,elem,pdfViewer);
                ielement = new HighLight(property);
                html = ielement.html();
                pdfViewer.getLayoutPage(property.page).AnnotationLayer.append(html);
                isSuccess = PDFSaveAnnotation.saveHTMLElement(pdfViewer,property);
                
              }
            }  
          }   
          else
          {

            property = getPropertyElement(range.startContainer.parentElement,pdfViewer);
            ielement = new HighLight(property);
            html = ielement.html();
            pdfViewer.getLayoutPage(property.page).AnnotationLayer.append(html);
            isSuccess = PDFSaveAnnotation.saveHTMLElement(pdfViewer,property);
          }
          
        }
    }
}
function highlightSelection() {
  var userSelection = window.getSelection().getRangeAt(0);
  var safeRanges = getSafeRanges(userSelection);
  for (var i = 0; i < safeRanges.length; i++) {
    highlightRange(safeRanges[i]);
  }
}
function highlightRange(range) {
  var newNode = document.createElement("div");
  newNode.setAttribute(
    "style",
    "background-color: yellow; display: inline;"
  );
  range.surroundContents(newNode);
}

function getSafeRanges(dangerous) {
  var a = dangerous.commonAncestorContainer;
  // Starts -- Work inward from the start, selecting the largest safe range
  var s = new Array(0), rs = new Array(0);
  if (dangerous.startContainer != a) {
    for (var i = dangerous.startContainer; i != a; i = i.parentNode) {
      s.push(i);
    }
  }
  if (s.length > 0) {
    for (var i = 0; i < s.length; i++) {
      var xs = document.createRange();
      if (i) {
        xs.setStartAfter(s[i - 1]);
        xs.setEndAfter(s[i].lastChild);
      } else {
        xs.setStart(s[i], dangerous.startOffset);
        xs.setEndAfter((s[i].nodeType == Node.TEXT_NODE) ? s[i] : s[i].lastChild);
      }
      rs.push(xs);
    }
  }

  // Ends -- basically the same code reversed
  var e = new Array(0), re = new Array(0);
  if (dangerous.endContainer != a) {
    for (var i = dangerous.endContainer; i != a; i = i.parentNode) {
      e.push(i);
    }
  }
  if (e.length > 0) {
    for (var i = 0; i < e.length; i++) {
      var xe = document.createRange();
      if (i) {
        xe.setStartBefore(e[i].firstChild);
        xe.setEndBefore(e[i - 1]);
      } else {
        xe.setStartBefore((e[i].nodeType == Node.TEXT_NODE) ? e[i] : e[i].firstChild);
        xe.setEnd(e[i], dangerous.endOffset);
      }
      re.unshift(xe);
    }
  }

  // Middle -- the uncaptured middle
  if ((s.length > 0) && (e.length > 0)) {
    var xm = document.createRange();
    xm.setStartAfter(s[s.length - 1]);
    xm.setEndBefore(e[e.length - 1]);
  } else {
    return [dangerous];
  }

  // Concat
  rs.push(xm);
  var response = rs.concat(re);

  // Send to Console
  return response;
}

function getRectByRotate(property,elem,pdfViewer) {
  var rotate = property.rotate;
  var page_number = property.page;
  var parent = pdfViewer.getLayoutPage(page_number).AnnotationLayer;
  var mouseDown = pdfViewer.pointMouseDown;
  var mouseUp = pdfViewer.pointMouseUp;
  if(rotate%180 == 0){
    if(mouseDown.clientX > mouseUp.clientX){
      mouseDown = pdfViewer.pointMouseUp;
      mouseUp = pdfViewer.pointMouseDown;
    }
  }
  else{
    if(mouseDown.clientY > mouseUp.clientY){
      mouseDown = pdfViewer.pointMouseUp;
      mouseUp = pdfViewer.pointMouseDown;
    }
  }
  
  var elemBounding = elem.getBoundingClientRect();
  var parentBounding = parent.getBoundingClientRect();
  var page_width = parseFloat(parent.style.width);
  var page_height = parseFloat(parent.style.height);
  var left;
  var top;
  var width;
  var height;  
  var mouseDownOnPage = 
  {
    x: Math.abs(mouseDown.clientX - parentBounding.x),
    y: Math.abs(mouseDown.clientY - parentBounding.y),
  }
  var mouse_move_width = Math.abs(mouseUp.clientX - mouseDown.clientX);
  var mouse_move_height = Math.abs(mouseUp.clientY - mouseDown.clientY);
  
  switch(rotate){
    case 0:
    case 360:
      width =  mouse_move_width;
      height =  elemBounding.height;
      left =  mouseDownOnPage.x;
      top =  parseFloat(elem.style.top);     
      break;
    case -90:
    case 270:
      width = mouse_move_height;
      height =  elemBounding.width;
      left =  Math.abs(page_width - mouseDownOnPage.y - width);
      top = Math.abs( parseFloat(elem.style.left));      
      break;
    case 180:
    case -180:
      width = mouse_move_width;
      height =  elemBounding.height;
      left =  Math.abs(page_width - mouseDownOnPage.x - width);
      top = Math.abs(page_height - parseFloat(elem.style.top));     
      break;
    case -270:
    case 90:
      left =  mouseDownOnPage.y;
      top = page_height - parseFloat(elem.style.left);
      width = mouse_move_height;
      height =  elem.getBoundingClientRect().width;
      break;
  }
  return {left:left,top:top, width: width,height:height}
}
function getRectNodeByRotate(first,end,property,elem,elemContainer,pdfViewer) {
  var rotate = property.rotate;
  var page_number = property.page;
  var parent = pdfViewer.getLayoutPage(page_number).AnnotationLayer;
  var mouseDown = pdfViewer.pointMouseDown;
  var mouseUp = pdfViewer.pointMouseUp;
  if(rotate%180 == 0){
    if(mouseDown.clientX > mouseUp.clientX){
      mouseDown = pdfViewer.pointMouseUp;
      mouseUp = pdfViewer.pointMouseDown;
    }
  }
  else{
    if(mouseDown.clientY > mouseUp.clientY){
      mouseDown = pdfViewer.pointMouseUp;
      mouseUp = pdfViewer.pointMouseDown;
    }
  }
  
  var elemBounding = elemContainer.getBoundingClientRect();
  var parentBounding = parent.getBoundingClientRect();
  var page_width = parseFloat(parent.style.width);
  var page_height = parseFloat(parent.style.height);
  var left;
  var top;
  var width;
  var height;  
  var mouseDownOnPage = 
  {
    x: Math.abs(mouseDown.clientX - parentBounding.x),
    y: Math.abs(mouseDown.clientY - parentBounding.y),
  }
  var mouseUpOnPage = 
  {
    x: Math.abs(mouseUp.clientX - parentBounding.x),
    y: Math.abs(mouseUp.clientY - parentBounding.y),
  }


  switch(rotate){
    case 0:
    case 360:
      if(first){
        left = Math.abs( page_width - mouseDownOnPage.x);
      }
      else 
      {
        left = parseFloat(elem.style.left);
      }
    
      if(end)
      {
        width = mouseUp.clientX - parentBounding.x - parseInt(elem.style.left) ;
      }
      else
      {
        var rect = getBoundingRect(elemContainer);
        if(first){
          width = parseFloat(elem.style.left) +  rect.width  - left;
        }
        else{
          width = rect.width ;
        }   
      }  
      width =  width ;
      height =  elemBounding.height;
      left =  left;
      top =  parseFloat(elem.style.top);     
      break;
    case -90:
    case 270:
      if(first){
        left = Math.abs( page_width - mouseDownOnPage.y);
      }
      else 
      {
        left = Math.abs(page_width - parseFloat(elem.style.top));
      }
    
      if(end)
      {
        width = Math.abs( mouseUp.clientY - parentBounding.y - parseInt(elem.style.top)) ;
      }
      else
      {
        var rect = getBoundingRect(elemContainer);
        if(first){
          width =  Math.abs( page_width - parseFloat(elem.style.top) +  rect.height  - left);
          
        }
        else{

          width = rect.height ;
        }   
      }  
      left =  left;
      top = Math.abs( parseFloat(elem.style.left));
      //width = Math.abs(page_width - width);
      height =  elemBounding.width;
      break;    
    case 180:
    case -180:
      if(first)
      {
        left = mouseDownOnPage.x;
      }
      else 
      {
        left = parseFloat(elem.style.left);
      }
      
      if(end)
      {
        width = Math.abs(mouseUpOnPage.x - left)
      }
      else
      {
        var rect = getBoundingRect(elemContainer);
        if(first){
            width = Math.abs( mouseDownOnPage.x -(parseFloat(elem.style.left) - elemBounding.width)) ;
        }
        else{
          width = rect.width ;
        }   
      }  

      left =  Math.abs(page_width - left);
      top = Math.abs(page_height - parseFloat(elem.style.top));  
      height =  elemBounding.height;
      width =  width;
      break;
    case -270:
    case 90:
      if(first){
        left = mouseDownOnPage.y;
      }
      else 
      {
        left = parseFloat(elem.style.top);
      }
    
      if(end)
      {
        width = mouseUp.clientY - parentBounding.y - parseInt(elem.style.top) ;
      }
      else
      {
        var rect = getBoundingRect(elemContainer);
        if(first){
          width = parseFloat(elem.style.top) +  rect.height  - left;
        }
        else{

          width = rect.height ;
        }   
      }  
      left =  left;
      top = page_height - parseFloat(elem.style.left);
      width = width;
      height =  elemBounding.width;
      break;
  }
  return {left:left,top:top, width: width,height:height}
}


function getPropertyElement(elem,pdfViewer){   
  var property = {};
  setStaticPropertiesOptions(property,elem,pdfViewer);

  var rect = getRectByRotate(property,elem,pdfViewer);
  property.left = rect.left;
  property.top = rect.top;
  property.width = rect.width;
  property.height = rect.height;  

  if(pdfViewer.current_annotation == ANNOTATION.TEXT_UNDERLINE){
    property.top += property.height;
    property.height = pdfViewer.currentScaleValue ;   
  }
  if(pdfViewer.current_annotation == ANNOTATION.TEXT_STROKEOUT){
    property.top += property.height/2;
    property.height = pdfViewer.currentScaleValue ;    
  }
  return property; 
}
function getPropertiesNode(node,first,end,elem,pdfViewer) {
  var property = {};
  setStaticPropertiesOptions(property,elem,pdfViewer);  
  var rect = getRectNodeByRotate(first,end,property,node,elem,pdfViewer);
  property.left = rect.left;
  property.top = rect.top;
  property.width = rect.width;
  property.height = rect.height;  

  if(pdfViewer.current_annotation == ANNOTATION.TEXT_UNDERLINE){
    property.top += property.height;
    property.height = pdfViewer.currentScaleValue ;   
  }
  if(pdfViewer.current_annotation == ANNOTATION.TEXT_STROKEOUT){
    property.top += property.height/2;
    property.height = pdfViewer.currentScaleValue ;    
  }
  return property; 
}

function setStaticPropertiesOptions(option,elem,pdfViewer){
  var config = configHelper.getConfigByAnnotation(pdfViewer.current_annotation);
  var currentPageDiv = elem.closest('.page');
  var page_number = parseInt(currentPageDiv.getAttribute("data-page-number")) ;
  var page = pdfViewer.file.pages[page_number-1];
  var rotate = (page.rotate + page.viewRotate) % 360;

  option.annotation = pdfViewer.current_annotation;
  option.className = "annotation-added";
  option.id = random_string(10);
  option.scale = pdfViewer.currentScaleValue;
  option.opacity = config.opacity;
  option.color = config.color;
  option.name = config.name;
  option.page = page_number;
  option.rotate = rotate;
}


   


 function getElemOrigin(node){
    var listChildSpan = document.body.querySelectorAll("span");    
    for (let index = 0; index < listChildSpan.length; index++) {
      const element = listChildSpan[index];
      if(parseInt(element.style.left) == parseInt(node.style.left) && parseInt(element.style.top) == parseInt(node.style.top) && element.textContent.includes(node.textContent))
      {
        return element;
      }
    }
    return null;
  }

  function getBoundingRect(element) {
    var style = window.getComputedStyle(element); 
    var margin = {
        left: parseInt(style["margin-left"]),
        right: parseInt(style["margin-right"]),
        top: parseInt(style["margin-top"]),
        bottom: parseInt(style["margin-bottom"])
    };
    var padding = {
        left: parseInt(style["padding-left"]),
        right: parseInt(style["padding-right"]),
        top: parseInt(style["padding-top"]),
        bottom: parseInt(style["padding-bottom"])
    };
    var border = {
        left: parseInt(style["border-left"]),
        right: parseInt(style["border-right"]),
        top: parseInt(style["border-top"]),
        bottom: parseInt(style["border-bottom"])
    };    
    
    var rect = element.getBoundingClientRect();
    rect = {
        left: rect.left - margin.left,
        right: rect.right - margin.right - padding.left - padding.right,
        top: rect.top - margin.top,
        bottom: rect.bottom - margin.bottom - padding.top - padding.bottom - border.bottom  
    };
    rect.width = rect.right - rect.left;
    rect.height = rect.bottom - rect.top;
    return rect;
    
  };