$(document).on('turbo:load', function() {
	formatDatatableWork();

	$('body').delegate('#tbl_work > tbody > tr', 'click', function (e) {
		var arr_work_name = [];
		$.each($('.tbl_work tbody tr'), function (i, row) {
			if ($(row).hasClass("selected")) {
				arr_work_name.push($(row).data('item-id'));
			}
		});

		getListWorkItem(arr_work_name);
	});

	$('body').delegate('#tbl_work tr input[type="checkbox"]', 'change', function (e) {
		var arr_work_name = [];
		$.each($('.tbl_work tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_work_name.push($(row).data('item-id'));
			}
		});
		getListWorkItem(arr_work_name);

	});

	// Destroy work
	$("#btnDestroyWork").click(function() {
		var arr_work = [];
		$.each($('.tbl_work tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_work.push($(row).data('item-id'));
			} 
		});

		if (arr_work.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "/confirm_destroy_work",
				dataType: 'script',
				data: {
					id: arr_work
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	// Destroy work item
	// $("#btnDestroyWorkItem").click(function() {
	$('body').delegate("#btnDestroyWorkItem", 'click', function() {
		var arr_work_item = [];
		$.each($('.tbl_work_item tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_work_item.push($(row).data('item-id'));
			} 
		});

		if (arr_work_item.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "/confirm_destroy_work_item",
				dataType: 'script',
				data: {
					id: arr_work_item
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});


});

window.formatDatatableWork = function() {
	var tblWork = document.getElementsByClassName("tbl_work");
	if (tblWork.length > 0) {
		window.$('.tbl_work').dataTable({
			"initComplete": function(settings, json) {
				var work_row_selected = document.getElementById("work_row_selected");
				var api = this.api();
				var row = api.row(function ( idx, data, node ) {
					return data.DT_RowId === work_row_selected.value;
				});
				if (row.length == 1) {
					row.select().show().draw(false);
					getListWorkItem(work_row_selected.value.split());
				}
			},

			'stateSave': false,
			'destroy': true,
			'columnDefs': [
			{
				'targets': 0,
				'width': "5%",
				'checkboxes': {
					'selectRow': true
				},
			},
			{ "width": "45%", "targets": 1 },
			{ "width": "40%", "targets": 2 },
			{ "width": "10%", "targets": 3, "orderable": false }               
			],
			'select': {
				'style': 'os'
			},
			'order': [3, 'asc'],
			drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
			previous:"<i class='mdi mdi-chevron-left'>",
			next:"<i class='mdi mdi-chevron-right'>"}},
		});
	}
}

window.formatDatatableWorkItem = function() {
	var tblWorkItem = document.getElementsByClassName("tbl_work_item");
	if (tblWorkItem.length > 0) {
		window.$('.tbl_work_item').dataTable({
			"initComplete": function(settings, json) {
				var work_item_row_selected = document.getElementById("work_item_row_selected");
				var api = this.api();
				var row = api.row(function ( idx, data, node ) {
					return data.DT_RowId === work_item_row_selected.value;
				});
				if (row.length == 1) {
					row.select().show().draw(false);
				}
			},
			'stateSave': false,
			'destroy': true,
			'columnDefs': [
			{
				'targets': 0,
				'width': "4%",
				'checkboxes': {
					'selectRow': true
				}
			},
			{ "width": "46%", "targets": 1 },
			{ "width": "40%", "targets": 2 },
			{ "width": "10%", "targets": 3, "orderable": false }               
			],
			'select': {
			'style': 'os'
			},
			'order': [3, 'asc'],
			drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
			previous:"<i class='mdi mdi-chevron-left'>",
			next:"<i class='mdi mdi-chevron-right'>"}},
		});
	}
}

function getListWorkItem(arr_work_name) {
	$.ajax({
			type: 'GET',
			url: "/get_list_work_item",
			dataType: 'script',
			data: {
				arr_work_name: arr_work_name,
			},
			success: function (data, textStatus, jqXHR) {
				
				console.log("OK!")
			},
			error: function (jqXHR, textStatus, errorThrown) {
				console.log("AJAX Error")
			}
		});
}

