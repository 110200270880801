$(document).on('turbo:load', function() {
	$("#btnEditStatus").click(function() {
		var arr_status = [];
		$.each($('.tbl_status tbody tr'), function (i, row) {
			if ($(row).find("input[class='checkbox_status_table']:checked").is(":checked")) {
				arr_status.push($(row).data('item-id'));
			} 
		});
		if (arr_status.length == 1) {
			let page_value = $(".status-tbl").find(".pagination .current").text().trim();
			$.ajax ({
				type: 'GET',
				url: "statuses/" + arr_status[0] + "/edit",
				dataType: 'script',
				data: {
					id: arr_status[0],
					page: page_value
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}
	});

	$("#btnDeleteStatus").click(function() {
		var arr_status = [];
		$.each($('.tbl_status tbody tr'), function (i, row) {
			if ($(row).find("input[class='checkbox_status_table']:checked").is(":checked")) {
				arr_status.push($(row).data('item-id'));
			} 
		});
		if (arr_status.length > 0) {
			let page_value = $(".status-tbl").find(".pagination .current").text().trim();
			$.ajax ({
				type: 'GET',
				url: "confirm_destroy_status",
				dataType: 'script',
				data: {
					id: arr_status,
					page: page_value
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	$("body").delegate('.color_status_tag .color-select .input-color', 'input', function(ev) {
    el('btn_color_status').style.backgroundColor =  this.value;
    $("#status_color").val(this.value);
  });

  $("body").delegate('.color_status_tag .color-select .color-item', 'click', function(ev) {
    el('btn_color_status').style.backgroundColor = $(this).attr("style").replace("background: ", "");
    $("#status_color").val($(this).attr("style").replace("background: ", ""));
  });

	$("body").delegate("#select_all_column_options", "click", function(){
    $("input[class='checkbox_status_table']").prop('checked', this.checked);
  })

  $("body").delegate(".checkbox_status_table", "click", function(){
    set_select_all_option(".tbl_status", "checkbox_status_table")
  })
});
