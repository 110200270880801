$(document).on('turbo:load', function() {
	formatDatatableUserShift();

	$("body").delegate(".select-shift", "change", function(){
		if( $(this).val() != "") {
			$.ajax ({
				type: 'PATCH',
				url: `/user_shifts/${$(this).parent().data("id")}`,
				dataType: 'script',
				data: {
					shift_id: $(this).val()
				},
				success: function(data, textStatus, jqXHR){
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error: #{textStatus}")
				}
			});
		}
  })

	if($(".tbl_user_shift").length > 0){
		$("body").delegate('.dt-checkboxes-select-all input', 'change', function (ev) {
			display_change_shift_tbn(ev.currentTarget.checked)
		})

		$("body").delegate("td.dt-checkboxes-cell", "click", function(){
			display_change_shift_tbn($(".tbl_user_shift tbody").find("input.dt-checkboxes:checked").length > 0)
		})

		$("body").delegate("input.dt-checkboxes", "change", function(){
			display_change_shift_tbn($(".tbl_user_shift tbody").find("input.dt-checkboxes:checked").length > 0)
		})
	}
});

$(document).ready(function(){
	$("body").delegate("#btn_save_change_shift", "click", function(){
		let shift_id = $(".change-shift-for-user").val();
		let arr_shift_user = [];

		$.each($('.tbl_user_shift tbody tr'), function (i, row) {
			if ($(row).find("input.dt-checkboxes:checked").is(":checked")) {
				arr_shift_user.push($(row).data('user-id'));
			}
		});

		if (arr_shift_user.length > 0) {
			$("#modal_user_shift").find(".user_count").text(arr_shift_user.length)
			window.$("#modal_user_shift").modal("show");
		}
  })

	$("body").delegate("#btn_accept_change_shift", "click", function(){
		let shift_id = $(".change-shift-for-user").val();
		let arr_shift_user = [];

		$.each($('.tbl_user_shift tbody tr'), function (i, row) {
			if ($(row).find("input.dt-checkboxes:checked").is(":checked")) {
				arr_shift_user.push($(row).data('user-id'));
			}
		});

		if (arr_shift_user.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "/user_shifts/change_shift",
				dataType: 'script',
				data: {
					ids: arr_shift_user,
					shift_id: shift_id,
				},
				success: function(data, textStatus, jqXHR){
					//
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}
  })
})

function display_change_shift_tbn(condition) {
	if(condition){
		$("#btn_save_change_shift").attr("disabled", false)
	}else {
		$("#btn_save_change_shift").attr("disabled", true)
	}
}

window.formatDatatableUserShift = function() {
	var tblUserTimeWork = document.getElementsByClassName("tbl_user_shift");
	if (tblUserTimeWork.length > 0) {
    window.$('.tbl_user_shift').DataTable({
      'destroy': true,
      'responsive': false,
      'scrollX': true,
      'columnDefs': [
        {
					'targets': 0,
					'width': "4%",
					'checkboxes': {
						'selectRow': false,
					}
				}
			],
      "ordering": true,
      drawCallback: function () {
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      },
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>"
        }
      },
    });
  };
};
