import { values } from "pdf-lib";

$(document).on('turbo:load', function() {
	$('.button-menu-mobile').click(function() {       
	    var attr_leftbar_compact_mode = $("body").attr("data-leftbar-compact-mode")
	    if (attr_leftbar_compact_mode == 'condensed') {
	    	$("body").attr("data-leftbar-compact-mode", "");
	    	$("body").addClass("hide-menu")
	    } 
	    else {
	    	$("body").attr("data-leftbar-compact-mode", "condensed");
	    	$("body").addClass("hide-menu")
	    }
	});	
	$("body").delegate("#allow-link-pdf-edit .form-check-input", "input", function (e){
		console.log(this.checked)
		var objectDataSubmit = {name: this.id,value: this.checked ? 1 : 0,module:'PDF-EDIT'};
		AjaxSubmit( "/setting/save_config",'POST',objectDataSubmit);  
	});
	
});

