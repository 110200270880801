$(document).on('turbo:load', function() {
    $('#iconDateTo').click(function(){
        $("#endDate").datepicker().focus();
    });

    $('#iconDateFrom').click(function(){
        $("#beginDate").datepicker().focus();
    });
    //check condition set color column finish equal 'missing'
    $(".tbl_log_admin td:nth-child(6)").each(function() {
        if ($(this).text() === "Missing") {
            $(this).parent().addClass("bg-danger text-white");
    	}
    });
    //datetimepicker input
    var date = new Date(), y = date.getFullYear(), m = date.getMonth();
    var firstDay = new Date(y, m, 1);
    var lastDay = new Date(y, m + 1, 0);
    var dayofmonthStart = formatDate(firstDay);
    var dayofmonthEnd = formatDate(lastDay);
    $('.txtStart').val(dayofmonthStart);
    $('.txtEnd').val(dayofmonthEnd);
    // $('#beginDate').add('#endDate').datepicker().focus(function () {
    //     $(".ui-datepicker-next").hide();
    //     $(".ui-datepicker-prev").hide();
    // });
    $("#beginDate").datepicker({
        dateFormat: 'yy/mm/dd',
    	showMonthAfterYear: true,
		yearSuffix: '年',
		dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
		monthNames: ['1月','２月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
        onSelect: function(selected) {
            $("#endDate").datepicker("option","minDate", selected)
        }
    });
    $("#endDate").datepicker({
        dateFormat: 'yy/mm/dd',
    	showMonthAfterYear: true,
		yearSuffix: '年',
		dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
		monthNames: ['1月','２月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
        onSelect: function(selected) {
            $("#beginDate").datepicker("option","maxDate", selected)
        }
    });
    // click button increment month
    var seletedDate = moment(new Date());
    var d = new Date(seletedDate);
    var str = moment(d).format('yy年mm月');
    $(".text_date_admin").text(str);

    $('#nextAdmin').click(function(e) {
        //change popup datepicker
        var end = $("#endDate").datepicker('getDate');
        var newEndededDate = moment(end).add(1, 'months').format('YYYY/MM/DD');
        $("#endDate").datepicker('setDate', newEndededDate);
        $("#beginDate").datepicker("option","maxDate", newEndededDate)

        var start = $("#beginDate").datepicker('getDate');
        var newStartedDate = moment(start).add(1, 'months').format('YYYY/MM/DD');
        $("#endDate").datepicker("option","minDate", newStartedDate)
        $("#beginDate").datepicker('setDate', newStartedDate);

        var month = moment(end).add(1, 'months').format('MM');
        var year = moment(end).format('YYYY');
        // Update selected date
        
        var text_date = moment(end).add(1, 'months').format('YYYY年MM月')
        $(".text_date_admin").text(text_date);
        // $.ajax
        // ({
        //     type: 'GET',
        //     url: "log_next_month_Admin",
        //     dataType: 'script',
        //     data: { month: month,
        //             year: year
        //      },
        //     success: function(data, textStatus, jqXHR){
        //         // console.log(data)
        //         // 
        //     },
        //     error:function(jqXHR, textStatus, errorThrown){
        //         // console.log("AJAX Error: #{textStatus}")
        //     }
        // })
        $("#search").trigger("click");
    });
    // click button decrease month
    $('#preAdmin').click(function(e) {
        var start = $("#beginDate").datepicker('getDate');
        var newStartedDate = moment(start).add(-1,"M").format('YYYY/MM/DD');
        $("#endDate").datepicker("option","minDate", newStartedDate)
        $("#beginDate").datepicker('setDate', newStartedDate);

        var end = $("#endDate").datepicker('getDate');
        var newEndededDate = moment(end).add(-1,"M").format('YYYY/MM/DD');
        
        $("#endDate").datepicker('setDate', newEndededDate);
        $("#beginDate").datepicker("option","maxDate", newEndededDate)

        var month = moment(end).add(-1,"M").format('MM');
        var year = moment(end).format('YYYY');
        // Update selected date
        
        var text_date = moment(end).add(-1,"M").format('YYYY年MM月')
        $(".text_date_admin").text(text_date);
        // $.ajax
        // ({
        //     type: 'GET',
        //     url: "log_next_month_Admin",
        //     dataType: 'script',
        //     data: { month: month,
        //             year: year
        //      },
        //     success: function(data, textStatus, jqXHR){
        //         // console.log(data)
        //         // 
        //     },
        //     error:function(jqXHR, textStatus, errorThrown){
        //         // console.log("AJAX Error: #{textStatus}")
        //     }
        // })
        $("#search").trigger("click");
    });
    // click button now month
    $("#nowAdmin").click(function(e){
        var currStartDate = $("#beginDate").datepicker('getDate');
        dayStart= currStartDate.getDate();
        var dateNow = new Date();
        y = dateNow.getFullYear();
        m = dateNow.getMonth();
        var currFinishDate = $("#endDate").datepicker('getDate');
        dayFinish = currFinishDate.getDate();
        var fromDayOfNowMonth = new Date(y, m, dayStart);
        var newStartedDate = moment(fromDayOfNowMonth).format('YYYY/MM/DD');
        var toDayOfNowMonth = new Date(y, m, dayFinish);
        var newEndededDate = moment(toDayOfNowMonth).format('YYYY/MM/DD');
        if(currStartDate > dateNow)
        {
            $("#beginDate").datepicker('setDate', newStartedDate);
            $("#endDate").datepicker("option","minDate", newStartedDate)

            $("#beginDate").datepicker("option","maxDate", newEndededDate)
            $("#endDate").datepicker('setDate', newEndededDate);
        }
        else
        {
            $("#beginDate").datepicker("option","maxDate", newEndededDate)
            $("#endDate").datepicker('setDate', newEndededDate);

            $("#endDate").datepicker("option","minDate", newStartedDate)
            $("#beginDate").datepicker('setDate', newStartedDate);
        }
        var seletedDate = moment(new Date());
        var d = new Date(seletedDate);
        var str = $.datepicker.formatDate('yy年mm月', d);
        $(".text_date_admin").text(str);
        // $.ajax
        // ({
        //     type: 'GET',
        //     url: "log_admin",
        //     dataType: 'script',
        //     data: { month: d.getUTCMonth() + 1,
        //             year: d.getUTCFullYear(),
        //      },
        //     success: function(data, textStatus, jqXHR){
        //         // console.log(data)
        //         // 
        //     },
        //     error:function(jqXHR, textStatus, errorThrown){
        //         // console.log("AJAX Error: #{textStatus}")
        //     }
        // })
        $("#search").trigger("click");
    });
    
    $("#btn_log_admin_download_csv").click(function(e){
        var date = $(".text_date_admin").text()
        var regex = /\d+/g;;
        var number_date = date.match(regex);
        var year = number_date[0];
        var month = number_date[1];
        var formParent = this.parentElement;
        formParent.action="/download_file_csv?number_date=" +  number_date;
    });    
    
    //show day off
    // var month = d.getUTCMonth() + 1
    // var year = d.getUTCFullYear()
    // var dayofmonth = daysInMonth(month,year);
    // var arr = $('.tbl_log_admin tr').find('td:first').map(function(){
    //     return Number($(this).text())
    // }).get()
    // var day_array = new Array(dayofmonth).fill(1).map( (_, i) => i+1 )
    // var diff_day = day_array.filter(x => arr.indexOf(x) === -1);
    // for(let i=0;i<diff_day.length;i++){
    //     var row = "<tr><td class='day'>" + checkTime(diff_day[i])+"</td>"+
    //                    "<td>" + "--" + "</td>"+
    //                    "<td>"+ "--" +"</td>"+
    //                    "<td>"+ "--" +"</td>"+
    //                    "<td>"+ "--" +"</td>"+
    //                    "<td>"+ "--" +"</td>"+
    //                    "<td class='table-action'>" +
    //                             "<a href='javascript:void(0);' class='action-icon'>" + 
    //                                 "<i class='dripicons-clock text-success'>" + "</i>" + 
    //                             "</a>" +
    //                     "</td>" +
    //                    "<td>" + "--" +"</td>"+
    //                 "</tr>"
    // $(".tbl_log_admin tbody").append(row);
    // }
    //check condition set color today
    $(".tbl_log_admin tr").find('td:first').each(function() {
        if (Number($(this).text()) === d.getUTCDate()) {
            $(this).parent().addClass("bg-primary text-white");
    	}
    });
    $(".tbl_log_admin tbody tr").map(function() {
        if($(this).find("td:first-child").text().indexOf("土")>-1 || $(this).find("td:first-child").text().indexOf("日")>-1)
        {
            if($(this).find("td:nth-child(6)").text().indexOf("Missing")>-1)
            {
                $(this).find("td:first-child").addClass("text-warning")
            }
            else{
                $(this).find("td:first-child").addClass("text-danger")
            }
        }    
    });
    var tbl_log_admin = $('.tbl_log_admin');
    try{
        if (tbl_log_admin.length>0)
    { 
        window.$('.tbl_log_admin').DataTable({
            "searching": false,
            "bPaginate": false,
            "scrollX": false,
            "autoWidth" : false,
            "stateSave": true,
            "pagingType": "simple_numbers",
            columnDefs: [
                       { targets: 0,
                          width: '7%',
                          render: function ( data, type, row ) {
                             return type === 'display' && data.length > 10 ?
                                 data.substr( 0, 10 ) +'…' :
                                 data;
                         }
                       },
                       { targets: 1,
                          width: '7%',
                          render: function ( data, type, row ) {
                             return type === 'display' && data.length > 10 ?
                                 data.substr( 0, 10 ) +'…' :
                                 data;
                         }
                       },
                       { targets: 2,
                          width: '10%',
                          render: function ( data, type, row ) {
                             return type === 'display' && data.length > 10 ?
                                 data.substr( 0, 10 ) +'…' :
                                 data;
                         }
                       },
                       { targets: 3,
                          width: '15%',
                          render: function ( data, type, row ) {
                             return type === 'display' && data.length > 10 ?
                                 data.substr( 0, 10 ) +'…' :
                                 data;
                         }
                       },
                       { targets: 4,
                          width: '10%',
                          render: function ( data, type, row ) {
                             return type === 'display' && data.length > 10 ?
                                 data.substr( 0, 10 ) +'…' :
                                 data;
                         }
                       },
                       { targets: 5,
                          width: '6%',
                          render: function ( data, type, row ) {
                             return type === 'display' && data.length > 10 ?
                                 data.substr( 0, 10 ) +'…' :
                                 data;
                         }
                       },
                       { targets: 6,
                          width: '10%',
                       },
                       { targets: 7,
                          width: '20%',
                          render: function ( data, type, row ) {
                             return type === 'display' && data.length > 10 ?
                                 data.substr( 0, 20 ) +'…' :
                                 data;
                         }
                       },
                       { targets: 8,
                          width: '20%',
                          render: function ( data, type, row ) {
                             return type === 'display' && data.length > 10 ?
                                 data.substr( 0, 7 ) +'…' :
                                 data;
                         }
                       }
               ],
               destroy:true
             });
    }
    }
    catch{window.location.reload();}
    
    
});

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('/');
}
function daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
}

function checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
}



