import { ANNOTATION,link_enum } from '../edit/annotation';
import {Config} from '../define/AppConfig';
import {PdfHistory,TYPE_HISTORY,TYPE_OBJECT} from '../edit/undo_redo';
import { PDFSaveAnnotation } from './save_annotation';
import { scale } from 'pdf-lib';
import { stamp_helper } from '../common/stamp_helper';
import { Convert } from '../../../packs/convert';
export const toolBarHelper = {    
  hideAllBarRibbon(){
    hideAllBarRibbon();
  },
    setdisplay(annotation){
      hideAllBarRibbon(); 
        switch(annotation){
          case ANNOTATION.NONE:
            var btnSelectPoint = document.getElementById('btnSelectPoint');
            if(btnSelectPoint){
              btnSelectPoint.click();
            }
            break;
          case ANNOTATION.SELECT_OBJECT:       
            $('.annotation-buttons .annotation-popup a').removeClass("active");
            break;
          case ANNOTATION.ADD_TEXT:       
            var parent = document.querySelector('.text-properties');
            parent.style = "display: flex !important"; 
            set_color(parent,Config.text.addtext.fill);
            set_font(parent, Config.text.addtext.fontFamily);
            set_font_size(parent, Config.text.addtext.fontSize);
            set_font_weight(parent,parseInt( Config.text.addtext.boldText),parseInt(Config.text.addtext.italicText));
            break;
          case ANNOTATION.TEXT_HIGHLIGHT:
            var parent = document.querySelector('.text-highlight-properties');
            parent.style = "display: flex !important"; 
            set_color(parent,Config.text.highlight.color);
            break;
          case ANNOTATION.TEXT_STROKEOUT:
            var parent = document.querySelector('.text-strokeout-properties');
            parent.style = "display: flex !important"; 
            set_color(parent,Config.text.strokeout.color);
            break;
          case ANNOTATION.TEXT_UNDERLINE:
            var parent = document.querySelector('.text-underline-properties');
            parent.style = "display: flex !important"; 
            set_color(parent,Config.text.underline.color);
            break;
          case ANNOTATION.ADD_XMARK:
          case ANNOTATION.ADD_CHECKMARK:
          case ANNOTATION.ADD_DOT:
            var parent = document.querySelector('.form-xmark-properties');
            parent.style = "display: flex !important"; 
            var width,height,opacity,color;
            if(annotation == ANNOTATION.ADD_XMARK){
              width = Config.form.xmark.width;
              height = Config.form.xmark.height;
              opacity = Config.form.xmark.opacity;
              color = Config.form.xmark.color;
            }
            if(annotation == ANNOTATION.ADD_CHECKMARK){
              width = Config.form.checkmark.width;
              height = Config.form.checkmark.height;
              opacity = Config.form.checkmark.opacity;
              color = Config.form.checkmark.color;
            }
            if(annotation == ANNOTATION.ADD_DOT){
              width = Config.form.dot.width;
              height = Config.form.dot.height;
              opacity = Config.form.dot.opacity;
              color = Config.form.dot.color;
            }            
            set_width(parent, width);
            set_height(parent, height);
            set_opacity(parent, opacity);
            set_color(parent,color);
            break;  
          case ANNOTATION.ADD_TEXTBOX:
          case ANNOTATION.ADD_TEXT_AREA:
          case ANNOTATION.ADD_RADIO:  
          case ANNOTATION.ADD_CHECKBOX: 
          case ANNOTATION.ADD_DROPDOWN:  
              var width,height,color,borderWidth,borderColor;
              var parent = document.querySelector('.form-textfield-properties');
              if(annotation == ANNOTATION.ADD_TEXTBOX){
                width = Config.form.textfield.width;
                height = Config.form.textfield.height;
                color = Config.form.textfield.color;
                borderWidth = Config.form.textfield.borderWidth;
                borderColor = Config.form.textfield.borderColor;
              }
              if(annotation == ANNOTATION.ADD_TEXT_AREA){
                width = Config.form.textarea.width;
                height = Config.form.textarea.height;
                color = Config.form.textarea.color;
                borderWidth = Config.form.textarea.borderWidth;
                borderColor = Config.form.textarea.borderColor;
              }
              if(annotation == ANNOTATION.ADD_RADIO){
                width = Config.form.radio.width;
                height = Config.form.radio.height;
                color = Config.form.radio.color;
                borderWidth = Config.form.radio.borderWidth;
                borderColor = Config.form.radio.borderColor;
              }
              if(annotation == ANNOTATION.ADD_CHECKBOX){
                width = Config.form.checkbox.width;
                height = Config.form.checkbox.height;
                color = Config.form.checkbox.color;
                borderWidth = Config.form.checkbox.borderWidth;
                borderColor = Config.form.checkbox.borderColor;
              }
              if(annotation == ANNOTATION.ADD_DROPDOWN){
                width = Config.form.dropdown.width;
                height = Config.form.dropdown.height;
                color = Config.form.dropdown.color;
                borderWidth = Config.form.dropdown.borderWidth;
                borderColor = Config.form.dropdown.borderColor;
              }
              parent.style = "display: flex !important";       
              set_width(parent, width);
              set_height(parent, height);
              set_thickness(parent, borderWidth);
              set_color(parent,borderColor);
              set_color(parent,color, false );                              
              break;  
            case ANNOTATION.DRAW_LINE:
              var parent = document.querySelector('.shape-line-properties');
              parent.style = "display: flex !important";     
              var setting = Config.shape.line;
              set_thickness(parent,setting.strokeWidth);
              set_dash(parent,setting.dash);
              set_color(parent, setting.backGroundColor);
              set_opacity(parent, setting.opacity)
              break;
            case ANNOTATION.DRAW_ELLIPSE:
              var parent = document.querySelector('.shape-ellipse-properties');
              parent.style = "display: flex !important";     
              var setting = Config.shape.ellipse;
              set_thickness(parent,setting.strokeWidth);
              set_dash(parent,setting.dash);
              set_color(parent, setting.stroke);
              set_opacity(parent, setting.opacity)
              break;
            case ANNOTATION.DRAW_RECTANGLE:
              var parent = document.querySelector('.shape-rectangle-properties');
              parent.style = "display: flex !important";     
              var setting = Config.shape.rectangle;
              set_thickness(parent,setting.strokeWidth);
              set_dash(parent,setting.dash);
              set_color(parent, setting.stroke);
              set_opacity(parent, setting.opacity)
              break;
            case ANNOTATION.PENCIL:
              var parent = document.querySelector('.pencil-properties');
              parent.style = "display: flex !important";     
              var setting = Config.pencil;
              set_thickness(parent,setting.strokeWidth);
              set_dash(parent,setting.dash);
              set_color(parent, setting.color);
              break;
            case ANNOTATION.FREE_DRAW:
              var parent = document.querySelector('.freedraw-properties');
              parent.style = "display: flex !important";     
              var setting = Config.freedraw;
              set_thickness(parent,setting.strokeWidth);
              set_dash(parent,setting.dash);
              set_color(parent, setting.color);
              break;     
            case ANNOTATION.DRAW_ARROW:        
              var parent = document.querySelector('.shape-arrow-properties');
              parent.style = "display: flex !important"; 
              var setting = Config.shape.arrow;
              set_thickness(parent,setting.size);
              set_arrow_style(parent,setting.number_of_head);
              set_dash(parent,setting.dash);
              set_color(parent, setting.color);
              break;   
              
        }
    }, 
    showtoolObject(object, viewer){  
      var tool = null;
      
      switch(object.type)
      {       
        case 'i-text':
          if(object.name == "date"){
            tool = el('stamp-date-editable-menu') ;  
            set_color(tool,object.fill);
            update_display_tool_date(object,tool,viewer.currentScaleValue);
          }
          else{
            tool = el('text-editable-menu') ;         
            set_color(tool,object.fill);
            set_font(tool, object.fontFamily);
            set_font_size(tool,object.originFontSize);          
            set_font_weight(tool,object.fontWeight == 'bold' ? 1 : 0,object.fontStyle == 'italic' ? 1 : 0);
          }
          
          break;
        
        case 'image':    
          if(object.annotation == ANNOTATION.ADD_STAMP){
            tool = el('stamp-editable-menu') ;  
            show_modal_setting_stamp(tool,object);       
            // set_opacity(tool,object.opacity);
          } 
          else{
            tool = el('image-editable-menu') ;     
            set_opacity(tool,object.opacity);
          }
         
          break;
       case 'path':  
          tool = el('free-draw-editable-menu') ;
          set_dash(tool, object.origin_strokeDashArray);
          set_thickness(tool,  object.origin_strokeWidth);
          set_color(tool, object.stroke);         
          break;           
       case 'line':   
          if(object.annotation == ANNOTATION.DRAW_LINE){
            tool = el('line-editable-menu') ;
            if(object.element == 1 || object.element == 2){
              object = object.line;
            }
            set_dash(tool, object.origin_strokeDashArray);
            set_thickness(tool,object.origin_strokeWidth);
            set_color(tool, object.stroke); 
            set_opacity(tool,object.opacity); 
          }
          if(object.annotation == ANNOTATION.DRAW_ARROW){
            tool = el('arrow-editable-menu') ;       
            if(object.element == 1 || object.element == 2){
              object = object.line;
            }
            set_dash(tool, object.origin_strokeDashArray);
            set_thickness(tool,  object.origin_strokeWidth);
            set_arrow_style(tool,object.number_of_head);
            set_color(tool, object.stroke); 
            set_opacity(tool,object.opacity);  
          }
                  
          break;     
       case 'rect':    
       case 'ellipse':   
            if(object.annotation == ANNOTATION.ADD_LINK){
              tool = el('link-editable-menu') ;  
           var link = object.link_type;
           switch(link.link_type){
            case link_enum.url:
              setCheckedRadio("rd-url",true);
              break;
            case link_enum.mail:
              setCheckedRadio("rd-mail",true);
              break;
            case link_enum.phone:
              setCheckedRadio("rd-phone",true);
              break;
            case link_enum.page:
              setCheckedRadio("rd-page",true);
              break;
          }
          setValueTextBox("targetURL",link.url);
          setValueTextBox("targetEmailAddress",link.email);
          setValueTextBox("targetTel",link.phone);
          setValueTextBox("targetPage",link.page);
            }
            else{
              tool = el('rectangle-editable-menu') ;                    
              set_dash(tool, object.origin_strokeDashArray);
              set_thickness(tool,  object.origin_strokeWidth);
              set_color(tool, object.stroke); 
              set_opacity(tool,object.opacity);  
            }
              
           break;  
       case ANNOTATION.ADD_TEXTBOX:
           tool = el('textbox-editable-menu') ;      
           break;
       case ANNOTATION.ADD_TEXT_AREA:
           tool = el('textbox-editable-menu') ; 
           break;
       case ANNOTATION.ADD_RADIO:           
       case ANNOTATION.ADD_CHECKBOX:
          tool = el('radio-editable-menu') ; 
          break;;
       case ANNOTATION.ADD_DROPDOWN:
            tool = el('select-editable-menu') ; 
            break;
       case ANNOTATION.ERASE:
         break;
        
      }  
      hideToolsPeroperties(tool);
      if(tool){
        if(object.annotation == ANNOTATION.ADD_STAMP || (object.annotation == ANNOTATION.ADD_TEXT && object.name == "date")){
          tool.style.display = 'inline';
        }
        else{
          tool.style.display = 'flex';
        }

        if(tool.getAttribute("moved") == true || tool.getAttribute("moved") === "true"){
          // keep
        }
        else{
          var page = object.canvas.getSelectionElement();
          var rect = page.getBoundingClientRect();    
          tool.style.left = rect.left + getMinLeft(object) - 70 + "px";
          tool.style.top = rect.top + getMaxBottom(object) + 4 + "px";
        }
       
      }
    }   ,
    hideToolProperties(){
      hideToolsPeroperties();
    },
    showtoolElement(data,viewer ){
      var tool,obj = data.source;
      
      switch(data.type){
        case "textbox":
        case "textarea":       
        case "radio":
        case "checkbox":       
          tool = el('textbox-editable-menu');
          // set_color(tool, window.getComputedStyle(obj).borderColor,true);
          // set_thickness(tool,parseInt(window.getComputedStyle(obj).borderWidth))
          // set_color(tool, Convert.rgbToHex(window.getComputedStyle(obj).color),false);
          break;  
        case "dropdown":
          tool = el('select-editable-menu');
          document.getElementById("select_fieldName").value = obj.getAttribute("name");
          var ops =  obj.querySelectorAll("option");
          var opString = ""
          if(ops){
            for (let index = 0; index < ops.length; index++) {
              const element = ops[index];
              if(index == 0)
                opString += element.text
              else 
                opString = opString +  "\n" + element.text      

            }
            
          }
          document.getElementById("fieldOptions").value = opString
          break;
      }
      hideToolsPeroperties(tool);
      tool.style.display = 'flex';
      var currentPageDiv = document.getElementById("page-"+ viewer.currentPageNumber);
      var formLayer = currentPageDiv.querySelector(".formLayer");
      var rect = formLayer.getBoundingClientRect();
      var parent = obj.closest('.ff-editable-inner');
      
      
      tool.style.left = rect.left + parseInt(parent.style.left ) - 70 + "px";
      tool.style.top = rect.top + parseInt( parent.style.top ) + parent.offsetHeight +   "px";

    },
    updateStatusUndoRedo(viewer){
      var statusUndo = false,statusRedo = false;
      if(viewer.undoRedoHandler._undoStack.length > 0){
        statusUndo = true;
      }
      if(viewer.undoRedoHandler._redoStack.length > 0){
        statusRedo = true;
      }
      var btnUndo = viewer.viewComponents.edittoolbar.undo;
      var btnRedo = viewer.viewComponents.edittoolbar.redo;
      if(btnRedo && btnRedo){
        btnUndo.setAttribute('can_click',statusUndo);
        btnRedo.setAttribute('can_click',statusRedo);
      }     
    },
    updateItemStack(historyStacks){
      var list = document.querySelector('.list-annotation');
      if(list){
        list.replaceChildren();
        for (let index = 0; index < historyStacks.length; index++) {
          const element = historyStacks[index];
          this.addItemStack(element);
          
        }
      }     
    },
    addItemStack(historyStack){
      var list = document.querySelector('.list-annotation');
      if(list){
        var item = document.createElement("div");  
        item.setAttribute("class", "item-annotation");  
        item.setAttribute("page-num", historyStack.pageNumber);
        if(historyStack.canvasObject){
          item.setAttribute("id", historyStack.canvasObject.id);
          item.setAttribute("type", 'canvas');
          
        }
        else{
          item.setAttribute("id", historyStack.htmlObject.getAttribute('id-object'));
          item.setAttribute("type", 'html');
        }
          
        list.appendChild(item);

        var row = document.createElement("div");  
        row.setAttribute("class", "ms-1");  
        item.appendChild(row);

        var colPage = document.createElement("div");  
        colPage.setAttribute("class", "col text-start p-0");  
        colPage.innerHTML = "Page " + historyStack.pageNumber;
        row.appendChild(colPage);

        var colAction = document.createElement("div");  
        colAction.setAttribute("class", "col ms-1 text-start p-0 fw-bold");  
        var labelAction = 'Add ';
        if(historyStack.typeHistory == TYPE_HISTORY.DELETE){
          labelAction = "Delete " ;
        }
        
        if(historyStack.canvasObject){
          colAction.innerHTML = labelAction + historyStack.canvasObject.name;
        }
        else
        {
          colAction.innerHTML = labelAction + historyStack.htmlObject.getAttribute('name');
        }
        
        item.appendChild(colAction);

        var row2 = document.createElement("div");  
        row2.setAttribute("class", "text-end");  
        item.appendChild(row2);

        var buttonDellete = document.createElement("button");  
        buttonDellete.setAttribute("class", "btn button-delete-annotation");  
        buttonDellete.setAttribute("type", "button");  
        
        var iDellete = document.createElement("i");  
        iDellete.setAttribute("class", "mdi mdi-window-close");  
        buttonDellete.appendChild(iDellete);

        row2.appendChild(buttonDellete);

    }
      // <button type="button" class="btn btn-danger"><i class="mdi mdi-window-close"></i> </button>
      //<input type="hidden" name="id" id="id" value="117" autocomplete="off">
    },
    removeItemStack(historyStack){
      var list = document.querySelector('.list-annotation');
      if(list){
        var item;
        if(historyStack.canvasObject){
          item = list.querySelector(`[id='${historyStack.canvasObject.id}']`);
        }
        else{
          item = list.querySelector(`[id='${historyStack.htmlObject.getAttribute("id-object")}']`);
        }
       if(item){
        item.remove();
       }
      }
      
    },
    activeItem(historyStack){
      var active = 'active';
      $('.list-annotation .item-annotation').removeClass(active);
      var list = document.querySelector('.list-annotation');
      var item;
      if(historyStack.canvasObject){
        item = list.querySelector(`[id='${historyStack.canvasObject.id}']`);
      }
      else{
        item = list.querySelector(`[id='${historyStack.htmlObject.getAttribute("id-object")}']`);
      }
      if(item){
        item.classList.add(active);
        item.scrollIntoViewIfNeeded();
      }
      
    },
    clearListannotations(){
      var list = document.querySelector('.list-annotation');
      if(list){
        list.replaceChildren();
      }
      
    },
    setHiddenButton(){
      var currentUrl = window.location.pathname;
      if (currentUrl.includes(`/pdf/`) && currentUrl.includes(`/edit`)) { 
          var containerButton = document.querySelector('.top-page');
          var containerWidth = containerButton.offsetWidth;
          var right = document.querySelector('.top-col-right');
          var button_right_show = right.querySelectorAll(`.edit-button`);
          var right_width = 0;
          for (let index = 0; index < button_right_show.length; index++) {
            const element = button_right_show[index];
            right_width += element.offsetWidth;
          }
          right.style.width = right_width + "px";
  
          var containnerleftButton =  document.getElementById('sortable');
          if(containnerleftButton){
            var listButton = containnerleftButton.querySelectorAll(`.edit-button[display="${true}"`);
            if(listButton && listButton.length > 0)
            {
              var width = listButton[0].offsetWidth;
              // var maxButton = (containerWidth - rightWidth - 56 * 4 - 25*2 )/width - 2; Comment undo
              var maxButton = (containerWidth - right_width - 56 * 5 - 25*2 )/width - 2;
              var exist = false;
              for (let index = 0; index < listButton.length; index++) 
              {
                  const element = listButton[index];
                  if(index < maxButton){
                      element.setAttribute('show-response',true); 
                      document.getElementById(element.id + '2').setAttribute('show-response',false); 
                  }
                  else
                  {
                      element.setAttribute('show-response',false);  
                      exist = true; 
                      document.getElementById(element.id + '2').setAttribute('show-response',true);    
                  }                
              }
  
              var dropdownSecond = document.querySelector('.secondary-button-list');
              if(dropdownSecond){
                if(exist){
                  el("btnShowMore").style.display = "block";
                               
                }
                else
                {
                  el("btnShowMore").style.display = "none"; 
                }
    
              }
            }         
          }
           
      }
      
  },
  updateMenuButton(annotation){
    updateColor(annotation);
  }
  
}
function updateColor(annotation){
  var svg,color;
  svg = document.querySelector('.edit-button[selected=true]').querySelector('svg');
    switch(annotation){
    case ANNOTATION.ADD_XMARK:
      color = Config.form.xmark.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.ADD_CHECKMARK:
      color = Config.form.checkmark.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.ADD_DOT:
      color = Config.form.dot.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.DRAW_ARROW:
      color = Config.shape.arrow.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.FREE_DRAW:
      color = Config.freedraw.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.PENCIL:
      color = Config.pencil.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.DRAW_LINE:
      color = Config.shape.line.backGroundColor;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.DRAW_RECTANGLE:
      color = Config.shape.rectangle.stroke;
      svg = document.querySelector('.edit-button[selected=true]').querySelector('svg path');
      svg.setAttribute('stroke', color);
      break;
    case ANNOTATION.ADD_TEXT:
      color = Config.text.addtext.fill;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.DRAW_ELLIPSE:
      color = Config.shape.ellipse.stroke;
      svg = document.querySelector('.edit-button[selected=true]').querySelector('svg path');
      svg.setAttribute('stroke', color);
      break;
    case ANNOTATION.TEXT_HIGHLIGHT:
      color = Config.text.highlight.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.TEXT_STROKEOUT:
      color = Config.text.strokeout.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.TEXT_UNDERLINE:
      color = Config.text.underline.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.ADD_TEXTBOX:
      color = Config.form.textfield.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.ADD_TEXT_AREA:
      color = Config.form.textarea.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.ADD_CHECKBOX:
      color = Config.form.checkbox.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.ADD_RADIO:
      color = Config.form.radio.color;
      svg.setAttribute('fill', color);
      break;
    case ANNOTATION.ADD_DROPDOWN:
      color = Config.form.dropdown.color;
      svg.setAttribute('fill', color);
      break;
  }
  
}
function getMinLeft(element){
  var min = element.aCoords.bl.x;
  if(min > element.aCoords.br.x){
    min = element.aCoords.br.x;
  }
  if(min > element.aCoords.tl.x){
    min = element.aCoords.tl.x;
  }
  if(min > element.aCoords.tr.x){
    min = element.aCoords.tr.x;
  }
  return min;
 }
 function getMaxBottom(element){
  var max = element.aCoords.bl.y;
  if(max < element.aCoords.br.y){
    max = element.aCoords.br.y;
  }
  if(max < element.aCoords.tl.y){
    max = element.aCoords.tl.y;
  }
  if(max < element.aCoords.tr.y){
    max = element.aCoords.tr.y;
  }
  return max;
}
function hideAllBarRibbon(){
  let children = document.querySelectorAll(".toolbars .toolbarItem");
  children.forEach(child => {
    child.style = "display: none !important";
  })  
}
function hideToolsPeroperties(tool = null){
  if(tool){
    $(".toolbars .dropdown-toolbar").not(tool).css("display","none"); 
  }
  else
  {
    $(".toolbars .dropdown-toolbar").css("display","none"); 
  }
  
}


function colorIsRGBA(color){
  if (!color || color.indexOf("rgba") < 0) {
    return false;
  }
  return true;
}

function show_tool_textbox(element,viewer){
  $("#textbox-editable-menu").css("display","block");
  var currentPageDiv = document.getElementById("page-"+ viewer.currentPageNumber);
  var formLayer = currentPageDiv.querySelector(".formLayer");
  var rect = formLayer.getBoundingClientRect();

  $("#textbox-editable-menu").css("left",rect.left + element.parentElement.offsetLeft + "px");
  $("#textbox-editable-menu").css("top",rect.top + element.parentElement.offsetTop + element.parentElement.offsetHeight + 20 + "px");
  setDisplayTooltextbox(element);
 }
 function hide_tool_textbox(){
  $("#textbox-editable-menu").css("display","none"); 
 }
 function setDisplayTooltextbox(obj){
  document.getElementById("textbox-border-color").value = Convert.rgbToHex( window.getComputedStyle(obj).borderColor);
  document.getElementById("textbox-border-width").value = parseInt(window.getComputedStyle(obj).borderWidth);
  document.getElementById("textbox-color").value = Convert.rgbToHex(window.getComputedStyle(obj).color);
}

function show_tool_radio(element,viewer){
  $("#radio-editable-menu").css("display","block");
  var currentPageDiv = document.getElementById("page-"+ viewer.currentPageNumber);
  var formLayer = currentPageDiv.querySelector(".formLayer");
  var rect = formLayer.getBoundingClientRect();

  $("#radio-editable-menu").css("left",rect.left + element.parentElement.offsetLeft + "px");
  $("#radio-editable-menu").css("top",rect.top + element.parentElement.offsetTop + element.parentElement.offsetHeight + 20 + "px");
  setDisplayToolRadio(element);
 }
 function hide_tool_radio(){
  $("#radio-editable-menu").css("display","none"); 
 }
 function setDisplayToolRadio(obj){
  document.getElementById("radio-border-color").value = Convert.rgbToHex( window.getComputedStyle(obj).borderColor);
  document.getElementById("radio-border-width").value = parseInt(window.getComputedStyle(obj).borderWidth);

  var backgroundColor = getPropertyBeforeElement(obj,'background-color') ;

  if(colorIsRGBA(backgroundColor)){
    var rgb = Convert.rGBAToRGB(backgroundColor);
    var color = 'rgb(' + rgb.r + ',' + rgb.g + ',' +  rgb.b +')';
    document.getElementById("radio-color").value = Convert.rgbToHex(color);
  }
  else{
    document.getElementById("radio-color").value = Convert.rgbToHex(backgroundColor);
  }
}

function show_tool_select(element,viewer){
  $("#select-editable-menu").css("display","block");
  var currentPageDiv = document.getElementById("page-"+ viewer.currentPageNumber);
  var formLayer = currentPageDiv.querySelector(".formLayer");
  var rect = formLayer.getBoundingClientRect();

  $("#select-editable-menu").css("left",rect.left + element.parentElement.offsetLeft + "px");
  $("#select-editable-menu").css("top",rect.top + element.parentElement.offsetTop + element.parentElement.offsetHeight + 20 + "px");
  setDisplayToolSelect(element);
 }
 function hide_tool_select(){
  $("#select-editable-menu").css("display","none"); 
 }
 function setDisplayToolSelect(obj){
  document.getElementById("select-border-color").value = Convert.rgbToHex( window.getComputedStyle(obj).borderColor);
  document.getElementById("select-text-color").value = Convert.rgbToHex( window.getComputedStyle(obj).color);
  document.getElementById("textbox-border-width").value = parseInt(window.getComputedStyle(obj).borderWidth);
  document.getElementById("select_fieldName").value = obj.getAttribute("name");
  var ops =  obj.querySelectorAll("option");
  var opString = ""
  if(ops){
    for (let index = 0; index < ops.length; index++) {
      const element = ops[index];
      if(index == 0)
        opString += element.text
      else 
        opString = opString +  "\n" + element.text      

    }
    
  }
  document.getElementById("fieldOptions").value = opString

}

function setCheckedRadio(id,value){
  $(`#${id}`).prop("checked", value);   
 }
 function setValueTextBox(id,value){
  var txt = document.getElementById(id);
  txt.value = value; 
 }

 


function set_thickness(container,thick){
  if(container){
    container.querySelectorAll(`.thick_item`).forEach(child => {
      child.classList.remove('selected');
    })  
    var item_select = container.querySelector(`.thick_item[data-item="${Math.round(thick)}"]`);
    if(item_select){
      item_select.classList.add('selected');
    }
  }
}

function set_dash(container,dash){  
  if(container){
    var dash_container = container.querySelector('.dash-style');
    if(dash_container == null){
      dash_container = container;
    }
    container.querySelectorAll(`.dash_item`).forEach(child => {
      child.classList.remove('selected');
    })  
    var li_selected;
    if(dash=="" || dash == null){
      li_selected = dash_container.querySelectorAll(`li`)[0];
    }
    else
    {
      li_selected = dash_container.querySelector(`li[dash="[${dash}]"]`);
    }
    if(li_selected){
      li_selected.classList.add('selected');
      var buttonDash = dash_container.querySelector("button");
      buttonDash.querySelector('img').setAttribute('src',li_selected.querySelector('img').getAttribute('src'));
    }
  }
 
  
}

function set_arrow_style(container,arrow){  
  var arrow_style_container = container.querySelector('.dropdownArrowStyle');
  if(arrow_style_container == null){
    arrow_style_container = container;
  }
  var li = arrow_style_container.querySelectorAll('li');
  li.forEach(child => {
    child.classList.remove('selected');
  }) 

  var buttonArrow = arrow_style_container.querySelector("button");
  var li_selected = arrow_style_container.querySelector(`li[arrow="${arrow}"]`);
  if(li_selected){
    li_selected.classList.add('selected');
    buttonArrow.querySelector('img').setAttribute('src',li_selected.querySelector('img').getAttribute('src'));
  }  
}

function set_color(container, color, is_border = true){
  if(container){
    var color_container;
    color = Convert.toRGBString(color);
    color = Convert.rgbToHex(color);
    if(is_border){
      color_container = container.querySelector('.border-color');
    }
    else{
      color_container = container.querySelector('.background-color');
    }
    if(color_container == null){
      color_container = container;
    }
    var buttonColor = color_container.querySelector('.color-selecter');
    buttonColor.style.backgroundColor = color;

    var color_selections = color_container.querySelector('.color-select');
    color_selections.querySelectorAll(`button`).forEach(child => {
      child.classList.remove('selected');
    })  
    var item_select = color_selections.querySelector(`button[style="background: ${color.toUpperCase()}"]`);
    if(item_select){
      item_select.classList.add('selected');
    }
    else{
      var input_container = color_selections.querySelector(`.input-color-container`);
      input_container.classList.add('selected');
      input_container.querySelector(`input`).value = color;
    }
  }  
}

function set_opacity(container, opacity){
  if(container){
    var opacity_container = container.querySelector('.opacity');
    if(opacity_container == null){
      opacity_container = container;
    }
    if(opacity_container){
      opacity_container.querySelector('.opacity_item').value = parseInt(parseFloat(opacity) *100);
    }
  }  
}

function set_width(container, width){
  if(container){
    var opacity_container = container.querySelector('.width');
    if(opacity_container == null){
      opacity_container = container;
    }
    opacity_container.querySelector('input').value = parseInt(width);
  }
  
}
function set_height(container, height){
  if(container){
    var opacity_container = container.querySelector('.height');
    if(opacity_container == null){
      opacity_container = container;
    }
    opacity_container.querySelector('input').value = parseInt(height);
  }
 
}

function set_font(container,fontFamily){
  if(container){
    var font_container = container.querySelector('.font');
    if(font_container == null){
      font_container = container;
    }
    font_container.querySelector('.font-family').value = fontFamily;
}
}

function set_font_size(container,fontSize){
  var font_size_container = container.querySelector('.font_size');
  if(font_size_container == null){
    font_size_container = container;
  }
  font_size_container.querySelector('.font-size').value = Math.round(fontSize);
}

function set_font_weight(container, boldText, italicText){
  var boldButton = container.querySelector('.bold-text');
  var italicButton = container.querySelector('.italic-text');
  if(boldText == 1){
    boldButton.classList.add('selected');
  }
  else{
    boldButton.classList.remove('selected');
  }  
  if(italicText == 1){
    italicButton.classList.add('selected');
  }
  else{
    italicButton.classList.remove('selected');
  }  
}


function map_scale_array(num,scale) {
  return num * scale;
}

function hide_modal_setting_stamp(){
  var tool = document.getElementById('tool-stamp')  ;
  if(tool){
    tool.style.display = 'none';     
  }    
}
function show_modal_setting_stamp(tool,stamp){  
  if(tool){  
    update_display_tool_stamp(stamp,tool);
    set_position_tool(stamp,tool)
  }
}

function update_display_tool_stamp(obj,tool){
  if(tool){
    tool.querySelector('#stamp_size_setting').value = parseInt(obj.height*obj.scaleY);
    tool.querySelector('#size_text').value = parseInt(obj.height*obj.scaleY);   
    var isTrueSet = (obj.hasDate === 'true' || obj.hasDate == true);
    $(tool.querySelector('#date_stamp')).prop("checked", isTrueSet);        
  }
}

function update_display_tool_date(date,tool,scale){
  if(tool){
   create_option_format_date(tool); 
   var stamp = stamp_helper.get_stamp_by_date(date);   
   if(stamp){
    tool.querySelector('#date_setting_font').value = parseInt(date.originFontSize);
    tool.querySelector('#size_font_date').value = parseInt(date.fontSize/scale);    
    tool.querySelector('#date_format').value = stamp.formatDate;
    tool.querySelector('#date_out_put').value = date.text;     
   }
   }   
}
function set_position_tool(obj,tool){  
  if(tool){    
    tool.style.display = 'block';
    if(tool.getAttribute("moved") == true || tool.getAttribute("moved") === "true"){
      return;
    }
    var rect = obj.canvas.upperCanvasEl.getBoundingClientRect();    
    tool.style.left = rect.left + getMinLeft(obj) - 70 + "px";
    tool.style.top = rect.top + getMaxBottom(obj) - obj.height*obj.scaleY - tool.offsetHeight - 10 + "px";
  }  
}

function create_option_format_date(tool){
  var date_field = tool.querySelector('#date_format');
  if(date_field && date_field.options.length == 0){
    var i, L = date_field.options.length - 1;
    for(i = L; i >= 0; i--){
      date_field.remove(i);
    }
    var list_format = [      
      "YYYY/MM/DD",
      "YYYY/MM/DD HH:mm",
      "YYYY/MM/DD HH:mm Z",
      "MMMM, Do YYYY",
      "MMMM, Do YYYY HH:mm",
      "MMMM, Do YYYY HH:mm Z",
      "YYYY年MM月DD日",
      "YYYY年MM月DD日 HH:mm",
      "YYYY年MM月DD日 HH:mm Z",
      "DD/MM/YYYY",
      "DD/MM/YYYY HH:mm",
      "DD/MM/YYYY HH:mm Z"
    ] ;
    for (i = 0, L = list_format.length; i < L; ++i) {          
      $(date_field).append($('<option />')
        .text(moment(new Date()).format(list_format[i]))
        .val(list_format[i]))
    }        
  }
  date_field.value="YYYY/MM/DD";

}