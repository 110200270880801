import  html2canvas from 'html2canvas';
export class TakeScreenShot {
    viewer = null
    constructor(viewer) {    
        this.viewer = viewer   
        createScreenShot(document.body.clientWidth,document.body.clientHeight)
        
        this.init();
        this.mounted();
        this.start();
      } 
      init(){
        this.mouseIsDown = false,
        this.isDragging = false, 
        this.tookScreenShot = false, // After the mouse is released
        
        // Used to calculate where to start showing the dragging area
        this.startX = 0,
        this.startY= 0,
        this.endX = 0,
        this.endY = 0,
        
        this.borderWidth = "",
        
        // Handling the positioning of the crosshairs
        this.crossHairsLeft = 0,
        this.crossHairsTop = 0,
        
        // The box that contains the border and all required numbers.
        this.boxTop = 0,
        this.boxLeft = 0,
        this.boxEndWidth = 0,
        this.boxEndHeight = 0,
        
        // The tooltip's required positioning numbers.
        this.toolTipLeft = 0,
        this.toolTipTop = 0,
        this.toolTipWidth = 0,
        this.toolTipHeight = 0;
        
      }
      mounted(){
       
        this.screenshot = document.getElementById('screenshot') ;
        this.screenshot.crosshairs = document.querySelector(".crosshairs");
        this.screenshot.overlay = document.querySelector(".overlay");
        this.screenshot.box = document.querySelector(".borderedBox");
        this.screenshot.tooltip = document.querySelector(".tooltip");
        var self = this;
        
        this.screenshot.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.screenshot.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        
        this.toolTipWidth = this.screenshot.tooltip.getBoundingClientRect().width;
        
        // To recalculate the width and height if the screen size changes.
        window.onresize = function () {
          if(this.screenshot){
            this.screenshot.windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            this.screenshot.windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
          }
          
        };
        
        
      }
      start(){
        this.screenshot.addEventListener('mousedown', this.mousedown, false);
        this.screenshot.addEventListener('mousemove',this.mousemove,false )
        this.screenshot.addEventListener('mouseup', this.mouseup, false)   
        this.screenshot.addEventListener('onresize', this.mouseup, false)   
        // this.screenshot.body.style.cursor = "crosshair";
      }
      
      end(){
        this.screenshot.removeEventListener('mousedown', this.mousedown, false);
        this.screenshot.removeEventListener('mousemove',this.mousemove,false )
        this.screenshot.removeEventListener('mouseup', this.mouseup, false)
        var screenshot = document.getElementById('screenshot');
        if(screenshot){screenshot.remove()}
        // this.screenshot.body.style.cursor = "default";
      }
      mousedown(e){
        if(this.tookScreenShot){
          document.getElementById('btnSelectPoint').click();
          return;
        }

        this.borderWidth = this.windowWidth + "px " + this.windowHeight + "px"; 
      
        this.startX = e.clientX;
        this.startY = e.clientY;
        
        this.toolTipWidth = this.tooltip.getBoundingClientRect().width;
        
        this.mouseIsDown = true;
        this.tookScreenShot = false;
        this.crosshairs.className = "crosshairs hidden";
      }
      mousemove(e){
        this.crossHairsTop = e.clientY;
        this.crossHairsLeft = e.clientX;
        this.crosshairs.style.left = this.crossHairsLeft + "px";
        this.crosshairs.style.top = this.crossHairsTop + "px";
        var tooltipBoundingRect = this.tooltip.getBoundingClientRect();
        
        this.toolTipWidth = tooltipBoundingRect.width;
        this.toolTipHeight = tooltipBoundingRect.height;
        
        /* 
         * Change how the borderWidth is being calculated based on the x and y values.
         * Calculate the box with the 1px border's positioning and width 
         * Calculate the positioning of the tooltip */
        if (this.mouseIsDown) {
          
          var endY = this.endY = e.clientY,
              endX = this.endX = e.clientX,
              startX = this.startX,
              startY = this.startY,
              windowWidth = this.windowWidth,
              windowHeight = this.windowHeight;
          
           // Calculating the values differently depending on how the user start's dragging.
           if (endX >= startX && endY >= startY) {
             
             this.isDragging = true;
             
             this.borderWidth = startY + "px " + (windowWidth - endX) + "px " + (windowHeight - endY) + "px " + startX + "px";
            
             this.boxTop = startY;
             this.boxLeft = startX;
             this.boxEndWidth = endX - startX;
             this.boxEndHeight = endY - startY;
             
             this.toolTipLeft = endX;
             this.toolTipTop = endY;
             
             if (endX + this.toolTipWidth >= windowWidth) {
               this.toolTipLeft = windowWidth - this.toolTipWidth - (10 * 2);
             }
             
             if (endY + this.toolTipHeight + (10 * 2) >= windowHeight) {
               this.toolTipTop = windowHeight - this.toolTipHeight - (10 * 2);
             }
             
           } else if (endX <= startX && endY >= startY) {
             
             this.isDragging = true;
             
             this.borderWidth = startY + "px " + (windowWidth - startX) + "px " + (windowHeight - endY) + "px " + endX + "px";
             
             this.boxLeft = endX;
             this.boxTop = startY;
             this.boxEndWidth = startX - endX;
             this.boxEndHeight = endY - startY;
             
             this.toolTipLeft = endX - this.toolTipWidth;
             this.toolTipTop = endY;
             
             if (endX - this.toolTipWidth <= 0) {
               this.toolTipLeft = 10;
             }
             
             if (endY + this.toolTipHeight + (10 * 2) >= windowHeight) {
               this.toolTipTop = windowHeight - this.toolTipHeight - (10 * 2);
             }
             
           } else if (endX >= startX && endY <= startY) {
             
             this.isDragging = true;
             
             this.boxLeft = startX;
             this.boxTop = endY;
             this.boxEndWidth = endX - startX;
             this.boxEndHeight = startY - endY;
             
             this.toolTipLeft = endX;
             this.toolTipTop = endY - this.toolTipHeight;
             
             this.borderWidth = endY + "px " + (windowWidth - endX) + "px " + (windowHeight - startY) + "px " + startX + "px";
             
             if (endX + this.toolTipWidth >= windowWidth) {
               this.toolTipLeft = windowWidth - this.toolTipWidth - (10 * 2);
             }
             
             if (endY - this.toolTipHeight <= 0) {
               this.toolTipTop = 10;
             }

             
           } else if (endX <= startX && endY <= startY) {
             
             this.isDragging = true;
             
             this.boxLeft = endX;
             this.boxTop = endY;
             this.boxEndWidth = startX - endX;
             this.boxEndHeight = startY - endY;
             
             this.borderWidth = endY + "px " + (windowWidth - startX) + "px " + (windowHeight - startY) + "px " + endX + "px";
             
             this.toolTipLeft = endX - this.toolTipWidth;
             this.toolTipTop = endY - this.toolTipHeight;
             
             if (endX - this.toolTipWidth <= 0) {
               this.toolTipLeft = 10;
             }
             
             if (endY - this.toolTipHeight <= 0) {
               this.toolTipTop = 10;
             }

           
             
           } else {
             this.isDragging = false;
           }
           this.tooltip.style.left = this.toolTipLeft + "px";
           this.tooltip.style.top = this.toolTipTop + "px";
           this.tooltip.innerHTML = this.boxEndWidth + " x " + this.boxEndHeight;
           this.tooltip.style.opacity = 1;

           this.box.style.left = this.boxLeft + "px";
           this.box.style.top = this.boxTop + "px";
           this.box.style.width = this.boxEndWidth + "px";
           this.box.style.height = this.boxEndHeight + "px";
          
           this.overlay.style.borderWidth = this.borderWidth;

           
        }
      }
      mouseup(e){
        this.borderWidth = 0;
      
        if (this.isDragging) {
          // Don't take the screenshot unless the mouse moved somehow.
          this.tookScreenShot = true;
          this.style.display = "none";          
          takeScreenshot(this.startX, this.startY, this.endX,this.endY);
          document.querySelector('.Flash').className = 'Flash';
          this.style.display = "block";
        }
        
        this.isDragging = false;
        this.mouseIsDown = false;
        this.crosshairs.className = "crosshairs";
      }
      selectstart(e){
        e.preventDefault();
      }

      
    
}


 function takeScreenshot(startX,startY,endX,endY) {
  
    html2canvas(document.querySelector('body')).then(canvas => {
      let croppedCanvas = document.createElement('canvas'),
      croppedCanvasContext = croppedCanvas.getContext('2d');
      var croppedImageWidth = Math.abs(endX - startX);
      var croppedImageHeight = Math.abs(endY - startY);;
      croppedCanvas.width  = croppedImageWidth;
      croppedCanvas.height = croppedImageHeight;  
      croppedCanvasContext.drawImage(canvas, startX, startY, croppedImageWidth, croppedImageHeight, 0, 0, croppedImageWidth, croppedImageHeight);
      copyCanvas(croppedCanvas);
      // let a = document.createElement("a");
      // a.download = "ss.png";
      // a.href = croppedCanvas.toDataURL("image/png");
      // a.click();

    });
  }

  async function copyCanvas(canvas) {
    try {
      canvas.toBlob(function(blob) { 
        const item = new window.ClipboardItem({ "image/png": blob });
        navigator.clipboard.write([item]); 
    });
      console.log('Image copied.');
    } catch (err) {
      console.error(err.name, err.message);
    }
  };
  async function copyPicture(image) {
    try {
      const canvas = document.createElement('canvas')
      canvas.width = image.naturalWidth
      canvas.height = image.naturalHeight
      const context = canvas.getContext('2d')
      context.drawImage(image, 0, 0)
      canvas.toBlob(blob => {
        navigator.clipboard.write([
          new ClipboardItem({
            [blob.type]: blob
          })
        ]).then(() => {
          console.log('Image copied.');
        })
      })
      
  } catch (error) {
      console.error(error);
  }
  };
function createScreenShot(startX, startY){
    var div = document.createElement('div');
    div.className = 'screenshot_container';
    div.id = 'screenshot'

    var divChild = document.createElement('div');
    divChild.className = 'Flash d-none';
    div.appendChild(divChild);

    divChild = document.createElement('div');    
    divChild.className = 'overlay highlighting';
    divChild.style.borderLeftWidth = startX + "px";
    divChild.style.borderTopWidth = startY + "px";
    divChild.style.borderBottomWidth = startY + "px";
    divChild.style.borderRightWidth = startX + "px";
    div.appendChild(divChild);
    
    divChild = document.createElement('div');
    divChild.style.left = startX + "px";
    divChild.style.top = startY + "px";
    divChild.className = 'crosshairs';
    div.appendChild(divChild);

    divChild = document.createElement('div');
    divChild.style.left = startX + "px";
    divChild.style.top = startY + "px";
    divChild.className = 'borderedBox';
    div.appendChild(divChild);

    divChild = document.createElement('SPAN');
    divChild.style.left = startX + "px";
    divChild.style.top = startY + "px";
    divChild.className = 'tooltip';
    div.appendChild(divChild);

    document.body.appendChild(div);

  }
  function setScreenshot(startX, startY,endX, endY){
    var div = document.querySelector('.crosshairs');
    if(div){
      div.style.width = Math.abs(endX - startX) + "px";
      div.style.height = Math.abs(endY - startY) + "px";
    }
    
  }