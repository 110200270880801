

$(document).on('turbo:load', function () {
    // $("#btnDownloadMultiFile").click(function() {
    //     var checkboxes = $("table tbody tr td .col_checkbox");
    //     var lstPdf = [];
    //     checkboxes.filter(':checked').map(function() {
    //         lstPdf.push($(this).closest('tr')[0].getAttribute("data-item"));
    //     })
    
    //     if(lstPdf.length==0) return;
    //     window.location.href = window.origin + '/pdf/download_multi_file?list_id_pdf='+lstPdf;
    //     hideFormLoading();
    // });
    
    $("#btnDeleteLogicPdf").click(function() {
        var checkboxes = $("table tbody tr td .col_checkbox");
        var lstPdf = [];
        checkboxes.filter(':checked').map(function() {
            lstPdf.push($(this).closest('tr')[0].getAttribute("data-item"));
        })        
      
        if(lstPdf.length==0) return;
        $.ajax({
                type: 'GET',
                url: window.origin + "/pdf/confirm_delete_multi_pdf_logic",
                dataType: 'script',
                data: {
                 list_id_pdf: lstPdf
                },
                success: function(data, textStatus, jqXHR){
                
                },
                error:function(jqXHR, textStatus, errorThrown){
                console.log("AJAX Error: #{textStatus}")
                }
        });
    });
})
