export const ObjectHelper = {    
    define_custome_properties(object,viewer,canvas,parent_object = null) {
        object.id = random_string(10);
        object.parent_id = (parent_object != null ? parent_object.id : null);
        object.page = canvas.pageNum + 1;
        object.annotation = viewer.current_annotation;
        object.display = true;
    },    
    find_by_id(list,id){
        for (let index = 0; index < list.length; index++) {
            if(list[index].id == id){
                return list[index];
            }
        }
        return null;
    }

}
