$(document).on('turbo:load', function() {
	$("#btnEditCustomer").click(function() {
		var arr_customer = [];
		
		$.each($('.tbl_customer tbody tr'), function (i, row) {
			if ($(row).find("input[class='checkbox_customer_table']:checked").is(":checked")) {
				arr_customer.push($(row).data('item-id'));
			} 
		});
		if (arr_customer.length == 1) {
			let page_value = $(".customer-tbl").find(".pagination .current").text().trim();
			$.ajax ({
				type: 'GET',
				url: "customers/" + arr_customer[0] + "/edit",
				dataType: 'script',
				data: {
					id: arr_customer[0],
					page: page_value
				},
				success: function(data, textStatus, jqXHR){$.each($('.tbl_customer tbody tr'), function (i, row) {
					if ($(row).find("input[class='checkbox_customer_table']:checked").is(":checked")) {
						arr_customer.push($(row).data('item-id'));
					} 
				});
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}
	});

	$("#btnDeleteCustomer").click(function() {
		var arr_customer = [];
		$.each($('.tbl_customer tbody tr'), function (i, row) {
			if ($(row).find("input[class='checkbox_customer_table']:checked").is(":checked")) {
				arr_customer.push($(row).data('item-id'));
			} 
		});

		if (arr_customer.length > 0) {
			let page_value = $(".customer-tbl").find(".pagination .current").text().trim();
			$.ajax ({
				type: 'GET',
				url: "confirm_destroy_customer",
				dataType: 'script',
				data: {
					id: arr_customer,
					page: page_value
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	$("body").delegate("#select_all_column_options", "click", function(){
    $("input[class='checkbox_customer_table']").prop('checked', this.checked);
  })

  $("body").delegate(".checkbox_customer_table", "click", function(){
    set_select_all_option(".tbl_customer", "checkbox_customer_table")
  })
});
