$(document).on('turbo:load', function() {
	$("#btnEditJobType").click(function() {
		var arr_job_type = [];
		$.each($('.tbl_job_type tbody tr'), function (i, row) {
			if ($(row).find("input[class='checkbox_job_type']:checked").is(":checked")) {
				arr_job_type.push($(row).data('item-id'));
			} 
		});
		if (arr_job_type.length == 1) {
			let page_value = $(".job-type-tbl").find(".pagination .current").text().trim();
			$.ajax ({
				type: 'GET',
				url: "job_types/" + arr_job_type[0] + "/edit",
				dataType: 'script',
				data: {
					id: arr_job_type[0],
					page: page_value
				},
				success: function(data, textStatus, jqXHR){

					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}
	});

	$("#btnDeleteJobType").click(function() {
		var arr_job_type = [];
		$.each($('.tbl_job_type tbody tr'), function (i, row) {
			if ($(row).find("input[class='checkbox_job_type']:checked").is(":checked")) {
				arr_job_type.push($(row).data('item-id'));
			} 
		});

		if (arr_job_type.length > 0) {
			let page_value = $(".job-type-tbl").find(".pagination .current").text().trim();
			$.ajax ({
				type: 'GET',
				url: "confirm_destroy_job_type",
				dataType: 'script',
				data: {
					id: arr_job_type,
					page: page_value
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	$("body").delegate("#select_all_column_options", "click", function(){
    $("input[class='checkbox_job_type']").prop('checked', this.checked);
  })

  $("body").delegate(".checkbox_job_type", "click", function(){
    set_select_all_option(".tbl_job_type", "checkbox_job_type")
  })
});
