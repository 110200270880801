export const canvas_helper = {
    find_object_by_id(canvas,id){
        var object = null;
        var objects =  canvas.getObjects();
        
        for (let index = 0; index < objects.length; index++) {
            const element = objects[index];
            if(id == element.id){
                object = element;
                break;
            }
        }
        return object;
    },
    getFabricItem(fabricpageAnnotations,idx){
        return fabricpageAnnotations[idx];       
    },
    add_annotation_to_list(list_annotation,annotation){
        var exist = false;
        for (let index = 0; index < list_annotation.length; index++) {
            const element = list_annotation[index];
            if(element.id == annotation.id){
                list_annotation.splice(index, 1); 
                exist = true;
            }            
        }
        list_annotation.push(annotation);
    },
    check_exist_item(list,annotation){
        var out = null;
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            if(element.id == annotation.id){
                out = element;
                break;
            }  
        }
        return out;
    }
}