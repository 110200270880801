export class EditToolbar{  
    eventBus = null  
    constructor(options, eventBus){
        this.eventBus = eventBus;
        this.buttons = [
          {
          element: options.select_text,
          eventName: "select_text"
        },
        {
          element: options.select_annotation,
          eventName: "select_annotation"
        },
        {
          element: options.hand,
          eventName: "select_hand"
        },
        
        {
          element: options.add_text,
          eventName: "add_text"
        },
        {
          element: options.highlight,
          eventName: "highlight"
        },
        {
          element: options.strikeout,
          eventName: "strikeout"
        },
        {
          element: options.underline,
          eventName: "underline"
        },
        {
          element: options.draw_line,
          eventName: "draw_line"
        },
        {
          element: options.draw_arrow,
          eventName: "draw_arrow"
        },
        {
          element: options.draw_ellipse,
          eventName: "draw_ellipse"
        },
        {
          element: options.draw_rectangle,
          eventName: "draw_rectangle"
        },
        {
          element: options.mark_x,
          eventName: "mark_x"
        },
        {
          element: options.mark_v,
          eventName: "mark_v"
        },
        {
          element: options.mark_o,
          eventName: "mark_o"
        },
        {
          element: options.form_textbox,
          eventName: "form_textbox"
        },
        {
          element: options.form_textarea,
          eventName: "form_textarea"
        },
        {
          element: options.form_radio,
          eventName: "form_radio"
        },
        {
          element: options.form_checkbox,
          eventName: "form_checkbox"
        },
        {
          element: options.form_dropdown,
          eventName: "form_dropdown"
        },
        {
          element: options.add_image,
          eventName: "add_image"
        },
        {
          element: options.pen,
          eventName: "pen"
        },
        {
          element: options.free_draw,
          eventName: "free_draw"
        },
        {
          element: options.erase,
          eventName: "erase"
        },
        {
          element: options.link,
          eventName: "link"
        },
        {
          element: options.customize_tool,
          eventName: "customize_tool"
        }
        ,
        {
          element: options.close_tool,
          eventName: "close_tool"
        },
        {
          element: options.undo,
          eventName: "undo"
        }
        ,
        {
          element: options.redo,
          eventName: "redo"
        }
        ,
        {
          element: options.reload,
          eventName: "reload"
        },
        {
          element: options.download,
          eventName: "download"
        },
        // ,
        // {
        //   element: options.save,
        //   eventName: "save"
        // },
        {
          element: options.screenshot,
          eventName: "screenshot"
        }
        
      ]
        this.currentpage = options.current_page;
        this.selectScale = options.select_scale;
        this.#bindListeners(options);
    }   
 
    
    #bindListeners(options) {   
        for (const {
          element,
          eventName,
          eventDetails
        } of this.buttons) {
          if(!element){
            // console.log('element is null ');
            continue;
          }
          element.addEventListener("click", evt => {
            if(evt.target.parentNode.classList.contains('annotation-popup'))
            {
              setToggle(evt.target);
            }
            
            if(evt.target.tagName == 'IMG' && evt.target.parentNode.parentNode.classList.contains('annotation-popup')){
              setToggle(evt.target.parentNode);
            }
            if (eventName !== null) {
              const details = {
                source: this,
                element : evt.target

              };
    
              if (eventDetails) {
                for (const property in eventDetails) {
                  details[property] = eventDetails[property];
                }
              }
    
              this.eventBus.dispatch(eventName, details);
            }
          });
        }          
       
      }
}
function setToggle(btn){
  var active = 'active';
  if(!btn.classList.contains(active)){      
    btn.classList.add(active);
    removeOtherButtonActive(btn);
  }  
}

function removeOtherButtonActive(btn){
  $('.annotation-buttons .annotation-popup a').not(btn).removeClass("active");
  $('.secondary-button-list .dropdown-item').not(btn).removeClass("active");
}
