import {fabric} from 'fabric'; 
import {Config} from '../../define/AppConfig';
import { ANNOTATION } from '../../edit/annotation';
import {PdfHistory, TYPE_HISTORY, TYPE_OBJECT} from '../../edit/undo_redo';
import { FText } from '../items/sanze';

import { PDFSaveAnnotation } from '../../edit/save_annotation';
import { factory } from '../../annotation/factory';
import { ultil_mdethods } from '../../common/ultil_methods';
import { annotation_helper } from '../../common/annotation_helper';
import {fabric_format} from '../fabric_format'
import { stamp_helper } from '../../common/stamp_helper';
import { toolBarHelper } from '../../edit/toolbar_helper';
import { Convert } from '../../../../packs/convert';
import { ObjectHelper } from './object_helper';
import { TYPE_STAMP } from '../../../../packs/common';
var listObjectDelete = [],listObjectHTMLDelete = [];

export const fabricUltil = {   

  mouse_down_before(viewer,fcanvas){   
    viewer.objectDrawing = null;
    var annotation = viewer.current_annotation;
    if(annotation == ANNOTATION.MOVE ){
       setSelectableAll(fcanvas, true);
    }
    else{
      setSelectableAll(fcanvas, false);
    }
    // if(annotation == ANNOTATION.ADD_STAMP ){
    //   customizeSetSelectionAll(fcanvas, true);
    // }
    // else{
    //   setSelectableAll(fcanvas, true);
    // }
    
    if(annotation == ANNOTATION.FREE_DRAW ){
      if(fcanvas.isDrawingMode != true) 
      {
       fcanvas.isDrawingMode = true;
       fcanvas.freeDrawingBrush = new fabric['PencilBrush'](fcanvas);

       var stroke = ultil_mdethods.get_stroke_by_scale(Config.freedraw.dash,Config.freedraw.strokeWidth,viewer.currentScaleValue);
       fcanvas.freeDrawingBrush.width = stroke.strokeWidth;
       fcanvas.freeDrawingBrush.strokeDashArray = stroke.strokeDashArray;

       fcanvas.freeDrawingBrush.origin_strokeWidth = Config.freedraw.strokeWidth;
       fcanvas.freeDrawingBrush.origin_strokeDashArray = Config.freedraw.dash;

       fcanvas.freeDrawingBrush.strokeLineCap = 'butt';
       fcanvas.freeDrawingBrush.strokeLineJoin = 'butt';
       let color = getPencilColor(annotation);    
        color = Convert.toRGB(color);
        fcanvas.freeDrawingBrush.color = `rgba(${color.r},${color.g},${color.b},${Config.freedraw.opacity})`;
      }
    }  
    else if(annotation == ANNOTATION.PENCIL) {
      if(fcanvas.isDrawingMode != true) 
      {
       fcanvas.isDrawingMode = true;
       fcanvas.freeDrawingBrush = new fabric['PencilBrush'](fcanvas);
   
      var stroke = ultil_mdethods.get_stroke_by_scale(Config.pencil.dash,Config.pencil.strokeWidth,viewer.currentScaleValue);
      fcanvas.freeDrawingBrush.width = stroke.strokeWidth;
      fcanvas.freeDrawingBrush.strokeDashArray = stroke.strokeDashArray;

      fcanvas.freeDrawingBrush.origin_strokeWidth = Config.pencil.strokeWidth;
      fcanvas.freeDrawingBrush.origin_strokeDashArray = Config.pencil.dash;

      fcanvas.freeDrawingBrush.strokeLineCap = 'butt';
      fcanvas.freeDrawingBrush.strokeLineJoin = 'butt';
       let color = getPencilColor(annotation);
       color = Convert.toRGB(color);
       fcanvas.freeDrawingBrush.color = `rgba(${color.r},${color.g},${color.b},${Config.pencil.opacity})`;
      }
    } 
    else if(annotation == ANNOTATION.ERASE) {
      if(fcanvas.isDrawingMode != true) 
      {
       fcanvas.isDrawingMode = true;
       fcanvas.freeDrawingBrush = new fabric['PencilBrush'](fcanvas);
       fcanvas.freeDrawingBrush.width = 2;
       fcanvas.freeDrawingBrush.strokeDashArray = [2,6];
       fcanvas.freeDrawingBrush.color = `rgba(255,0,0,0.99)`;
      }
    } 
   else{
    fcanvas.isDrawingMode = false;
   }
  }    ,
  mouseDown(event,canvas,viewer){    
        viewer.mouseDown = true;        
        canvas.hoverCursor = 'auto';    
        var pointer = canvas.getPointer(event.e);
        viewer.origX = pointer.x;
        viewer.origY = pointer.y;
        switch(viewer.current_annotation)
        {
          case ANNOTATION.NONE:          
          case ANNOTATION.MOVE:            
            break
          case ANNOTATION.ADD_STAMP:
            startAddingImage(event,canvas,viewer);            
            break;
          case ANNOTATION.ADD_TEXT:
            startAddingLabel(event,canvas,viewer);      
            break;
         case ANNOTATION.FREE_DRAW:  
            break;
         case ANNOTATION.ADD_LINK:         
             startAddingLink(canvas,viewer);             
            //  canvas.selection = true;
             break;
         case ANNOTATION.ADD_IMAGE:         
             startAddingImage(event,canvas,viewer);             
             break;
         case ANNOTATION.DRAW_LINE:                     
             startAddingLine(event,canvas,viewer);   
             break;
        case ANNOTATION.DRAW_ARROW:
            startAddingArrow(event,canvas,viewer); 
            break;
         case ANNOTATION.DRAW_RECTANGLE:  
             startAddingRectangle(event,canvas,viewer);
             break;
         case ANNOTATION.DRAW_ELLIPSE:     
             startAddingEllipse(event,canvas,viewer);
             break;  
         case ANNOTATION.ADD_TEXTBOX:
             startAddingTextbox(event,viewer,canvas);
             break;
         case ANNOTATION.ADD_TEXT_AREA:
             startAddingTextArea(event,viewer,canvas);
             break;
         case ANNOTATION.ADD_RADIO:
             startAddingRadio(event,viewer,canvas);
             break;
         case ANNOTATION.ADD_CHECKBOX:
             startAddingCheckbox(event,viewer,canvas);
             break;
         case ANNOTATION.ADD_DROPDOWN:
             startAddingDropdown(event,viewer,canvas);
             break;
         case ANNOTATION.ERASE:
          listObjectDelete = [];
          listObjectHTMLDelete = [];
            break;
         case ANNOTATION.ADD_XMARK: 
         case ANNOTATION.ADD_CHECKMARK:  
         case ANNOTATION.ADD_DOT:         
            if(viewer.image_selected)
            {
                startAddingMark(event,canvas,viewer);                
            }   
            break;
        
        }    
    },   
    mouseMove(viewer,canvas,event){
      if(!viewer.mouseDown) return;
      switch(viewer.current_annotation)
      {    
       case ANNOTATION.ADD_LINK:    
          startDrawingLink(canvas,event,viewer);
          break;
        case ANNOTATION.ADD_STAMP:         
          break;
        case ANNOTATION.DRAW_LINE:         
          viewer.objectDrawing = startDrawingLine(canvas,event,viewer);
          break;
        case ANNOTATION.DRAW_ELLIPSE:     
          startDrawingLineEllipse(canvas,event,viewer);       
          break;
        case ANNOTATION.DRAW_RECTANGLE:   
          // case ANNOTATION.ERASE:    
          startDrawingRectangle(canvas,event,viewer);   
          break;       
       case ANNOTATION.DRAW_ARROW:
         viewer.objectDrawing = startDrawingLine(canvas,event,viewer);    
          break;
       case ANNOTATION.ERASE:
        var targetPoint = new fabric.Point(event.pointer.x, event.pointer.y);
        
        canvas.forEachObject(function(targ){
          targ.setCoords();
          if (targ.containsPoint(targetPoint)) {
            if (!canvas.isTargetTransparent(targ, targetPoint.x, targetPoint.y)) {      
              listObjectDelete.push(targ);
            };
          } 
        });
        var elem = null;
        if(mobileAndTabletCheck()){
          elem = document.elementFromPoint(event.pointer.x, event.pointer.y) ;         
          
        }
        else{
          elem = document.elementFromPoint(event.e.clientX, event.e.clientY) ;         
        }
        if(elem && elem.className == 'annotation-added'){
          listObjectHTMLDelete.push(elem);
        }       
        break;
       default:  
          break;
      }    
     },      
     mouseUp(viewer,canvas,event){
      viewer.mouseDown = false;      
      if(!viewer.objectDrawing) return;     
      if(viewer.objectDrawing.annotation == ANNOTATION.ADD_STAMP){
        stamp_helper.saveStamp(viewer.objectDrawing,viewer);
      } 
      else if(viewer.objectDrawing.annotation == ANNOTATION.ADD_TEXT && viewer.objectDrawing.name == "date"){
        var stamp = stamp_helper.get_stamp_by_date(viewer.objectDrawing);
        stamp_helper.saveStamp(stamp,viewer);
      }
      else{
        canvas.discardActiveObject().renderAll();
        canvas.setActiveObject(viewer.objectDrawing);
        PDFSaveAnnotation.saveElement(viewer,viewer.objectDrawing);
      }              
      viewer.objectDrawing = null;     
     },   
    pathCreated(obj,annotation,viewer) {       
        var ob = obj.ob;        
        var canvas = obj.canvas;
        if( annotation == ANNOTATION.ERASE){
          factory.delete(canvas,ob.path);
          factory.delete_multil_element(canvas,listObjectDelete);
          
          listObjectHTMLDelete.forEach(element => {
            element.remove();
            PDFSaveAnnotation.delete_element(viewer,element);
          });
          canvas.renderAll();
          return;
        }
        switch(annotation)
        {    
         case ANNOTATION.PENCIL:      
            ob.path.myOpacity =  Config.pencil.opacity;
            ob.path.name =  'highlighter';
            // ob.path.opacity = Config.pencil.opacity;
            break;
          case ANNOTATION.FREE_DRAW:      
            ob.path.name =  'brush';
            // ob.path.opacity = Config.freedraw.opacity;
            break;      
        }
        ob.path.id =  random_string(10);
        ob.path.scale = viewer.currentScaleValue; 
        ob.path.type_path = annotation;  
        ob.path.annotation = annotation;        
        ob.path.origin_strokeWidth = canvas.freeDrawingBrush.origin_strokeWidth;
        ob.path.origin_strokeDashArray = canvas.freeDrawingBrush.origin_strokeDashArray;
        fabric_format.path(ob.path);           
        canvas.renderAll();
        PDFSaveAnnotation.saveElement(viewer,ob.path);
        // viewer.addHistory(new PdfHistory(ob.path.canvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,ob.path,null));
        },
      
    objectModified(viewer,canvas,event) {
      toolBarHelper.hideToolProperties();
        if (canvas.getActiveObjects().length > 1){
            var sel = new fabric.ActiveSelection(canvas.getActiveObjects(),{
              canvas: canvas,
            });
            sel.setObjectsCoords();
            sel.setCoords();
            canvas.setActiveObject(sel);
            sel._objects.forEach(element => {
              if(element.annotation == ANNOTATION.DRAW_LINE || element.annotation == ANNOTATION.DRAW_ARROW ){
                setMovingLine(element,canvas,viewer);  
              }             
            });
            canvas.requestRenderAll();
           
        }
        let modifiedObject = event.target;   
        // if(modifiedObject.type == "path"){
        //   let oldState = JSON.parse(JSON.stringify(modifiedObject._stateProperties));
        //   modifiedObject.setCoords();
        //    modifiedObject.path.forEach(point => {
        //     for(let i = 1;i< point.length;i++){
        //       if(i%2!=0){
        //         point[i] = point[i] + modifiedObject.left - oldState.left * modifiedObject.scaleX;
        //       }
        //       else{
        //         point[i] = point[i] + modifiedObject.top - oldState.top * modifiedObject.scaleY;
        //       }
        //     }  
        //   })
        // }   
        
        if(modifiedObject.annotation == ANNOTATION.ADD_STAMP){          
         var config = stamp_helper.update_config(modifiedObject);
         stamp_helper.saveConfig(config);
        }
         
      },
      objectMoving(canvas,e,viewer){
        var p = e.target;
        hideTools();
        switch(p.annotation)
        {    
         case ANNOTATION.DRAW_ARROW:   //https://jsfiddle.net/LordWolfer/2pf0Lm81/99/
         case ANNOTATION.DRAW_LINE:   //https://jsfiddle.net/LordWolfer/2pf0Lm81/99/
            viewer.objectDrawing = setMovingLine(p,canvas,viewer);                        
            break;
          case ANNOTATION.ADD_STAMP:   //https://jsfiddle.net/LordWolfer/2pf0Lm81/99/
            viewer.objectDrawing = setMovingStamp(p,canvas,viewer);                        
            break;
          case ANNOTATION.ADD_TEXT:   //https://jsfiddle.net/LordWolfer/2pf0Lm81/99/
            if(p.name == "date"){
              viewer.objectDrawing = setMovingDate(p,canvas,viewer);
            }                 
            break;
        }
        let modifiedObject = e.target;   
        if(modifiedObject.type == "path"){
          let oldState = JSON.parse(JSON.stringify(modifiedObject._stateProperties));
          modifiedObject.setCoords();
           modifiedObject.path.forEach(point => {
            for(let i = 1;i< point.length;i++){
              if(i%2!=0){
                point[i] = point[i] + modifiedObject.left - oldState.left * modifiedObject.scaleX;
              }
              else{
                point[i] = point[i] + modifiedObject.top - oldState.top * modifiedObject.scaleY;
              }
            }  
          })
        }   
        
      },
      setMovingArrow(p,canvas,viewer){
        setMovingArrow(p,canvas,viewer)
      },
      
         
       objectSelected(canvas,event,viewer){
        
        switch(viewer.current_annotation)
        {    
         case ANNOTATION.ADD_LINK:         
            // drawingMode = false;
            break;
         default:  
          break;
        }    
       },
       selectionCleared(canvas,event,viewer){
        switch(viewer.current_annotation)
        {    
         case ANNOTATION.ADD_LINK:         
            // drawingMode = false;
            break;
         default:  
          break;
        }    
  }
}
function getPencilColor(annotation){  
    var c =  Config.pencil.color;   
    if(annotation == ANNOTATION.FREE_DRAW){
      c =  Config.freedraw.color;      
    }     
    return c;
  }
  function setMovingLine(p,canvas,viewer){
    var line ;
    if(p.element == 0){
      line = p;
      p.setMovedLine();   
    }
    if(p.element == 1 || p.element == 2){
      line = p.line;
      line.setMovedHead();    
    }       
    canvas.calcOffset();
    canvas.renderAll();
    return line;
  }
  function setMovingArrow(p,canvas,viewer){
    var circle1,circle2, line1, line2,arrow  ;
    if(p.element == 0){
      arrow =  p;
      circle1 = p.circle1,circle2 = p.circle2 , line1 = p.line1, line2 = p.line2;
      var c1Left, c1Top, c2Left, c2Top;
      // CALCULATE THE circle1 AND circle2 POINTS FOR EACH SCENARIO
      if (circle1.top < circle2.top) {
         if (circle1.left < circle2.left) {
            c1Left = p.left  -  circle1.radius/2;
            c1Top = p.top  -  circle1.radius/2;
            c2Left = p.left + p.width - circle2.radius/2;
            c2Top = p.top + p.height - circle2.radius/2;
         }
         else {
            c1Left = p.left + p.width - circle1.radius/2;
            c1Top = p.top - circle1.radius/2;
            c2Left = p.left - circle1.radius ;
            c2Top = p.top + p.height ;// ok
         }
      }
      else {
         if (circle1.left < circle2.left) {
            c1Left = p.left - circle1.radius/2;
            c1Top = p.top + p.height - circle1.radius/2;
            c2Left = p.left + p.width - circle1.radius/2;
            c2Top = p.top - circle1.radius/2;
         }
         else {
            c1Left = p.left + p.width - circle1.radius/2;
            c1Top = p.top + p.height - circle1.radius/2;
            c2Left = p.left - circle1.radius;
            c2Top = p.top - circle1.radius ;
         }
      }
      circle1.set({ left: c1Left, top: c1Top });
      circle2.set({ left: c2Left, top: c2Top });
      circle1.setCoords();
      circle2.setCoords();   
      arrow.setCoords();
    }      
    if(p.element == 1 || p.element == 2){
      circle1 = p.line.circle1,circle2 = p.line.circle2 , line1 = p.line.line1, line2 = p.line.line2;
      p.line && p.line.set({ 'x1': circle1.getCenterPoint().x - circle1.radius/2, 'y1': circle1.getCenterPoint().y - circle1.radius/2});
      p.line && p.line.set({ 'x2': circle2.getCenterPoint().x - circle1.radius/2, 'y2': circle2.getCenterPoint().y - circle1.radius/2});
      p.line.setCoords();   
      arrow =  p.line; 
    }   
    var headlen = line1.strokeWidth*3;
    var points = getLinesArrowBeside(circle1.getCenterPoint().x - circle1.radius/2 ,circle1.getCenterPoint().y - circle1.radius/2
    ,circle2.getCenterPoint().x - circle1.radius/2,    circle2.getCenterPoint().y- circle1.radius/2,headlen);
    line1 && line1.set({ 'x1': points.start[0], 'y1': points.start[1] });
    line1 && line1.set({ 'x2': points.start[2], 'y2': points.start[3] });
    line2 && line2.set({ 'x1': points.end[0], 'y1': points.end[1]});
    line2 && line2.set({ 'x2': points.end[2], 'y2': points.end[3] });
    line1.setCoords();
    line2.setCoords();
    if(arrow.number_of_head == 2){
      var line3 , line4 ;
      if(p.element == 0){
        line3 = p.line3;
        line4 = p.line4;
      }
      if(p.element == 1 || p.element == 2){
        line3 = p.line.line3;
        line4 = p.line.line4;
      }
      line3 && line3.set({ 'x1': points.startLeft[0], 'y1': points.startLeft[1] });
      line3 && line3.set({ 'x2': points.startLeft[2], 'y2': points.startLeft[3] });
      line4 && line4.set({ 'x1': points.endLeft[0], 'y1': points.endLeft[1]});
      line4 && line4.set({ 'x2': points.endLeft[2], 'y2': points.endLeft[3] });
      line3.setCoords();
      line4.setCoords();
    }  
    canvas.calcOffset();
    canvas.renderAll();
  }
  
   function startDrawingLineEllipse(canvas,o,viewer){    
    var pointer = canvas.getPointer(o.e);
    var rx = Math.abs(viewer.origX - pointer.x)/2;
    var ry = Math.abs(viewer.origY - pointer.y)/2;
    if (rx > viewer.objectDrawing.strokeWidth) {
      rx -= viewer.objectDrawing.strokeWidth/2
    }
     if (ry > viewer.objectDrawing.strokeWidth) {
      ry -= viewer.objectDrawing.strokeWidth/2
    }
    viewer.objectDrawing.set({ rx: rx, ry: ry});
    viewer.objectDrawing.set("strokeWidth", viewer.objectDrawing.strokeWidth);    
    viewer.objectDrawing.set("strokeDashArray", viewer.objectDrawing.strokeDashArray);    
    if(viewer.origX>pointer.x){
        viewer.objectDrawing.set({originX: 'right' });
    } else {
        viewer.objectDrawing.set({originX: 'left' });
    }
    if(viewer.origY>pointer.y){
        viewer.objectDrawing.set({originY: 'bottom'  });
    } else {
        viewer.objectDrawing.set({originY: 'top'  });
    }    
    canvas.renderAll();
  }
  function startDrawingLink(canvas,event,viewer){    
    var pointer = canvas.getPointer(event.e);
    
    if(viewer.origX>pointer.x){
        viewer.objectDrawing.set({ left: Math.abs(pointer.x) });
    }
    if(viewer.origY>pointer.y){
        viewer.objectDrawing.set({ top: Math.abs(pointer.y) });
    }
    
    viewer.objectDrawing.set({ width: Math.abs(viewer.origX - pointer.x) });
    viewer.objectDrawing.set({ height: Math.abs(viewer.origY - pointer.y) });
    
    
    canvas.renderAll();
   }
   function startDrawingLine(canvas,event,viewer){
    if(viewer.objectDrawing){
      var pointer = canvas.getPointer(event.e);
      viewer.objectDrawing.set_end(pointer);
      canvas.renderAll();
    }
   
    return viewer.objectDrawing;
  }


  function startAddingEllipse(o,canvas,viewer) {    
      var pointer = canvas.getPointer(o.e);      
      var scale = viewer.currentScaleValue;          

      var stroke = ultil_mdethods.get_stroke_by_scale(Config.shape.ellipse.dash,Config.shape.ellipse.strokeWidth,viewer.currentScaleValue);
      viewer.objectDrawing = new fabric.Ellipse({
        left: viewer.origX,
        top: viewer.origY,
        originX: 'left',
        originY: 'top',
        rx: pointer.x-viewer.origX,
        ry: pointer.y-viewer.origY,
        angle: 0,
        fill: '',
        stroke: Config.shape.ellipse.stroke,
        strokeWidth: stroke.strokeWidth,
        opacity: Config.shape.ellipse.opacity,
        name: 'ellipse',
        annotation : viewer.current_annotation,
        strokeDashArray: stroke.strokeDashArray,
        origin_strokeDashArray: Config.shape.ellipse.dash,
        origin_strokeWidth: Config.shape.ellipse.strokeWidth,
        scale: scale,
        id: random_string(10),
        rotate:  viewer.file.pages[viewer.currentPageNumber-1].viewRotate  ,
        lockUniScaling: true,
        strokeUniform: true,
        page: viewer.currentPageNumber
    });
    ObjectHelper.define_custome_properties(viewer.objectDrawing,viewer,canvas);
      canvas.add(viewer.objectDrawing);
      canvas.renderAll();
      viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,viewer.objectDrawing,null));
  }
  function startAddingRectangle(o,canvas,viewer) {        
      var pointer = canvas.getPointer(o.e);
      var stroke = ultil_mdethods.get_stroke_by_scale(Config.shape.rectangle.dash,Config.shape.rectangle.strokeWidth,viewer.currentScaleValue);
      viewer.objectDrawing = new fabric.Rect({
        typyObject:"rectangle",
        left: viewer.origX,
        top: viewer.origY,
        originX: 'left',
        originY: 'top',
        stroke: Config.shape.rectangle.stroke,
        width: pointer.x - viewer.origX,
        height: pointer.y - viewer.origY,
        angle: 0,
        strokeWidth: stroke.strokeWidth,             
        fill: '',
        transparentCorners: false,
        hasControls:true,
        opacity: Config.shape.rectangle.opacity,
        name: 'rectangle',
        annotation : viewer.current_annotation,
        strokeDashArray: stroke.strokeDashArray,
        origin_strokeDashArray: Config.shape.rectangle.dash,
        origin_strokeWidth: Config.shape.rectangle.strokeWidth,           
        scale: viewer.currentScaleValue,
        id: random_string(10),
        lockUniScaling: true,
        strokeUniform: true,
        rotate:  viewer.file.pages[viewer.currentPageNumber-1].viewRotate,
        page: viewer.currentPageNumber   
      });
      ObjectHelper.define_custome_properties(viewer.objectDrawing,viewer,canvas);
      canvas.add(viewer.objectDrawing);      

      canvas.renderAll();
      viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,viewer.objectDrawing,null));
      return viewer.objectDrawing;
  }
  function startDrawingRectangle(canvas,o,viewer){
   
      var pointer = canvas.getPointer(o.e);
  
      if (viewer.origX > pointer.x) {
        viewer.objectDrawing.set({
          left: Math.abs(pointer.x)
        });
      }
      if (viewer.origY > pointer.y) {
        viewer.objectDrawing.set({
          top: Math.abs(pointer.y)
        });
      }
  
      viewer.objectDrawing.set({
        width: Math.abs(viewer.origX - pointer.x)
      });
      viewer.objectDrawing.set({
        height: Math.abs(viewer.origY - pointer.y)
      });
      canvas.renderAll();
  }
  function startAddingLabel(event,canvas,viewer){ 
    var boldText = parseInt( Config.text.addtext.boldText) == 1 ? 'bold' : 'normal';
    var italicText = parseInt(Config.text.addtext.italicText) == 1 ? 'italic' : 'normal';
    var font_size = Config.text.addtext.fontSize ;
    var txt = new FText(Config.text.addtext.value, { 
        left: event.pointer.x,
        top:   event.pointer.y,
        fontFamily: Config.text.addtext.fontFamily,
        fontWeight: boldText,
        fontStyle: italicText,
        fill: Config.text.addtext.fill,
        fontSize: parseInt(font_size*viewer.currentScaleValue),
        name: 'text',
        annotation : viewer.current_annotation,
        id: random_string(10),
        scale: viewer.currentScaleValue,
        lockUniScaling: true,
        originFontSize: font_size,
        rotate:  viewer.file.pages[viewer.currentPageNumber-1].viewRotate ,
        page: viewer.currentPageNumber
    })
    ObjectHelper.define_custome_properties(txt,viewer,canvas);
    fabric_format.text(txt,viewer);    
    canvas.add(txt);
    canvas.renderAll();  
    canvas.setActiveObject(txt);
    txt.enterEditing()
    txt.hiddenTextarea.focus();
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,txt,null));
}
Array.prototype.replace = function(t, v) {
  if (this.indexOf(t)!= -1)
      this[this.map((e, i) => [i, e]).filter(e => e[1] == t)[0][0]] = v;
};

function startAddingLine(o,canvas,viewer) {    
    var pointer = canvas.getPointer(o.e);
    viewer.origX = pointer.x;
    viewer.origY = pointer.y;    
    var scale = viewer.currentScaleValue;

    var stroke = ultil_mdethods.get_stroke_by_scale(Config.shape.line.dash,Config.shape.line.strokeWidth,viewer.currentScaleValue);
   
    var aCoords = [pointer.x, pointer.y, pointer.x, pointer.y]    
    var properties = {
      fill: Config.shape.line.backGroundColor,
      stroke: Config.shape.line.backGroundColor,
      strokeWidth: stroke.strokeWidth,     
      annotation: viewer.current_annotation,
      id: random_string(10),
      strokeDashArray: stroke.strokeDashArray,
      scale: scale,
      origin_strokeDashArray: Config.shape.line.dash,
      origin_strokeWidth: Config.shape.line.strokeWidth,
      name: 'line',
      page: viewer.currentPageNumber
    }
    
    properties = annotation_helper.config.line.fix_config(properties);

    ObjectHelper.define_custome_properties(properties,viewer,canvas);
    var line = factory.create.line(canvas,aCoords,properties)  

    viewer.objectDrawing = line;
    canvas.renderAll();
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,viewer.objectDrawing,null));
    return line;
}
function startAddingArrow(event,canvas,view){
  var pointer = canvas.getPointer(event.e);
  var stroke = ultil_mdethods.get_stroke_by_scale(Config.shape.arrow.dash,Config.shape.arrow.size,view.currentScaleValue);
  var options = {
      fill: Config.shape.arrow.color,
      stroke: Config.shape.arrow.color,
      strokeWidth:  stroke.strokeWidth,
      selectable: true,
      hasControls: false,
      hasBorders: false,
      centeredRotation: false,
      centeredScaling: false,
      perPixelTargetFind: true,
      name: 'arrow',
      annotation: view.current_annotation,
      number_head: parseInt(Config.shape.arrow.position_arrow),
      strokeDashArray: stroke.strokeDashArray,
      origin_strokeDashArray: Config.shape.arrow.dash,
      origin_strokeWidth: Config.shape.arrow.strokeWidth,
      scale: view.currentScaleValue,
      id: random_string(10),
      page: view.currentPageNumber
  }
  ObjectHelper.define_custome_properties(options,view,canvas);
  var aCoords = [pointer.x, pointer.y, pointer.x, pointer.y]    
  view.objectDrawing = factory.create.arrow(canvas,aCoords,options);
   
  canvas.renderAll();
  view.addHistory(new PdfHistory(canvas.pageNum,view.current_annotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,view.objectDrawing,null));
}
function startAddingLink(canvas,viewer){    
    var default_link = {link_type: Config.link.radio, url: Config.link.url, email:Config.link.mail,phone:Config.link.phone,page:Config.link.page}
    viewer.objectDrawing = new fabric.Rect({
              typyObject:"link",
              link_type: default_link,            
              left:viewer.origX,
              top:viewer.origY,
              width:0,
              height:0,
              stroke:'#0282e5',
              strokeDashArray: [5, 5],
              strokeWidth:1,
              fill:'rgba(8,157,227,.1)',
              name: 'link',
              annotation : viewer.current_annotation,
              scale: viewer.currentScaleValue,
              id: random_string(10),
              origin_strokeDashArray: [5, 5],
              origin_strokeWidth: 1 ,
              page: viewer.currentPageNumber  
          });      
          viewer.objectDrawing.setControlsVisibility({        
          mtr: false
        });    
     ObjectHelper.define_custome_properties(viewer.objectDrawing,viewer,canvas);
     canvas.add(viewer.objectDrawing);
     canvas.renderAll(); 
     viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,viewer.objectDrawing,null));
 }

 function startAddingImage(event,fcanvas,viewer){ 
    if(!viewer.image_selected) return;
    var scale_resolution = 10;
    var image_selected = viewer.image_selected;
    var scale_absolute =  ultil_mdethods.get_scaleXY_image(image_selected);
    var real_height = image_selected.height*scale_absolute;
    var real_width = image_selected.width*scale_absolute; 
    var src = image_selected.src;
   
    if (image_selected.tagName == 'CANVAS'){
      // image_selected.toBlob(function(blob){
      //   src = URL.createObjectURL(blob);      
      // },'image/png');
      src = image_selected.toDataURL('image/png');
    }
   
   
   
    
    if(viewer.current_annotation == ANNOTATION.ADD_STAMP){   
      var type_stamp = parseInt(image_selected.getAttribute("type_stamp"));      
      if(image_selected.className.includes('img_autocad')){
        type_stamp = TYPE_STAMP.AUTOCARD;
      }
      var config = stamp_helper.get_config(type_stamp);  
      real_height = parseFloat(config.size);
      real_width = real_height*image_selected.width/image_selected.height;      
    }   
    const canvas = document.createElement("canvas");
    canvas.width = real_width * viewer.currentScaleValue*scale_resolution;
    canvas.height = real_height* viewer.currentScaleValue*scale_resolution;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(viewer.image_selected, 0, 0,canvas.width,canvas.height);
    // if (image_selected.tagName == 'IMG'){     
    //   src = canvas.toDataURL('image/png');
    // }
    var properties = 
      {
        left: event.pointer.x - real_width * viewer.currentScaleValue/2, 
        top: event.pointer.y   - real_height* viewer.currentScaleValue/2,
        name: 'image',
        annotation : viewer.current_annotation,
        id: random_string(10),
        src: src,
        scale: viewer.currentScaleValue,
        scaleY: 1/scale_resolution,
        scaleX: 1/scale_resolution ,
        page: viewer.currentPageNumber,
        user_id: viewer.user_id
    }

    if(viewer.current_annotation == ANNOTATION.ADD_STAMP){
      var type_stamp = parseInt(image_selected.getAttribute("type_stamp"));

      if(image_selected.className.includes('img_autocad')){
        type_stamp = TYPE_STAMP.AUTOCARD;
      }
      var config = stamp_helper.get_config(type_stamp);
      properties.typeStamp = type_stamp;
      properties.page = viewer.currentPageNumber;
      properties.fontSize = parseFloat(config.date.fontSize);
      properties.hasDate = (config.has_date == true || config.has_date == "true");
      properties.formatDate = config.date.format;
      properties.color = config.date.color;
      properties.x_relative = parseFloat(config.date.x_relative);
      properties.y_relative = parseFloat( config.date.y_relative);
      real_height = parseFloat(config.size);
      real_width = real_height*image_selected.width/image_selected.height; 
      // properties.stroke = "#2b6dca";
      // properties.strokeWidth = scale_resolution;      
      properties.left = event.pointer.x - real_width * viewer.currentScaleValue/2;
      properties.top = event.pointer.y   - real_height* viewer.currentScaleValue/2;    
    }    
    
    ObjectHelper.define_custome_properties(properties,viewer,fcanvas);
    
    var img = factory.create.image(fcanvas,canvas,properties); 
    if(img.annotation == ANNOTATION.ADD_STAMP){
      img.setControlsVisibility({
        mt: false, 
        mb: false, 
        ml: false, 
        mr: false,     
      });
      if(img.hasDate){
        factory.create.add_date(img,viewer);        
      }
      stamp_helper.saveStamp(img,viewer);
      viewer.set_annotation(ANNOTATION.MOVE);  
      $('.col-stamp a').removeClass("active");
      $('.col-stamp canvas').removeClass("active");
      $('.col-stamp img').removeClass("active");
      $('.img_autocad').removeClass("active");    
    }   
    else{
      PDFSaveAnnotation.saveElement(viewer,img);
    }
    viewer.addHistory(new PdfHistory(fcanvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,img,null));  
  }

  
  function startAddingTextbox(event,viewer,canvas){
    var currentPageDiv = document.getElementById("page-"+ canvas.pageNum);
    var formLayer = currentPageDiv.querySelector(".formLayer");
    var divText = document.createElement("div");
  
    // Set id attribute with page-#{pdf_page_number} format
    divText.setAttribute("class", "ff-editable-inner texboxer");
    divText.style.width = Config.form.textfield.width*viewer.currentScaleValue  + "px";
    divText.style.height = Config.form.textfield.height*viewer.currentScaleValue  + "px";
    divText.style.left = event.pointer.x + "px";
    divText.style.top = event.pointer.y + "px";
    divText.style.position =  "absolute";
    var input = document.createElement("input");
    input.className = "f-input"; 
    input.setAttribute("kind","textbox");
    input.setAttribute("annotation",viewer.current_annotation);   
    input.style.borderWidth = Config.form.textfield.borderWidth + "px";
    input.style.color = Config.form.textfield.color;
    input.style.borderColor = Config.form.textfield.borderColor ;
    divText.appendChild(input);  
    divText.id = random_string(10);
    divText.setAttribute('id-object',random_string(10));
    formLayer.appendChild(divText);
    divText.setAttribute('name','textbox');
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,divText));
    input.click();
    $(".texboxer").resizable({handles: "all",
    resize: function( event, ui ) {
      var item = this.querySelector('.f-input'); 
      shrinkToFill(item,16);
    }
  
    });
    $(".texboxer").draggable({
      drag: function( event, ui ) {
        hideTools();
      }
    });
    shrinkToFill(input,16);
    
    formLayer.style.pointerEvents = "auto";
   $('.annotation-buttons .annotation-popup a').removeClass("active");    
   
  }
  function shrinkToFill(input,shrinkValue) {
    input.style.fontSize = parseInt(window.getComputedStyle(input).height) - shrinkValue + "px";
  }

  function defineRadioButtonEvent(name){
    $("body").delegate('input[name='+name+']', 'click', function() {	
       var $radio = $(this);
         
         // if this was previously checked
         if ($radio.data('waschecked') == true)
         {
             $radio.prop('checked', false);
             $radio.data('waschecked', false);
         }
         else
             $radio.data('waschecked', true);
             
         
         // remove was checked from other radios
         $radio.siblings('input[name='+name+']').data('waschecked', false);
    })
  }  
  
  function startAddingRadio(event,viewer,canvas){
    var currentPageDiv = document.getElementById("page-"+ canvas.pageNum);
    var formLayer = currentPageDiv.querySelector(".formLayer");
    var divText = document.createElement("div");
  
    // Set id attribute with page-#{pdf_page_number} format
    divText.setAttribute("class", "ff-editable-inner radio");
    
    divText.style.width = Config.form.radio.width*viewer.currentScaleValue + "px";
    divText.style.height = Config.form.radio.height*viewer.currentScaleValue + "px";
    divText.style.left = event.pointer.x - Config.form.radio.width*viewer.currentScaleValue/2 + "px";
    divText.style.top = event.pointer.y - Config.form.radio.height*viewer.currentScaleValue/2 + "px";
    divText.style.position =  "absolute";
    
    var input = document.createElement("input");
    input.className = "f-input"; 
    input.setAttribute("type","radio");
    input.setAttribute("kind","radio");
    input.setAttribute('checked', "checked" );
    input.setAttribute('data-waschecked', "true" );
    input.setAttribute("annotation",viewer.current_annotation);
    var groundName = "rad_" + random_string(6);
    input.setAttribute('name', groundName);
    input.style.borderColor =  Config.form.radio.borderColor;
    input.style.borderWidth = Config.form.radio.borderWidth*viewer.currentScaleValue + 'px';
    if(Config.form.radio.backGroundColor.length>0){
      input.style.background =  Config.form.radio.backGroundColor;
    }
    defineRadioButtonEvent(groundName);    
    divText.appendChild(input);

    divText.id = random_string(10);
    divText.setAttribute('id-object',random_string(10));
    divText.setAttribute('name','radio');
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,divText));
    formLayer.appendChild(divText);
    $(".radio").resizable({handles: "all"});
    $(".radio").draggable();
    
    formLayer.style.pointerEvents = "auto";
    $(".with-tooltip").css("background-color","white");
   $('.annotation-buttons .annotation-popup a').removeClass("active");
    addRule(`input[name="${input.getAttribute('name')}"]:before`,`background-color: ${Config.form.radio.color} !important`); 
   }
   function startAddingTextArea(event,viewer,canvas){
    var currentPageDiv = document.getElementById("page-"+ canvas.pageNum);
    var formLayer = currentPageDiv.querySelector(".formLayer");
    var divText = document.createElement("div");
  
    // Set id attribute with page-#{pdf_page_number} format
    divText.setAttribute("class", "ff-editable-inner arear");
    
    divText.style.width = (parseInt(Config.form.textarea.width*viewer.currentScaleValue) + 10) + "px";
    divText.style.height = (parseInt(Config.form.textarea.height*viewer.currentScaleValue) + 10) + "px";
    divText.style.left = event.pointer.x + "px";
    divText.style.top = event.pointer.y + "px";
    divText.style.position =  "absolute";
    
    var input = document.createElement("textarea");
    input.className = "f-input"; 
    input.setAttribute("kind","textarea")    ;
    input.setAttribute("annotation",viewer.current_annotation);
    input.setAttribute("placeholder", `This is
    some placeholder text
    as an example`);
  
    input.style.borderWidth = Config.form.textarea.borderWidth + "px";
    input.style.color = Config.form.textarea.color;
    input.style.borderColor = Config.form.textarea.borderColor ;
    divText.appendChild(input);
    divText.setAttribute('name','textarea');
    divText.id = random_string(10);
    divText.setAttribute('id-object',random_string(10));
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,divText));

    formLayer.appendChild(divText);
    $(".arear").resizable({handles: "all",
    resize: function( event, ui ) {
      var item = this.querySelector('.f-input');
      item.style.fontSize = parseInt(window.getComputedStyle(input).height)/3 + "px";
    }
  
    });
    $(".arear").draggable({
      drag: function( event, ui ) {
        hideTools();
      }
    });
    input.style.fontSize = parseInt(window.getComputedStyle(input).height)/3 + "px";
    
    formLayer.style.pointerEvents = "auto";
    $(".with-tooltip").css("background-color","white");
   $('.annotation-buttons .annotation-popup a').removeClass("active");
   }

   function startAddingCheckbox(event,viewer,canvas){
    var currentPageDiv = document.getElementById("page-"+ canvas.pageNum);
    var formLayer = currentPageDiv.querySelector(".formLayer");
    var divText = document.createElement("div");
  
    // Set id attribute with page-#{pdf_page_number} format
    divText.setAttribute("class", "ff-editable-inner checkbox");
    
    divText.style.width = Config.form.checkbox.width*viewer.currentScaleValue + "px";
    divText.style.height = Config.form.checkbox.height*viewer.currentScaleValue + "px";
    divText.style.left = event.pointer.x - Config.form.checkbox.width*viewer.currentScaleValue/2 + "px";
    divText.style.top = event.pointer.y - Config.form.checkbox.height*viewer.currentScaleValue/2 + "px";
    divText.style.position =  "absolute";
    
    var input = document.createElement("input");
    input.className = "f-input"; 
    input.setAttribute("type","checkbox");
    input.setAttribute("kind","checkbox");
    input.setAttribute('name', "checkbox_"+ random_string(3) );
    input.setAttribute("annotation",viewer.current_annotation);
    var groundName = "checkbox_" + random_string(6);
    input.setAttribute('name', groundName);
    input.style.borderColor =  Config.form.checkbox.borderColor;
    input.style.borderWidth = Config.form.checkbox.borderWidth*viewer.currentScaleValue + 'px';
    // defineRadioButtonEvent(groundName);
    divText.appendChild(input);
    divText.setAttribute('name','checkbox');
    divText.id = random_string(10);
    divText.setAttribute('id-object',random_string(10));
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,divText));
    formLayer.appendChild(divText);
    $(".checkbox").resizable({handles: "all"});
    $(".checkbox").draggable();
    addRule(`input[name="${groundName}"]:before`,`background-color: ${Config.form.checkbox.color} !important`);  
    addRule(`input[name="${groundName}"]:after`,`background-color: ${Config.form.checkbox.color} !important`);
    formLayer.style.pointerEvents = "auto";
    $(".with-tooltip").css("background-color","white");
   $('.annotation-buttons .annotation-popup a').removeClass("active");
   }
   function startAddingDropdown(event,viewer,canvas){
    var currentPageDiv = document.getElementById("page-"+ canvas.pageNum);
    var formLayer = currentPageDiv.querySelector(".formLayer");
    var divText = document.createElement("div");
  
    // Set id attribute with page-#{pdf_page_number} format
    divText.setAttribute("class", "ff-editable-inner select_tag");
    
    divText.style.width = Config.form.dropdown.width*viewer.currentScaleValue  + "px";
    divText.style.height = Config.form.dropdown.height*viewer.currentScaleValue  + "px";
    divText.style.left = event.pointer.x - Config.form.dropdown.width/2 + "px";
    divText.style.top = event.pointer.y - Config.form.dropdown.height/2 + "px";
    divText.style.position =  "absolute";
    
    var input = document.createElement("select");
    input.className = "f-input ff-select-tag"; 
    input.setAttribute("kind","dropdown");
    input.setAttribute("annotation",viewer.current_annotation);
    input.setAttribute('name', "dropdown_"+ random_string(3) );
    input.append(new Option("Option 1"));
    input.append(new Option("Option 2"));
    input.append(new Option("Option 3"));
    // var groundName = "rad_" + random_string(6);
    // input.setAttribute('name', groundName);
    input.style.borderColor =   Config.form.dropdown.borderColor; //"rgb(251, 192, 45)";
    input.style.color =   Config.form.dropdown.color; //"rgb(251, 192, 45)";
    input.style.borderWidth = Config.form.dropdown.borderWidth*viewer.currentScaleValue + 'px';
    // defineRadioButtonEvent(groundName);
    divText.appendChild(input);
    divText.setAttribute('name','dropdown');
    divText.id = random_string(10);
    divText.setAttribute('id-object',random_string(10));
    viewer.addHistory(new PdfHistory(canvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_HTML,TYPE_HISTORY.ADD,null,divText));
    formLayer.appendChild(divText);
    shrinkToFill(input,16);
    $(".select_tag").resizable({handles: "all",
    resize: function( event, ui ) {
      var item = this.querySelector('.f-input');
      shrinkToFill(item,16);
    }
  
    });
    $(".select_tag").draggable({
      drag: function( event, ui ) {
        hideTools();
      }
    });
    
    formLayer.style.pointerEvents = "auto";
    $(".with-tooltip").css("background-color","white");
   $('.annotation-buttons .annotation-popup a').removeClass("active");
   }

   function startAddingStamp(event,fcanvas,viewer){    
    if(!viewer.image_selected) return;  
    var image,scale_absolute,real_height,real_width;   
    const myImage = document.createElement("canvas");
    myImage.width = viewer.image_selected.width * viewer.currentScaleValue;
    myImage.height = viewer.image_selected.height* viewer.currentScaleValue;
    const ctx = myImage.getContext("2d");
    ctx.drawImage(viewer.image_selected, 0, 0,myImage.width,myImage.height);
    
    var image,scale_absolute,real_height,real_width;
    var width_fix = Config.stamp.width;
    scale_absolute = width_fix/viewer.image_selected.width;      
      if(!viewer.image_selected.className.includes('canvas_signal')){
        scale_absolute = viewer.currentScaleValue/3;
      }

      real_height = viewer.image_selected.height*scale_absolute;
      real_width = viewer.image_selected.width*scale_absolute;
      image = new fabric.Image(myImage, {
        left: event.pointer.x - real_width/2, 
        top: event.pointer.y   - real_height/2 ,
        name: 'stamp',
        annotation : viewer.current_annotation,
        id : random_string(10)
      });
      image.set({
        scaleY: scale_absolute ,
        scaleX: scale_absolute 
      });
    fcanvas.add(image);
    fcanvas.setActiveObject(image);
    fcanvas.renderAll();
    viewer.addHistory(new PdfHistory(fcanvas.pageNum,viewer.current_annotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,image,null));
    PDFSaveAnnotation.saveElement(viewer,image);
   }

   function startAddingMark(event,fcanvas,viewer){  
    var width_fix,height_fix,opacity,name;

    if(viewer.current_annotation==ANNOTATION.ADD_XMARK){
      width_fix = Config.form.xmark.width;
      height_fix = Config.form.xmark.height;
      opacity = Config.form.xmark.opacity;
      name = 'cross';
    }
    if(viewer.current_annotation==ANNOTATION.ADD_CHECKMARK){
      width_fix = Config.form.checkmark.width;
      height_fix = Config.form.checkmark.height;
      opacity = Config.form.checkmark.opacity;
      name = 'check';
    }
    if(viewer.current_annotation==ANNOTATION.ADD_DOT){
      width_fix = Config.form.dot.width;
      height_fix = Config.form.dot.height;
      opacity = Config.form.dot.opacity;
      name = 'circle';
    }
    const canvas = document.createElement("canvas");
    canvas.width = width_fix * viewer.currentScaleValue;
    canvas.height = height_fix* viewer.currentScaleValue;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(viewer.image_selected, 0, 0,canvas.width,canvas.height);
    
    var src = canvas.toDataURL('image/png');
    var properties = 
      {
        left: event.pointer.x - width_fix/2, 
        top: event.pointer.y   - height_fix/2,
        name: name,
        annotation : viewer.current_annotation,
        id: random_string(10),
        imageSmoothing: true,
        src: src,
        scale: viewer.currentScaleValue,
        scaleX: 1,
        scaleY: 1,
        opacity: opacity,
        borderColor: '#0282e5',
        cornerColor: 'white',
        cornerStrokeColor: '#0282e5',
        cornerSize: 6,
        transparentCorners: false,
        page: viewer.currentPageNumber       
    }
    var img = factory.create.image(fcanvas,canvas,properties);  
    fcanvas.setActiveObject(img); 
    viewer.addHistory(new PdfHistory(viewer.currentPageNumber,viewer.current_annotation,TYPE_OBJECT.OBJECT_CANVAS,TYPE_HISTORY.ADD,img,null)); 
    PDFSaveAnnotation.saveElement(viewer,img);

  }



  function hideTools(){
    let children = document.querySelectorAll(".toolbars  .dropdown-toolbar");
    children.forEach(child => {
        child.style.display = 'none';
    })  
  }


function setSelectableAll(canvas,status){  
  var user_id = el('user_id').value;
  var currentUrl = window.location.pathname;
  if (currentUrl.includes('/pdf/view/') || currentUrl.includes(`/pdf/view_share/`)) {  
    canvas.getObjects().forEach(o => {
      if(o.disableSelect == true) {
         o.set({selectable: false})
        }
      else
      {
        if(parseInt(o.annotation) == ANNOTATION.ADD_STAMP || (parseInt(o.annotation) == ANNOTATION.ADD_TEXT && o.name == "date")) 
        {
          if(parseInt(o.user_id) == parseInt(user_id)){
            o.set({selectable: status})
          }
          else{
            o.set({selectable: false})
          }
        }
      else{
        o.set({selectable: false})
      }
      }
    })
  }
  else{
    canvas.getObjects().forEach(o => {
      if(o.disableSelect == true) {
         o.set({selectable: false})
        }
      else
      {
        if(parseInt(o.annotation) == ANNOTATION.ADD_STAMP || (parseInt(o.annotation) == ANNOTATION.ADD_TEXT && o.name == "date")) 
        {
          if(parseInt(o.user_id) == parseInt(user_id)){
            o.set({selectable: status})
          }
          else{
            o.set({selectable: false})
          }
        }
      else{
        o.set({selectable: status})
      }
      }
    })
  }
  
  // canvas.selection = status;
  
}

function customizeSetSelectionAll(canvas){  
  var user_id = el('user_id').value;
  canvas.getObjects().forEach(o => {
    if((parseInt(o.annotation) == ANNOTATION.ADD_STAMP || (parseInt(o.annotation) == ANNOTATION.ADD_TEXT && o.name == "date")) 
    && (parseInt(o.user_id) == parseInt(user_id))
     ) {
       o.set({selectable: true})
      }
    else
    { 
      o.set({selectable: false})
    }
   
  })
}

function setMovingStamp(object,canvas,viewer){
  if(object.type == "image"){ // stamp
    if(object.hasDate == true || object.hasDate == "true"){
      var date = stamp_helper.get_date_by_stamp(object);
      if(date){
        var x_relative = object.x_relative*viewer.currentScaleValue;
        var y_relative = object.y_relative*viewer.currentScaleValue;
        
        date.top = object.top - y_relative;
        date.left = object.left - x_relative;
        date.setCoords();        
        object.canvas.renderAll(); 
        // PDFSaveAnnotation.saveElement(viewer,date);       
      }      
    }    
  }
  return object;
}

function setMovingDate(object,canvas,viewer){
  if(object && object.name == "date"){ // date
    var stamp = stamp_helper.get_stamp_by_date(object);    
    if(stamp){
      var x_relative = (stamp.left - object.left)/viewer.currentScaleValue;
      var y_relative = (stamp.top - object.top)/viewer.currentScaleValue;    
  
      stamp.x_relative = x_relative;
      stamp.y_relative = y_relative;
  
      object.canvas.renderAll();
      stamp_helper.save_config_stamp(stamp,viewer);
    }   
    
  }  
  return object;
}

 

function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}