import { error } from "jquery"

export class IElement{
    constructor(options){  
        this.options = options      
    }
    click (var1, var2) {}
    remove (var1, var2) {}
    focusout(){}
    html(){throw error}
}