Calendar.locales['vi'] = {
	days: ["Chủ nhật", "Thứ 2", "Thứ 2", "Thứ 2", "Thứ 2", "Thứ 2", "Thứ 7"],
	daysShort: ["CN", "T.2", "T.3", "T.4", "T.5", "T.6", "T.7"],
	daysMin:  ["CN", "T.2", "T.3", "T.4", "T.5", "T.6", "T.7"],
	months: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
	monthsShort: ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6", "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"],
	weekShort: 'T',
	weekStart: 0
};
Calendar.locales['ja'] = {
	days: ["日曜", "月曜", "火曜", "水曜", "木曜", "金曜", "土曜"],
	daysShort: ["日", "月", "火", "水", "木", "金", "土"],
	daysMin: ["日", "月", "火", "水", "木", "金", "土"],
	months: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
	monthsShort: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
	weekShort: '週',
	weekStart:0
};
Calendar.locales['es'] = {
	days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
	daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
	daysMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
	months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
	monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
	weekShort: 'S',
	weekStart: 1
};