

$(document).on('turbo:load', function () {
    $("#btnRestoreLogicPdf").click(function() {
        var checkboxes = $("table tbody tr td .col_checkbox");
        var lstPdf = [];
        checkboxes.filter(':checked').map(function() {
            lstPdf.push($(this).closest('tr')[0].getAttribute("data-item"));
        })
        if(lstPdf.length==0) return;
        $.ajax({
            type: 'GET',
            url: "confirm_restore_multifile",
            dataType: 'script',
            data: {
                list_id_pdf: lstPdf
            },
            success: function(data, textStatus, jqXHR){
                
            },
            error:function(jqXHR, textStatus, errorThrown){
                console.log("AJAX Error: #{textStatus}")
            }
        });    
    });
})
