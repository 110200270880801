
$(document).on('ready turbo:load', function() {
  $("#group_currency_list").delegate(".currency_checkbox", "click", function () {
    $.ajax({
      type: "PATCH",
      url: "/project_manager/currencies/"+ $(this).parent().attr("data-id"),
      dataType: "json",
      data: {
        group_currency: {
          active: $(this).is(":checked")
        }
      },
      success: function(data, textStatus, jqXHR){
        let $input = $("#group_currency_list").find(`#group_currency_${JSON.parse(data.screen_property).id}`)
        if(data.errors == null){
          $input.next(".errors").remove()
        } else {
          $input.children("input.currency_checkbox").prop("checked", !JSON.parse(data.screen_property).active)
          $input.append(`<span class="errors">${data.errors}</span`)
        }
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error")
      }
    })
  });
})
