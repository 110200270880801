import { FolderEntity } from "./folder_entity";
var folder, is_finish_Upload = false,datas = [],count_file_done = 0,total = 0;
window.section_code;
$(document).on('turbo:load', function() { 
    $('body').delegate('#btnBorrowFolder', 'click', function (e){
        folder = null;
        window.section_code = random_string(16);
        count_file_done = 0;
        total = 0;
        $("#picker_folder").click()
    }) 
    let  fileupload = document.getElementById('folder_upload');
    if(fileupload){
        $(fileupload).fileupload({       
            dataType: 'json',   
            formData: {},
            add: function(e, data){   
                is_finish_Upload = false;
                datas.push(data);
                var parent_folder_id = getSelectedTreeFolder();
                if(folder == null){
                    folder = new FolderEntity(data.originalFiles);
                    startProcess(folder.name);
                    $.ajax({
                        method: 'post',
                        dataType: 'json',
                        url: window.origin + '/folders/create_folder',
                        data:{ 
                            
                            folder: JSON.stringify(folder),
                            parent_folder_id: parent_folder_id,
                            section_code: window.section_code
                        },
                        success: function(res) {
                            document.getElementById('btnStartUploadFolder').click();
                        }
                    });
                    
                }           
                
            },
            progressall: function(e, data) {
                console.log('progressall event')   
              
            },
            progress: function(e, data){
                console.log('progress event')  
            },
            done: function(e, data){
                console.log('done event');
                count_file_done ++;
                updateProcess(total);
                if(data.result.is_End == 'true'){
                    console.log('Upload Done');
                    endProcess();
                    window.location.reload();
                } 
                
            },
            processfail: function (e, data) {
                alert(data.files[data.index].name + "\n" + data.files[data.index].error);
            }
        
        });    
        $(fileupload).on('submit', function(e) {
            total = datas.length;
            $.each(datas, function(index, data) {     
                if(datas.length == index + 1){
                    console.log('Finish');
                    is_finish_Upload = true;
                    submit_data(data,true); 
                }         
                else{
                    submit_data(data); 
                }
                                        
            });
            e.preventDefault();
            datas = [];
        });   
    }
   
    function submit_data(data,is_End = false) {
        var upload_path = data.files[0].webkitRelativePath;
        data.formData = {upload_path: upload_path.replace(/[\n\r]/g,' '),section_code: window.section_code,is_End: is_End};     
        data.submit();
    }
})

function startProcess(foldername) {    
    var app = document.getElementById('app_process');
    app.classList.remove('d-none');
    var folder_name = app.querySelector("#folder_name");
    folder_name.innerHTML = foldername;
    var progress = app.querySelector("#current_progress");
    progress.innerHTML = `Starting...`;
}
function updateProcess(total) {    
    var app = document.getElementById('app_process');
   
    var progress = app.querySelector("#current_progress");
    progress.innerHTML = `(${count_file_done}/${total})`;

}
function endProcess() {    
    var app = document.getElementById('app_process');
    var icon_done = app.querySelector("#icon_done");
    icon_done.classList.remove('d-none');

}