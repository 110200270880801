$(document).on('turbo:load', function() {
	var tblBulletinSubject = document.getElementsByClassName("tbl_bulletin_subject");
	if (tblBulletinSubject.length > 0) {
		window.$('.tbl_bulletin_subject').DataTable({
			// 'destroy': true,
			// 'stateSave': true,
			'columnDefs': [
				{
					'targets': 0,
					'width': "4%",
					'checkboxes': {
						'selectRow': true
					}
				},
				{ "width": "36%", "targets": 1 },
        { "width": "20%", "targets": 2 },
        { "width": "10%", "targets": 3 },
        { "width": "20%", "targets": 4 },

			],
			'select': {
				'style': 'multi'
			},
			'order': [4, 'desc'],
			drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"},
				emptyTable: I18n.t('emptyTableMessage'),
				info: I18n.t('infoCustom'),
				infoEmpty: I18n.t('infoEmptyCustom'),
				lengthMenu : I18n.t('lengthMenuCustom'),
				search: I18n.t('searchCustom'),
				zeroRecords: I18n.t('zeroRecordsCustom')},
		});
	}


	$("#list_bulletin_category_default a").click(function(){
    // remove classname 'active' from all li who already has classname 'active'
    $("#list_bulletin_category_default a.bulletin_active").removeClass("bulletin_active");
    $("#list_bulletin_category a.bulletin_active").removeClass("bulletin_active"); 
    // adding classname 'active' to current click li 
    this.classList.add("bulletin_active"); 
	});

	$("#list_bulletin_category a").click(function(){
    // remove classname 'active' from all li who already has classname 'active'
    $("#list_bulletin_category a.bulletin_active").removeClass("bulletin_active");
    $("#list_bulletin_category_default a.bulletin_active").removeClass("bulletin_active");
    // adding classname 'active' to current click li 
    this.classList.add("bulletin_active"); 
	});

	// Remove bulletin
	$("#btnRemoveMultiBulletin").click(function() {
		var current_bulletin_category = $("#current_bulletin_category").val();
		var arr_bulletin = [];
		$.each($('.tbl_bulletin_subject tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_bulletin.push($(row).data('item-id'));
			} 
		});

		if (arr_bulletin.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "/confirm_remove_multi_bulletin",
				dataType: 'script',
				data: {
					list_bulletin: arr_bulletin,
					bulletin_category_id: current_bulletin_category
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}
	});

	// Move bulletin
	$("#btnMoveBulletin").click(function() {
		var current_bulletin_category = $("#current_bulletin_category").val();
		var bulletin_category_id = $("#lstBulletinCategory option:selected").val();
		var arr_bulletin = [];
		$.each($('.tbl_bulletin_subject tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_bulletin.push($(row).data('item-id'));
			} 
		});

		if (arr_bulletin.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "/move_bulletin",
				dataType: 'script',
				data: {
					list_bulletin: arr_bulletin,
					bulletin_category_selected: bulletin_category_id,
					bulletin_category_id: current_bulletin_category
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}

	});

});


