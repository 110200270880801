import { factory } from "../annotation/factory";
import { canvas_helper } from "../canvas/canvas_helper";
import { annotation_helper } from "../common/annotation_helper";
import { ANNOTATION } from "./annotation";

export const PDFSaveAnnotation = {             
    saveElement(viewer,element){
        document.body.style.cursor = "pointer";

        try {
            if(!isValid(element)){
                if(element.name.includes('point-'))
                {
                    document.body.style.cursor = "default";
                    return; 
                }
                
            }        
            var pageIndex = element.page - 1; 
            if(element.canvas){
                pageIndex = element.canvas.pageNum;
            }
            else
            {
                if(element.page == undefined)
                {
                    pageIndex = viewer.currentPageNumber - 1;
                    
                }
            }
            element.page = pageIndex + 1;      
            var page = viewer.file.pages[pageIndex];
            var rotate = (page.rotate + page.viewRotate) % 360;  
    
            var config = {
                scale: viewer.currentScaleValue,
                rotate: rotate
            }
            element.setCoords();
            annotation_helper.toObject(element,config);  
            updateLocalData(element,viewer,pageIndex);          
            ajaxSave(element,viewer) 
            
        } catch (error) {
            // alert(error);
            console.log(error);
            document.body.style.cursor = "default";
        }
        
    },
    saveHTMLElement(viewer,htmlElement){       
        return ajaxSaveHTMLElement(htmlElement,viewer)
    },
    delete_element(viewer,element){
        deleteLocalData(element,viewer);
        ajaxDelete(viewer,element);
    }  
}
function updateLocalData(element,viewer,pageIndex) {
     var   jsonString = JSON.stringify(element);
    // update data
    var object_annotation = JSON.parse(jsonString);
    var exist = false;
    for (var i = viewer.file.pages[pageIndex].annotation.length - 1; i >= 0; --i) {
        if (viewer.file.pages[pageIndex].annotation[i].id == object_annotation.id) {
            viewer.file.pages[pageIndex].annotation[i] = object_annotation;
            exist = true;
            break;
        }
    }
    if(!exist){
        canvas_helper.add_annotation_to_list(viewer.file.pages[pageIndex].annotation,object_annotation); 
    }
    
    // update thumnail   
    viewer.pdfThumbnail.update_annotation(object_annotation,pageIndex);
}
function deleteLocalData(element,viewer) {
   // update data
   var pageIndex = element.page - 1; 
   if(element.canvas){
       pageIndex = element.canvas.pageNum;       
       factory.defaultDelete(element.canvas,element);
   }
   else
   {
       if(element.page == undefined)
       {
           pageIndex = viewer.currentPageNumber - 1;           
       }
   }

   for (var i = viewer.file.pages[pageIndex].annotation.length - 1; i >= 0; --i) {
        if (viewer.file.pages[pageIndex].annotation[i].id == element.id){
            viewer.file.pages[pageIndex].annotation.splice(i,1);
            break;
        }
   }
   // update thumnail   
   viewer.pdfThumbnail.remove_annotation(element.id,pageIndex)   
}
function ajaxSave(element,viewer){    
    var success = false;
    var jsonString;
    jsonString = JSON.stringify(element);
    var objectDataSubmit = {
        id: viewer.file.id,
        page: element.page,
        json:jsonString,
        annotation_id: element.id
    };  

    $.ajax({
        type: "POST",
        url: window.location.origin + "/pdf/save_pdf_json",
        data: objectDataSubmit,   
        dataType: 'json',
        success: function (data, text) {
            //...
            success = true;
            document.body.style.cursor = "default";
        },
        error: function (request, status, error) {
            var mess = formatErrorMessage(request)
            console.log(mess);
            alert(mess);
            window.location.reload();
            // deleteLocalData(element,viewer);
            success = false;
            document.body.style.cursor = "default";
        }
        
    });
    return success;
}
function ajaxSaveHTMLElement(element,viewer){
    updateLocalData(element,viewer,parseInt(element.page)-1);
    var success = false;
    var jsonString;    
    jsonString = JSON.stringify(element)
    
    var objectDataSubmit = {id: viewer.file.id,page: element.page,json:jsonString,annotation_id: element.id};  

    $.ajax({
        type: "POST",
        url: window.location.origin + "/pdf/save_pdf_json",
        data: objectDataSubmit,    
        success: function(data)
        {            
            
            success = true;
        },
        error: function (request, status, error) {
            var mess = formatErrorMessage(request)
            console.log(mess);
            alert(mess);
            var elem = elem(element.id);
            if(elem){elem.remove()}
            deleteLocalData(element,viewer);
            success = false;
            document.body.style.cursor = "default";
        }
        
    });
    return success;
}

function ajaxDelete(viewer,element){
    var success = false;
    if(!isValid(element)) {return true;}
    if(!element.id) return true;
    if(element.annotation == ANNOTATION.ERASE) return true;

    var objectDataSubmit = {id: viewer.file.id,page: viewer.currentPageNumber,annotation_id: element.id};  
    $.ajax({
        type: "POST",
        url: window.location.origin + "/pdf/delete_element_annotation",
        data: objectDataSubmit,    
        success: function(data)
        {
            success = true;
        },
        error: function (request, status, error) {
            var mess = formatErrorMessage(request)
            console.log(mess);
            alert(mess);
            success = false;
            document.body.style.cursor = "default";
        }
    });
    return success;
}
function formatErrorMessage(jqXHR) {

    if (jqXHR.status === 0) {
        return ('Not connected.\nPlease verify your network connection.');
    } else if (jqXHR.status == 404) {
        return ('The requested page not found. [404]');
    } else if (jqXHR.status == 500) {
        return ('Internal Server Error [500].');   
    } else {
        return ('Uncaught Error.\n' + jqXHR.responseText);
    }
}

function vaidate_element(element){
    var valid = true;
    switch(element.type)
            {          
              case 'image':
               
                break;
              case 'i-text':
                // if(element.text.length == 0 || (element.text.length > 0 && element.text.trim().length == 0)){
                //     valid = false;                    
                // }           
                break;
             case "path":  
               
                break;
             case "rect":         
               
                break;
                 
             case 'line':   
                break;         
             case "ellipse":                       
                 break;  
             case "circle":    
               
               break;  
             case ANNOTATION.ADD_TEXTBOX:
               
                 break;
             case ANNOTATION.ADD_TEXT_AREA:
                 break;
             case ANNOTATION.ADD_RADIO:
                 break;
             case ANNOTATION.ADD_CHECKBOX:
                 break;
             case ANNOTATION.ADD_DROPDOWN:
                 break;
             case ANNOTATION.ERASE:
                break;       
             case ANNOTATION.DRAW_ARROW:
                
                break;
            }    
            return valid;

}
function isValid(element){
    if(!element) return false;
    if(!element.id) return false;
    if(vaidate_element(element) == false) return false;
    if(validate_width(element) == false) return false;
    if(element.annotation == ANNOTATION.ERASE) return false;
    if(element.display === 'false') return false;
    
    return true;
}
function validate_width(element){
    if(element.width == 0 || element.height == 0){
        return false;
    }
    else{
        return true;
    }
}
