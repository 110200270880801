import { annotation_helper } from '../../../libs/pdf-editor/common/annotation_helper';

export function definePropertiesPage(pages) {
  var rotate_element, page_rotate = 0;

  for (let index = 0; index < pages.length; index++) {
    const element = pages[index];
    page_rotate = 0
    rotate_element = el('rotation-page-' + (index + 1))
    if (rotate_element && rotate_element.value) {
      page_rotate = parseInt(rotate_element.value);
    }
    Object.defineProperties(element, {
      viewRotate: {
        value: page_rotate,
        writable: true
      }
    })

    var list_annos = annotation_helper.get_annotations_by_index(index);
    Object.defineProperties(element, {
      annotation: {
        value: list_annos,
        writable: true
      }
    })
  }

  $(el('data_json_annotation')).remove();
  $(el('data_page_rotation')).remove();
}
