$(document).on('turbo:load', function () {
  //for user 
  $("body").delegate('#add-decentralized-department', 'click', function () {
    pushleft();
  })
  $("body").delegate('#remove-decentralized-department', 'click', function () {
    pushright();
  });

  $("body").delegate('#btn_add_permission', 'click', function () {
    add_permision();
  })
  $("body").delegate('#btn_remove_permission', 'click', function () {
    remove_permission();
  });

  $("body").delegate('#lstFilter', 'change', function () {
   
    var selecteds = getSelectValues(this)[0];
    var id = selecteds[0];
    var type = selecteds[1];
    filter_changed(id,type);
  });

  $("body").delegate('.name_permission', 'click', function () {
    if(this.classList.contains("selected-row"))        {
      $(this).removeClass("selected-row")
      $(this.parentElement).removeClass("tr-selected")
    }
    else{  
      $(this).addClass("selected-row")
      $(this.parentElement).addClass("tr-selected")
    }
  });
})
function pushleft() {
    var rightSelectElement = document.getElementById('lst_items_right');
    var left_items = [], right_Items = [];
    var listSelectedRight = getSelectValues(rightSelectElement);
    if(listSelectedRight.length>0){
        for (let index = 0; index < listSelectedRight.length; index++) {
          right_Items.push(listSelectedRight[index]);            
        }
        
        var left_items = [];
        var tableleft = document.getElementById('lst_items_left');
        var rows = tableleft.querySelectorAll("tr");
        for (let index = 0; index < rows.length; index++) {
          var tr = rows[index];
          var id = tr.getAttribute("id");          
          var data_type = tr.getAttribute("data_type");
          var row_is_read_only = tr.getElementsByClassName("rdb-is-read-only")[0].checked;
          var permission = 1
          if(row_is_read_only)
          {
            permission = 0
          }
          left_items.push([id,data_type,permission]);
        }

        $.ajax({
            type: 'GET',
            url: "/commons/pushleft",
            dataType: 'script',
            data: {
              right_items: right_Items,
              left_items: left_items
            },
            success: function (data, textStatus, jqXHR) {      
            },
            error: function (jqXHR, textStatus, errorThrown) {
              console.log("AJAX Error: #{textStatus}")
            }
        });
    }
  }

  function pushright() {
    var selected = $(".selected-row");
    if (selected.length > 0) {
      if (selected.length > 0) {
        for (let td of selected) {
          var tr = td.parentNode
          $(tr).remove();
        }
      }   
    }
  }

  function filter_changed(value,type_data){
    $.ajax({
        type:"GET",
        url: window.origin + "/commons/filter_permistion",
        dataType:"script",
        data: {
          id: value,
          type:type_data
        },
        success: function(data, textStatus, jqXHR){
            document.getElementById('lst_items_right').setAttribute('data-type',value);
        },
        error:function(jqXHR, textStatus, errorThrown){
          console.log(jqXHR.responseText);
          alert(jqXHR.responseText);
        }
      })
  }

  function getSelectValues(select) {
    var result = [];
    var options = select && select.options;
    var opt;
  
    for (var i=0, iLen=options.length; i<iLen; i++) {
      opt = options[i];
  
      if (opt.selected) {
        result.push([opt.value, opt.getAttribute('type-data')]);
      }
    }
    return result;
  }

  function savePermision(url,id_parent,name){
    var selected = document.getElementById('lst_items_left');
    var current_department_item = []
    for (let tr of selected.getElementsByTagName("tr")) {
      var id = parseInt(tr.getAttribute('id'));
      var row_is_read_only = tr.getElementsByClassName("rdb-is-read-only")[0].checked;
      var canEdit = 1;
      if(row_is_read_only)
      {
        canEdit = 0;
      }
      var data_type = parseInt(tr.getAttribute('data_type'));
      var row = [id, canEdit,data_type]
      current_department_item.push(row);
    }  
    
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      data: {
        id: id_parent,
        name: name,
        children: current_department_item,
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    });
  }

  // type 2
  function add_permision() {
    var rightSelectElement = document.getElementById('lst_items_right');
    var left_items = [], right_Items = [];
    var listSelectedRight = getSelectValues(rightSelectElement);
    if(listSelectedRight.length>0){
        for (let index = 0; index < listSelectedRight.length; index++) {
          right_Items.push(listSelectedRight[index]);            
        }
        
        var left_items = [];
        var tableleft = document.getElementById('lst_items_left');
        var rows = tableleft.querySelectorAll("tr");
        for (let index = 0; index < rows.length; index++) {
          var tr = rows[index];
          var id = tr.getAttribute("id");          
          var data_type = tr.getAttribute("data_type");
          var permission_type = parseInt(tr.querySelector('input[name="permission_type_' + id + '"]:checked').value);         
          left_items.push([id,data_type,permission_type]);
        }

        $.ajax({
            type: 'GET',
            url: "commons/push_permision",
            dataType: 'script',
            data: {
              right_items: right_Items,
              left_items: left_items
            },
            success: function (data, textStatus, jqXHR) {      
            },
            error: function (jqXHR, textStatus, errorThrown) {
              console.log("AJAX Error: #{textStatus}")
            }
        });
    }
  }
  function remove_permission() {
    var selected = $(".selected-row");
    if (selected.length > 0) {
      if (selected.length > 0) {
        for (let td of selected) {
          var tr = td.parentNode
          $(tr).remove();
        }
      }   
    }
  }

  function save_permission_type_2(url,id_parent,name){    
    var list_permissions = [],id,row_permission_type,row,data_type,tr;
    var lst_items_left = document.getElementById('lst_items_left');

    for (tr of lst_items_left.getElementsByTagName("tr")) {
      id = parseInt(tr.getAttribute('id'));
      row_permission_type = parseInt(tr.querySelector('input[name="permission_type_' + id + '"]:checked').value);     
      data_type = parseInt(tr.getAttribute('data_type'));
      row = [id, row_permission_type,data_type]
      list_permissions.push(row);
    }
    var data = {
        id: id_parent,
        name: name,
        children: list_permissions,
      };

    if (url == "update_bulletin_permission_category") {
      var department_id = document.getElementById("bulletin_department_id").value;
      var default_value = document.getElementById("default").value;
      data = {
        id: id_parent,
        name: name,
        children: list_permissions,
        department_id: department_id,
        default: default_value,
      }
    }

    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'script',
      data: data,
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("AJAX Error: #{textStatus}")
      }
    });
  }
  export {savePermision,save_permission_type_2};
