import { IElement } from "./ielement";

export class HighLight extends IElement{
    constructor(options){
        super(options); 
    }
    html(){
        return createHTML(this.options);
    }
}

function createHTML(options){
    var div = document.createElement('div');
    div.id = options.id;
    div.className = options.className;  
    div.setAttribute('id-object',options.id);
    div.style.position = "absolute";      
    div.style.left = options.left + "px";  
    div.style.top = options.top + "px";  

    div.style.width = options.width + "px";    
    div.style.height = options.height + "px";    
    
    div.style.opacity = options.opacity;
    div.style.backgroundColor = options.color;
    div.setAttribute('name',options.name);
    div.setAttribute('annotation',options.annotation);
    if(!options.disable){
        div.addEventListener("click", show_tool_text_format);
        div.addEventListener("focusout", hide_tool_text_format);
    }
    else{
        div.style['pointer-events'] = 'none';

    }
    
    return div;
}

function show_tool_text_format(e){
    var element = e.target; 
    $("#text-anno-format").css("display","block");
    var currentPageDiv = element.parentElement.parentNode;
    var page = currentPageDiv.querySelector(".textLayer");
    var rect = page.getBoundingClientRect();
    var tool = el('text-anno-format');
    tool.style.display = 'flex';
    tool.style.left = e.clientX + "px";
    tool.style.top = e.clientY  +   "px";
    // tool.style.left = rect.left + parseInt(element.style.left ) - 70 + "px";
    // tool.style.top = rect.top + parseInt( element.style.top ) + element.offsetHeight +   "px";
    // document.getElementById("text-format-color").value = Convert.rgbToHex( element.style.backgroundColor);
   }
  
   function hide_tool_text_format(){
    $("#text-anno-format").css("display","none"); 
   }