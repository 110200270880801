import { ANNOTATION } from './annotation';
import {Config} from '../define/AppConfig';
import { Convert } from '../../../packs/convert';
import { factory } from '../annotation/factory';
export const element_helper = {    
    set_thick(element,thick){           
        var annotation = element.annotation;   
        switch(annotation)
        {
          case ANNOTATION.DRAW_LINE:
            element.set("strokeWidth", thick);    
            element.head1.set("radius", thick); 
            element.head2.set("radius", thick);  
            element.setMovedHead();          
            break;
          case ANNOTATION.DRAW_ARROW:
            element.set("strokeWidth", thick);    
            element.head1.set("radius", thick); 
            element.head2.set("radius", thick);  
            element.line1 && element.line1.set("strokeWidth", thick); 
            element.line2 && element.line2.set("strokeWidth", thick); 
            element.line3 && element.line3.set("strokeWidth", thick); 
            element.line4 && element.line4.set("strokeWidth", thick); 
            element.setMovedHead();          
            break;
          case ANNOTATION.PENCIL:       
          case ANNOTATION.FREE_DRAW:
            element.set("strokeWidth", thick);            
            break;
          case ANNOTATION.DRAW_ELLIPSE:            
          case ANNOTATION.DRAW_LINE:            
          case ANNOTATION.DRAW_RECTANGLE:
            element.set("strokeWidth", thick);          
            break;    
        }
      },
      set_dash(element,dash){        
        var annotation = element.annotation;
        switch(annotation)
        {
          case ANNOTATION.DRAW_ARROW:
            element.set("strokeDashArray", dash);
            element.line1 && element.line1.set("strokeDashArray", dash);
            element.line2 && element.line2.set("strokeDashArray", dash);
            if(element.number_of_head == 2){
              element.line3 && element.line3.set("strokeDashArray", dash);
              element.line4 && element.line4.set("strokeDashArray", dash);
            }
            
            break;
          default:
            element.set("strokeDashArray", dash);
            
            break;
        }
      },
      set_arrow(element,arrow){
        if(element.number_of_head == arrow) return;
        element.number_of_head = arrow;
        if(arrow == 1){
          if( element.line3 && element.line4 ){           
            factory.delete(element.canvas,element.line3);
            factory.delete(element.canvas,element.line4);            
            element.line3 = null;
            element.line4 = null;
            element.canvas.bringToFront(element.head1);
            element.canvas.bringToFront(element.head1);
          }
        }
        else{         
          element.canvas.add(element.line3);
          element.canvas.add(element.line4);
          element.canvas.bringToFront(element.head1);
          element.canvas.bringToFront(element.head2);      
          element.setMovedLine(); 
        }
      },
      set_color(element,color){      
        var annotation = element.annotation;
        if(annotation == null){
          annotation = parseInt( element.getAttribute('annotation'))
        }
        switch(annotation)
        {
          case ANNOTATION.ADD_TEXT:
            element.set("fill", color);
            break;
          case ANNOTATION.PENCIL:
            color = Convert.toRGB(color);
            color = `rgba(${color.r},${color.g},${color.b},${Config.pencil.opacity})`;
            element.set("stroke", color);
            break;
          case ANNOTATION.FREE_DRAW:
            color = Convert.toRGB(color);
            color = `rgba(${color.r},${color.g},${color.b},${Config.freedraw.opacity})`;
            element.set("stroke", color);
            break;
          case ANNOTATION.DRAW_ELLIPSE:            
          case ANNOTATION.DRAW_LINE:            
          case ANNOTATION.DRAW_RECTANGLE:
            element.set("stroke", color);
            break;   
          case ANNOTATION.DRAW_ARROW:
            element.set("stroke", color);
            element.line1.set("stroke", color);
            element.line2.set("stroke", color);
            if(element.number_of_head == 2){
              element.line3.set("stroke", color);
              element.line4.set("stroke", color);
            }
            break;        
          case ANNOTATION.ADD_XMARK:
            element.set("fill", color);
            break;
          case ANNOTATION.ADD_CHECKMARK:
            element.set("fill", color);
            break;
          case ANNOTATION.ADD_DOT:
            element.set("fill", color);
            break;   
          case ANNOTATION.ADD_TEXTBOX:
          case ANNOTATION.ADD_TEXT_AREA:
          case ANNOTATION.ADD_CHECKBOX:
          case ANNOTATION.ADD_RADIO:
          case ANNOTATION.ADD_DROPDOWN:
            if(element)
            // input.style.borderWidth = Config.form.textfield.borderWidth + "px";
            element.style.color = color;
            break;      
         
        }
      },      
      
      set_opacity(element,opacity){
        var  opacity =  parseFloat(opacity)/100;   
        var annotation = element.annotation;
        switch(annotation)
        {
          case ANNOTATION.DRAW_ARROW:
            element.set("opacity", opacity);
            element.line1.set("opacity", opacity);
            element.line2.set("opacity", opacity);
            if(element.number_of_head == 2){
              element.line3.set("opacity", opacity);
              element.line4.set("opacity", opacity);
            }
            break;
          default:
            element.set("opacity", opacity);
            break;
        }
      },
      set_width(data,annotation){
        alert("Not define")
         
        
      },
      set_height(data,annotation){
        alert("Not define")
    },
    set_font_family(element,value){
      element.set("fontFamily", value);
  },
  set_font_size(element,value){
    element.set("fontSize", value);
  },
  set_bold_text(element,value){    
    if(value){
      element.set("fontWeight", 'bold');
    }
    else
    {
      element.set("fontWeight", 'normal');
    }
  },
  set_italic_text(element,value){
    if(value){
      element.set("fontStyle", 'italic');
    }
    else
    {
      element.set("fontStyle", 'normal');
    }
  },
}

