$(document).on('turbo:load', function() {
  $("body").delegate('.plan_time_end_issue', 'click', function() {
    var start_date = $(".plan_time_start_issue").val();
    if (start_date == undefined) {
      start_date = $(this).data('start-time')
    }
    $("#" + this.id).datepicker({
      showMonthAfterYear: true,
      autoclose: true,
      language: get_locate(),
    }).focus().on('changeDate', function(e) {
      plan_time_end = this.value;
      $.ajax({
        type: "PUT",
        url: $(this).data('url'),
        dataType: "script",
        data: {
          plan_time_end: plan_time_end,
          id: $(this).data('id'),
          sub_issue: $(this).data('sub-issue'),
          issue: $(this).data('issue')
        },

        success: function (data, textStatus, jqXHR) {

        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        }
      })
    });
      $("#" + this.id).datepicker('setStartDate', start_date);
  });

  $("body").delegate('.icon_plan_time_end_issue', 'click', function() {
    $(".plan_time_end_issue").click();
  });

  $("body").delegate('#plan-start-issue', 'click', function() {
    var end_date = $(".plan_time_end_issue").val();
    $(".plan_time_start_issue").datepicker({
      showMonthAfterYear: true,
      autoclose: true,
      language: get_locate(),
    }).focus().on('changeDate', function(e) {
      plan_time_start = $(".plan_time_start_issue").val();
      $.ajax({
        type: "PUT",
        url: $(".due_issue").data('url'),
        dataType: "script",
        data: {
          plan_time_start: plan_time_start,
        },
        success: function (data, textStatus, jqXHR) {
        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        }
      })
    });
      $('.plan_time_start_issue').datepicker('setEndDate', end_date);
  });

  $("body").delegate('#due-issue', 'click', function() {
    var start_date = $(".plan_time_start_issue").val();
    $(".due_issue").datepicker({
      showMonthAfterYear: true,
      autoclose: true,
      language: get_locate(),
    }).focus().on('changeDate', function(e) {
      plan_time_end = $(".due_issue").val();
      var selectedDate = new Date(e.date);
      var today = new Date();
      if (selectedDate.toDateString() === today.toDateString()) {
        $(".due_issue").val('today');
      }
      $.ajax({
        type: "PUT",
        url: $(".due_issue").data('url'),
        dataType: "script",
        data: {
          plan_time_end: plan_time_end,
          id: $(".due_issue").data('id'),
          due: $(".due_issue").data('due')
        },

        success: function (data, textStatus, jqXHR) {

        },
        error: function (jqXHR, textStatus, errorThrown) {
          console.log(jqXHR.responseText)
        }
      })
    });
      $('.due_issue').datepicker('setStartDate', start_date);
  });

  $("body").delegate('#issue-duration', 'click', function() {
    $(".duration-input").addClass("d-none");
    var input = $(this).find("input").last();
    var currentValue = input.val();
    input.removeClass("d-none").val("").focus().val(currentValue);
  });

  $("body").delegate('.plan_hour_issue', 'click', function() {
    $(".duration-time-input").addClass("d-none");
    var input = $(this).find("input").last();
    var currentValue = input.val();
    input.removeClass("d-none").val("").focus().val(currentValue);
  });

  var ajaxInProgress = false;
  $("body").delegate('.duration_issue', 'blur keydown', function(event) {
    var duration = $(this).val()
    if (event.type === 'keydown' && event.keyCode !== 13) {
      return;
    }
    if (ajaxInProgress) {
      return;
    }
    ajaxInProgress = true;
    $.ajax({
      type: "PUT",
      url: $(".due_issue").data('url'),
      dataType: "script",
      data: {
        duration: duration
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      },
      complete: function() {
        // Reset the flag to false when the AJAX call is complete
        ajaxInProgress = false;
      }
    })
  });

  var ajaxInProgress_for_duration_time = false;
  $("body").delegate('.duration_time_issue', 'blur keydown', function(event) {
    var duration = $(this).val()
    if (event.type === 'keydown' && event.keyCode !== 13) {
      return;
    }
    if (ajaxInProgress_for_duration_time) {
      return;
    }
    ajaxInProgress_for_duration_time = true;
    $.ajax({
      type: "PUT",
      url: $(".due_issue").data('url'),
      dataType: "script",
      data: {
        duration_time: parseFloat(duration).toFixed(2)
      },
      success: function (data, textStatus, jqXHR) {
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log(jqXHR.responseText)
      },
      complete: function() {
        // Reset the flag to false when the AJAX call is complete
        ajaxInProgress_for_duration_time = false;
      }
    })
  });
});
