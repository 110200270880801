$(document).on('ready turbo:load', function() {
  $("#issue-filter-modal").delegate("#clear_filter", "click", function () {
    let option_list = $("#issue-filter-modal").find("input[type='checkbox']");
    $.each(option_list, function( index, col ) {
      $(col).prop("checked", false);
    });
    $("#task_title").val("");
  });

  $("#issue-filter-modal").delegate("#gantt_assigned_to", "click", function () {
    $('#select_member_filter').toggle();
  });
})
