// class create Container contain content of file
import { PDF } from '../../../libs/pdf-editor/define/PDF';
import { url_helper } from '../../../packs/supports/url_helper';
import { definePropertiesPage } from '../common/definePropertiesPage';
import { selected_page } from '../common/selected_page';
import { load_element_annotation_thumbnail } from '../common/load_annotation_thumbnail';

export class PDFArrangePage {
  file
  pdf

  constructor(url) {
    this.url = url;
    this.file = new PDF();
  }

  async open() {   
    this.file.url = this.url;

    if (el('slug')) {
      this.file.id = el('slug').value;
    }
    var result = await setDataByURL(this);
    if (!result) return;

    load(this);
    this.file.loading = false;
  }
}

function load(arrangePage) {
  var loadingTask = pdfjsLib.getDocument({
    data: arrangePage.file.bytes,
    cMapUrl: '/cmaps/',
    cMapPacked: true
  });

  loadingTask.promise.then(function (pdf) {
    arrangePage.pdf = pdf;
    createComponents(arrangePage);
  })
}

async function setDataByURL(arrangePage) {
  try {
    var bytes = await getPdfBytes(arrangePage.file.url);
    arrangePage.file.bytes = bytes;
    return true;
  }
  catch (error) {
    console.log(error);
    alert(error.message);
    return false;
  }
}

async function getPdfBytes(url) {
  var bytes =  await fetch_data(url); 
  return bytes;
}

async function getPages(pdf) {
  const pageNumbers = Array.from({ length: pdf.numPages }, (_, i) => i + 1);
  const promises = pageNumbers.map(pageNo => pdf.getPage(pageNo));
  return await Promise.all(promises);
}

async function createComponents(arrangePage) {
  var init_page = get_page_number_init();

  if (arrangePage.app_type == 0) {
    init_page = 1;
  }
  arrangePage.file.pages = await getPages(arrangePage.pdf);
  definePropertiesPage(arrangePage.file.pages);

  arrangePage.file.numPages = arrangePage.pdf.numPages;
  loadArrangePage(arrangePage);
}

function get_page_number_init() {
  var init_page = 1;
  var page = url_helper.get_value_params_from_URL('page');
  if (page) {
    init_page = parseInt(page);
  }
  return init_page;
}

async function loadArrangePage(arrangePage) {
  var page, div_page, div_canvas, canvas;
  var array_fabric_canvas = []
  var i;
  var parent = document.getElementById("lstPages");
  var addButton = document.getElementById("btnAddPage").cloneNode(true);
  var rowImage = document.createElement("ul");

  addButton.setAttribute("id", "add_page");
  addButton.setAttribute("class", "btn mx-2 my-auto order-last");

  rowImage.className = "row p-0 m-2 lstPage d-flex align-items-center";
  rowImage.addEventListener('drop', dropArrayPage);
  rowImage.addEventListener('dragover', allowDrop);

  for (i = 0; i < arrangePage.file.pages.length; i++) {
    page = arrangePage.file.pages[i];
    div_page = create_arrange_page(page);
    rowImage.appendChild(div_page);
    div_canvas = div_page.firstChild;
    canvas = renderArrangePage(page, arrangePage, div_canvas);
    array_fabric_canvas.push(canvas);
  }

  rowImage.appendChild(addButton);
  parent.appendChild(rowImage, parent.id);

  return array_fabric_canvas;
}

function create_arrange_page(page) {
  var liPage, divPage;
  var rotate = (page.rotate + page.viewRotate) % 360;
  var viewport = page.getViewport({ scale: 1, rotation: rotate });
  var scale_thum;
  var toolDiv = document.getElementsByClassName('btn-each-page')[0];
  var clonedToolDiv = toolDiv.cloneNode(true);

  if (Math.abs(rotate) % 180) {
    scale_thum = 120 / viewport.height;
  } else {
    scale_thum = 120 / viewport.width;
  }

  liPage = document.createElement("li");
  liPage.setAttribute("id", "thumbnail-frame-page" + (page._pageIndex));
  liPage.className="p-0 position-relative thumbnail-frame-page";
  liPage.style.width = viewport.width * scale_thum + "px";
  liPage.style.height = viewport.height * scale_thum + "px";

  divPage = document.createElement("div");
  divPage.setAttribute("id", "thumbnail-frame-" + (page._pageIndex));
  divPage.setAttribute("class", "thumbnail-frame droptarget p-0 d-flex justify-content-center align-items-center");
  divPage.style.width = viewport.width * scale_thum + 4 + "px";
  divPage.style.height = viewport.height * scale_thum + 4 + "px";
  liPage.setAttribute("data-page-number", page._pageIndex);
  liPage.setAttribute("aria-label", "Page " + (page.pageNumber));
  liPage.setAttribute('draggable', true);
  liPage.addEventListener('dragstart', drag);
  liPage.addEventListener('dragover', function (event) {
    if (event.target.parentElement.className.includes("droptarget")) {
      $(".droptarget").css("border", "white");
      event.target.parentElement.style.border = "2px dotted #369FF9";
    }
    if (event.target.parentElement.className.includes("pdf-thump")) {
      $(".droptarget").css("border", "white");
      event.target.parentElement.parentElement.style.border = "2px dotted #369FF9";
    }
  });

  liPage.appendChild(divPage);
  liPage.appendChild(clonedToolDiv);

  return liPage;
}
function renderArrangePage(page, arrangePage, div_page) {
  var rotate = (page.rotate + page.viewRotate) % 360;
  var viewport = page.getViewport({ scale: 1, rotation: rotate });
  var scale_thum;

  if (Math.abs(rotate) % 180) {
    scale_thum = 120 / viewport.height;
  } else {
    scale_thum = 120 / viewport.width;
  }
  viewport = page.getViewport({ scale: scale_thum, rotation: rotate });
  div_page.replaceChildren();
  div_page.setAttribute("scale", scale_thum);

  var canvas = create_canvas_page(viewport, arrangePage, div_page, page, scale_thum);
  var span = document.createElement("span");
  span.className = "thumbnailNumber";
  span.textContent = (page._pageIndex + 1);
  div_page.appendChild(span);

  return canvas;
}

function create_canvas_page(viewport, arrangePage, div_page, page, scale_thum) {
  var width = parseFloat(viewport.width);
  var height = parseFloat(viewport.height);
  var canvas = document.createElement("canvas");
  canvas.style.width = width + "px";
  canvas.style.height = height + "px";

  var currentPathFile = document.getElementById('url_file').value;
  var divcanvasWrapper = document.createElement("div");
  divcanvasWrapper.setAttribute("class", "canvasWrapper");
  divcanvasWrapper.id = "pdf_thum" + page._pageIndex;
  divcanvasWrapper.className = "pdf-thump box";
  divcanvasWrapper.setAttribute("selected", false);
  divcanvasWrapper.setAttribute("blank", false);
  divcanvasWrapper.setAttribute("data-path", currentPathFile);
  divcanvasWrapper.setAttribute("num", page._pageIndex);
  divcanvasWrapper.addEventListener('contextmenu', event => event.preventDefault());
  divcanvasWrapper.style.width = width + "px";
  divcanvasWrapper.style.height = height + "px";
  canvas.addEventListener('click', selected_page, false);

  divcanvasWrapper.appendChild(canvas);
  div_page.appendChild(divcanvasWrapper);
  canvas.height = height;
  canvas.width = width;

  var canvasannotation = createElementCanvasannotation(canvas);
  div_page.appendChild(canvasannotation);

  var annotationLayer = document.createElement("div");
  annotationLayer.setAttribute("class", "annotationLayer");
  div_page.appendChild(annotationLayer);
  const renderContext = {
    annotationMode: 2,
    background: 'rgba(0,0,0,0)',
    get canvasContext() {
      return canvas.getContext('2d');
    },
    viewport: viewport,
  };
  var renderTask = page.render(renderContext);
  var fabricCanvas = init_arrange_page(canvasannotation, arrangePage, page._pageIndex, div_page, scale_thum);
  renderTask.promise.then(function () {
    page.getTextContent().then(function (textContent) {
      const eventBus = new pdfjsViewer.EventBus();
      const pdfLinkService = new pdfjsViewer.PDFLinkService({
        eventBus,
      });

      page.getAnnotations().then(function (annotationsData) {
        pdfjsLib.AnnotationLayer.render({
          viewport: viewport.clone({ dontFlip: true }),
          div: annotationLayer,
          annotations: annotationsData,
          page: page,
          linkService: pdfLinkService,
          textLayerMode: 2
        });
      })
    });
  });
  return fabricCanvas;
}

function init_arrange_page(input_canvas, arrangePage, page_index, div_page, scale) {
  var canvas = new fabric.Canvas(input_canvas, {
    preserveObjectStacking: true
  });

  load_element_annotation_thumbnail(canvas, arrangePage, page_index, div_page, scale);
  canvas.forEachObject(object => {
    object.selectable = false;
    object.evented = false;
  });
  return canvas;
}

function createElementCanvasannotation(canvas) {
  var canvasannotation = canvas.cloneNode();
  canvasannotation.width = canvas.width;
  canvasannotation.height = canvas.height;
  return canvasannotation;
}

function drag(ev) {
  ev.dataTransfer.setData("text", ev.target.id);
}

function dropArrayPage(ev) {
  ev.preventDefault();
  var data = ev.dataTransfer.getData("text");
  var thisdiv = ev.target;
  if (thisdiv.tagName == 'CANVAS' && thisdiv.parentElement.className.includes("pdf-thump")) {
    thisdiv = thisdiv.closest('.thumbnail-frame');
  }
  if (thisdiv.className.includes("droptarget")) {
    $(document.getElementById('lstPages').querySelector('#' + data)).insertBefore(thisdiv.parentElement);
  }
  $(".droptarget").css("border", "white");
}

function allowDrop(ev) {
  ev.preventDefault();
}
