import { moveOption } from './schedule.js';
$(document).on('turbo:load', function() {
	//https://jqueryvalidation.org/documentation/
	window.$('#modal_department').on('shown.bs.modal', function () {
		$("#new_department").validate({
			//error place
			errorPlacement: function (error, element) {
			  error.insertAfter(element);
			},
		  //adding rule
			rules: {
			// username is required with max of 20 and min of 6
			"department[name]":{
			  required: true		  
			},		
			},
			// error messages
			messages: {
				"department[name]":{
				required: I18n.t("validation.department.name")				
			  }
			}
		  });
	})
	// move top/up/down/bottom department
		$("body").delegate('#btnMoveTopDepartments', 'click', function () {
			var count_department = 0;
					$.each($('#tblDepartmentList select :selected'), function (i, row) {
							count_department = count_department + 1
					});
					if (count_department > 0){
				var first_elem = $("#tblDepartmentList option:first");
				if(first_elem.is(':selected')) return true;
				while(!first_elem.prev().length){
					$('#tblDepartmentList select :selected').each(function (i, selected) {
						if (!$(this).prev().length) return false;
						$(this).insertBefore($(this).prev());
					});
					$('#tblDepartmentList select').focus();
				} 
			}
		});

		$("body").delegate('#btnMoveUpDepartments', 'click', function () {
			$('#tblDepartmentList select :selected').each(function (i, selected) {
				if (!$(this).prev().length) return false;
				$(this).insertBefore($(this).prev());
			});
			$('#tblDepartmentList select').focus();
		});

		$("body").delegate('#btnMoveDownDepartments', 'click', function () {
			$($('#tblDepartmentList select :selected').get().reverse()).each(function (i, selected) {
				if (!$(this).next().length) return false;
				$(this).insertAfter($(this).next());
			});
			$('#tblDepartmentList select').focus();
		});

		$("body").delegate('#btnMoveBottomDepartments', 'click', function () { 
			var count_department = 0;
					$.each($('#tblDepartmentList select :selected'), function (i, row) {
							count_department = count_department + 1
					});
					if (count_department > 0){
				var last_elem = $("#tblDepartmentList option:last");
				if(last_elem.is(':selected')) return true;
				while(!last_elem.next().length){
					$($('#tblDepartmentList select :selected').get().reverse()).each(function (i, selected) {
						if (!$(this).next().length) return false;
						$(this).insertAfter($(this).next());
					});
					$('#tblDepartmentList select').focus();
				}
			}
		});

	// update index departments
		$("body").delegate('#btnUpdateIndexDepartments', 'click', function () {
			var lst = [];
			$("#lstDepartmentChangeIndex option").each(function () {
				lst.push($(this).val());
			});
			$.ajax({
				type: 'GET',
				url: "/update_index_departments",
				dataType: 'script',
				data: {
					lst_departments: lst,
				},
				success: function (data, textStatus, jqXHR) {
						
				},
				error: function (jqXHR, textStatus, errorThrown) {
						console.log("AJAX Error")
				}
			});
		});

	// filter user by department in modal
		$("body").delegate('#listDepartment', 'change', function() {
	    var options_user = document.getElementById("user_department_selected");
	    var department_id = $("#listDepartment option:selected").val();
	    var data_type = "1";
	    data_type = $("#listDepartment option:selected").attr('data-type');
	    var lst_user_selected = [];
	    for (var i=0; i<options_user.length; i++) {
	      lst_user_selected.push(options_user[i].value);
	    }
	    $.ajax({
	      type: 'GET',
	      url: "/filter_list_user_department",
	      dataType: 'script',
	      data: {
	        department_id: department_id,
	        list_user: lst_user_selected,
	        data_type: data_type
	      },
	      success: function(data, textStatus, jqXHR){
	        
	      },
	      error:function(jqXHR, textStatus, errorThrown){
	        console.log("AJAX Error")
	      }
	    });
	  });

	// move top/up/down/bottom user department
		$("body").delegate('#btnMoveTopUserDepartment', 'click', function () {
			var count_user_department = 0;
			$.each($('#user_department_selected option:selected'), function (i, row) {
				count_user_department = count_user_department + 1
			});
			if (count_user_department > 0){
				var first_elem = $("#user_department_selected option:first");
				if(first_elem.is(':selected')) return true;
				while(!first_elem.prev().length){
					$('#user_department_selected option:selected').each(function (i, selected) {
						if (!$(this).prev().length) return false;
						$(this).insertBefore($(this).prev());
					});
					$('#user_department_selected').focus();
				}	
			}
		});

		$("body").delegate('#btnMoveUpUserDepartment', 'click', function () {
			$('#user_department_selected option:selected').each(function (i, selected) {
				if (!$(this).prev().length) return false;
				$(this).insertBefore($(this).prev());
			});
			$('#user_department_selected').focus();
		});
		
		$("body").delegate('#btnMoveDownUserDepartment', 'click', function () {
			$($('#user_department_selected option:selected').get().reverse()).each(function (i, selected) {
				if (!$(this).next().length) return false;
				$(this).insertAfter($(this).next());
			});
			$('#user_department_selected').focus();
		});
		
		$("body").delegate('#btnMoveBottomUserDepartment', 'click', function () {	
			var count_user_department = 0;
			$.each($('#user_department_selected option:selected'), function (i, row) {
				count_user_department = count_user_department + 1
			});
			if (count_user_department > 0){
				var last_elem = $("#user_department_selected option:last");
				if(last_elem.is(':selected')) return true;
				while(!last_elem.next().length){
					$($('#user_department_selected option:selected').get().reverse()).each(function (i, selected) {
						if (!$(this).next().length) return false;
						$(this).insertAfter($(this).next());
					});
					$('#user_department_selected').focus();
				}
			}
		});

	// add/remove from list user to list user selected modal user department
		$("body").delegate('#add_partner_user_department', 'click', function() {
			var lst_user_select = document.getElementById('list_user_department');
			var lst_user_selected = document.getElementById('user_department_selected');
			moveOption(lst_user_select, lst_user_selected);
		});

		$("body").delegate('#remove_partner_user_department', 'click', function() {
			var lst_user_select = document.getElementById('list_user_department');
			var lst_user_selected = document.getElementById('user_department_selected');
			moveOption(lst_user_selected, lst_user_select);
		});

	// event click button create/update record user department
		$("body").delegate('#btnSaveDepartment', 'click', function () {
			var options_user = document.getElementById("user_department_selected");
			for (var i=0; i<options_user.length; i++) {
				options_user[i].selected = "true";
			}
		});

  // event keyup Enter form department
  $("body").delegate('#modal_department', 'keyup', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btnSaveDepartment").click();
    }
  });

  $("body").delegate('#modal_change_index_departments', 'keyup', function (event) {
    if (event.key === "Enter") {
      event.preventDefault();
      $("#btnUpdateIndexDepartments").click();
    }
  });
	
});