import { Convert } from '../../../packs/convert';
import { link_enum } from '../edit/annotation';
var signalFabricCanvas;
export class SettingToolbar{  
    eventBus = null  
    constructor(options, eventBus){    
        this.eventBus = eventBus;
        events(this.eventBus);
    }   
}
function events(eventBus){
    addEvents(eventBus);
    loadEventShare(eventBus);
    eventCommon();
}
function eventCommon(){  
    $("body").delegate('.buttonToggle', 'click', function(ev) {
        toggle(this);
    })     
    
}
function addEvents(eventBus){    
      
  $(".toolbars .dropdown-toolbar").draggable({
    containment: "document", 
    stop: function( event, ui ) {
        event.target.setAttribute('moved', true)
    }       
   });   
   $(".view_tool_bar").draggable({
    containment: "parent",  
    stop: function( event, ui ) {
        event.target.setAttribute('moved', true)
    }       
   }); 
    $("body").delegate('.c_stamp_personal, .c_stamp_manager,.c_stamp_company,.canvas_signal,.img_autocad', 'click', function(ev) {        
        setToggle(ev.target);
    })
    
    $("body").delegate('#buttonid', 'click', function() {	
        document.getElementById('fileid').click();
     })
    
    //
   
    $("body").delegate('.annotation-added', 'click', function(ev) {
        var element = ev.target;       
        eventBus.dispatch("selectElement", {
            element: element
        }); 
    })
    $("body").delegate('.delete_element', 'click', function(ev) {
        eventBus.dispatch("delete_element", {
            source: this
        }); 
        hideTools();  
    })  
    
    $("body").delegate('#text-format-color', 'input', function(ev) {
        eventBus.dispatch("updateBackgroundColorDiv", {
            color: this.value
        });         
    })
    $("body").delegate('.textLayer,.hideTool', 'click', function() {
        hideTools();  
      })
      $("body").delegate('.formLayer', 'click', function(ev) {
        eventBus.dispatch("formLayerClick", {
            event: ev
       });         
    })
       
    $("body").delegate('#btnFullScreen', 'click', function(ev) {
        var isFullScreen = this.getAttribute('full-screen');
        var el = document.querySelector('.content-edit-form'); 
        if(isFullScreen == "true"){
            this.setAttribute("full-screen",false);
            closeFullscreen();
        }
        else{
           
            el.webkitRequestFullscreen();
            this.setAttribute("full-screen",true);
        }
       
    })   
    $("body").delegate('#btnFullScreenStamp', 'click', function(ev) {
      var isFullScreen = this.getAttribute('full-screen');
      var el = document.getElementById('pdfViewContent'); 
      if(isFullScreen == "true"){
          this.setAttribute("full-screen",false);
          closeFullscreen();
      }
      else{
         
          el.webkitRequestFullscreen();
          this.setAttribute("full-screen",true);
      }
     
    })   

    // close full screen esc, f11
    document.addEventListener('fullscreenchange', exitFullscreenHandler);
    document.addEventListener('webkitfullscreenchange', exitFullscreenHandler);
    function exitFullscreenHandler() {
      var isFullScreen = document.fullscreenElement || document.webkitFullscreenElement;
      var btnFullScreenStamp = document.getElementById('btnFullScreenStamp');
      var btnFullScreenPreview = document.getElementById('btnFullScreenPreview');
      var btnFullScreen = document.getElementById('btnFullScreen');
      
      if (!isFullScreen) {
        if (btnFullScreenStamp) {
          btnFullScreenStamp.setAttribute("full-screen", false);
        }
        if (btnFullScreenPreview) {
          btnFullScreenPreview.setAttribute("full-screen", "false");
        }
        if (btnFullScreen) {
          btnFullScreen.setAttribute("full-screen", "false");
        }
        closeFullscreen();
      }
    }
    
    $("body").delegate('#select_fieldName', 'change', function(ev) {
        eventBus.dispatch("changeFieldName", {
            source: null,
            fieldName: this.value,        
        }); 
      })
      $("body").delegate('#fieldOptions', 'change', function(ev) {
        eventBus.dispatch("updateOptionSelect", {
            source: null,
            strOption: this.value,        
        }); 
      }) 
      
      $("body").delegate('.item-annotation', 'click', function(ev) {
        var active = 'active';
        $('.list-annotation .item-annotation').removeClass(active);
        if(!this.classList.contains(active)){      
            this.classList.add(active);
        }  
        eventBus.dispatch("itemannotation_click", {
            element: this                   
        }); 
      }) 
      $("body").delegate('#show_pdf_edit_page_button', 'click', function () {
        if ($("#pdf_edit_show_edit_page").hasClass('d-none')){
            $(".pdf-edit-left-bar-item").removeClass('d-none')
            $("#pdf_edit_show_list_page").addClass('d-none')
        } else{
                $(".pdf-edit-left-bar-item").removeClass('d-none')
                $("#pdf_edit_show_edit_page").addClass('d-none')
            }
      });
      $("body").delegate('#hide_left', 'click', function () {
        var body_page = this.closest('.body-page');
        var hide_page = body_page.getAttribute('hide-page');
        if(hide_page == 'true'){
            body_page.setAttribute('hide-page',false);
            this.removeAttribute('selected');
        }
        else{
            this.setAttribute('selected',true);
            body_page.setAttribute('hide-page',true);
        }
      });
      $("body").delegate('#btnApplyArrangePages', 'click', function () {
        eventBus.dispatch("saveArrageFile", {
            source: this,
        }); 
      });  
      
      $("body").delegate('#btnSplitDownload', 'click', function () {
        eventBus.dispatch("downloadSplitFile", {
          source: this,
        });
      });

      $("body").delegate('#btnSaveMerge', 'click', function () {
        eventBus.dispatch("saveMergeFile", {
          source: this,
        });
      });

      $("body").delegate('#line-stroke-width,#rectangle-stroke-width,#ellipse-stroke-width,#free-draw-stroke-width', 'input', function(ev) {
        eventBus.dispatch("updateLineWidth", {
            width: this.value,
        });     
      })
      
     
      $("body").delegate('#line-opacity,#rectangle-opacity,#ellipse-opacity,#free-draw-opacity,#img-opacity', 'input', function(ev) {
        eventBus.dispatch("updateLineOpacity", {
            opacity: this.value,
        });   
      })
      $("body").delegate('.close_dialog', 'click', function(ev) {
        eventBus.dispatch("close_dialog", {
            source: this,
        });   
      })
    
    $("body").delegate('#btnSaveLink', 'click', function(ev) {
        var link_type = link_enum.url,url,email,phone,page;
        if(document.getElementById('rd-url').checked ==true){
        link_type = link_enum.url;
        }
        
        if(document.getElementById('rd-mail').checked ==true){
        link_type = link_enum.mail;
        }
        if(document.getElementById('rd-phone').checked ==true){
        link_type = link_enum.phone;
        }
        if(document.getElementById('rd-page').checked ==true){
        link_type = link_enum.page;
        }
        url = document.getElementById("targetURL").value;
        email = document.getElementById("targetEmailAddress").value;
        phone = document.getElementById("targetTel").value;
        page = document.getElementById("targetPage").value;
        
   

        var new_link = {link_type: link_type, url: url, email:email,phone:phone,page:page}
        eventBus.dispatch("savelink", {
            link: new_link,
        });
    })
    $("body").delegate('.button-delete-annotation', 'click', function(ev) {
        var parent = this.closest('.item-annotation');
        var objectId = parent.getAttribute('id');
        var typeObject = parent.getAttribute('type');
        var pageNum = parent.getAttribute('page-num');
        // parent.remove();
        eventBus.dispatch("delete_annotation", {
            pageNum: parseInt(pageNum),
            objectId: objectId,    
            typeObject : typeObject
        }); 
    })

   
    fabric.util.addListener(document.body, 'keydown', function(options) {
        if (options.repeat) {
          return;
        }
        var key = options.which || options.keyCode; // key detection
        if (key === 37) { // handle Left key
          // moveSelected(Direction.LEFT);
        } else if (key === 38) { // handle Up key
          // moveSelected(Direction.UP);
        } else if (key === 39) { // handle Right key
          // moveSelected(Direction.RIGHT);
        } else if (key === 40) { // handle Down key
          // moveSelected(Direction.DOWN);
        }
      else if (key === 46) { // handle Down key
        eventBus.dispatch("keyDelete", {
            element: options.target                 
        }); 
    }})
    // signal
    $("body").delegate('#bntSaveSignals', 'click',saveSignals) ;
    $("body").delegate('.signal-delete', 'click', deleteSignal) ;
    $("body").delegate('#bntClearSignal', 'click', clearSignal) ;
    $("body").delegate('#txtSignature', 'input', function(ev) {
        createListSinalPreview();
    }) 
    $("body").delegate('.signaturePreview', 'click', function(ev) {
        var active = 'selected';
        if(!this.classList.contains(active)){        
            $('.signaturePreview').removeClass(active);
            this.classList.add(active);
        }     
        createListSinalPreview();
    }) 
    $("body").delegate('#signatureTypeColorPicker .color-signal', 'click', function(ev) {
        var active = 'selected';
        if(!this.classList.contains(active)){        
            $('.color-signal').removeClass(active);
            this.classList.add(active);
        }     
        createListSinalPreview();
    }) 
    $("body").delegate('#signatureDrawColorPicker .color-signal-draw', 'click', function(ev) {
        var active = 'selected';
        if(!this.classList.contains(active)){        
            $('.color-signal-draw').removeClass(active);
            this.classList.add(active);
        }     
        var color = this.style.color;
        signalFabricCanvas.freeDrawingBrush.color = color;
    }) 

    window.$("#modal_add_signals").on("shown.bs.modal", function () {
        createListSinalPreview();
        defineDrawSignal();
    }) 
    $("body").delegate('.image_item', 'click', function() {	
        let children = document.querySelectorAll(".image_item");
        children.forEach(child => {
            child.classList.remove('selected')
        })  
        this.classList.add('selected');
        eventBus.dispatch("image_item", {
          element: this                   
        }); 
    })


  $("body").delegate('.f-input', 'click', function(ev) {
    eventBus.dispatch("showtoolElement", {
        source: this,        
        type: this.getAttribute("kind")
    }); 
  })
 

$("body").delegate('#fileid', 'change', function() {	
    const files = this.files;
    var div,button;
    let i,img;
    var imageContainer = document.getElementById("imageContainer");
    if (files.length>0) {
       for(i = 0;i<files.length;i++){
          div = document.createElement("div");
          div.className = 'img-box';

          img = document.createElement("img");
          img.setAttribute("alt",i)
          img.setAttribute("class","image_item p-1")
          img.src = window.URL.createObjectURL(files[i]);
          div.appendChild(img);

          button = document.createElement("a");
          button.setAttribute("class","remove_img_pdf")
          button.setAttribute("title","delete image")
          button.setAttribute("data-remote",true)
          button.setAttribute("href",'')
          button.innerHTML = 'X';
          button.addEventListener("mouseover", requestReloadImage);
          div.appendChild(button);
          imageContainer.appendChild(div);            
       }
    }

    $("#btnSubmitUploadImage").click();

 })


}


function requestReloadImage(event) {   
    if(event.target.getAttribute('href').length>0) return;
    $.ajax({
       type: 'GET',
       url: window.origin + "/pdf/reloadImage",
       dataType: 'script',           
       success: function(data, textStatus, jqXHR){
           
       },
       error:function(jqXHR, textStatus, errorThrown){
           console.log("AJAX Error: #{textStatus}")
       }
   });
 }
function toggle(btn){
    var active = 'selected';
    if(!btn.classList.contains(active)){      
      btn.classList.add(active);      
    }  
    else{
        btn.classList.remove(active);
    }
}


function setToggle(btn){
    var active = 'active';
    if(!btn.classList.contains(active)){      
      btn.classList.add(active);
      removeOtherButtonActive(btn);
    }  
}
  
function removeOtherButtonActive(btn){
    $('.annotation-buttons a').not(btn).removeClass("active");
    $('.annotation-buttons canvas').not(btn).removeClass("active");
    $('.annotation-buttons img').not(btn).removeClass("active");

    $('.col-stamp a').not(btn).removeClass("active");
    $('.col-stamp canvas').not(btn).removeClass("active");
    $('.col-stamp img').not(btn).removeClass("active");
    $('.img_autocad').not(btn).removeClass("active");
    
}

function createListSinalPreview(){
    var divPreview = document.getElementById('signaturePreviewWrap');
    var colorElementSelected = document.getElementById('signatureTypeColorPicker').querySelector('.selected');
    var color = colorElementSelected.style.color;
    var text = document.getElementById('txtSignature').value;
    var divPreview = document.getElementById('signaturePreviewWrap');
    divPreview.style.color = color;
    var listSinalPreview = divPreview.querySelectorAll('.signaturePreview');
    for (let index = 0; index < listSinalPreview.length; index++) {
      const element = listSinalPreview[index];
      element.innerHTML = text;    
    }  
  }
  function defineDrawSignal(){ 
    if(!signalFabricCanvas){
      var signatureDrawCanvas = document.getElementById('signatureDrawCanvas');
      signalFabricCanvas = new fabric.Canvas(signatureDrawCanvas); 
      signalFabricCanvas.stateful = true;    
      signalFabricCanvas.isDrawingMode = true;
      signalFabricCanvas.freeDrawingBrush = new fabric['PencilBrush'](signalFabricCanvas);
      signalFabricCanvas.freeDrawingBrush.width = 4;
      var colorElementSelected = document.getElementById('signatureDrawColorPicker').querySelector('.selected');
      var color = colorElementSelected.style.color;
      signalFabricCanvas.freeDrawingBrush.color = color;
    }
    else{
      signalFabricCanvas.clear();
    }
   
  }
  function saveSignals(){
    var tabName = $('#modal_add_signals .nav-tabs .active');
    var tabId = tabName[0].id;
    if(tabId == 'tabText'){
      saveTabText();
    }
    if(tabId == 'tabDraw'){
      saveTabDraw();
    } 
  }
  function saveTabText() {
    var divPreview = document.getElementById('signaturePreviewWrap');
    var colorElementSelected = document.getElementById('signatureTypeColorPicker').querySelector('.selected');
    var color = Convert.rgbToHex( colorElementSelected.style.color);
    var text = document.getElementById('txtSignature').value;
    var divPreview = document.getElementById('signaturePreviewWrap');
    var font_family = divPreview.querySelector('.selected').style.fontFamily;
    var objectDataSubmit = {color: color,text:text,font_family:font_family};
    AjaxSubmit("/pdf/save_signal",'GET',objectDataSubmit);  
  }
  function saveTabDraw() {
    var dataURL = signalFabricCanvas.toDataURL(); 
    // Get our file
    // var file= dataURLtoBlob(dataURL);
    // Create new form data
    var fd = new FormData();
    // Append our Canvas image file to the form data
    fd.append("image", dataURL);
    // And send it
    // var objectDataSubmit = {data: fd};
    // AjaxSubmit("/pdf/upload_signal",'POST',objectDataSubmit);  
    $.ajax({
       url:window.location.origin + "/pdf/upload_signal",
       type: "POST",
       data: fd,
       dataType: 'script',
       processData: false,
       contentType: false,
       success: function(data, textStatus, jqXHR){
        console.log("AJAX success!")
      },
      error:function(jqXHR, textStatus, errorThrown){
          console.log("AJAX Error: #{textStatus}")
      }
    });
  }
  function deleteSignal(){
    var id = this.closest(".row-signal-element").querySelector('.signal_ID').value;
    var objectDataSubmit = {id: id};
    AjaxSubmit("/pdf/deleteSignal",'GET',objectDataSubmit);  
  }
  function clearSignal() {
    var m = confirm("Do you want to clear?");
    if (m) {
      signalFabricCanvas.clear();
    }
  }
  
  function dataURLtoBlob(dataURL) {
    // Decode the dataURL
    var binary = atob(dataURL.split(',')[1]);
    // Create 8-bit unsigned array
    var array = [];
    for(var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    // Return our Blob object
    return new Blob([new Uint8Array(array)], {type: 'image/png'});
  }

  function hideTools(){
    let children = document.querySelectorAll(".toolbars  .dropdown-toolbar");
    children.forEach(child => {
        child.style.display = 'none';
    })  
  }
  

  function loadEventShare(eventBus) {
    $("#btnFinishPdf").click(function(){
      $("#btnFinishPdf").prop('disabled', true);

      eventBus.dispatch("saveComplete", {
        source: this             
      }); 
    });   
    $("body").delegate('#btnSharePdf', 'click', function() {
      eventBus.dispatch("show_modal_share", {
        source: this,                
      });      
    });
    $("body").delegate('#btn_pdf_reject', 'click', function() {
      eventBus.dispatch("show_modal_reject", {
        id: this.getAttribute("data-id"),                
      });      
    });

    
    $("body").delegate('#btn_pdf_final', 'click', function() {
      eventBus.dispatch("show_modal_final", {
        source: this,                
      });      
    });

    // $('#lst_department').select2({dropdownParent: $("#modal_share_pdf")});
    // $("body").delegate('#lst_department', 'change', function() {
    // $('#lst_department').on('select2:select', function (e) {
    //   var department_id = $("#lst_department option:selected").val().split("_")[0];
    //   var data_type = $("#lst_department option:selected").attr('data-type');
    //   $.ajax({
    //     type: 'GET',
    //     url: "filter_users",
    //     dataType: 'script',
    //     data: {
    //       department_id: department_id,
    //       data_type: data_type
    //     },
    //     success: function(data, textStatus, jqXHR){   
    //       set_status_button_submit_share();
    //     },
    //     error:function(jqXHR, textStatus, errorThrown){
    //       console.log("AJAX Error: #{textStatus}")
    //     }
    //   });   
    // });


    // $("body").delegate('#user_shared', 'change', function() {
    //   set_status_button_submit_share();
    // });

    $("body").delegate('#user_setting_finish', 'change', function() {
      if ($("#user_setting_finish").is(":checked")) 
      {
        $('#lst_department').prop('disabled', 'disabled');
        $('#user_shared').prop('disabled', 'disabled');
        $('#user_setting_remember').prop('disabled', 'disabled');

        $('#label_department_id').prop('disabled', 'disabled');
        $('#label_user_shared_id').prop('disabled', 'disabled');
        $('#label_department_id').addClass('disabled');
        $('#label_user_shared_id').addClass('disabled');
        $('#comment').prop('disabled', 'disabled');
        document.getElementById("btn_share_submit").disabled = false;
      }
      else 
      {
        $('#lst_department').prop('disabled', false);
        $('#user_shared').prop('disabled', false);
        $('#user_setting_remember').prop('disabled', false);
        $('#label_department_id').removeClass('disabled');
        $('#label_user_shared_id').removeClass('disabled');
        $('#comment').prop('disabled', false);
        set_status_button_submit_share();
      }
    });


  }

  function set_status_button_submit_share(){
    var $option = $("#user_shared").find('option:selected');
    //Added with the EDIT
    var value = $option.val();//to get content of "value" attrib
    if(value === "0" )    {    
      document.getElementById("btn_share_submit").disabled = true;
    }     
    else{
      document.getElementById("btn_share_submit").disabled = false;
    }
  }

window.handle_select2_stamp_share = function(select2_departments, select2_users, modal_share_pdf) {
  $(select2_departments).select2({dropdownParent: $(modal_share_pdf)});
  $(select2_users).select2({dropdownParent: $(modal_share_pdf)});

  $(select2_departments).on('select2:select', function (e) {
    var data = e.params.data;
    var department_id = data.id.split("_")[0];
    var data_type = data.element.attributes[0].value;

    $.ajax({
      type: 'GET',
      url: "filter_users",
      dataType: 'script',
      data: {
        department_id: department_id,
        data_type: data_type
      },
      success: function(data, textStatus, jqXHR){   
        set_status_button_submit_share();
      },
      error:function(jqXHR, textStatus, errorThrown){
        console.log("AJAX Error: #{textStatus}")
      }
    });   
  });

  $(select2_users).on('select2:select', function (e) {
    set_status_button_submit_share();
  });
}