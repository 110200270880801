$(document).on('turbo:load', function() {
	var tblHoliday = document.getElementsByClassName("tbl_holiday");
	if (tblHoliday.length > 0) {
		window.$('.tbl_holiday').DataTable({
			'destroy': true,
			'stateSave': true,
			'columnDefs': [
				{
					'targets': 0,
					'width': "4%",
					'checkboxes': {
						'selectRow': true
					}
				},
				{ "width": "15%", "targets": 1 }
			],
			'select': {
				'style': 'multi'
			},
			'order': [1, 'asc'],
			drawCallback: function() {
				$(".dataTables_paginate > .pagination").addClass("pagination-rounded");
			},
			language:{paginate:{
				previous:"<i class='mdi mdi-chevron-left'>",
				next:"<i class='mdi mdi-chevron-right'>"},
				emptyTable: I18n.t('emptyTableMessage'),
				info: I18n.t('infoCustom'),
				infoEmpty: I18n.t('infoEmptyCustom'),
				lengthMenu : I18n.t('lengthMenuCustom'),
				search: I18n.t('searchCustom'),
				zeroRecords: I18n.t('zeroRecordsCustom'),
			}
		});
	}

	$("body").delegate('#icon_date_holiday, #date_holiday', 'click', function() {
		$("#date_holiday").datepicker({		
			language: get_locate(),      
			sideBySide: true,
			"setDate": new Date(),
			"autoclose": true		
		}).focus();
	});

	$("#btnEditHoliday").click(function() {
		var arr_holiday = [];
		$.each($('.tbl_holiday tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_holiday.push($(row).data('item-id'));
			} 
		});
		if (arr_holiday.length == 1) {
			$.ajax ({
				type: 'GET',
				url: "holidays/" + arr_holiday[0] + "/edit",
				dataType: 'script',
				data: {
					id: arr_holiday[0]
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}
	});


	$("#btnDeleteHoliday").click(function() {
		var arr_holiday = [];
		$.each($('.tbl_holiday tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_holiday.push($(row).data('item-id'));
			} 
		});

		if (arr_holiday.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "confirm_destroy_holiday",
				dataType: 'script',
				data: {
					id: arr_holiday
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});

	$("#file_holiday").change(function() {
     
    window.$("#loadMe").modal({
      backdrop: "static", //remove ability to close modal with click
      keyboard: false, //remove option to close with keyboard
      show: true //Display loader!
    });
    setTimeout(function() {
      $("#close_loading").click();
    }, 100000);

    var elem = document.getElementById('form_holiday') // or $('#myform')[0] with jQuery
    Rails.fire(elem, 'submit');
   });
});