import { ANNOTATION } from '../edit/annotation';
import {Config} from '../define/AppConfig';
import {toolBarHelper} from '../edit/toolbar_helper';
export const configHelper = {    
    saveConfig(){        
        var tool_type = parseInt(document.querySelector('.edit-button[selected=true]').getAttribute('type'));
        var config = getConfigByType(tool_type);
        var objectDataSubmit = {setting: config,tool_type: tool_type};
        AjaxSubmit("/pdf/save_config",'GET',objectDataSubmit);  
    },
    getConfigByAnnotation(annotation){
      return getConfigByAnnotation(annotation);
    },
    select_thick(data,annotation){
        var element = data.element;      
        var thick = parseInt(element.getAttribute('data-item'));
        switch(annotation)
        {
          case ANNOTATION.DRAW_ARROW:
            Config.shape.arrow.size = thick;
            break;
          case ANNOTATION.FREE_DRAW:
            Config.freedraw.strokeWidth = thick;
            break;
            case ANNOTATION.PENCIL:
            Config.pencil.strokeWidth = thick;
            break;
          case ANNOTATION.DRAW_LINE:
            Config.shape.line.strokeWidth = thick;
            break;
          case ANNOTATION.DRAW_RECTANGLE:
            Config.shape.rectangle.strokeWidth = thick;
            break;
          case ANNOTATION.DRAW_ELLIPSE:
            Config.shape.ellipse.strokeWidth = thick;
            break;
          case ANNOTATION.ADD_TEXTBOX:
            Config.form.textfield.borderWidth = thick;
            break;
          case ANNOTATION.ADD_TEXT_AREA:
            Config.form.textarea.borderWidth = thick;
            break;
          case ANNOTATION.ADD_RADIO:
            Config.form.radio.borderWidth = thick;
            break;
          case ANNOTATION.ADD_CHECKBOX:
            Config.form.checkbox.borderWidth = thick;
            break;
          case ANNOTATION.ADD_DROPDOWN:
            Config.form.dropdown.borderWidth = thick;
            break;
        }
       
        let children = element.parentElement.querySelectorAll(".thick_item");
        children.forEach(child => {
            child.classList.remove('selected')
        })  
        element.classList.add('selected');
      
    },
    select_dash(dash,annotation){
      
    
      switch(annotation)
      {
        case ANNOTATION.DRAW_ARROW:
          Config.shape.arrow.dash = dash;
          break;
        case ANNOTATION.FREE_DRAW:
          Config.freedraw.dash = dash;
          break;
          case ANNOTATION.PENCIL:
          Config.pencil.dash = dash;
          break;
        case ANNOTATION.DRAW_LINE:
          Config.shape.line.dash = dash;
          break;
        case ANNOTATION.DRAW_RECTANGLE:
          Config.shape.rectangle.dash = dash;
          break;
        case ANNOTATION.DRAW_ELLIPSE:
          Config.shape.ellipse.dash = dash;
          break;
        
      }      
      
    },
    set_selected_thick(element){
      let children = element.parentElement.querySelectorAll(".thick_item");
      children.forEach(child => {
          child.classList.remove('selected')
      })  
      element.classList.add('selected');      
    },
    set_selected_dash(element){
      let children = element.parentElement.querySelectorAll(".dash_item");
      children.forEach(child => {
          child.classList.remove('selected')
      })  
      element.classList.add('selected');      
      element.closest('.dash-style').querySelector("button").querySelector('img').setAttribute('src',element.querySelector('img').getAttribute('src'));      
    },
    set_selected_arrow(element){
      let children = element.parentElement.querySelectorAll(".arrow_item");
      children.forEach(child => {
          child.classList.remove('selected')
      })  
      element.classList.add('selected');      
      element.closest('.dropdownArrowStyle').querySelector(".toolbarButtonArrowStyle").querySelector('img').setAttribute('src',element.querySelector('img').getAttribute('src'));      
    },
    select_color(element,color){        
      let children = element.parentElement.querySelectorAll(".color_item");
      children.forEach(child => {
          child.classList.remove('selected')
      })  
      element.closest('.color-select').querySelector('.input-color-container').classList.remove('selected');
      element.classList.add('selected');
      var div_dropdown = element.closest('.color-container');
      div_dropdown.querySelector(".toolbarButton").style.backgroundColor = color;
    },      
    color_pick(element,color){        
      let children = element.closest('.color-select').querySelectorAll(".color_item");
      children.forEach(child => {
          child.classList.remove('selected')
      })  
      element.closest('.color-select').querySelector('.input-color-container').classList.add('selected');
      var div_dropdown = element.closest('.color-container');
      div_dropdown.querySelector(".toolbarButton").style.backgroundColor = color;
    },        
    set_config_color(element,color,annotation){
      var div_dropdown = element.closest('.color-container');
      var isBorder = div_dropdown.getAttribute("border-color");
      set_config_color(color,annotation,isBorder == "true");
    },
    select_opacity(data,annotation){
      var element = data.element;
      var  opacity =  parseFloat(element.value)/100;   
      switch(annotation)
      {
        case ANNOTATION.DRAW_LINE:
          Config.shape.line.opacity = opacity;
          break;
        case ANNOTATION.DRAW_RECTANGLE:
          Config.shape.rectangle.opacity = opacity;
          break;
        case ANNOTATION.DRAW_ELLIPSE:
          Config.shape.ellipse.opacity = opacity;
          break;
        case ANNOTATION.DRAW_LINE:
          Config.shape.line.opacity = opacity;
          break;
        case ANNOTATION.DRAW_RECTANGLE:
          Config.shape.line.opacity = opacity;
          break;
        case ANNOTATION.DRAW_ELLIPSE:
          Config.shape.line.opacity = opacity;
          break;
        case ANNOTATION.ADD_XMARK:
          Config.form.xmark.opacity = opacity;
          break;
        case ANNOTATION.ADD_CHECKMARK:
          Config.form.checkmark.opacity = opacity;
          break;
        case ANNOTATION.ADD_DOT:
          Config.form.dot.opacity = opacity;
          break;         
        
      }
    },
    select_width(data,annotation){
      var element = data.element;
      var  width =  parseInt(element.value);   
      switch(annotation)
      {          
        case ANNOTATION.ADD_XMARK:
          Config.form.xmark.width = width;
          break;
        case ANNOTATION.ADD_CHECKMARK:
          Config.form.checkmark.width = width;
          break;
        case ANNOTATION.ADD_DOT:
          Config.form.dot.width = width;
          break; 
        case ANNOTATION.ADD_DOT:
            Config.form.dot.width = width;
            break; 
        case ANNOTATION.ADD_TEXTBOX:
          Config.form.textfield.width = width;
          break;
        case ANNOTATION.ADD_TEXT_AREA:
          Config.form.textarea.width = width;
          break;
        case ANNOTATION.ADD_CHECKBOX:
          Config.form.checkbox.width = width;
          break; 
        case ANNOTATION.ADD_RADIO:
          Config.form.radio.width = width;
          break; 
        case ANNOTATION.ADD_DROPDOWN:
          Config.form.dropdown.width = width;
          break;          
        
      }
    },
    select_height(data,annotation){
      var element = data.element;
      var  height =  parseInt(element.value);   
      switch(annotation)
      {          
        case ANNOTATION.ADD_XMARK:
          Config.form.xmark.height = height;
          break;
        case ANNOTATION.ADD_CHECKMARK:
          Config.form.checkmark.height = height;
          break;
        case ANNOTATION.ADD_DOT:
          Config.form.dot.height = height;
          break; 
        case ANNOTATION.ADD_DOT:
            Config.form.dot.height = height;
            break; 
        case ANNOTATION.ADD_TEXTBOX:
          Config.form.textfield.height = height;
          break;
        case ANNOTATION.ADD_TEXT_AREA:
          Config.form.textarea.height = height;
          break;
        case ANNOTATION.ADD_CHECKBOX:
          Config.form.checkbox.height = height;
          break; 
        case ANNOTATION.ADD_RADIO:
          Config.form.radio.height = height;
          break; 
        case ANNOTATION.ADD_DROPDOWN:
          Config.form.dropdown.height = height;
          break;  
    }
  },
select_font_family(annotation,value){
    switch(annotation)
    {          
      case ANNOTATION.ADD_TEXT:
        Config.text.addtext.fontFamily = value;
        break;  
    }
},
select_font_size(annotation,value){
  switch(annotation)
  {          
    case ANNOTATION.ADD_TEXT:
      Config.text.addtext.fontSize = parseInt(value);
      break;  
  }
},
select_bold_text(annotation){
  var value = 0;
  switch(annotation)
  {          
    case ANNOTATION.ADD_TEXT:
      if(parseInt(Config.text.addtext.boldText) == 0)   {
        value = 1;
      }
      else{
        value = 0;
      }
      Config.text.addtext.boldText  = value;     
      break;  
  }  
  return value == 1;
},
select_italic_text(annotation){
  var value = 0;
  switch(annotation)
  {          
    case ANNOTATION.ADD_TEXT:
      if(parseInt(Config.text.addtext.italicText) == 0)   {
        value = 1;
      }
      else{
        value = 0;
      }
      Config.text.addtext.italicText  = value;     
      break;  
  }  
  return value == 1;
},
setSelected(element,status){
  setSelected(element,status);
}
}
function setSelected(element, status){
  if(status){
    element.classList.add('selected');
  }
  else{
    element.classList.remove('selected');
  }  
}
function set_config_color(color, annotation,is_border = true){
    switch(annotation)
    {
      case ANNOTATION.DRAW_ARROW:
        Config.shape.arrow.color = color;
        break;
      case ANNOTATION.FREE_DRAW:
        Config.freedraw.color = color;
        break;
        case ANNOTATION.PENCIL:
        Config.pencil.color = color;
        break;
      case ANNOTATION.DRAW_LINE:
        Config.shape.line.backGroundColor = color;
        break;
      case ANNOTATION.DRAW_RECTANGLE:
        Config.shape.rectangle.stroke = color;
        break;
      case ANNOTATION.DRAW_ELLIPSE:
        Config.shape.ellipse.stroke = color;
        break;
      case ANNOTATION.ADD_TEXT:
        Config.text.addtext.fill = color;
        break;
      case ANNOTATION.ADD_CHECKMARK:
        Config.form.checkmark.color = color;        
        break;
      case ANNOTATION.ADD_XMARK:
        Config.form.xmark.color = color;        
        break;
      case ANNOTATION.ADD_DOT:
        Config.form.dot.color = color;        
        break;
      case ANNOTATION.TEXT_HIGHLIGHT:
        Config.text.highlight.color = color;
        break;
      case ANNOTATION.TEXT_STROKEOUT:
        Config.text.strokeout.color = color;
        break;
      case ANNOTATION.TEXT_UNDERLINE:
        Config.text.underline.color = color;
        break;
      case ANNOTATION.ADD_TEXTBOX:
        if(is_border){
          Config.form.textfield.borderColor = color;
        }
        else{
          Config.form.textfield.color = color;
        }        
        break;
      case ANNOTATION.ADD_TEXT_AREA:
        if(is_border){
          Config.form.textarea.borderColor = color;
        }
        else{
          Config.form.textarea.color = color;
        }
        break;
      case ANNOTATION.ADD_RADIO:
        if(is_border){
          Config.form.radio.borderColor = color;
        }
        else{
          Config.form.radio.color = color;
        }
        break;
      case ANNOTATION.ADD_CHECKBOX:
        if(is_border){
          Config.form.checkbox.borderColor = color;
        }
        else{
          Config.form.checkbox.color = color;
        }
        break;
      case ANNOTATION.ADD_DROPDOWN:
        if(is_border){
          Config.form.dropdown.borderColor = color;
        }
        else{
          Config.form.dropdown.color = color;
        }
        break;
    }
    toolBarHelper.updateMenuButton(annotation);
  }

  // "select_text" => 1,
// "pan" => 2,

// "line" => 10,
// "arrow" => 11,
// "ellipse" => 12,
// "rectangle" => 13,
// "xsign" => 14,
// "vsign" => 15,
// "dot" => 16,
// "image" => 17,
// "stamp" => 18,
// "link" => 19,
// "text" => 20,
// "highlight" => 21,
// "strikeout" => 22,
// "underline" => 23,

// "textbox" => 30,
// "area" => 31,
// "radio" => 32,
// "checkbox" => 33,
// "dropdown" => 34, 

// "brush" => 40,
// "highlighter" => 41,
// "erase" => 42,
// "screenshot" => 51
function getConfigByType(tool_type){
    var setttings = null;
    switch(tool_type){
        case 10:
            setttings = Config.shape.line;
            break;
        case 11:
            setttings = Config.shape.arrow;
            break;
        case 12:
            setttings = Config.shape.ellipse;
            break;
        case 13:
            setttings = Config.shape.rectangle;
            break;
        case 14:
            setttings = Config.form.xmark;
            break;
        case 15:
            setttings = Config.form.checkmark;
            break;
        case 16:
            setttings = Config.form.dot;
            break;
        case 17:
            // image not supported
            break;
        case 18:
            setttings = Config.stamp;
            break;
        case 19:
            setttings = Config.link;
            break;
        case 20:
            setttings = Config.text.addtext;
            break;
        case 21:
            setttings = Config.text.highlight;
            break;
        case 22:
            setttings = Config.text.strokeout;
            break;
        case 23:
            setttings = Config.text.underline;
            break;
        case 30:
            setttings = Config.form.textfield;
            break;
        case 31:
            setttings = Config.form.textarea;
            break;
        case 32:
            setttings = Config.form.radio;
            break;
        case 33:
            setttings = Config.form.checkbox;
            break;
        case 34:
            setttings = Config.form.dropdown;
            break;
        case 40:
            setttings = Config.freedraw;
            break;
        case 41:
            setttings = Config.pencil;
            break;
        case 42:
            // not suppport erase
            break;
            
            
    }
    return setttings;
  }
  function getConfigByAnnotation(annotation){
    var setttings = null;
    switch(annotation){
        case ANNOTATION.DRAW_LINE:
            setttings = Config.shape.line;
            break;
        case ANNOTATION.DRAW_ARROW:
            setttings = Config.shape.arrow;
            break;
        case ANNOTATION.DRAW_ELLIPSE:
            setttings = Config.shape.ellipse;
            break;
        case ANNOTATION.DRAW_RECTANGLE:
            setttings = Config.shape.rectangle;
            break;
        case ANNOTATION.ADD_XMARK:
            setttings = Config.form.xmark;
            break;
        case ANNOTATION.ADD_CHECKMARK:
            setttings = Config.form.checkmark;
            break;
        case ANNOTATION.ADD_DOT:
            setttings = Config.form.dot;
            break;
        case ANNOTATION.ADD_IMAGE:
            // image not supported
            break;
        case ANNOTATION.ADD_STAMP:
            setttings = Config.stamp;
            break;
        case ANNOTATION.ADD_LINK:
            setttings = Config.link;
            break;
        case ANNOTATION.ADD_TEXT:
            setttings = Config.text.addtext;
            break;
        case ANNOTATION.TEXT_HIGHLIGHT:
            setttings = Config.text.highlight;
            break;
        case ANNOTATION.TEXT_STROKEOUT:
            setttings = Config.text.strokeout;
            break;
        case ANNOTATION.TEXT_UNDERLINE:
            setttings = Config.text.underline;
            break;
        case ANNOTATION.ADD_TEXTBOX:
            setttings = Config.form.textfield;
            break;
        case ANNOTATION.ADD_TEXT_AREA:
            setttings = Config.form.textarea;
            break;
        case ANNOTATION.ADD_RADIO:
            setttings = Config.form.radio;
            break;
        case ANNOTATION.ADD_CHECKBOX:
            setttings = Config.form.checkbox;
            break;
        case ANNOTATION.ADD_DROPDOWN:
            setttings = Config.form.dropdown;
            break;
        case ANNOTATION.FREE_DRAW:
            setttings = Config.freedraw;
            break;
        case ANNOTATION.PENCIL:
            setttings = Config.pencil;
            break;
        case ANNOTATION.ERASE:
            // not suppport erase
            break;
            
            
    }
    return setttings;
  }