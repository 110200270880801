$(document).on('turbo:load', function() {
	formatDatatableProcess();

	$("#btnEditProcess").click(function() {
		var arr_process = [];
		$.each($('.tbl_process tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_process.push($(row).data('item-id'));
			} 
		});
		if (arr_process.length == 1) {
			$.ajax ({
				type: 'GET',
				url: "processeds/" + arr_process[0] + "/edit",
				dataType: 'script',
				data: {
					id: arr_process[0]
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error")
				}
			});
		}
	});

	$("#btnDeleteProcess").click(function() {
		var arr_process = [];
		$.each($('.tbl_process tbody tr'), function (i, row) {
			if ($(row).find("input[class='dt-checkboxes']:checked").is(":checked")) {
				arr_process.push($(row).data('item-id'));
			} 
		});

		if (arr_process.length > 0) {
			$.ajax ({
				type: 'GET',
				url: "confirm_destroy_process",
				dataType: 'script',
				data: {
					id: arr_process
				},
				success: function(data, textStatus, jqXHR){
					// 
				},
				error:function(jqXHR, textStatus, errorThrown){
					console.log("AJAX Error");
				}
			});
		}
	});



});

window.formatDatatableProcess = function() {
  var tblProcess = document.getElementsByClassName("tbl_process");
  if (tblProcess.length > 0) {
    window.$('.tbl_process').dataTable({
      'stateSave': false,
      'destroy': true,
      // "lengthMenu": [5,10,15,20],
      'columnDefs': [
      {
        'targets': 0,
        'width': "4%",
        'checkboxes': {
          'selectRow': true
          }
      },
      { "width": "6%", "targets": 1 },
      { "width": "90%", "targets": 2 }                
      ],
      'select': {
      'style': 'multi'
      },
      'order': [1, 'asc'],
      drawCallback: function() {
        $(".dataTables_paginate > .pagination").addClass("pagination-rounded");
      },
      language:{paginate:{
      previous:"<i class='mdi mdi-chevron-left'>",
      next:"<i class='mdi mdi-chevron-right'>"}},
    });
  }
}