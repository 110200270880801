import { selected_page } from '../common/selected_page';

var maxPage = 0;
$(document).on('turbo:load', function () {
  $("body").delegate('#btnPushToRight', 'click', function () {
    pushDataToRightForMergeFile();
  });

  $("body").delegate('#btnReset', 'click', function () {
    var url_file = $('#url_file').val();
    window.openMergePDF(url_file);
  });
  
  $("body").delegate('#btnAddMoreFile', 'click', function () {
    previewTab();
  });
})

function pushDataToRightForMergeFile() {
  var form_image_input = document.getElementById("form_image_input");
  var listCanvasSelected = form_image_input.querySelectorAll(".page_selected");

  maxPage = document.getElementById('lstRightItems').querySelectorAll('.thumbnail-frame').length;
  pushToRightMergeFile(listCanvasSelected);
}

async function pushToRightMergeFile(listCanvasSelected) {
  for await (const c of listCanvasSelected) {
    createRightMergeFile(c);
    maxPage++;
  }

  var totalPage = document.getElementById('lstRightItems').querySelectorAll('.thumbnail-frame').length;
  createOption(totalPage);
}

function setAtrributeRightCanvas(backCanvas, path_file, numberOfPage) {
  backCanvas.className = "pdf-thump box";
  backCanvas.setAttribute("data-path", path_file);
  backCanvas.setAttribute("num", numberOfPage);
  backCanvas.addEventListener('contextmenu', event => event.preventDefault());
  return backCanvas;
}

function createRightMergeFile(canvasSelected) {
  var list_right = document.getElementById('lstRightItems').querySelector(".lstPage");
  var divCanvas = document.createElement('div');
  var backCanvas = document.createElement('canvas');

  var path_file = canvasSelected.closest('fieldset').getAttribute("data-path");
  var numberOfPage = canvasSelected.getAttribute("num");

  divCanvas.style.width = canvasSelected.width + "px";
  divCanvas.style.height = canvasSelected.height + "px";

  backCanvas.width = canvasSelected.width;
  backCanvas.height = canvasSelected.height;
  backCanvas.style.width = canvasSelected.width + "px";
  backCanvas.style.height = canvasSelected.height + "px";

  var backCtx = backCanvas.getContext('2d');

  divCanvas.appendChild(backCanvas);
  backCtx.drawImage(canvasSelected, 0, 0);
  var dv = createContainerPage(maxPage, divCanvas);
  setAtrributeRightCanvas(divCanvas, path_file, numberOfPage);
  divCanvas.addEventListener('click', selected_page, false);

  var position = $('#lstPosition').find(":selected").text();
  if (position == 'End') {
    list_right.appendChild(dv);
    dv.scrollIntoViewIfNeeded();
  } else {
    position = parseInt(position);
    let sp2 = document.getElementById("lstRightItems").querySelector('#thumbnail-frame-' + position);
    list_right.insertBefore(dv, sp2);
  }

}

function removeOptions(selectElement) {
  var i, L = selectElement.options.length - 1;
  for (i = L; i >= 0; i--) {
    selectElement.remove(i);
  }
}

function createOption(totalPage) {

  var select = document.getElementById('lstPosition');
  removeOptions(select);
  if (totalPage == 0) return;
  for (let index = 0; index <= totalPage; index++) {
    if (index == totalPage) {
      select.append(new Option('End'));
    }
    else {
      select.append(new Option(index));
    }
  }
  select.options[totalPage].setAttribute('selected', 'selected');
}

function createContainerPage(numPage, canvas) {
  var li = document.createElement("li");

  li.id = "thumbnail-frame-" + numPage;
  li.className = "thumbnail-frame d-flex btn-fit-content p-0 m-1 droptarget";
  li.setAttribute('draggable', true);
  li.addEventListener('dragstart', drag);
  li.addEventListener('dragover', function (event) {
    if (event.target.parentElement.className.includes("droptarget")) {
      $(".droptarget").css("border", "white");
      event.target.parentElement.style.border = "2px dotted #369FF9";
    }
    if (event.target.tagName == 'CANVAS' && event.target.parentElement.className.includes("pdf-thump")) {
      $(".droptarget").css("border", "white");
      event.target.parentElement.parentElement.style.border = "2px dotted #369FF9";
    }
  });
  var span = document.createElement("span");
  span.className = "thumbnailNumber";
  span.textContent = (numPage + 1);

  li.appendChild(canvas);
  li.appendChild(span);
  return li;
}

function drag(ev) {
  ev.dataTransfer.setData("text", ev.target.id);
}

function previewTab() {
  document.querySelector('.merge-process-step').style.display = 'none';
  document.querySelector('#btnSaveMerge').style.display = 'none';
  document.querySelector('#btnAcceptFiles').style.display = 'block';
  document.querySelector('.merge-borrow-step').style.display = 'block';
}
