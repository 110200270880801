import {PDFViewerApplication } from '../libs/pdf-editor/define/PDFViewerApplication';
$(document).on('turbo:load', function(){ 
   if (window.location.pathname.includes('/pdf/show/')){   
    var path_file = document.getElementById('path_file').value;
    Show_file_pdf(path_file); 
   }
})

async function Show_file_pdf(url){
    await open(url);	
}   

async function open(url){		
    var viewComponent = getViewComponents();
    PDFViewerApplication.set_app_type(0);
    await PDFViewerApplication.initialize(null,viewComponent);
    await PDFViewerApplication.open(url);
}

function getViewComponents(){

    var container = document.getElementById('pdf_show_form');
    var viewContainer = container.querySelector('#viewer');
    var thumnailContainer = container.querySelector('.page-thumnail');
    const simpleBar = new SimpleBar(container.querySelector('#pdf-viewer'));		
    var scroll = simpleBar.getScrollElement(); 
    return {
        toolbar:{
            firstpage:container.querySelector('#btnFirstPage'),
            endpage:container.querySelector('#btnEndPage'),
            previous: container.querySelector('#btnPrevPage'),				
            next: container.querySelector('#btnNextPage'),
            rotateleft: container.querySelector('#btnRotateLeft'),
            rotateright: container.querySelector('#btnRotateRight'),
            zoomIn: container.querySelector('#btnZoomIn'),
            zoomOut: container.querySelector('#btnZoomOut'),
            current_page: container.querySelector('#current_page_value'),
            total_page: container.querySelector('#total_page_value'),
            select_scale: container.querySelector('#scaleSelect')
        },
        edittoolbar:{          
            select_text: el('btnSelectPoint'),
            hand : el('btnHand'), 
        },
        propertiesToolbar:{  
        },
        viewContainer:viewContainer,
        scrollContent: scroll,
        pageScroll: container.querySelector('#pdf-viewer') ,
        thumnailContainer:thumnailContainer
    }
}